import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import { AccountService } from '../../common/services/account.service';
import { Account } from '../../common/interfaces/account';
import { DomSanitizer } from '@angular/platform-browser';
import { LoaderOverlayService } from '../../common/services/loader-overlay.service';
import {Subscription} from "rxjs";
import {environment} from "../../../environments/environment";

@Component({

  selector: 'app-dashboard-viewrepeatevent',
  templateUrl: './dashboard-viewrepeatevent.component.html',
  styles: [`
        :host {
            position: relative;
            display: block;
            overflow: hidden;
        }
        iframe {
            overflow: hidden;
            width: 100%;
            height: 100vh;
        }
    `],
  changeDetection: ChangeDetectionStrategy.Default
})

export class DashboardViewrepeateventComponent implements OnInit, OnDestroy {

  account: Account;

  returnurl = null;
  username = null;
  epassw = null;
  url = null;
  safe = false;
  eventid = null;

  private suba: Subscription;
  private subb: Subscription;

  constructor(private LoaderOverlayService: LoaderOverlayService, private route: ActivatedRoute, private accountService: AccountService, private domSanitizer: DomSanitizer) {
    this.url = '#';
    this.suba = this.accountService.cast.subscribe(data => {
      this.account = data;
      if(this.account.id !== null) {
        if(this.suba !== undefined) {
          this.suba.unsubscribe();
          this.subb = this.route.params.subscribe(routeParams => {
            this.eventid = routeParams.id;
            this.reloadiframe();
          });
        }
      }
    });
  }

  reloadiframe() {
    if(this.LoaderOverlayService.overlayRef == null) {

      setTimeout(() => {
        this.killoverlay();

      }, 3000);

      this.LoaderOverlayService.show();
    }

    this.returnurl = environment.service.trackithubapi + '/index.php?option=com_jevents&task=icalrepeat.detail&evid=' + this.eventid;
    this.epassw = this.account.current_profile.seckey;
    this.username = this.account.current_profile.username;
    this.returnurl = btoa(this.returnurl);
    this.url = this.domSanitizer.bypassSecurityTrustResourceUrl(environment.service.trackithubapi + '/index.php?user=' + this.username + '&epassw=' + this.epassw + '&redirect=' + this.returnurl);
    if (this.username !== null && this.username !== null && this.returnurl !== null) {
      this.safe = true;
    } else {
      this.safe = false;
    }
  }

  killoverlay(){
    if(this.LoaderOverlayService.overlayRef != null) {
      this.LoaderOverlayService.hide();
    }
  }

  ngOnDestroy() {
    this.LoaderOverlayService.overlayRef = null;
    if (this.suba !== undefined) {
      this.suba.unsubscribe();
    }
    if (this.subb !== undefined) {
      this.subb.unsubscribe();
    }
  }

  ngOnInit() {

  }

}
