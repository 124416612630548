import {AfterViewInit, ChangeDetectorRef, Component, Inject, Input, OnInit, ViewChild} from '@angular/core';
import { Account } from 'src/app/common/interfaces/account';
import {RosterElement} from "../../dashboard/dashboard-roster/roster-table.component";
import { MatPaginator, MatSort, MatTable, MatTableDataSource } from '@angular/material';
import {SelectionModel} from "@angular/cdk/collections";
import {environment} from "../../../environments/environment";
import {ProfileComponent} from "../profile.component";
import {MatDialog} from "@angular/material/dialog";
import {ModalService} from "../../common/services/modal.service";
import {Router} from "@angular/router";
import {ToastService} from "../../common/toast";
import {DomSanitizer} from "@angular/platform-browser";
import {HttpClient} from "@angular/common/http";
import {CalendarService} from "../../common/services/calendar.service";
import {AccountService} from "../../common/services/account.service";
import {BottomSheetService} from "../../common/services/bottom-sheet.service";
import {MAT_BOTTOM_SHEET_DATA} from "@angular/material/bottom-sheet";
import {ChatService} from "../../common/services/chat.service";
import {DashboardCreateGroupComponent} from "../../dashboard";


@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss']
})
export class GroupsComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatTable, {static: false}) table: MatTable<RosterElement>;


  @Input() account: Account;
  @Input() friends;
  @Input() groups;
  @Input() is_owner;

  env: any;
  dataSource = new MatTableDataSource();
  selection = new SelectionModel<RosterElement>(true, []);
  displayedColumns = [];
  siteUrl = environment.service.trackithubapi + '/';

  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any, public chatService: ChatService, public dialog: MatDialog, private modalService: ModalService, private router: Router, private toastService: ToastService, private sanitizer: DomSanitizer,  private http: HttpClient, private calendarService: CalendarService, private accountService: AccountService, private ref: ChangeDetectorRef, private bottomSheetService: BottomSheetService) {}

  ngOnInit() {

    this.env = environment;



    if(this.is_owner == 1){
      this.displayedColumns = ['name','chat'];
    } else {
      this.displayedColumns = ['name','chat'];
    }

    this.dataSource.data = this.groups;
    this.selection.clear();

  }


  refresh() {
    this.http.get<Account>(environment.service.trackithubapi + '/api/inside/loadprofile?profile=' + this.account.current_profile.profile_id).subscribe((profileData: any) => {
      this.friends = profileData.profile.friends;
      this.dataSource.data = this.friends;
      this.selection.clear();
      this.dataSource.sort = this.sort;
      //this.dataSource.paginator = this.paginator;
    });
  }


  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(<RosterElement>row));
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openProfile(group_id, profile_type) {
    if(this.env.service.profile == true && profile_type == 'group') {
      this.http.get<Account>(environment.service.trackithubapi + '/api/inside/loadgroup?clean_group=yes&group=' + group_id + '&profile_id=' + this.account.current_profile.profile_id).subscribe((profileData: any) => {
        this.bottomSheetService.openBottomSheetWithDataCustomClass(ProfileComponent, 'ProfileComponent',{
          'profileData': profileData,
          'type': profile_type
        }, 'custom-bottom-p' );
      });
    }

    if(this.env.service.profile == true && profile_type != 'group') {
      this.http.get<Account>(environment.service.trackithubapi + '/api/inside/loadprofile?clean_group=yes&profile=' + group_id).subscribe((profileData: any) => {
        this.bottomSheetService.openBottomSheetWithDataCustomClass(ProfileComponent, 'ProfileComponent',{
          'profileData': profileData,
          'type': profile_type
        }, 'custom-bottom-p' );

      });
    }

  }


  shareGroup(id){

  }


  joinGroup(id){

  }

  leaveGroup(id){

  }

  editGroup(id){
    for (let i = 0; i < this.groups.length; i++) {
      if(this.groups[i].name != 'Primary Club Group' && id == this.groups[i].id){
        this.bottomSheetService.openBottomSheetWithData(DashboardCreateGroupComponent, 'DashboardCreateGroupComponent',{'id': id, 'card': this.groups[i]});
      }
    }
  }

}
