import {ChangeDetectionStrategy, Component} from '@angular/core';
import { MatDialogRef} from '@angular/material/dialog';
@Component({
  selector: 'app-dashboard-purchase-support-modal',
  templateUrl: './dashboard-purchase-support-modal.component.html',
  styleUrls: ['./dashboard-purchase-support-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class DashboardPurchaseSupportModalComponent {
  constructor(public dialogRef: MatDialogRef<DashboardPurchaseSupportModalComponent>,) {
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}
