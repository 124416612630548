import {Component, Input} from '@angular/core';
import {KebabCasePipe} from "../../../common/pipes/kebab-case.pipe";

@Component({
  selector: 'app-form-custom-checkbox',
  templateUrl: './form-custom-checkbox.component.html',
  styleUrls: ['./form-custom-checkbox.component.scss']
})
export class FormCustomCheckboxComponent {
  @Input() label: string;
  @Input() id: string;



  ngOnInit() {
  }
}
