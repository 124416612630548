import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from "@angular/router";
import { MapResultsComponent } from './map-results.component';
import { MapSearchFilterComponent } from '../map-search-filter/map-search-filter.component';
import { MapComponent} from '../map/map.component';
import { HorizontalscrollModule } from "../horizontal-infinite-scroll/horizontalscroll.module";
import { MaterialModule } from "../../../material.module";
import {DynamicFormModule} from "../../dynamic-form/dynamic-form.module";
import {AgmCoreModule} from "@agm/core";



@NgModule({
  declarations: [
    MapResultsComponent,
    MapSearchFilterComponent,
    MapComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    HorizontalscrollModule,
    MaterialModule,
    DynamicFormModule,
    AgmCoreModule.forRoot({
      apiKey: `AIzaSyAfRESSHmts4lPb0h-f74y4spV0L5P1jFA`
    }),
  ],
    exports: [
        HorizontalscrollModule,
        MapResultsComponent,
        MapSearchFilterComponent
    ]
})
export class MapResultsModule { }
