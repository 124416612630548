import { Component, Inject, OnInit } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {FormControl, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-album-dialog',
  templateUrl: './album-dialog.component.html',
  styleUrls: ['./album-dialog.component.scss']
})
export class AlbumDialogComponent implements OnInit {

  securityPost: number;
  myForm: any;
  current_album: any;

  constructor(public dialogRef: MatDialogRef<AlbumDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: {}) {
      this.current_album = data;
   }

  ngOnInit() {
    this.myForm = new FormGroup({
      albumname: new FormControl()
    });

    if(this.current_album.album !== undefined){
      this.securityPost = this.current_album.album.security;
      this.myForm.controls.albumname.setValue(this.current_album.album.title);
    }

  }

  changeSecurity(sec){
    this.securityPost = sec;
    return false;
  }

  onNoClick(): void {
    this.dialogRef.close({});
  }

  onYesClick(): void {
    if(this.current_album.album !== undefined){
      this.dialogRef.close({
        'status': 'update',
        'security': this.securityPost,
        'title': this.myForm.controls.albumname.value,
        'album_id': this.current_album.album.id
      });
    } else {
      this.dialogRef.close({
        'status': 'create',
        'security': this.securityPost,
        'title': this.myForm.controls.albumname.value
      });
    }
  }

}
