import {ChangeDetectionStrategy, Component, ChangeDetectorRef, OnDestroy, Inject, ViewChild} from '@angular/core';
import { SessionStorageService} from 'ngx-webstorage';
import { MygroupsService} from '../../common/services/mygroups.service';
import {AccountService} from '../../common/services/account.service';
import {Account} from '../../common/interfaces/account';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material';
import { MatTabGroup } from '@angular/material/tabs';

@Component({
  selector: 'app-dashboard-join-groups',
  templateUrl: './dashboard-join-groups.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})


export class DashboardJoinGroupsComponent implements OnDestroy {

  @ViewChild(MatTabGroup, { static: true }) tabGroup: MatTabGroup;

  account: Account;
  groupdata: any;
  profile_type: any;
  paymentStatus = 'paid';
  timer: any;
  timer2: any;
  tabindex: any;
  rrcount: any;
  sscount: any;
  trigger_check = 0;
  triggered = false;
  triggered2 = false;
  s1 = null;
  s2 = null;

  constructor(
      private sessionSt: SessionStorageService,
      private mygroups: MygroupsService,
      private accountService: AccountService,
      private ref: ChangeDetectorRef,
      @Inject(MAT_BOTTOM_SHEET_DATA) public datax: any
  ) {


    this.timer2 = setInterval(() => {

      if(this.sessionSt.retrieve('current_profile') != null && this.triggered2 == false){
        this.triggered2 = true;

        console.log(this.datax.id.group, this.sessionSt.retrieve('current_profile'));

        if(this.datax.id.group != undefined && this.datax.id.group != null){
          this.mygroups.load(this.sessionSt.retrieve('current_profile'), this.datax.id.group);
        } else {
          this.mygroups.load(this.sessionSt.retrieve('current_profile'), null);
        }

        this.s1 = this.mygroups.cast.subscribe(data => {
          this.groupdata = data;
          //if(this.groupdata.notapproved[0].value.length == 0 && this.datax.id.group != null){
          //  this.datax.ref.dismiss();
          //}
          this.rrcount = this.groupdata.rrcount;
          this.sscount = this.groupdata.sscount;
        });

        this.s2 = this.accountService.cast.subscribe(data => {
          this.profile_type = data.current_profile.profile_type;
        });






        ref.detach();

        this.timer = setInterval(() => {

          if(this.tabGroup.selectedIndex == 1 && this.trigger_check == 10){
            this.triggered = true;
          }

          if (this.datax.id.tab == 'recommend' && this.triggered == false) {
            this.tabGroup.selectedIndex = 1;
            this.trigger_check++;
          }


          this.ref.detectChanges();

        }, 250);


      }






    }, 750);






  }

  sheetRef: any = this.datax.ref;

  changetab($event) {
    this.tabindex = $event;
  }

  ngOnDestroy() {
    clearInterval(this.timer);
    this.mygroups.killtimer();
    if (this.s1 != null) {
      this.s1.complete();
    }
    if (this.s2 != null) {
      this.s2.complete();
    }
  }

}
