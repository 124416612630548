import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';

@Component({
  selector: 'app-stepper-responsive',
  templateUrl: './stepper-responsive.component.html',
  styleUrls: ['./stepper-responsive.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: {displayDefaultIndicatorType: false}
    },
  ],
  changeDetection: ChangeDetectionStrategy.Default
})
export class StepperResponsiveComponent {
  smallScreen: boolean;
  @ViewChild('myElem', {static: false}) MyProp: ElementRef;
  @Input() stepLabel: string[];
  @Input() finalStepButtonLabel: string;
  @Input() stepOne: TemplateRef<any>;
  @Input() stepTwo: TemplateRef<any>;
  @Input() stepThree: TemplateRef<any>;

  @Input() stepOneFlag: boolean;
  @Input() stepTwoFlag: boolean;
  @Input() stepThreeFlag: boolean;

  @Output() bigScreen: boolean;
  @Output() onFinish = new EventEmitter();

  constructor(
    private breakpointObserver: BreakpointObserver
  ) {
    breakpointObserver.observe([
          Breakpoints.XSmall,
          Breakpoints.Small
        ]).subscribe(result => {
          this.smallScreen = result.matches;
      });
  }

  totop() {
    window.scroll(0,0);
  }

  finish() {
    this.onFinish.emit('ready');
  }

}
