import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Renewallist } from '../interfaces/renewallist';

@Injectable()

export class RenewallistService {

  private _url = '';

  constructor(private http: HttpClient) {
  }

  pullrenewals(group_id) {
    this.http.get<Renewallist>(environment.service.trackithubapi + '/api/inside/grouprenewals?group_id=' + group_id).subscribe((data: any) => {
      return data;
    });
    return null;
  }
}
