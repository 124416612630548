import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'kebabCase'
})
export class KebabCasePipe implements PipeTransform {

  transform(value: string ) {
    return value ? value.replace(/\s+/g, '-').toLowerCase() : value;
  }

}
