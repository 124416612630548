import {Component, Input, ViewChild, OnInit, ChangeDetectionStrategy, OnDestroy} from '@angular/core';
import {AccountService} from '../../../services/account.service';
import {Account} from '../../../interfaces/account';

import {BottomSheetService} from '../../../services/bottom-sheet.service';
import {
  DashboardContactSheetComponent,
  DashboardEmailGroupSheetComponent,
  DashboardLeagueScoreComponent, DashboardMyleaguesComponent
} from '../../../../dashboard';
import {DashboardEmailSheetComponent} from "../../../../dashboard/dashboard-email-sheet/dashboard-email-sheet.component";
import {ToastService} from "../../../toast";
import {DashboardeventsService} from '../../../services/dashboardevents.service';
import {environment} from "../../../../../environments/environment";
import {ProfileComponent} from "../../../../profile/profile.component";
import {HttpClient} from "@angular/common/http";
import {
  ChangeAvatarComponent
} from "../../../../dashboard/dashboard-settings/personal-info/change-avatar/change-avatar.component";
import {ModalService} from "../../../services/modal.service";

@Component({
    selector: 'app-dashboard-card-footer',
    templateUrl: './dashboard-card-footer.component.html',
    styleUrls: ['./dashboard-card-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class DashboardCardFooterComponent implements OnInit, OnDestroy {
    @Input() buttons: any;
    @Input() isLeaguePlayer: boolean;
    @Input() isLeagueAdmin: boolean;
    @Input() isClubAdmin: boolean;
    @Input() isClubPlayer: boolean;
    @Input() group_id: any;
    @Input() owner_id: any;
    @Input() clubcard: boolean;
    @Input() sscount: any;
    @Input() rrcount: any;
    @Input() members: any;
    @Input() card: object;
    @Input() is_league: any;

    s1 = null;

    attendance = true;
    account: Account;
    constructor(
      private accountService: AccountService,
      private bottomSheetService: BottomSheetService,
      private toastService: ToastService,
      private dashboardevents: DashboardeventsService,
      private http: HttpClient,
      private modalService: ModalService
    ) {}

    openContactSheet(event, group_id, group_owner) {
      event.preventDefault();
      this.bottomSheetService.openBottomSheetWithData(DashboardContactSheetComponent, 'DashboardContactSheetComponent',{
        group: group_id,
        id: this.account.current_profile.profile_id,
        name: this.account.current_profile.name,
        owner: group_owner
      });
    }

    openEmailSheet(event) {
      event.preventDefault();
      this.bottomSheetService.openBottomSheetWithData(DashboardEmailGroupSheetComponent, 'DashboardEmailGroupSheetComponent',{
        list: [],
        group: this.card,
        id: this.account.current_profile.profile_id,
        name: this.account.current_profile.name,
        current_profile: this.account.current_profile
      });

    }

    ngOnInit(): void {
      this.s1 = this.accountService.cast.subscribe(data => {
        this.account = data;
      });
    }

    ngOnDestroy() {
      if (this.s1 != null) {
        this.s1.complete();
      }
    }

    nostandings(): void {
      this.toastService.show({
        text: 'There are no standings associated with this group.',
        type: 'warn',
        buttonText: '',
      });
    }

    nomembers(): void {
      this.toastService.show({
        text: 'There are no members in this group.',
        type: 'warn',
        buttonText: '',
      });
    }

    gotoevents(groupid){
      this.dashboardevents.updategroup(groupid);
    }

    gomatchups(groupid){

      this.bottomSheetService.openBottomSheetWithData(DashboardMyleaguesComponent, 'DashboardMyleaguesComponent','');

      //this.http.get<any>(environment.service.trackithubapi + '/api/inside/loadleaguescore?&profile_id=' + this.account.current_profile.profile_id + '&group_id=' + groupid).subscribe((data: any) => {
      //  this.modalService.openDialogMaxw(DashboardLeagueScoreComponent, 'auto', 'auto', {data: data});
      //});

    }

    viewProfileMedia(profile_id){
      this.http.get<Account>(environment.service.trackithubapi + '/api/inside/loadprofile?clean_group=yes&profile=' + profile_id).subscribe((profileData: any) => {
        this.bottomSheetService.openBottomSheetWithDataCustomClass(ProfileComponent, 'ProfileComponent',{
          'profileData': profileData,
          'tab': 'media'
        }, 'custom-bottom-p' );

      });
    }

}
