import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DashBoardEvents } from '../interfaces/dashboardeventsservice.interface';


@Injectable()

export class DashboardeventsService {

  private dashboardevents = new BehaviorSubject<DashBoardEvents>({
    calendargroupchange: ''
  });

  cast = this.dashboardevents.asObservable();

  constructor() {}

  updategroup(group_id) {
    this.dashboardevents.next({
      calendargroupchange: group_id});
  }


}
