import {ChangeDetectionStrategy, Component, Input, OnDestroy, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {AccountService} from '../../../services/account.service';
import {Account} from '../../../interfaces/account';
import {environment} from '../../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {ToastService} from '../../../toast';
import {BottomSheetService} from '../../../services/bottom-sheet.service';
import {DashboardUploadAvatarComponent} from '../../../../dashboard/dashboard-upload-avatar/dashboard-upload-avatar.component';

interface Avatar {
  value: string;
  viewValue: string;
}


@Component({
  selector: 'app-dashboard-card-header',
  templateUrl: './dashboard-card-header.component.html',
  styleUrls: ['./dashboard-card-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class DashboardCardHeaderComponent implements OnDestroy {
  @Input() isAdmin = false;
  @Input() groupName: string;
  @Input() organization: string;
  @Input() permissions: string;
  @Input() rating: string;

  account: Account;
  selectedAvatar: any;

  s1 = null;

  avatars: Avatar[] = [
    {value: 'club', viewValue: 'Club'},
    {value: 'grin', viewValue: 'Grin'},
    {value: 'slight-smile', viewValue: 'Slight Smile'},
    {value: 'smile', viewValue: 'Smile'},
    {value: 'smiling-face-eyes', viewValue: 'Smiling Face Eyes'},
    {value: 'smirking-face', viewValue: 'Smirking Face'},
    {value: 'tilted-grin', viewValue: 'Tilted-grin'},
    {value: 'winking-grin', viewValue: 'Winking-Grin'},
    {value: 'worried-smile', viewValue: 'Worried-Smile'},
  ];

  constructor(private accountService: AccountService, public _router: Router, private http: HttpClient, private toastService: ToastService, private bottomSheetService: BottomSheetService) {
    this.s1 = this.accountService.cast.subscribe(data => {
      this.account = data;
      if (this.account.current_profile.avataricon !== '') {
        this.selectedAvatar = this.account.current_profile.avataricon;
      } else {
          this.selectedAvatar = this.account.current_profile.avatar;
      }
    });
  }

  uploadpanel() {
    this.bottomSheetService.openBottomSheetWithData(DashboardUploadAvatarComponent, 'DashboardUploadAvatarComponent',{});
  }

  updatedavatar($event) {
    if (this.selectedAvatar.length > 19) {
      this.account.current_profile.avataricon = '';
      this.account.current_profile.avatar = this.selectedAvatar;
    } else {
      this.account.current_profile.avataricon = this.selectedAvatar;
    }

    this.http.get<any>(environment.service.trackithubapi + '/api/inside/setavataricon?profile_id=' + this.account.current_profile.profile_id + '&icon=' + this.account.current_profile.avataricon).subscribe((data: any) => {
      this.accountService.load();
      this.toastService.show({
        text: 'Avatar has been updated.',
        type: 'success',
        buttonText: '',
      });
    });
  }

  ngOnDestroy() {
    if (this.s1 != null) {
      this.s1.complete();
    }
  }

}
