import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef} from '@angular/core';


@Component({
  selector: 'app-stepper-vertical',
  templateUrl: './stepper-vertical.component.html',
  styleUrls: ['../stepper-responsive/stepper-responsive.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class StepperVerticalComponent {
  @Input() stepLabel: string[];
  @Input() finalStepButtonLabel: string;
  @Input() stepOne: TemplateRef<any>;
  @Input() stepTwo: TemplateRef<any>;
  @Input() stepThree?: TemplateRef<any>;
  @Input() stepTwoButtonLabel?: string;
  @Output() onFinish = new EventEmitter();
  @Input() finalStepButtonValid: boolean;


  constructor(
  ) {

  }

  finish() {
    this.onFinish.emit('ready');
  }
}
