import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import { Account } from 'src/app/common/interfaces/account';
import {MatDialog} from "@angular/material/dialog";
import {AlbumDialogComponent} from "./album-dialog/album-dialog.component";
import {UploadPictureDialogComponent} from "./uploadpicture-dialog/uploadpicture-dialog.component";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {ToastService} from "../../common/toast";
import {RosterElement} from "../../dashboard/dashboard-roster/roster-table.component";
import {DeletealbumDialogComponent} from "./deletealbum-dialog/deletealbum-dialog.component";
import {DeletepictureDialogComponent} from "./deletepicture-dialog/deletepicture-dialog.component";
import {EditpictureDialogComponent} from "./editpicture-dialog/editpicture-dialog.component";
import {
  ChangeAvatarComponent
} from "../../dashboard/dashboard-settings/personal-info/change-avatar/change-avatar.component";
import {ModalService} from "../../common/services/modal.service";
import {AccountService} from "../../common/services/account.service";
import {element} from "protractor";

@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaComponent implements OnInit, OnDestroy {

  @Input() account: Account;
  @Input() is_owner: any;
  @Input() profile_id: any;
  @Input() profile_type: any;
  @Input() media_id: any;
  @Input() folder_id: any;

  current_folder: any;
  current_picture: any;
  album_list: any;
  s1: any;
  gotoalbum: any;

  constructor(public dialog: MatDialog, private accountService: AccountService, private http: HttpClient, private toastService: ToastService, private changeDetectorRef: ChangeDetectorRef, private modalService: ModalService) {

    this.s1 = this.accountService.cast.subscribe(data => {
      this.account = data;
    });

    this.gotoalbum = null;

    if(this.profile_type == 'group'){
      this.profile_type = 'group';
    } else {
      this.profile_type = 'profile';
    }
  }

  ngOnInit() {

    if(this.profile_type == 'group'){
      this.profile_type = 'group';
    } else {
      this.profile_type = 'profile';
    }

    this.reset();
  }

  ngOnDestroy() {
    if (this.s1 != null) {
      this.s1.complete();
    }
  }

  newAlbum(){
    const dialogRef = this.dialog.open(AlbumDialogComponent, {data: {}});
    dialogRef.afterClosed().subscribe(result => {
      if(result.status == 'create') {
        this.http.post<RosterElement>(environment.service.trackithubapi + '/api/inside/createalbum', {
          'profile_id': this.profile_id,
          'profile_type': this.profile_type,
          'security': result.security,
          'title': result.title
        }, {}).subscribe((data: any) => {



          this.toastService.show({
            text: 'New album created.',
            type: 'success',
          });

          this.gotoalbum = data.album_id;
          this.reset();
        });
      }
    });
  }

  reset(){
    this.current_folder = null;
    this.current_picture = null;
    this.pulldata();
  }

  pulldata(){
    this.http.get<any>(environment.service.trackithubapi + '/api/inside/getprofilemedia?profile_type=' + this.profile_type + '&profile_id=' + this.profile_id + '&user_id=' + this.account.current_profile.profile_id).subscribe((data: any) => {
      this.album_list = data.info;
      this.changeDetectorRef.markForCheck();

      if(this.folder_id){
        if (this.album_list.length > 0) {
          this.album_list.forEach((element) => {
            if (element.id == this.folder_id) {
              this.current_folder = element;
              this.folder_id = '';


              element.pictures.forEach((pic) => {

                if (pic.id == this.media_id) {
                  this.current_picture = pic;
                  this.media_id = '';
                }
              });
            }
          });

        }
      } else {
        if (this.gotoalbum === null) {
          if (this.album_list.length > 0 && this.current_folder !== null) {
            this.album_list.forEach((element) => {
              if (element.id == this.current_folder.id) {
                this.current_folder = element;
              }
            });
          }
        } else {
          if (this.album_list.length > 0) {
            this.album_list.forEach((element) => {
              if (element.id == this.gotoalbum) {
                this.current_folder = element;
              }
            });
          }
          this.gotoalbum = null;
          this.newPicture();
        }
      }
    });
  }

  changeAvatar(picurl) {
    this.modalService.openDialogMax(ChangeAvatarComponent, 'auto', 'auto', {profile_type: this.profile_type,id: this.account.current_profile.profile_id, is_account: this.account.current_profile.is_account, image: picurl});
  }

  gotoAlbum(album){
    this.current_folder = album;
  }

  gotoPicture(picture){
    this.current_picture = picture;
  }

  backToAlbum(){
    this.current_picture = null;
  }

  backAlbum(){
    this.current_folder = null;
  }



  editPicture() {
    const dialogRef = this.dialog.open(EditpictureDialogComponent, {data: {'picture': this.current_picture}});
    dialogRef.afterClosed().subscribe(result => {
      if(result.status == 'update') {
        this.http.post<RosterElement>(environment.service.trackithubapi + '/api/inside/editpicture', {
          'profile_id': this.profile_id,
          'picture_id': result.picture_id,
          'profile_type': this.profile_type,
          'title': result.title
        }, {}).subscribe((data: any) => {
          this.toastService.show({
            text: 'Picture has been updated.',
            type: 'success',
          });
          this.pulldata();

          this.current_picture.title = result.title;

          this.changeDetectorRef.detectChanges();
        });
      }
    });
  }

  setalbumcover() {
    this.http.post<RosterElement>(environment.service.trackithubapi + '/api/inside/setalbumcover', {
      'profile_id': this.profile_id,
      'album_id': this.current_folder.id,
      'picture_id': this.current_picture.id,
      'profile_type': this.profile_type,
    }, {}).subscribe((data: any) => {
      this.toastService.show({
        text: 'Album has been updated.',
        type: 'success',
      });
      this.current_picture = null;
      this.current_folder = null;
      this.pulldata();
      this.changeDetectorRef.detectChanges();
    });



  }

  editAlbum(){
    const dialogRef = this.dialog.open(AlbumDialogComponent, {data: {'album': this.current_folder}});
    dialogRef.afterClosed().subscribe(result => {
      if(result.status == 'update') {
        this.http.post<RosterElement>(environment.service.trackithubapi + '/api/inside/editalbum', {
          'profile_id': this.profile_id,
          'album_id': result.album_id,
          'security': result.security,
          'profile_type': this.profile_type,
          'title': result.title
        }, {}).subscribe((data: any) => {
          this.toastService.show({
            text: 'Album has been updated.',
            type: 'success',
          });
          this.reset();
          this.changeDetectorRef.detectChanges();
        });
      }
    });
  }

  deleteAlbum(){
    const dialogRef = this.dialog.open(DeletealbumDialogComponent, {
      data: {'album': this.current_folder}
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result.status == 'delete'){
        this.http.post<RosterElement>(environment.service.trackithubapi + '/api/inside/deletealbum', {
          'profile_id': this.profile_id,
          'profile_type': this.profile_type,
          'album_id': result.album.album.id
        }, {}).subscribe((data: any) => {
          this.toastService.show({
            text: 'Album has been deleted.',
            type: 'success',
          });
          this.reset();
        });
      }
    });
  }

  deletePicture(){
    const dialogRef = this.dialog.open(DeletepictureDialogComponent, {
      data: {'picture': this.current_picture}
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result.status == 'delete'){
        this.http.post<RosterElement>(environment.service.trackithubapi + '/api/inside/deletepicture', {
          'profile_id': this.profile_id,
          'picture_id': result.picture.picture.id,
          'profile_type': this.profile_type,
        }, {}).subscribe((data: any) => {
          this.toastService.show({
            text: 'Album has been deleted.',
            type: 'success',
          });
          this.reset();
        });
      }
    });
  }

  newPicture(){
    const dialogRef = this.dialog.open(UploadPictureDialogComponent, {
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result.status == 'add'){
        this.http.post<RosterElement>(environment.service.trackithubapi + '/api/inside/uploadimage', {
          'profile_id': this.profile_id,
          'album_id': this.current_folder.id,
          'profile_type': this.profile_type,
          'image': result.image,
          'title': result.title
        }, {}).subscribe((data: any) => {
          this.toastService.show({
            text: 'Picture has been added.',
            type: 'success',
          });
          this.pulldata();
        });
      }
    });
  }


}
