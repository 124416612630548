import {AfterViewInit, ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { Account } from 'src/app/common/interfaces/account';
import {RosterElement} from "../../dashboard/dashboard-roster/roster-table.component";
import { MatPaginator, MatSort, MatTable, MatTableDataSource } from '@angular/material';
import {SelectionModel} from "@angular/cdk/collections";
import {environment} from "../../../environments/environment";
import {MatDialog} from "@angular/material/dialog";
import {ModalService} from "../../common/services/modal.service";
import {Router} from "@angular/router";
import {ToastService} from "../../common/toast";
import {DomSanitizer} from "@angular/platform-browser";
import {HttpClient} from "@angular/common/http";
import {CalendarService} from "../../common/services/calendar.service";
import {AccountService} from "../../common/services/account.service";
import {BottomSheetService} from "../../common/services/bottom-sheet.service";
import {MAT_BOTTOM_SHEET_DATA} from "@angular/material/bottom-sheet";
import {removeclublocationDialogComponent} from "../../dashboard";
import {Subscription} from "rxjs";
import {CreateLocationComponent, PickleballPlaceDetailsComponent} from "../../frontend";
import {PanelService} from "../../common/services/panel.service";
import {SearchAddressService} from "../../common/services/searchaddress.service";
import {PanelCardService} from "../../common/services/panelcard.service";



@Component({
  selector: 'app-locations-panel',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss']
})
export class LocationsPanelComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatTable, {static: false}) table: MatTable<RosterElement>;


  @Input() account: Account;
  @Input() friends;
  @Input() groups;
  @Input() is_owner;
  @Input() profile_id;
  @Input() profile_type;

  env: any;
  xpro: any;
  dataSource = new MatTableDataSource();
  selection = new SelectionModel<RosterElement>(true, []);
  displayedColumns = [];
  siteUrl = environment.service.trackithubapi + '/';
  private suba: Subscription;

  constructor(    private panelService: PanelService,
                  private searchSubject: SearchAddressService,
                  private panelcardSubject: PanelCardService, @Inject(MAT_BOTTOM_SHEET_DATA) public data: any, public dialog: MatDialog, private modalService: ModalService, private router: Router, private toastService: ToastService, private sanitizer: DomSanitizer,  private http: HttpClient, private calendarService: CalendarService, private accountService: AccountService, private ref: ChangeDetectorRef, private bottomSheetService: BottomSheetService) {}


  sheetRef: any = this.data.ref;

  ngOnDestroy() {
    this.suba.unsubscribe();
  }

  ngOnInit() {




    this.env = environment;

    this.suba = this.accountService.cast.subscribe(data => {

    });


    this.displayedColumns = ['title','chat'];


    this.dataSource.data = this.groups;
    this.selection.clear();

  }


  refresh() {
    this.http.get<Account>(environment.service.trackithubapi + '/api/inside/loadprofile?profile=' + this.account.current_profile.profile_id).subscribe((profileData: any) => {
      this.friends = profileData.profile.locations;
      this.dataSource.data = this.friends;
      this.selection.clear();
      this.dataSource.sort = this.sort;
    });
  }


  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }


  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }




  removeLocation(id){
    for (let i = 0; i < this.groups.length; i++) {
      if(this.groups[i].loc_id == id ){

        const dialogRef = this.dialog.open(removeclublocationDialogComponent, {
          data: {location: this.groups[i], club: this.profile_id}
        });

        dialogRef.afterClosed().subscribe(result => {




          if (result.status == 'remove') {



            this.http.post<any>(environment.service.trackithubapi + '/api/inside/removelocationfromclub',  {
              location: result.post.location.loc_id,
              profile_id: result.post.club
            }).subscribe((data: any) => {
              this.toastService.show({
                text: 'You have successfully removed the selected location.',
                type: 'success',
                buttonText: '',
              });

              this.refresh();

            });
          } else if (result.event == 'Error') {
            this.toastService.show({
              text: `System error, please try again.   If you continue to get this error please contact support.`,
              type: 'warn',
            });
          }
        });




      }
    }
  }


  editLocation(id){
    for (let i = 0; i < this.groups.length; i++) {
      if(this.groups[i].loc_id == id ){
        this.modalService.openDialog(CreateLocationComponent, 'auto', 'auto', {location: this.groups[i], accountService: this.accountService});
      }
    }
  }


  goto(location) {

      if(this.profile_type == 'group'){
        this.xpro = location.created_by;
      } else {
        this.xpro = this.profile_id;
      }


      this.sheetRef.dismiss();
      this.http.get<Account>(environment.service.trackithubapi + '/api/outside/getcard?card=' + this.xpro).subscribe((datag: any) => {
        this.panelcardSubject.selectCard(datag.result, location, location.title);
        this.http.get<Account>(environment.service.trackithubapi + '/api/outside/clubscloseby?card=' + this.xpro).subscribe((datax: any) => {
          // tslint:disable-next-line:radix
          this.searchSubject.showclub(datax, parseFloat(location.geolat), parseFloat(location.geolon));
          this.panelService.openPanel(PickleballPlaceDetailsComponent, 'PickleballPlaceDetailsComponent');
        });
      });
    }




}
