import { Component, OnInit } from '@angular/core';
import {Observable, Observer} from 'rxjs';
import { WordpressService} from '../../common/services/wordpress.service';

@Component({
  selector: 'app-blog-posts',
  templateUrl: './blog-posts.component.html',
  styleUrls: ['./blog-posts.component.scss']
})
export class BlogPostsComponent implements OnInit {
  posts$: Observable<any>;
  constructor(private wp: WordpressService) {
    this.posts$ = new Observable((observer: Observer<any>) => {
      setTimeout(() => {
        observer.next(this.posts$ = this.wp.getPosts());
      }, 1000);
    });
  }

  ngOnInit() {
  }

}
