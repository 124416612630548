import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnDestroy} from '@angular/core';
import { BottomSheetService } from '../../common/services/bottom-sheet.service';
import {SessionStorageService} from "ngx-webstorage";
import {LeaguesEventsComponent} from "./leagues-events/league-events.component";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material';
import {MatBottomSheetRef} from "@angular/material/bottom-sheet";
import {SearchAddressService} from "../../common/services/searchaddress.service";
import {LeagueEventviewComponent} from "../league-eventview/league-eventview.component";

@Component({
  selector: 'app-leagues',
  templateUrl: './leagues.component.html',
  styleUrls: ['./leagues.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class LeaguesComponent implements OnDestroy {

  @Input() nosearch: boolean;
  @Input() type: any;

  cards: any;
  s1: any;
  header: number;
  miles: number;
  timer: any;
  xcity: string;
  xstate: string;
  xevents: any;

  constructor(
    private ref: ChangeDetectorRef,
    private sessionSt: SessionStorageService,
    private bottomSheetService: BottomSheetService,
    private bottomSheetRef: MatBottomSheetRef,
    private http: HttpClient,
    private searchSubject: SearchAddressService,
    @Inject(MAT_BOTTOM_SHEET_DATA) public datax: any
  ) {


    this.header = 1;

    this.timer = setInterval(() => {
      this.ref.detectChanges();
    }, 750);

    if(this.datax.id.referal !== undefined){
      this.sessionSt.store('ambassador', this.datax.id.referal);
    }


    this.miles = 0;
    this.http.get<any>(environment.service.trackithubapi + '/api/outside/get_league_list?lat='+this.sessionSt.retrieve('current_lat')+'&lng='+this.sessionSt.retrieve('current_lng')).subscribe((data: any) => {
      this.cards = data;
      if(this.cards.length > 0){
        this.miles = this.cards[0].distance_in_miles;
      }
      this.ref.detectChanges();
    });


    this.s1 = this.searchSubject.cast.subscribe(data => {


      this.cards = [];


      console.log(data.address_lat, data.address_lng);
      if(data.address_lat !== null && data.address_lng !== null) {
        this.http.get<any>(environment.service.trackithubapi + '/api/outside/get_league_list?lat=' + data.address_lat + '&lng=' + data.address_lng).subscribe((datax: any) => {
          this.cards = datax;
          if (this.cards.length > 0) {
            this.miles = this.cards[0].distance_in_miles;
          }
        //  console.log('done');
        //  this.ref.detectChanges();
        });
      }





    });





  }
  leagueevent(city, state, events) {



    console.log("sorry");

    this.header = 2;


    this.xcity = city;
    this.xstate = state;
    this.xevents = events;


    //this.bottomSheetService.openBottomSheetWithData(LeaguesEventsComponent, 'LeaguesEventsComponent',{'city': city, 'state': state, 'events': events, 'bottomref': this.bottomSheetRef});
    console.log("bi");
    this.ref.detectChanges();
    console.log("hi");

  }


  leagueinfo() {
    window.open('https://blog.trackithub.com/premier-pickleball-league/', '_blank');
  }

  openleague(rp_id, event_id, is_team){
    this.bottomSheetRef.dismiss();
    this.bottomSheetService.openBottomSheetWithDataCustomClass(LeagueEventviewComponent, 'LeagueEventviewComponent',{
      event:event_id,
      repeat_id: rp_id,
      paid: false,
      is_team: is_team
    }, 'custom-bottom');
  }

  ngOnDestroy() {
    if (this.s1 !== undefined) {
      this.s1.unsubscribe();
      console.log('gone');
    }
    clearInterval(this.timer);
  }

  openpage(){
    window.open('https://www.surveymonkey.com/r/N8N7YSD', '_blank');
  }





}
