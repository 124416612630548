import { HttpClient } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Subscription } from 'rxjs';
import { Account } from 'src/app/common/interfaces/account';
import { AccountService } from 'src/app/common/services/account.service';
import { FormAccountSubscriptionService } from 'src/app/common/services/form-account-subscription.service';
import { ToastService } from 'src/app/common/toast';
import { environment } from 'src/environments/environment';
import { RosterElement } from '../../dashboard-roster/roster-table.component';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit, OnDestroy {

  @Input() profile: Account["current_profile"];

  s1: Subscription = null;
  profileData: Account["current_profile"];
  selectedRequest: string;
  requests: String[] = [
    "Change Subscription",
    "Cancel Subscription",
    "Update Payment Method"
  ];

  constructor(private toastService: ToastService, private http: HttpClient, private accountService: AccountService, public dialog: MatDialog, public formService: FormAccountSubscriptionService) {
    this.formService.enableForm();

    this.s1 = this.accountService.cast.subscribe(data => {
      this.profileData = data.current_profile;
    });

  }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.s1 != null) {
      this.s1.unsubscribe();
    }
  }

  submit() {

        const selectR = this.selectedRequest;
        this.selectedRequest = '';

        this.http.post<RosterElement>(environment.service.trackithubapi + '/api/inside/managesub', {
          'profile_id': this.profileData.profile_id,
          'type': selectR,
          'transaction_id': this.profileData.subscription_transaction}, { }).subscribe((data: any) => {
          this.toastService.show({
            text: `Please allow 24 hours for your request to be processed.`,
            type: 'success',
          });

        });
      }
}
