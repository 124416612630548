import {Injectable} from '@angular/core';
import {Localdata, LocalDataService} from './local-data.service';

@Injectable()
export class LocalDataPublicService implements LocalDataService {
    getLocalDatas(): Localdata[] {
        const localdatas: Localdata[] = [
            {
                'name': 'Leagues',
                'route': '',
                'url': '#leagues',
                'fragment': 'leagues',
            },
            {
                'name': 'Club Play',
                'route': '',
                'url': '#club-play',
                'fragment': 'club-play',
            },
            {
                'name': 'Affiliations',
                'route': '',
                'url': '#affiliations',
                'fragment': 'affiliations',
            },
            {
                'name': 'Events',
                'route': '',
                'url': '#events',
                'fragment': 'events',
            },
            {
                'name': 'Contact',
                'route': '',
                'url': '#contact_top',
                'fragment': '',
            }
        ];
        return localdatas;
    }
    getMenuItems(): Localdata[] {
        const MenuItems: Localdata[] = [
            {
                'icon': 'dashboard',
                'name': 'Dashboard',
                'route': '',
                'url': '#leagues',
                'fragment': 'leagues',
            },
            {
                'icon': 'group',
                'name': 'Club Play',
                'route': '',
                'url': '#club-play',
                'fragment': 'club-play',
            },
            {
                'icon': 'group',
                'name': 'Affiliations',
                'route': '',
                'url': '#affiliations',
                'fragment': 'affiliations',
            },
            {
                'icon': 'event',
                'name': 'Events',
                'route': '',
                'url': '#events',
                'fragment': 'events',
            },
            {
                'icon': 'contact_support',
                'name': 'Support',
                'route': '',
                'url': '#contact_top',
                'fragment': '',
            }
        ];
        return MenuItems;
    }
}
