import {AfterViewInit, Component, Inject, Input, ViewChild} from '@angular/core';
import {MatDialogRef} from '@angular/material';
import {DynamicChangepassComponent} from '../../../../dynamic-form/containers';
import {FieldConfig} from '../../../../dynamic-form/models/field-config.interface';
import {Validators} from '@angular/forms';
import {environment} from "../../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {ToastService} from "../../../../common/toast";
import {AccountService} from '../../../../common/services/account.service';
import { MAT_DIALOG_DATA } from '@angular/material';
import {Router} from "@angular/router";

@Component({
  selector: 'app-delete-profile-modal',
  templateUrl: './delete-profile-modal.component.html'
})


export class DeleteProfileModalComponent implements AfterViewInit {

  disable = true;
  public pwdPattern: any = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{8,25}$/;
  @ViewChild(DynamicChangepassComponent, {static: false}) vform: DynamicChangepassComponent;

  formConfig: FieldConfig[] = [
    {
      type: 'password',
      name: 'password',
      placeholder: 'Current Password',
      validation: [Validators.required, Validators.pattern(this.pwdPattern)],
      required: true,
    }
  ];

  params: any;

  constructor(private router: Router, public dialogRef: MatDialogRef<DeleteProfileModalComponent>, private http: HttpClient, private toastService: ToastService, private accountService: AccountService, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.params = data;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngAfterViewInit() {
    this.vform.vform.valueChanges.subscribe(data => {
      this.disable = this.vform.vform.invalid;
    });
  }

  deleteprofile(){
    this.http.get<any>(environment.service.trackithubapi + '/api/inside/deleteprofile?password=' + this.vform.vform.controls.password.value + '&profile_id=' + this.params.id).subscribe((data: any) => {
      if (data.result === true) {
        this.toastService.show({
          text: `Profile has been deleted.`,
          type: 'success',
        });
        this.dialogRef.close();
        this.accountService.load();
        this.router.navigate(['/dashboard/overview'], {});
      } else {
        this.toastService.show({
          text: `Your account password could not be verified`,
          type: 'warn',
        });
      }
    });
  }


}
