import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { LoaderComponent} from '../fragments/loader/loader.component';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoaderOverlayService {
  overlayRef: OverlayRef = null;

  constructor( private overlay: Overlay) {}
  public show(loaderMessage = '') {
    // Returns an OverlayRef (which is a PortalHost)
    if (!this.overlayRef) {
        this.overlayRef = this.overlay.create();
    }
      // Create ComponentPortal that can be attached to a PortalHost
  const LoaderOverlayPortal = new ComponentPortal(LoaderComponent);
  const component = this.overlayRef.attach(LoaderOverlayPortal); // Attach ComponentPortal to PortalHost
  }

  public hide() {
    if (!!this.overlayRef) {
      this.overlayRef.detach();
    }
  }



}
