import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {AccountService} from '../../common/services/account.service';
import {Account} from '../../common/interfaces/account';
import {DomSanitizer} from '@angular/platform-browser';
import { LoaderOverlayService } from '../../common/services/loader-overlay.service';
import {Subscription} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {environment} from "../../../environments/environment";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-dashboard-showsuccess',
  templateUrl: './dashboard-showsuccess.component.html',
  styleUrls: ['./dashboard-showsuccess.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})


export class DashboardShowSuccessComponent implements OnInit, OnDestroy {

  account: Account;
  public type;

  returnurl = null;
  username = null;
  epassw = null;
  url = null;
  safe = false;

  private suba: Subscription;

  constructor(public dialogRef: MatDialogRef<DashboardShowSuccessComponent>, private LoaderOverlayService: LoaderOverlayService, private route: ActivatedRoute, private accountService: AccountService, private domSanitizer: DomSanitizer) {

  }

  onNoClick(){
    this.dialogRef.close();
  }



  ngOnDestroy() {

  }

  ngOnInit() {

  }

}
