import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material';


@Injectable()
export class SnackbarService {
    message: string;
    className: string;
    action: string;
    constructor(private snackBar: MatSnackBar) {}

    success(message, action) {
      this.className = 'success';
        this.snackBar.open(message, action, {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition: 'right',
            panelClass: this.className,
        });
    }

    alert(message, action) {
      this.className = 'alert';
        this.snackBar.open(message, action, {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition: 'right',
            panelClass: this.className
        });
    }
}
