import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';


@Injectable({
  providedIn: 'root'
})
export class FormRegisterleagueService {


  constructor() { }

  form: FormGroup = new FormGroup({
    team: new FormControl(),
    fname: new FormControl(),
    lname: new FormControl(),
    em: new FormControl(),
    password: new FormControl(),
    gender: new FormControl(),
    skill: new FormControl(),
    zipcode: new FormControl(),
    terms: new FormControl(),
    phone: new FormControl()
  });

  disableForm() {
    this.form.disable();
  }

  addControl(field) {
    this.form.addControl(field, new FormControl({disabled: true}));
  }

  removeControl(field) {
    this.form.removeControl(field);
  }

  enableForm() {
    this.form.enable();
  }

  initializeFormGroup() {

  }


}
