import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-terms-dialog',
  templateUrl: './terms-dialog.component.html',
  styleUrls: ['./terms-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class TermsDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<TermsDialogComponent>,
  @Inject(MAT_DIALOG_DATA) public data: any ) { }

  ngOnInit() {}
}
