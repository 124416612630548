import { Component, OnInit } from '@angular/core';
import { BottomSheetService } from 'src/app/common/services/bottom-sheet.service';
import { DashboardCreateGroupComponent } from 'src/app/dashboard/dashboard-create-group/dashboard-create-group.component';

@Component({
  selector: 'app-dashboard-card-create',
  templateUrl: './dashboard-card-create.component.html',
  styleUrls: ['./dashboard-card-create.component.scss']
})
export class DashboardCardCreateComponent implements OnInit {

  constructor(private bottomSheetService: BottomSheetService) { }

  ngOnInit() {
  }

  createGroup() {
    this.bottomSheetService.openBottomSheetWithData(DashboardCreateGroupComponent, 'DashboardCreateGroupComponent',{'id': 0});
  }

}
