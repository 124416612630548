import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {FieldConfig} from '../../../dynamic-form/models/field-config.interface';
import {DynamicFormComponent} from '../../../dynamic-form/containers';
import {RecordsService} from '../../../common/services/records.service';

@Component({
  selector: 'app-notification-setings',
  templateUrl: './notification-settings.component.html',
  styleUrls: ['./notification-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class NotificationSettingsComponent implements OnInit {
  notificationOptions: FieldConfig[] = [
        {
            type: 'checkbox',
            label: 'Account Notifications',
            name: 'accountNotifications',
            checked: true,
            required: false,
        },
        {
            type: 'checkbox',
            label: 'TrackitHub Marketing',
            name: 'trackitHubMarketing',
            checked: true,
            required: false,
        },
        {
            type: 'checkbox',
            label: 'Mobile Text Notifications',
            name: 'addressPrivacy',
            checked: true,
            required: false,
        },
    ];

  constructor() { }

  ngOnInit() {
  }

}
