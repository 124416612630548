import {ChangeDetectionStrategy, Directive, HostListener, Inject, Input} from '@angular/core';
import { BottomSheetService } from '../services/bottom-sheet.service';
import { DashboardLocationsComponent } from '../../dashboard/dashboard-locations/dashboard-locations.component';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material';

@Directive({
  selector: '[appCreateLocation]',
})
export class CreateLocationDirective {

  @Input() id: number;
  @Input() profile_id: number;
  @Input() clubcard: boolean;
  @Input() locations: any;
  @Input() club: any;

  constructor(
    private bottomSheetService: BottomSheetService,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
  ) { }
  @HostListener('click', ['$event.target.id']) onClick() {
    this.bottomSheetService.openBottomSheetWithData(DashboardLocationsComponent, 'DashboardLocationsComponent',{club: this.club, list: this.locations, id: this.id, profile_id: this.profile_id, clubcard: this.clubcard} );
  }

}
