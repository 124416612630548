import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  OnInit,
  AfterViewInit,
  OnDestroy
} from '@angular/core';
import {FormAccountSettingsService} from '../../../common/services/form-account-settings.service';
import {AccountService} from '../../../common/services/account.service';
// @ts-ignore
import { } from '@types/googlemaps';
import { ToastService} from '../../../common/toast';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-user-details-edit-settings',
  templateUrl: './user-details-edit-settings.component.html',
  styleUrls: ['./user-details-edit-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})

export class UserDetailsEditSettingsComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() adressType: string;

  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  @Output() setAddressAlt: EventEmitter<any> = new EventEmitter();

  @ViewChild('address', {static: false}) address: any;
  @ViewChild('altaddress', {static: false}) altaddress: any;

  @Input() userData;
  @ViewChild('picker', {static: true}) picker: any;

  s1 = null;

  profileData: any;
  formStatus: any;
  options: any;
  genders: any;
  nameLabel: any;
  emailprim: boolean;
  emailaltprim: boolean;
  addressprim: boolean;
  altaddressprim: boolean;
  phonepri: boolean;
  altphonepri: boolean;
  formState: boolean;
  genderValue: any;
  ratingValue: any;
  rawdata: any;
  mobilephone: any;
  altmobile: any;
  addressobj: any;
  addressaltobj: any;
  timezones: any;
  timezoneValue: any;

  constructor(private toastService: ToastService, private accountService: AccountService, public formService: FormAccountSettingsService, private http: HttpClient) {

    this.timezones = [
      {label: 'Hawaii-Aleutian Time', value: 'Hawaii-Aleutian Time'},
      {label: 'Alaska Time', value: 'Alaska Time'},
      {label: 'Pacific Time', value: 'Pacific Time'},
      {label: 'Mountain Time', value: 'Mountain Time'},
      {label: 'Central Time', value: 'Central Time'},
      {label: 'Eastern Time', value: 'Eastern Time'}
      ];

    this.options = ['1.0', '0.0', '1.5', '2.0', '2.5', '3.0', '3.5', '4.0', '4.5', '5.0'];
    this.genders = [ {label: 'Female', value: 'COM_COMMUNITY_FEMALE'}, {label: 'Male', value: 'COM_COMMUNITY_MALE'}, {label: 'Non-binary', value: 'COM_COMMUNITY_BIN'}, {label: 'Prefer not to say', value: 'COM_COMMUNITY_NON'}];

    this.addressobj = null;
    this.addressaltobj = null;

    this.s1 = this.accountService.cast.subscribe(data => {
      this.profileData = data.current_profile;
      if (this.profileData.group_id === '15') {
        if (this.profileData.league === '1' ) {
          this.nameLabel = 'Organization Name';
        } else {
          this.nameLabel = 'Club Name';
        }
      }


      this.rawdata = data.current_profile.raw;
      this.formService.initializeFormGroup(data.current_profile.raw);

      this.formStatus = false;
      this.emailaltprim = false;
      this.emailprim = true;
      this.addressprim = true;
      this.altaddressprim = false;
      this.altphonepri = false;
      this.phonepri = true;

      this.genderValue = this.rawdata.gender;
      this.ratingValue = this.rawdata.rating;

      this.timezoneValue = this.rawdata.timezone;



      if (this.rawdata.phonemobile === '1') {
        this.mobilephone = true;
      } else {
        this.mobilephone = false;
      }

      if (this.rawdata.altmobile === '1') {
        this.altmobile = true;
      } else {
        this.altmobile = false;
      }
    });
  }


  open() {
    this.picker.open();
  }


  ngOnInit() {
    this.formService.disableForm();
  }

  ngAfterViewInit() {
    this.getPlaceAutocomplete();
  }

  // Prevent search from submitting if user hits return
  noEnter(e) {
    const key = e.key ;
    return key !== 13;
  }

  private getPlaceAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(this.address.nativeElement,
      {
        componentRestrictions: { country: ['US', 'UK', 'CA', 'MX']},
        types: ['address']  // 'establishment' / 'address' / 'geocode'
      });
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();
      this.invokeEvent(place);
      this.addressobj = place;
    });

    const autocompletealt = new google.maps.places.Autocomplete(this.altaddress.nativeElement,
      {
        componentRestrictions: { country: ['US', 'UK', 'CA', 'MX']},
        types: ['address']  // 'establishment' / 'address' / 'geocode'
      });
    google.maps.event.addListener(autocompletealt, 'place_changed', () => {
      const place = autocompletealt.getPlace();
      this.invokeEvent(place);
      this.addressaltobj = place;
    });
  }

  invokeEvent(place: Object) {
    this.setAddress.emit(place);
  }



  save() {
    this.formState = false;


    this.http.post<any>(environment.service.trackithubapi + '/api/inside/updateprofile', { raw: this.formService.form.value, addressobj: this.addressobj, addressaltobj: this.addressaltobj, profile_id: this.profileData.profile_id, profile_type: this.profileData.profile_type }).subscribe(data => {

      this.accountService.load();
      this.toastService.show({
        text: `Profile Settings have been saved.`,
        type: 'success',
      });

    });


  }

  edit() {
    this.formStatus = true;
    this.formService.enableForm();
  }

  cancel() {
    this.formStatus = false;
    this.formService.disableForm();
    this.toastService.show({
      text: `Profile settings have been reset.`,
      type: 'warn',
    });
    this.formService.initializeFormGroup(this.rawdata);
  }


  functemailprim() {
    if (this.emailprim === true) {
      this.emailprim = false;
      this.emailaltprim = true;
    } else {
      this.emailprim = true;
      this.emailaltprim = false;
    }
  }

  functaddressprim() {
    if (this.addressprim === true) {
      this.addressprim = false;
      this.altaddressprim = true;
    } else {
      this.addressprim = true;
      this.altaddressprim = false;
    }
  }

  functphonepri() {
    if (this.phonepri === true) {
      this.phonepri = false;
      this.altphonepri = true;
    } else {
      this.phonepri = true;
      this.altphonepri = false;
    }
  }

  ngOnDestroy() {
    if (this.s1 != null) {
      this.s1.complete();
    }
  }

}
