import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewChild,
  AfterViewInit, OnDestroy
} from '@angular/core';
import { MatPaginator, MatSort, MatTable, MatTableDataSource } from '@angular/material';
import { SelectionModel} from '@angular/cdk/collections';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { AccountService} from '../../common/services/account.service';
import {ActivatedRoute, Router} from '@angular/router';
import { ToastService} from '../../common/toast';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { BottomSheetService } from '../../common/services/bottom-sheet.service';
import { LoaderOverlayService } from '../../common/services/loader-overlay.service';
import {MatDialog} from '@angular/material/dialog';


export interface AmbassadorElement {
  id: number;
  email: string;
  group_id: number;
  group_name: string;
  group_owner_id: number;
  group_owner: string;
  approved: string;
  admin: string;
  user_id: number;
  first_name: string;
  last_name: string;
  gender: string;
  rating: string;
  override: string;
  ipf_rating: string;
  groupping: string;
  played: string;
  filter?: any;
}


@Component({
  selector: 'app-ambassador-table',
  templateUrl: './ambassador-table.component.html',
  styleUrls: ['./ambassador-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})

export class AmbassadorTableComponent implements OnDestroy, OnInit, AfterViewInit {

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatTable, {static: false}) table: MatTable<AmbassadorElement>;

  env: any;

  s1 = null;
  lasturl = null;

  archived = false;

  smallScreen: boolean;
  bigScreen: boolean;

  selectedgroup = null;

  dataSource = new MatTableDataSource();
  selection = new SelectionModel<AmbassadorElement>(true, []);

  data = [];

  account;
  param = null;


  constructor(private router: Router, public dialog: MatDialog, private bottomSheetService: BottomSheetService, private breakpointObserver: BreakpointObserver, private http: HttpClient, private accountService: AccountService, private route: ActivatedRoute, private toastService: ToastService, private LoaderOverlayService: LoaderOverlayService) {
    breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small
    ]).subscribe(result => {
      this.smallScreen = result.matches;
      this.bigScreen = !result.matches;
    });

    this.env = environment;

    this.LoaderOverlayService.overlayRef = null;

    this.currentWindowWidth = window.innerWidth;


  }

  public currentWindowWidth: number;
  displayedColumns = [];


  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(<AmbassadorElement>row));
  }

  onResize() {
    this.currentWindowWidth = window.innerWidth;
    this.redraw(this.data);
  }

   applyFilter(filterValue: string) {
     this.dataSource.filter = filterValue.trim().toLowerCase();
     if (this.dataSource.paginator) {
       this.dataSource.paginator.firstPage();
     }
   }


  redraw(data) {

    if(this.LoaderOverlayService.overlayRef == null) {
      this.LoaderOverlayService.show();
    }

    this.data = data;

    if(this.account.current_profile.profile_type == 'player'){
      this.displayedColumns = ['event_name', 'player_name', 'registered', 'price', 'ambassador_pay'];
    } else {
      this.displayedColumns = ['event_name', 'ambassador_name', 'payer_name', 'registered', 'order_id', 'player_name', 'price', 'ambassador_perc', 'ambassador_pay'];
    }


    this.dataSource.data = data;
    this.selection.clear();
    this.killoverlay();
  }


  killoverlay(){
    if(this.LoaderOverlayService.overlayRef != null) {
      this.LoaderOverlayService.hide();
    }
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit() {
    this.s1 = this.accountService.cast.subscribe(data => {
      this.account = data;
      this.pulldata();
    });
  }

  ngOnDestroy() {
    this.LoaderOverlayService.overlayRef = null;
    if (this.s1 != null) {
      this.s1.complete();
    }
  }

  pulldata() {

    if (this.LoaderOverlayService.overlayRef == null) {
      this.LoaderOverlayService.show('Loading Data...');
    }


    if(this.account.current_profile.profile_type !== null) {
      this.lasturl = environment.service.trackithubapi + '/api/inside/pullambassador?profile_type=' + this.account.current_profile.profile_type + '&profile_id=' + this.account.current_profile.profile_id;
      this.http.get<AmbassadorElement>(this.lasturl).subscribe((data: any) => {
        this.redraw(data);
      });
    }
  }
}

