import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Output,
  ViewChild
} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AccountService} from '../../common/services/account.service';
import {Router} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {RetrioeveaddressService} from '../../common/services/retrioeveaddress.service';
import {ToastService} from '../../common/toast';
import {SessionStorageService} from 'ngx-webstorage';
import {FormControl} from '@angular/forms';
import {environment} from '../../../environments/environment';
import {Account} from '../../common/interfaces/account';
import {Subscription} from "rxjs";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-group-select',
  templateUrl: './group-select.component.html',
  styleUrls: ['./group-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class GroupSelectComponent implements AfterViewInit {
  @Output() TriggerClosePanel = new EventEmitter<any>();
  appDrawer: any;
  windowScrolling: any;
  account: Account;
  returnurl = null;
  username = null;
  epassw = null;
  url = null;

  toppings = new FormControl();
  list_groups = [];
  next = null;
  reflector = null;

  private suba: Subscription;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private sessionSt: SessionStorageService, private toastService: ToastService, private getaddress: RetrioeveaddressService, private domSanitizer: DomSanitizer, private http: HttpClient, private accountService: AccountService, private router: Router, public dialogRef: MatDialogRef<GroupSelectComponent>) {
    this.list_groups = this.data.location.list_groups;
    this.next = this.data.next;
    this.reflector = this.data.reflector;

    this.suba = this.accountService.cast.subscribe(data => {
      this.account = data;
    });
  }

  addgroups(nxt) {

    this.next = nxt;

    if (this.next === null) {
      this.epassw = this.account.current_profile.seckey;
      this.username = this.account.current_profile.username;

      if (this.toppings.value !== null) {
        const datastring = this.toppings.value.join('|');
        this.http.get<any>(environment.service.trackithubapi + '/api/inside/groupadd?group_id=' + datastring + '&profile=' + this.sessionSt.retrieve('current_profile')).subscribe((data: any) => {
          this.accountService.reloadprofile();
          this.toastService.show({
            text: 'Your request to join the group has been submitted and will be reviewed by the club admin.',
            type: 'success',
            buttonText: '',
          });

          this.returnurl = environment.service.trackithubapi + '/api/inside/checkmembership?list=' + datastring;
          this.returnurl = btoa(this.returnurl);
          this.url = environment.service.trackithubapi + '/index.php?user=' + this.username + '&epassw=' + this.epassw + '&redirect=' + encodeURI(this.returnurl);
          this.http.get<any>(this.url).subscribe((datass: any) => {
            if (datass != '0') {
              window.location.replace('/dashboard/frame/dues?group_id=' + datass);
            }
          });
          this.dialogRef.close(null);
        });

      } else {
        this.toastService.show({
          text: 'You have not selected any groups to join.',
          type: 'warn',
        });
      }
    } else if (this.next === 'login') {
      this.sessionSt.store('joinoutside', this.toppings.value.join('|'));
      this.dialogRef.close('login');
      this.router.navigate(['/login'], { queryParams: {} });
    } else {
      this.sessionSt.store('joinoutside', this.toppings.value.join('|'));
      this.dialogRef.close('register');
      this.router.navigate(['/register/player'], { queryParams: {} });
    }
  }

  closemodal() {
    this.dialogRef.close(null);
  }

  ngAfterViewInit() {

  }





}
