import {Component, NgZone, ViewChild, ChangeDetectionStrategy, OnInit, Inject, OnDestroy} from '@angular/core';
import {CdkTextareaAutosize} from '@angular/cdk/text-field';
import {HttpClient} from '@angular/common/http';
import {RecordsService} from '../../common/services/records.service';
import {Backplane} from '../../common/services/backplane.service';
import {take} from 'rxjs/operators';
import { Options, LabelType } from 'ng5-slider';
import { MAT_BOTTOM_SHEET_DATA} from '@angular/material';
import {ActivatedRoute} from '@angular/router';
import {ToastService} from '../../common/toast';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import {AccountService} from '../../common/services/account.service';
import {FormGroupeditService} from '../../common/services/form-groupedit.service';
import {ModalService} from '../../common/services/modal.service';
import {CreateLocationComponent} from '../../frontend/create-location/create-location.component';
import {BottomSheetService} from '../../common/services/bottom-sheet.service';
import {SessionStorageService} from "ngx-webstorage";
import {environment} from "../../../environments/environment";

interface Avatar {
  value: string;
  viewValue: string;
}


@Component({
  selector: 'app-dashboard-create-group',
  templateUrl: './dashboard-create-group.component.html',
  styleUrls: ['./dashboard-create-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})


export class DashboardCreateGroupComponent implements OnInit, OnDestroy {

  cropReady = false;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  isValid: boolean;
  sending: boolean;

  s1 = null;

  selectedAvatar: any = '';
  avatars: Avatar[] = [
    {value: 'club', viewValue: 'Club'},
    {value: 'grin', viewValue: 'Grin'},
    {value: 'slight-smile', viewValue: 'Slight Smile'},
    {value: 'smile', viewValue: 'Smile'},
    {value: 'smiling-face-eyes', viewValue: 'Smiling Face Eyes'},
    {value: 'smirking-face', viewValue: 'Smirking Face'},
    {value: 'tilted-grin', viewValue: 'Tilted-grin'},
    {value: 'winking-grin', viewValue: 'Winking-Grin'},
    {value: 'worried-smile', viewValue: 'Worried-Smile'},
  ];

  card: any;
  paymentRequired = false;
  primaryGroup = false;
  records = {};
  grpName: boolean;
  groupName = '';
  clubDescription = '';
  action = false;
  stepLabel = ['Step One', 'Step Two', 'Step Three'];
  visible = true;
  tags: string[] = [];
  allTags: string[] = ['Singles', 'Doubles', 'Summer', 'Winter', 'Fall', 'Spring', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday', 'Weekends', 'Weekdays', 'Mornings', 'Afternoons', 'Evenings', 'Challenge Play', 'Tennis', 'Indoor', 'Outdoor', 'Recreation', 'Competitive', 'Weekly', 'Bi-Weekly', 'Bi-Monthly', 'Monthly', 'Social'];
  groupLabels: string[] = ['Membership', 'Ladder-League', 'Shootout', 'Round-Robin', 'Admins', 'Clinics', 'Boot Camp', 'Open Play', 'League', 'Tournament', 'Executive Board', 'Test'];
  groupLists: string[] = ['Select all', 'Oripa Men And Ladies Shootout', 'Oripa Men\'s Group'];
  value = 100;
  sexs: string[] = ['Men', 'Women', 'Mixed', 'Genderless'];
  minValue = 2;
  maxValue = 3;
  createstring = 'Create Group';

  current_profile: any;
  account: any;
  loc_id: any;

  options: Options = {
    floor: .5,
    ceil: 5,
    step: .5,
    showTicks: true,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return value.toFixed(1);
        case LabelType.High:
          return value.toFixed(1);
        default:
          return value.toFixed(1);
      }
    }
  };

  storage: any;
  sheetRef: any = this.data.ref;


  constructor(
    private http: HttpClient,
    private validationService: RecordsService,
    private getters: Backplane,
    private _ngZone: NgZone,
    private route: ActivatedRoute,
    private toastService: ToastService,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private accountService: AccountService,
    public formService: FormGroupeditService,
    private bottomSheetService: BottomSheetService,
    private modalService: ModalService,
    private sessionSt: SessionStorageService
  ) {
    this.s1 = this.accountService.cast.subscribe(dataz => {
      this.current_profile = dataz.current_profile;
      if (this.data.id.id === 0) {
        this.createstring = 'Create Group';
        this.card = {
          loc_as_label: null,
          name: null,
          title: null,
          primary_location: null,
          minrate: 2,
          maxrate: 3,
          params: {
            wallnotification: true,
            newmembernotification: true,
            joinrequestnotification: true,
            membership: false,
            allmembers: false,
          },
          approvals: true,
        };
      } else {
        this.createstring = 'Save';
        this.card = this.data.id.card;

        if (this.card.chip1 !== '' && this.card.chip1 != null) {
          this.tags.push(this.card.chip1);
        }

        if (this.card.chip2 !== '' && this.card.chip2 != null) {
          this.tags.push(this.card.chip2);
        }

        if (this.card.chip3 !== '' && this.card.chip3 != null) {
          this.tags.push(this.card.chip3);
        }

        if (this.card.chip4 !== '' && this.card.chip4 != null) {
          this.tags.push(this.card.chip4);
        }


        if (this.card.minrate == null) {
          this.card.minrate = 2;
        }

        if (this.card.maxrate == null) {
          this.card.maxrate = 3;
        }


        this.loc_id = this.card.primary_location;

      }

      this.minValue = this.card.minrate;
      this.maxValue = this.card.maxrate;


    });




  }


  @ViewChild('autosize', {static: false}) autosize: CdkTextareaAutosize;

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.croppedImage = '';
    this.cropReady = false;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded() {
    this.cropReady = true;
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  updatedavatar($event) {
    this.selectedAvatar = $event.value;
  }

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1))
      .subscribe(() => this.autosize.resizeToFitContent(true));
  }

  location() {
    this.modalService.openDialog(CreateLocationComponent, 'auto', 'auto', {clucard: false, group_id: this.data.id.id, profile_id: this.current_profile.profile_id});
  }

  ngOnInit() {

    this.isValid = false;
    this.sending = false;

    this.selectedAvatar = this.card.avatar_icon;

    interface sModal {
      chips: any;
      primary_location: any;
      title: string;
      label: string;
      loc_as_group: boolean;
      minrate: string;
      maxrate: string;
      gender: string;
      description: string;
      wallnotification: boolean;
      newmembernotification: boolean;
      joinrequestnotification: boolean;
      approvals: boolean;
      allmembers: boolean;
      membership: boolean;
      limitgroups: any;
      profile: string;
      id: string;
      avatar: string;
      avatar_icon: string;
    }

    this.storage = <sModal>{};


    this.formService.initializeFormGroup(this.card);
    this.formService.form.valueChanges.subscribe(data => {
      this.isValid = false;
      this.storage.chips = this.tags;
      this.storage.primary_location = data.primary_location;
      this.storage.title = data.title;
      this.storage.loc_as_group = data.loc_as_group;
      this.storage.minrate = this.minValue;
      this.storage.maxrate = this.maxValue;
      this.storage.gender = data.gender;
      this.storage.label = data.label;
      this.storage.description = data.description;
      this.storage.wallnotification = data.wallnotification;
      this.storage.newmembernotification = data.newmembernotification;
      this.storage.joinrequestnotification = data.joinrequestnotification;
      this.storage.approvals = data.approvals;
      this.storage.allmembers = data.allmembers;
      this.storage.membership = data.membership;
      this.storage.limitgroups = data.limitgroups;
      this.storage.profile = this.current_profile.profile_id;
      this.storage.id = this.card.id;
      this.storage.avatar = this.croppedImage;
      this.storage.avatar_icon = data.selectavatar;
    });
  }

  finishReg($event) {



    if (this.sending === true) {
      //this.toastService.show({
      //  text: 'Your request is being processed.',
      //  type: 'success',
      //});
    } else {



      if (this.storage.description === undefined) {
        this.toastService.show({
          text: 'Complete all required fields before creating the group.',
          type: 'warn',
        });
      } else {
        this.isValid = true;
      }


      if (this.isValid === true) {


        this.storage.maxrate = this.maxValue;
        this.storage.minrate = this.minValue;
        this.storage.chips = this.tags;



        this.sending = true;

        this.http.post(environment.service.trackithubapi + '/api/inside/makeeditgroup', this.storage, {}).subscribe((data: any) => {

          if (this.sessionSt.retrieve('add_location') !== undefined) {
            if (this.sessionSt.retrieve('add_location') !== '') {
              this.http.get(environment.service.trackithubapi + '/api/inside/associategrouplocation?location=' + this.sessionSt.retrieve('add_location') + '&group=' + data.group_id + '&value=0', {}).subscribe((datax: any) => {
                this.sessionSt.clear('add_location');
                this.sending = false;
              }, err => {
                this.toastService.show({
                  text: 'System error, please contact support and report this error.',
                  type: 'alert',
                });
                this.sending = false;
                this.isValid = false;
              });
            }
          }

          if (this.storage.id === 0) {
            this.toastService.show({
              text: 'Group has been created.',
              type: 'success',
            });
          } else {
            this.toastService.show({
              text: 'Group has been updated.',
              type: 'success',
            });
          }

          this.accountService.reloadprofile();

          this.sheetRef.dismiss();
        });
      }

    }
  }

  ngOnDestroy() {
    if (this.s1 != null) {
      this.s1.complete();
    }
  }

}
