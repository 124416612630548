import {Component, Inject, ViewChild} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';


@Component({
  selector: 'app-image-dialog',
  templateUrl: './image-dialog.component.html',
  styleUrls: ['./image-dialog.component.scss']
})
export class ImageDialogComponent  {

  postPlaceholder: string = "What's New?";
  postOpen: boolean = true;
  postValue: string;
  post_id: number;

  @ViewChild('postField', {static: false}) postField;

  constructor(public dialogRef: MatDialogRef<ImageDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: {post: any}) {

   }

  onNoClick(): void {
    this.dialogRef.close({comment: null});
  }
}
