import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {PPLEvent} from '../../../../ppl/shared/ppl.interface';

@Component({
    selector: 'app-ppl-card-details',
    templateUrl: './ppl-card-details.component.html',
    styleUrls: ['./ppl-card-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class PPLCardDetailsComponent {
   @Input() event: PPLEvent;


  @Input() jomleaguetype: string;
  @Input() jomjomrating: string;
  @Input() leaguetype: string;
  @Input() maxdate: string;
  @Input() mindate: string;



    // /*
    // * Sets icon type based on data type
    // * */
    dataType(items) {
        switch (items) {
            case 'rating':
                return 'star_rate';
            case 'league_type':
                return 'person';
            case  'group':
                return 'group';
            case 'event:':
                return 'event';
            case 'location':
                return 'location_on';
            case 'event_time':
                return 'alarm';
            case 'league_contact':
                return 'perm_contact_calendar';
            case 'email_contact':
                return 'email';
        }
    }

    constructor() {
    }

    // When User clicks on address it will open either Apple Maps or Google Maps based on device
    mapsSelector() {
        if ((navigator.platform.indexOf('iPhone') !== -1) ||
            (navigator.platform.indexOf('iPad') !== -1) ||
            (navigator.platform.indexOf('iPod') !== -1)) {
            window.open(encodeURI(`http://maps.apple.com/?address=1,${this.event.location.address},${this.event.location.city},${this.event.location.state}`));
        } else { /* else use Google */
            window.open(encodeURI(`https://maps.google.com/maps?api-1&destination=${this.event.location.address}+${this.event.location.city}+${this.event.location.state}`));
        }
    }
}
