import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {BottomSheetService} from '../../common/services/bottom-sheet.service';
import {RegisterProfileComponent} from '../../register/profile_register/register-profile.component';


@Component({
  selector: 'app-create-profile-menu',
  templateUrl: './create-profile-menu.component.html',
  styleUrls: ['./create-profile-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class CreateProfileMenuComponent implements OnInit {

  @Input() account_id: number;

  constructor(private bottomSheetService: BottomSheetService) { }

  ngOnInit() {
  }

  onClickCreate(type) {
    this.bottomSheetService.openBottomSheetWithData(RegisterProfileComponent, 'RegisterProfileComponent',{type: type, account_id: this.account_id});
  }

}
