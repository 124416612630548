import {Component, Inject, ViewChild} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';


@Component({
  selector: 'app-comment-dialog',
  templateUrl: './comment-dialog.component.html',
  styleUrls: ['./comment-dialog.component.scss']
})
export class CommentDialogComponent  {

  postPlaceholder: string = "What's New?";
  postOpen: boolean = true;
  postValue: string;
  post_id: number;

  @ViewChild('postField', {static: false}) postField;

  constructor(public dialogRef: MatDialogRef<CommentDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: {post: any}) {
      this.post_id = data.post.id;
   }

  processPost(){
    this.dialogRef.close({comment: {comment: this.postValue, id: this.post_id}});
  }

  onNoClick(): void {
    this.dialogRef.close({comment: null});
  }
}
