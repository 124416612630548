import {MAT_BOTTOM_SHEET_DATA} from '@angular/material';
import {RenewalElement} from '../dashboard-usersheet-sheet/dashboard-usersheet-sheet.component';
import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  Inject,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef, OnDestroy
} from '@angular/core';
import { MatPaginator, MatSort, MatTable, MatTableDataSource } from '@angular/material';
import {environment} from '../../../environments/environment';
import {AccountService} from '../../common/services/account.service';
import {ToastService} from '../../common/toast';
import {HttpClient} from '@angular/common/http';
import {CreateLocationComponent} from '../../frontend/create-location/create-location.component'
import {ModalService} from '../../common/services/modal.service';
import {DialogData, DialogRosterAdminRemove, RosterElement} from '../dashboard-roster/roster-table.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {PanelService} from '../../common/services/panel.service';
import {PickleballPlaceDetailsComponent} from '../../frontend/pickleball-place-details/pickleball-place-details.component';
import { SearchAddressService } from '../../common/services/searchaddress.service';
import { PanelCardService } from '../../common/services/panelcard.service';
import {Account} from "../../common/interfaces/account";
import {Router} from "@angular/router";
import {Subscription} from "rxjs";
import {SessionStorageService} from "ngx-webstorage";
export interface RenewalElement {
  name: string;
  id: string;
}

@Component({
  selector: 'app-dashboard-locations',
  templateUrl: './dashboard-locations.component.html',
  styleUrls: ['./dashboard-locations.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class DashboardLocationsComponent implements OnInit, AfterViewInit, OnDestroy {

  private suba: Subscription;
  private subb: Subscription;

  profile_id: any;
  displayedColumns = ['title', 'action'];
  dataSource = new MatTableDataSource();
  dataSourcex = new MatTableDataSource();
  listdata: any;
  clubcard: boolean;
  group_id: number;
  timer: any;
  tabindex: any;

  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatTable, {static: false}) table: MatTable<RenewalElement>;
  @ViewChild(MatSort, {static: false}) sortx: MatSort;
  @ViewChild(MatTable, {static: false}) tablex: MatTable<RenewalElement>;

  constructor(
    private sessionSt: SessionStorageService,
    private panelService: PanelService,
    private searchSubject: SearchAddressService,
    private panelcardSubject: PanelCardService,
    private ref: ChangeDetectorRef,
    private http: HttpClient,
    private toastService: ToastService,
    private accountService: AccountService,
    private router: Router,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private modalService: ModalService,
    public dialog: MatDialog) {
      this.clubcard = this.data.id.clubcard;
      this.group_id = this.data.id.id;
    }
  sheetRef: any = this.data.ref;

  editlocation(loc) {
    this.modalService.openDialog(CreateLocationComponent, 'auto', 'auto', {location: loc, accountService: this.accountService});
  }

  goto(location) {
    this.sheetRef.dismiss();
    this.data.id.club.locations = this.data.id.list;
    this.http.get<Account>(environment.service.trackithubapi + '/api/outside/getcard?card=' + this.data.id.club.ownerid).subscribe((datag: any) => {
      this.panelcardSubject.selectCard(datag.result, location, location.title);
      this.http.get<Account>(environment.service.trackithubapi + '/api/outside/clubscloseby?card=' + this.data.id.club.ownerid).subscribe((datax: any) => {
        // tslint:disable-next-line:radix
        this.searchSubject.showclub(datax, parseFloat(location.geolat), parseFloat(location.geolon));
        this.panelService.openPanel(PickleballPlaceDetailsComponent,'PickleballPlaceDetailsComponent');
      });
    });
  }

  addlocation() {
    this.modalService.openDialog(CreateLocationComponent, 'auto', 'auto', {clubcard: this.clubcard, group_id: this.group_id, profile_id: this.profile_id, accountService: this.accountService});
  }

  removelocation(element) {
    const dRef = this.dialog.open(LocationDelete, {
      data: {
        location: element, accountService: this.accountService
      }
    });
    dRef.afterClosed().subscribe(result => {
      if (result.event == 'Success') {
        this.http.get<any>(environment.service.trackithubapi + '/api/inside/deletelocation?location=' + element.loc_id ).subscribe((data: any) => {
          this.accountService.reloadprofile();
          this.redraw();
          this.toastService.show({
            text: 'Location has been deleted.',
            type: 'success',
            buttonText: '',
          });
        });
      } else if (result.event == 'Error') {

      }
    });
  }

  ngOnDestroy() {
    this.subb.unsubscribe();
  }

  ngOnInit() {
    this.subb = this.accountService.cast.subscribe(data => {
      this.profile_id = data.current_profile.profile_id;
      this.listdata = data.current_profile.locations;
      this.redraw();
    });
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSourcex.sort = this.sortx;
  }

  redraw() {

    const array = this.listdata;
    const newarray = [];

    for (const element of array) {
      if (element.groups.includes(this.group_id)) {
        newarray.push(element);
      }
    }

    this.dataSource.data  = newarray;
    this.dataSourcex.data = this.listdata;
    this.ref.detectChanges();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  addlocationtogroup(element) {
    this.http.get<any>(environment.service.trackithubapi + '/api/inside/associategrouplocation?location=' + element.loc_id + '&group=' + this.group_id + '&value=0').subscribe((data: any) => {
      this.accountService.reloadprofile();
      this.redraw();
      this.toastService.show({
        text: 'Added location to group.',
        type: 'success',
        buttonText: '',
      });
    });
    this.tabindex = (1 + 1) % 2;
  }

  removelocationfromgroup(element) {
    this.http.get<any>(environment.service.trackithubapi + '/api/inside/associategrouplocation?location=' + element.loc_id + '&group=' + this.group_id + '&value=1').subscribe((data: any) => {
      this.accountService.reloadprofile();
      this.redraw();
      this.toastService.show({
        text: 'Removed location from group.',
        type: 'success',
        buttonText: '',
      });
    });
  }

  rejectConfirm(element) {
    this.http.get<any>(environment.service.trackithubapi + '/api/inside/rejectuser?group_id=' + element.groupid + '&player_id=' + element.id).subscribe((data: any) => {
      this.accountService.reloadprofile();
      this.redraw();
      this.toastService.show({
        text: element.name + ' has been Rejected.',
        type: 'success',
        buttonText: '',
      });
    });
  }

  changetab($event) {

    this.tabindex = $event;
  }
}

@Component({
  selector: 'location-delete',
  templateUrl: 'location-delete.html',
  styleUrls: ['location-delete.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class LocationDelete {
  constructor(
    public dialogRef: MatDialogRef<LocationDelete>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close({event:'Cancel'});
  }

  onYesClick(): void {
    this.dialogRef.close({event:'Success'});
  }
}
