import {Injectable, ComponentRef} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  component: ComponentRef<any>;
  width: string;
  height: string;
  data: any;

  constructor(public dialog: MatDialog) {
  }

  openDialogMaxw(component, width, height, data): void {
    const dialogRef = this.dialog.open(component, {
      width: width,
      height: height,
      data: data,
      maxHeight: '100vh',
      minWidth: '100vw'
    });
  }

  openDialogMax(component, width, height, data): void {
    const dialogRef = this.dialog.open(component, {
      width: width,
      height: height,
      data: data,
      maxHeight: '100vh',
    });
  }

  openDialog(component, width, height, data): void {
    const dialogRef = this.dialog.open(component, {
      width: width,
      height: height,
      data: data,
      maxHeight: '80vh'
    });
  }

  openDialogClass(component, width, height, data): void {
    const dialogRef = this.dialog.open(component, {
      width: width,
      height: height,
      data: data,
      maxHeight: '80vh',
      panelClass: 'special-modal',
    });
  }

  openDialogDisable(component, width, height, data): void {
    const dialogRef = this.dialog.open(component, {
      width: width,
      height: height,
      data: data,
      maxHeight: '80vh',
      disableClose: true
    });
  }

  openDialogFull(component, width, height, data): void {
    const dialogRef = this.dialog.open(component, {
      position: {
        top: '10px',
        right: '10px'
      },
      height: '98%',
      width: '100vw',
      panelClass: 'full-screen-modal',
    });
  }


}
