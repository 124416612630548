import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormAccountSettingsService {

  date: any;

  constructor() { }

  form: FormGroup = new FormGroup({
    name: new FormControl(),
    firstName: new FormControl(),
    lastName: new FormControl(),
    email: new FormControl( ),
    altEmail: new FormControl(),
    address: new FormControl(),
    altAddress: new FormControl(),
    phone: new FormControl(),
    altphone: new FormControl(),
    phonemobile: new FormControl(),
    altmobile: new FormControl(),
    emailprim: new FormControl(),
    emailaltprim: new FormControl(),
    addressprim: new FormControl(),
    altaddressprim: new FormControl(),
    rating: new FormControl(),
    gender: new FormControl(),
    birthday: new FormControl(),
    cfname: new FormControl(),
    clname: new FormControl(),
    phonepri: new FormControl(),
    altphonepri: new FormControl(),
    phonemobilepri: new FormControl(),
    phonemobilealt: new FormControl(),
    timezone: new FormControl()


  });

  disableForm() {
    this.form.disable();
  }

  addControl(field) {
    this.form.addControl(field, new FormControl({disabled: true}));
  }

  removeControl(field) {
    this.form.removeControl(field);
  }

  enableForm() {
    this.form.enable();
  }

  setemail(email) {
    this.form.controls.email.setValue(email);
  }

  initializeFormGroup(userData) {

    this.form.controls.name.setValue(userData.name);
    this.form.controls.firstName.setValue(userData.firstName);
    this.form.controls.lastName.setValue(userData.lastName);
    this.form.controls.email.setValue(userData.email);
    this.form.controls.altEmail.setValue(userData.altEmail);
    this.form.controls.address.setValue(userData.address);
    this.form.controls.altAddress.setValue(userData.altAddress);
    this.form.controls.phone.setValue(userData.phone);
    this.form.controls.altphone.setValue(userData.altphone);


    if (userData.birthday !== null) {
      this.date = new Date(userData.birthday);
      this.form.controls.birthday.setValue(this.date);
    }

    this.form.controls.rating.setValue(userData.rating);
    this.form.controls.gender.setValue(userData.gender);
    this.form.controls.timezone.setValue(userData.timezone);

    this.form.controls.cfname.setValue(userData.cfname);
    this.form.controls.clname.setValue(userData.clname);

    this.disableForm();
  }


}
