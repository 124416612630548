import {Injectable} from '@angular/core';

// Author: Ben Nadel
// URL: https://www.bennadel.com/blog/3249-wrapping-the-zendesk-web-widget-in-a-promise-based-zendesk-service-in-angular-2-4-9.htm

// The zEmbed function is a global object, so we have to Declare the interface so that
// TypeScript doesn't complain. The zEmbed object acts as both a pre-load queue as well
// as the API. As such, it must be invocable and expose the API.
declare var zEmbed: {
  // zEmbed can queue functions to be invoked when the asynchronous script has loaded.
  (callback: () => void): void;

  // ... and, once the asynchronous zEmbed script is loaded, the zEmbed object will
  // expose the widget API.
  activate?(options: any): void;
  hide?(): void;
  identify?(user: any): void;
  setHelpCenterSuggestions?(options: any): void;
  setLocale?(locale: string): void;
  show?(): void;
};

interface VisibilityQueueItem {
  resolve: any;
  reject: any;
  methodName: string;
}

@Injectable()

// I wrap the zEmbed object, providing Promise-based method calls so that the calling
// context doesn't have to worry about whether or not the underlying zEmbed object has
// been loaded.
export class ZendeskService {

  private isLoaded: boolean;
  private visibilityDelay: number;
  private visibilityQueue: VisibilityQueueItem[];
  private visibilityTimer: any;


  // I initialize the service.
  constructor() {

    this.isLoaded = false;
    this.visibilityDelay = 500; // Milliseconds.
    this.visibilityQueue = [];
    this.visibilityTimer = null;

    // Since show() and hide() appear to have some sort of race condition, we're
    // going to queue-up pre-loaded calls to those methods. Then, when the zEmbed
    // object has fully loaded, we'll flush that queue, giving us more control over
    // which method is actually applied.


    zEmbed(
      (): void => {

        this.isLoaded = true;
        this.flushVisibilityQueue();

      }
    );

  }


  // ---
  // PUBLIC METHODS.
  // ---


  // I activate and open the widget in its starting state.
  public activate(options: any): Promise<void> {

    return (this.promisify('activate', [options]));

  }


  // I completely hide all parts of the widget from the page.
  public hide(): Promise<void> {

    return (this.promisifyVisibility('hide'));

  }


  // I identify the user within Zendesk (and setup the pre-populated form data).
  public identify(user: any): Promise<void> {

    return (this.promisify('identify', [user]));

  }


  // I enhance the contextual help provided by the Zendesk web widget.
  public setHelpCenterSuggestions(options: any): Promise<void> {

    return (this.promisify('setHelpCenterSuggestions', [options]));

  }


  // I set the language used by the widget.
  public setLocale(locale: string): Promise<void> {

    // CAUTION: This method is provided for completeness; however, it really
    // shouldn't be invoked from this Service. Really, it should be called from
    // within the script that loads the bootstrapping script.
    return (this.promisify('setLocale', [locale]));

  }


  // I display the widget on the page in its starting 'button' state.
  public show(): Promise<void> {

    return (this.promisifyVisibility('show'));

  }


  // ---
  // PRIVATE METHODS.
  // ---


  // Since there is an apparent race condition in how often the show and hide methods
  // can be called for the Zendesk widget, these methods get queued up and flushed
  // periodically so that we can control the debouncing of these methods.
  private flushVisibilityQueue(): void {

    // The queue contains the Resolve and Reject methods for the associated Promise
    // objects. We need to iterate over the queue and fulfill the Promises.
    while (this.visibilityQueue.length) {

      const item = this.visibilityQueue.shift();

      // If the queue is still populated after the .shift(), then we are NOT on the
      // last item. As such, we're going to resolve this Promise without actually
      // calling the underlying zEmbed method.
      if (this.visibilityQueue.length) {

        // console.warn('Skipping queued method:', item.methodName);
        item.resolve();

        // If the queue is empty after the .shift(), then we are on the LAST ITEM,
        // which is the one we want to actually apply to the page.
      } else {

        this.tryToApply(item.methodName, [], item.resolve, item.reject);

      }

    }

  }


  // I turn the given zEmbed method invocation into a Promise.
  private promisify(methodName: string, methodArgs: any[]): Promise<void> {

    const promise = new Promise<void>(
      (resolve: Function, reject: Function): void => {

        zEmbed(
          (): void => {

            this.tryToApply(methodName, methodArgs, resolve, reject);

          }
        );

      }
    );

    return (promise);

  }


  // I turn the zEmbed show/hide methods into Promises. Since there is an apparent race
  // condition with these methods, they are queued internally rather than being queued
  // directly with the zEmbed() function. This way, we can control the debouncing.
  private promisifyVisibility(methodName: string): Promise<void> {

    const promise = new Promise<void>(
      (resolve: Function, reject: Function): void => {

        this.visibilityQueue.push({
          resolve: resolve,
          reject: reject,
          methodName: methodName
        });

        // If the zEmbed object hasn't loaded yet, there's nothing more to do -
        // the pre-load state will act as automatic debouncing.
        if (!this.isLoaded) {

          return;

        }

        // If we've made it this far, it means the zEmbed object has fully
        // loaded. As such, we need to explicitly debounce the show / hide method
        // calls by delaying the flushing of our internal queue.

        clearTimeout(this.visibilityTimer);

        this.visibilityTimer = setTimeout(
          (): void => {

            this.flushVisibilityQueue();

          },
          this.visibilityDelay
        );

      }
    );

    return (promise);

  }


  // I try to apply the given method to the zEmbed object, resolving or rejecting the
  // associated Promise object as necessary.
  private tryToApply(
    methodName: string,
    methodArgs: any[],
    resolve: Function,
    reject: Function
  ): void {

    try {

      zEmbed[methodName](...methodArgs);
      resolve();

    } catch (error) {

      reject(error);

    }

  }

}
