import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-remove-friends',
  templateUrl: './remove-friends-component.html',
  styleUrls: ['./remove-friends-component.scss']
})
export class RemoveFriendsComponent implements OnInit {



  constructor(
    public dialogRef: MatDialogRef<RemoveFriendsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {


  }


  onNoClick(): void {
    this.dialogRef.close({event: 'Cancel'});
  }

  onYesClick(): void {
    this.dialogRef.close({event: 'Success'});
  }

  ngOnInit() {
  }

}
