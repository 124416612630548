import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {groupCard} from '../../../class/group-card';

@Component({
  selector: 'app-dashboard-card-chips',
  templateUrl: './dashboard-card-chips.component.html',
  styleUrls: ['./dashboard-card-chips.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class DashboardCardChipsComponent implements OnInit {

  @Input() card: groupCard;

  chip = [];

  constructor() { }



  ngOnInit() {
    if( this.card.chip1 !== null) {
      this.chip.push(this.card.chip1);
    }
    if( this.card.chip2 !== null) {
      this.chip.push(this.card.chip2);
    }
    if( this.card.chip3 !== null) {
      this.chip.push(this.card.chip3);
    }
    if(this.card.chip4 !== null) {
      this.chip.push(this.card.chip4);
    }

    if(this.chip.length === 0){
      this.chip.push('No tags defined');
    }

  }

}
