import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';


@Component({
  selector: 'app-scroll-button',
  templateUrl: './scroll-button.component.html',
  styleUrls: ['./scroll-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class ScrollButtonComponent {
  // Options next || previous
  @Input() direction: string;

  arrowScroll(element) {
    element.scroll({
      left: 100,
      behavior: 'smooth'
    });
  }
}
