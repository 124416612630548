import {Component, ViewContainerRef} from '@angular/core';
import {FormGroup} from '@angular/forms';

import {Field} from '../../models/field.interface';
import {FieldConfig} from '../../models/field-config.interface';

@Component({
  selector: 'app-form-input',
  styleUrls: ['form-textarea.component.scss'],
  template: `
    <mat-form-field class="dynamic-field form-textarea" appearance="standard" [formGroup]="group">
      <ng-container *ngIf="config.label">
        <label for="{{textAreaElement}}">{{config.label}}</label><br><br>
      </ng-container>
        <textarea rows="{{config.rows}}" cols="{{config.cols}}" maxlength="{{config.maxlength}}" #textAreaElement
                  matInput
                  [placeholder]="config.placeholder"
                  [formControlName]="config.name"
                  [required]="config.required"></textarea>
      <mat-hint align="start">{{config.label}}</mat-hint>
      <mat-hint align="end" *ngIf="config.maxlength">{{textAreaElement.value.length}} / {{config.maxlength}}</mat-hint>
    </mat-form-field>
  `
})
export class FormTextareaComponent implements Field {
  config: FieldConfig;
  group: FormGroup;
}
