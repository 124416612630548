import {AfterViewInit, ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { Account } from 'src/app/common/interfaces/account';
import {RosterElement} from "../../dashboard/dashboard-roster/roster-table.component";
import { MatPaginator, MatSort, MatTable, MatTableDataSource } from '@angular/material';
import {SelectionModel} from "@angular/cdk/collections";
import {environment} from "../../../environments/environment";
import {ProfileComponent} from "../profile.component";
import {MatDialog} from "@angular/material/dialog";
import {ModalService} from "../../common/services/modal.service";
import {Router} from "@angular/router";
import {ToastService} from "../../common/toast";
import {DomSanitizer} from "@angular/platform-browser";
import {HttpClient} from "@angular/common/http";
import {CalendarService} from "../../common/services/calendar.service";
import {AccountService} from "../../common/services/account.service";
import {BottomSheetService} from "../../common/services/bottom-sheet.service";
import {MAT_BOTTOM_SHEET_DATA} from "@angular/material/bottom-sheet";
import {RemoveFriendsComponent} from "../../common/components/modals/remove-friends/remove-friends-component";
import {ChatService} from "../../common/services/chat.service";
import {DashboardEmailGroupSheetComponent, removeMemberDialogComponent} from "../../dashboard";


@Component({
  selector: 'app-friends',
  templateUrl: './friends.component.html',
  styleUrls: ['./friends.component.scss']
})
export class FriendsComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatTable, {static: false}) table: MatTable<RosterElement>;


  @Input() account: Account;
  @Input() friends;
  @Input() is_owner;
  @Input() findplayer;
  @Input() profile_type;

  searchValue: any;
  env: any;
  dataSource = new MatTableDataSource();
  selection = new SelectionModel<RosterElement>(true, []);
  displayedColumns = [];
  siteUrl = environment.service.trackithubapi + '/';
  s1: any;
  current_profile: any;

  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
              public chatService: ChatService,
              public dialog: MatDialog,
              private modalService: ModalService,
              private router: Router,
              private toastService: ToastService,
              private sanitizer: DomSanitizer,
              private http: HttpClient,
              private calendarService: CalendarService,
              private accountService: AccountService,
              private ref: ChangeDetectorRef,
              private bottomSheetService: BottomSheetService) {

  }

  ngOnInit() {

    this.env = environment;

    if(this.is_owner == 1){
      this.displayedColumns = ['select','name','chat'];
    } else {
      this.displayedColumns = ['name','friends','chat'];
    }

    this.dataSource.data = this.friends;

    this.selection.clear();


    this.s1 = this.accountService.cast.subscribe(data => {
      this.current_profile = data.current_profile;
    });

  }

  ngOnDestroy() {
    if (this.s1 != null) {
      this.s1.unsubscribe();
    }
  }

  hardSearch(x){
    this.searchValue = x;

    if(this.searchValue.length > 2){
      this.http.get<Account>(environment.service.trackithubapi + '/api/inside/searchusers?profile_id=' + this.account.current_profile.profile_id + '&term=' + this.searchValue).subscribe((data: any) => {
        this.friends = data;
        this.dataSource.data = this.friends;
        this.updateTable();
      });
    } else {
      this.friends = [];
    }

  }

  gotoSearch(){

  }

  refresh() {
    this.http.get<Account>(environment.service.trackithubapi + '/api/inside/loadprofile?profile=' + this.account.current_profile.profile_id).subscribe((profileData: any) => {
      this.friends = profileData.profile.friends;
      this.dataSource.data = this.friends;
      this.updateTable();
    });
  }

  updateTable(){
    this.selection.clear();
    this.dataSource.sort = this.sort;
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(<RosterElement>row));
  }

  applyFilter(filterValue: string) {
    if(filterValue.length > 0) {
      this.dataSource.filter = filterValue.trim().toLowerCase();
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
  }

  openProfile(profile_id, profile_type) {
    if(this.env.service.profile == true) {
      this.http.get<Account>(environment.service.trackithubapi + '/api/inside/loadprofile?clean_group=yes&profile=' + profile_id).subscribe((profileData: any) => {

        this.bottomSheetService.openBottomSheetWithDataCustomClass(ProfileComponent, 'ProfileComponent',{
          'profileData': profileData,
          'type': profile_type
        }, 'custom-bottom-p' );

      });
    }
  }

  AddFriend(profile_id, name) {
    this.http.get<any>(environment.service.trackithubapi + '/api/inside/addfriend?connection_to=' + profile_id + '&connection_from=' + this.account.current_profile.profile_id ).subscribe((data: any) => {
        this.toastService.show({
          text: 'You have successfully added your new friend.  Please wait for them to accept your invitation.',
          type: 'success',
          buttonText: '',
        });
    });
  }

  openChat(data){
    this.chatService.openchatuser({
      profile_id: data.profile_id,
      name: data.name,
      avatarImg: data.avatarImg,
      avatarPlaceholder: data.avatarPlaceholder,
      email: this.data
    });
  }

  emailSelectedMembers(){
    this.bottomSheetService.openBottomSheetWithData(DashboardEmailGroupSheetComponent, 'DashboardEmailGroupSheetComponent',{
      list: this.selection.selected,
      group: this.current_profile.groups[0],
      id: this.current_profile.profile_id,
      current_profile: this.current_profile
    });
  }

  removeSelectedMembersGroup(group_id){
    const dialogRef = this.dialog.open(removeMemberDialogComponent, {
      data: {list: this.selection.selected, club: this.current_profile.profile_id}
    });

    dialogRef.afterClosed().subscribe(result => {

      if(result.status == 'remove'){
        //result.post.name, result.post.name_id, result.post.profile_id

        this.http.post<any>(environment.service.trackithubapi + '/api/inside/bulkremovemembers', {
          profile_id: result.post.club,
          list: result.post.list,
          group_id: group_id
        }).subscribe((data: any) => {
          this.toastService.show({
            text: `Members have been removed.`,
            type: 'success',
          });

          this.refresh();

        });

      }

    });
  }

  removeSelectedMembers(){
    const dialogRef = this.dialog.open(removeMemberDialogComponent, {
      data: {list: this.selection.selected, club: this.current_profile.profile_id}
    });

    dialogRef.afterClosed().subscribe(result => {

      if(result.status == 'remove'){
        //result.post.name, result.post.name_id, result.post.profile_id

        this.http.post<any>(environment.service.trackithubapi + '/api/inside/bulkremovemembers', {
          profile_id: result.post.club,
          list: result.post.list,
          group_id: null
        }).subscribe((data: any) => {
          this.toastService.show({
            text: `Members have been removed.`,
            type: 'success',
          });

          this.refresh();

        });

      }

    });
  }

  clearPlaceholder() {
    const inputElement = document.querySelector('input[matInput]') as HTMLInputElement;
    inputElement.placeholder = '';
  }

  RemoveFriends() {

    const dialogRef = this.dialog.open(RemoveFriendsComponent, {
      data: {count: this.selection.selected.length}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == 'Success') {
        this.http.post<any>(environment.service.trackithubapi + '/api/inside/removefriends',  {
          friends: this.selection.selected
        }).subscribe((data: any) => {
          this.toastService.show({
            text: 'You have successfully removed the selected users.',
            type: 'success',
            buttonText: '',
          });

          this.refresh();

        });
      } else if (result.event == 'Error') {
        this.toastService.show({
          text: `System error, please try again.   If you continue to get this error please contact support.`,
          type: 'warn',
        });
      }
    });

  }



}
