import { Component, ViewContainerRef } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Field } from '../../models/field.interface';
import { FieldConfig } from '../../models/field-config.interface';

@Component({
  selector: 'app-form-input',
  styleUrls: ['form-input.component.scss'],
  template: `
      <mat-form-field [formGroup]="group" appearance="{{config.appearance}}" floatLabel="{{config.floatLabel}}">
          <input autocomplete="new-password" matInput [type]="config.type" mask='{{config.mask}}' [placeholder]="config.placeholder"
                  [formControlName]="config.name" [required]="config.required" [email]="config.email"
                 maxlength="{{config.maxlength}}" #inputField>
          <mat-hint >{{config.label}}</mat-hint>
        <mat-hint align="end" *ngIf="config.maxlength">{{inputField.value.length}} / {{config.maxlength}}</mat-hint>
       </mat-form-field>
  `
})
export class FormInputComponent implements Field {
  config: FieldConfig;
  group: FormGroup;
}
