import {ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {AccountService} from '../../common/services/account.service';
import {Account} from '../../common/interfaces/account';
import {MAT_BOTTOM_SHEET_DATA} from '@angular/material';

@Component({
  selector: 'app-dashboard-settings',
  templateUrl: './dashboard-settings.component.html',
  styleUrls: ['./dashboard-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class DashboardSettingsComponent implements OnInit, OnDestroy {
  settingsTabs = [
    {label: 'Basic Info', content: 'Content 1'},
    {label: 'Subscription', content: 'Content 2'},
    {label: 'Security', content: 'Content 3'},
  ]

  tabIndex: number;
  account: Account;
  s1 = null;

  constructor(private accountService: AccountService, @Inject(MAT_BOTTOM_SHEET_DATA) public data) {
    this.tabIndex = 0;
    if(data.id.tab == 'settings'){
      this.tabIndex = 1;
    }
  }

  ngOnInit() {
    this.s1 = this.accountService.cast.subscribe(data => {
      this.account = data;
    });

  }

  ngOnDestroy() {
    if (this.s1 != null) {
      this.s1.complete();
    }
  }

}
