import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from "@angular/router";
import { HorizontalInfiniteScrollComponent} from './horizontal-infinite-scroll.component';
import { BasicCardComponent } from "../../common/basic-card/basic-card.component";
import {ScrollingModule} from '@angular/cdk/scrolling';
import { MaterialModule } from "../../../material.module";

@NgModule({
  declarations: [
    BasicCardComponent,
    HorizontalInfiniteScrollComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ScrollingModule,
    MaterialModule
  ],
  exports: [
    HorizontalInfiniteScrollComponent
  ]
})
export class HorizontalscrollModule { }
