import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {AccountService} from '../../common/services/account.service';
import {Account} from '../../common/interfaces/account';
import {DomSanitizer} from '@angular/platform-browser';
import {ActivatedRoute, ParamMap} from '@angular/router';
import { LoaderOverlayService } from '../../common/services/loader-overlay.service';
import {Subscription} from "rxjs";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-dashboard-groups',
  templateUrl: './dashboard-groups.component.html',
  styleUrls: ['./dashboard-groups.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})


export class DashboardGroupsComponent implements OnInit, OnDestroy {

  account: Account;

  returnurl = null;
  username = null;
  epassw = null;
  url = null;
  safe = false;
  groupid = null;

  private suba: Subscription;

  constructor(private LoaderOverlayService: LoaderOverlayService, private route: ActivatedRoute, private accountService: AccountService, private domSanitizer: DomSanitizer) {
    this.url = '#';
    this.suba = this.accountService.cast.subscribe(data => {
      this.account = data;
      if(this.account.id !== null) {
        if(this.suba !== undefined) {
          this.suba.unsubscribe();
          this.groupid = this.route.snapshot.params.id;
          this.reloadiframe();
        }
      }
    });
  }

  reloadiframe() {
    if(this.LoaderOverlayService.overlayRef == null) {

      setTimeout(() => {
        this.killoverlay();
      }, 3000);

      this.LoaderOverlayService.show();
    }
    this.returnurl = environment.service.trackithubapi + '/index.php?option=com_community&view=groups&task=viewgroup&groupid=' + this.groupid;
    this.epassw = this.account.current_profile.seckey;
    this.username = this.account.current_profile.username;
    this.returnurl = btoa(this.returnurl);
    this.url = this.domSanitizer.bypassSecurityTrustResourceUrl(environment.service.trackithubapi + '/index.php?user=' + this.username + '&epassw=' + this.epassw + '&redirect=' + this.returnurl);
    if (this.username !== null && this.username !== null && this.returnurl !== null) {
      this.safe = true;
    } else {
      this.safe = false;
    }
  }

  killoverlay(){
    if(this.LoaderOverlayService.overlayRef != null) {
      this.LoaderOverlayService.hide();
    }
  }

  ngOnDestroy() {
    this.LoaderOverlayService.overlayRef = null;
    if (this.suba !== undefined) {
      this.suba.unsubscribe();
    }
  }

  ngOnInit() {

  }

}
