import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-membership',
  templateUrl: './membership.component.html',
  styleUrls: ['./membership.component.scss']
})
export class MembershipComponent implements OnInit {
  title = 'Membership Tracker';
  description = 'TrackitHub’s robust membership management software allows clubs to build customized membership plans. Custom membership creation allows you to build and customize membership types based on what your club has to offer. Align your membership plans by providing exclusive member benefits, like member-only programs and give your members visibility into the value of joining your club.';
  image = 'assets/angular-img/mockups/ipad-membership-tilted.png';
  constructor() { }

  ngOnInit() {
  }

}
