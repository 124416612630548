import { DataSource } from '@angular/cdk/collections';
import { MatPaginator, MatSort } from '@angular/material';
import { map } from 'rxjs/operators';
import { Observable, of as observableOf, merge } from 'rxjs';

// TODO: Replace this with your own data model type
export interface StandingsResultsItem {
  firstName: string;
  lastName: string;
  average: number;
  gender: string;
  played: number;
}

// TODO: replace this with real data from your application
const EXAMPLE_DATA: StandingsResultsItem[] = [
  {
    'firstName': 'Al',
    'lastName': 'Dadswell',
    'gender': 'Male',
    'average': 72.42,
    'played': 14,
  },
  {
    'firstName': 'Al',
    'lastName': 'Seguy',
    'gender': 'Male',
    'average': 77.75,
    'played': 18,
  },
  {
    'firstName': 'Alan',
    'lastName': 'Riley',
    'gender': 'Male',
    'average': 50.66,
    'played': 6,
  },
  {
    'firstName': 'Barbara',
    'lastName': 'Campbel',
    'gender': 'Female',
    'average': 68.20,
    'played': 0.2,
  },
  {
    'firstName': 'Barbara',
    'lastName': 'Hecker',
    'gender': 'Female',
    'average': 62.42,
    'played': 5,
  },
  {
    'firstName': 'Bill',
    'lastName': 'Bell',
    'gender': 'Male',
    'average': 76.20,
    'played': 81,
  },
  {
    'firstName': 'Bill',
    'lastName': 'Halter',
    'gender': 'Male',
    'average': 76.40,
    'played': 14,
  },
  {
    'firstName': 'Blaine',
    'lastName': 'Bertram',
    'gender': 'Male',
    'average': 43.94,
    'played': 1,
  },
  {
    'firstName': 'Bob',
    'lastName': 'Dausman',
    'gender': 'Male',
    'average': 79.97,
    'played': 13,
  },
  {
    'firstName': 'Bob',
    'lastName': 'EUiott',
    'gender': 'Male',
    'average': 75.02,
    'played': 14,
  },
  {
    'firstName': 'Bob',
    'lastName': 'Hansen',
    'gender': 'Male',
    'average': 0.7186,
    'played': 6,
  },
  {
    'firstName': 'Bob',
    'lastName': 'Little',
    'gender': 'Male',
    'average': 0.8,
    'played': 26,
  },
  {
    'firstName': 'Bonnie',
    'lastName': 'laul',
    'gender': 'Female',
    'average': 71.86,
    'played': 25,
  },
  {
    'firstName': 'Bonnie',
    'lastName': 'Senatore',
    'gender': 'Female',
    'average': 74.24,
    'played': 44,
  }
];

/**
 * Data source for the StandingsResults view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
export class StandingsResultsDataSource extends DataSource<StandingsResultsItem> {
  data: StandingsResultsItem[] = EXAMPLE_DATA;
  paginator: MatPaginator;
  sort: MatSort;

  constructor() {
    super();
  }

  /**
   * Connect this data source to the table. The table will only update when
   * the returned stream emits new items.
   * @returns A stream of the items to be rendered.
   */
  connect(): Observable<StandingsResultsItem[]> {
    // Combine everything that affects the rendered data into one update
    // stream for the data-table to consume.
    const dataMutations = [
      observableOf(this.data),
      this.paginator.page,
      this.sort.sortChange
    ];

    return merge(...dataMutations).pipe(map(() => {
      return this.getPagedData(this.getSortedData([...this.data]));
    }));
  }

  /**
   *  Called when the table is being destroyed. Use this function, to clean up
   * any open connections or free any held resources that were set up during connect.
   */
  disconnect() {}

  /**
   * Paginate the data (client-side). If you're using server-side pagination,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getPagedData(data: StandingsResultsItem[]) {
    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    return data.splice(startIndex, this.paginator.pageSize);
  }

  /**
   * Sort the data (client-side). If you're using server-side sorting,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getSortedData(data: StandingsResultsItem[]) {
    if (!this.sort.active || this.sort.direction === '') {
      return data;
    }

    return data.sort((a, b) => {
      const isAsc = this.sort.direction === 'asc';
      switch (this.sort.active) {
        case 'firstName': return compare(a.firstName, b.firstName, isAsc);
        case 'lastName': return compare(a.lastName, b.lastName, isAsc);
        case 'gender': return compare(a.gender, b.gender, isAsc);
        case 'average': return compare(a.average, b.average, isAsc);
        case 'played': return compare(a.played, b.played, isAsc);
        default: return 0;
      }
    });
  }
}

/** Simple sort comparator for example ID/Name columns (for client-side sorting). */
function compare(a, b, isAsc) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
