import {Component, OnInit, AfterViewInit, ChangeDetectionStrategy, Input, Inject} from '@angular/core';
import {FormAccountSubscriptionService} from '../../../common/services/form-account-subscription.service';
import {environment} from "../../../../environments/environment";
import {
  DialogData,
  DialogRosterAdminAdd,
  RosterElement
} from "../../../dashboard/dashboard-roster/roster-table.component";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {HttpClient} from "@angular/common/http";
import {AccountService} from "../../../common/services/account.service";
import {ToastService} from "../../../common/toast";

@Component({
  selector: 'app-subscription-settings',
  templateUrl: './subscription-settings.component.html',
  styleUrls: ['./subscription-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})

export class SubscriptionSettingsComponent implements OnInit, AfterViewInit {

  @Input() profile = null;

  constructor(private toastService: ToastService, private http: HttpClient, private accountService: AccountService, public dialog: MatDialog, public formService: FormAccountSubscriptionService) {
    this.formService.enableForm();
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
  }

  cancel() {
    const dialogRef = this.dialog.open(DialogSettingsConfirm, {
      data: {
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result.event == 'Success'){
        this.http.post<RosterElement>(environment.service.trackithubapi + '/api/inside/cancelsub', {
          'profile_id': this.profile.profile_id,
          'transaction_id': this.profile.subscription_transaction}, { }).subscribe((data: any) => {
          this.toastService.show({
            text: `Please allow 24 hours for your request to be processed.`,
            type: 'success',
          });
        });
      }
    });
  }

}


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'settings-confirm',
  templateUrl: 'settings-confirm.html',
  changeDetection: ChangeDetectionStrategy.Default
})

// tslint:disable-next-line:component-class-suffix
export class DialogSettingsConfirm {
  constructor(
    public dialogRef: MatDialogRef<DialogSettingsConfirm>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close({event: 'Cancel'});
  }

  onYesClick(): void {
    this.dialogRef.close({event: 'Success'});
  }
}
