import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ActivatedRoute} from "@angular/router";


@Component({
  selector: 'app-dashboard-map',
  templateUrl: './dashboard-map.component.html',
  styleUrls: ['./dashboard-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})


export class DashboardMapComponent {

  type: any;

  constructor(    private route: ActivatedRoute) {

    if(this.route.snapshot.queryParamMap.get('type') !== null) {
      this.type = this.route.snapshot.queryParamMap.get('type');
    } else {
      this.type = null;
    }
  }


}
