import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'app-group-blank-component',
  templateUrl: './group-blank.component.html',
  styleUrls: ['./group-blank.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class GroupBlankComponent {
  @Input() type: string;
  @Input() group_id: string;
  @Input() player: string;
}
