import {ChangeDetectionStrategy, Component} from '@angular/core';
import {FormGroup} from '@angular/forms';

import {Field} from '../../models/field.interface';
import {FieldConfig} from '../../models/field-config.interface';

@Component({
  selector: 'app-form-button',
  styleUrls: ['form-button.component.scss'],
  template: `
    <div [formGroup]="group" class="form-button">
      <button mat-raised-button color="warn" *ngIf="config.cancel === true"
              type="button">
        Cancel
      </button>
      <button mat-raised-button color="{{config.color}}"
              [disabled]="config.disabled"
              [ngClass]="{'button-left': config.left }"
              type="submit">
        {{ config.label }}
        <ng-container *ngIf="config.icon">
          <mat-icon>{{config.icon}}</mat-icon>
        </ng-container>
      </button>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.Default
})
export class FormButtonComponent implements Field {
  config: FieldConfig;
  group: FormGroup;
}
