import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";
import {AccountService} from "../../common/services/account.service";


@Component({
  selector: 'app-dashboard-leaguemodal',
  templateUrl: './dashboard-leaguemodal.component.html',
  styleUrls: ['./dashboard-leaguemodal.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})

export class DashboardLeaguemodalComponent implements OnInit, OnDestroy {

  account: any;


  constructor(public dialogRef: MatDialogRef<DashboardLeaguemodalComponent>, private accountService: AccountService) {

  }




  ngOnDestroy() {

  }

  ngOnInit() {

  }

  closepop(){
    this.dialogRef.close();
  }



}
