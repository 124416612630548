import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { SessionStorageService } from 'ngx-webstorage';

export interface LocationSearchInterface {
  location1: string;
  type1: string;
  results: any;
}

@Injectable()

export class LocationSearchService {

  private Location = new BehaviorSubject<LocationSearchInterface>({
    location1: null,
    type1: null,
    results: null
  });

  cast = this.Location.asObservable();


  constructor(private http: HttpClient, private sessionSt: SessionStorageService ) {}

  initialize() {
      this.Location.next({
        location1: this.sessionSt.retrieve('locationstring'),
        type1: this.sessionSt.retrieve('play_type'),
        results: null
      });
  }

  outsidesearch(locationstring) {
    this.sessionSt.store('locationstring', locationstring);
    this.http.get<[]>(environment.service.trackithubapi +  '/api/outside/placespublicdata?lat=' + this.sessionSt.retrieve('address_lat') + '&lng=' +  this.sessionSt.retrieve('address_lng') ).subscribe((data: any) => {
      this.Location.next({
        location1: this.sessionSt.retrieve('locationstring'),
        type1: this.sessionSt.retrieve('play_type'),
        results: data
      });
    });
  }

  insidesearch() {
    this.http.get<[]>(environment.service.trackithubapi +  '/api/outside/placespublicdata?lat=' + this.sessionSt.retrieve('address_lat') + '&lng=' +  this.sessionSt.retrieve('address_lng') ).subscribe((data: any) => {
      this.Location.next({
        location1: this.sessionSt.retrieve('locationstring'),
        type1: this.sessionSt.retrieve('play_type'),
        results: data
      });
    });
  }

}
