import { Component, Inject, OnInit } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';



@Component({
  selector: 'app-deletepost-dialog',
  templateUrl: './deletepost-dialog.component.html',
  styleUrls: ['./deletepost-dialog.component.scss']
})
export class DeletepostDialogComponent implements OnInit {

  post: any;

  constructor(public dialogRef: MatDialogRef<DeletepostDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: {}) {
    this.post = data;

   }

  ngOnInit() {

  }



  onNoClick(): void {
    this.dialogRef.close({});
  }

  onYesClick(): void {
    this.dialogRef.close({'status': 'delete', 'post': this.post});
  }

}
