import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-donation-portal',
  templateUrl: './donation-portal.component.html',
  styleUrls: ['./donation-portal.component.scss']
})
export class DonationPortalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }



}
