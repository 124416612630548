import {Component, OnInit, Input, TemplateRef, ComponentRef, ChangeDetectionStrategy} from '@angular/core';
import {Observable, Observer} from 'rxjs';

@Component({
  selector: 'app-dynamic-tabs',
  templateUrl: './dynamic-tabs.component.html',
  styleUrls: ['./dynamic-tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class DynamicTabsComponent implements OnInit {
  asyncTabs: Observable<any>;
  @Input() tabContent: any[];
  @Input() tabContentTemplate: TemplateRef<any>;
  constructor() {
    this.asyncTabs = new Observable((observer: Observer<any>) => {
      setTimeout(() => {
        observer.next(this.tabContent);
      }, 1000);
    });
  }

  ngOnInit(): void {
  }

}
