import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input, OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { Subscription } from 'rxjs';
import { AccountService } from 'src/app/common/services/account.service';
import { DatePipe } from '@angular/common';
import { FormProfileService } from "../../common/services/form-profile.service";
// @ts-ignore
import { } from '@types/googlemaps';
import {environment} from "../../../environments/environment";
import {ToastService} from "../../common/toast";
import {HttpClient} from "@angular/common/http";
import {RecordsService} from "../../common/services/records.service";

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() account: any;

  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  @Output() setAddressAlt: EventEmitter<any> = new EventEmitter();
  @Output() parentFunctionEvent = new EventEmitter();

  @ViewChild('address', {static: false}) address: any;
  @ViewChild('altaddress', {static: false}) altaddress: any;

  subscription: Subscription;
  s1 = false;
  s2 = false;
  s3 = false;
  s4: any;
  s5: any;

  new_email: string;
  edit = false;
  profile: any;
  oprofile_info: any;
  profile_info: any;
  is_owner: boolean;
  ratingValue: any;
  relationshipValue: any;
  options = ['1.0', '0.0', '1.5', '2.0', '2.5', '3.0', '3.5', '4.0', '4.5', '5.0'];
  relas = ['Single','In a relationship','Married','Divorced','Widowed']
  addressobj: any;
  addressaltobj: any;
  securityPost: number;
  securityPost1: number;
  new_data: boolean;

  constructor(private validationService: RecordsService, private toastService: ToastService, private accountService: AccountService, private datePipe: DatePipe, public formService: FormProfileService, private http: HttpClient,private changeDetectorRef: ChangeDetectorRef) {
    this.addressobj = null;
    this.addressaltobj = null;
  }




  ngOnDestroy() {
    if (this.s4 != null) {
      this.s4.unsubscribe();
    }
    if (this.s5 != null) {
      this.s5.unsubscribe();
    }
    if(this.subscription != null){
      this.subscription.unsubscribe();
    }

  }

  ngAfterViewInit() {
    this.getPlaceAutocomplete();
  }

  validateEmail(email){
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  noEnter(e) {
    const key = e.key ;
    return key !== 13;
  }

  private getPlaceAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(this.address.nativeElement,
      {
        componentRestrictions: { country: ['US', 'UK', 'CA', 'MX']},
        types: ['address']  // 'establishment' / 'address' / 'geocode'
      });
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();
      this.invokeEvent(place);
      this.addressobj = place;
    });

    const autocompletealt = new google.maps.places.Autocomplete(this.altaddress.nativeElement,
      {
        componentRestrictions: { country: ['US', 'UK', 'CA', 'MX']},
        types: ['address']  // 'establishment' / 'address' / 'geocode'
      });
    google.maps.event.addListener(autocompletealt, 'place_changed', () => {
      const place = autocompletealt.getPlace();
      this.invokeEvent(place);
      this.addressaltobj = place;
    });
  }

  invokeEvent(place: Object) {
    this.setAddress.emit(place);
  }

  ngOnInit() {

    this.s4 = this.formService.form.controls.email.valueChanges.subscribe(data => {

      this.new_email = data;




      if(data == ''){
        this.toastService.show({
          text: `Email is required.`,
          type: 'alert',
        });
        this.formService.setemail(this.oprofile_info.email);
      }


      if(this.oprofile_info.is_account == 1 && this.new_data == true && this.is_owner == true) {
        if(this.oprofile_info.email != data && data != '') {
          if(this.validateEmail(data)) {
            this.s5 = this.validationService.checkEmailPrimary(data).subscribe(ret => {
              if (ret.found === 1) {
                this.toastService.show({
                  text: `The email address entered is associated with a different account. Please enter a unique email address.`,
                  type: 'alert',
                });
                this.formService.setemail(this.oprofile_info.email);
              }
            });
          }
        }
      }
    });

    this.subscription = this.accountService.cast.subscribe(data => {
      this.profile = data.current_profile;


      if(this.profile.profile_id == this.account.profile.profile_id){
        this.is_owner = true;
      } else {
        this.is_owner = false;
      }

      this.profile_info = this.account.profile;

      this.oprofile_info = this.account.profile;

      this.new_email = this.profile_info.email;


      this.new_data = false;
      this.resetform();
      this.new_data = true;
    })




  }

  save(){

    if(!this.isValidEmail(this.formService.form.value.email)){
      this.toastService.show({
        text: `Email is not formatted properly.`,
        type: 'alert',
      });
      this.formService.setemail(this.oprofile_info.email);
    } else {

      this.http.post<any>(environment.service.trackithubapi + '/api/inside/updateabout', {
        raw: this.formService.form.value,
        addressobj: this.addressobj,
        addressaltobj: this.addressaltobj,
        profile_id: this.oprofile_info.profile_id,
        secemail: this.securityPost,
        secbirthday: this.securityPost1
      }).subscribe(data => {
        this.accountService.load();
        this.toastService.show({
          text: `Profile Settings have been saved.`,
          type: 'success',
        });
        this.accountService.reloadprofile();
        this.parentFunctionEvent.emit();

        this.http.get<any>(environment.service.trackithubapi + '/api/inside/loadprofile?clean_group=yes&profile=' + this.oprofile_info.profile_id).subscribe((profileData: any) => {

          this.account.profile = profileData.profile;
          this.new_data = false;
          this.resetform();
          this.new_data = true;

          this.changeDetectorRef.detectChanges();


        });


        this.changeDetectorRef.detectChanges();
      });

      this.editToggle();
    }

  }

  cancel(){
    this.new_data = false;
    this.resetform();
    this.new_data = true;

    this.editToggle();
  }

  resetform(){







    const date = new Date(this.profile_info.birthday);
    this.profile_info.birthday = this.datePipe.transform(date, 'MM/dd/yyyy');



    this.ratingValue = this.oprofile_info.profile_rating;
    this.relationshipValue = this.oprofile_info.relationship;

    this.formService.initializeFormGroup({
      email: this.oprofile_info.email,
      birthday: this.oprofile_info.birthday,
      bio: this.oprofile_info.bio,
      relationship: this.oprofile_info.relationship,
      rating: this.oprofile_info.profile_rating,
      address: this.oprofile_info.address +', '+this.oprofile_info.city+' '+this.oprofile_info.state+', '+this.oprofile_info.zip,
      altaddress: this.oprofile_info.altaddress +', '+this.oprofile_info.altcity+' '+this.oprofile_info.altstate+', '+this.oprofile_info.altzip,
    });
    this.formService.enableForm();

    this.securityPost = this.oprofile_info.secemail;
    this.securityPost1 = this.oprofile_info.secbirthday;

    this.new_data = false;




  }

  s1toggle(){
    this.s1 = !this.s1;
  }

  s2toggle(){
    this.s2 = !this.s2;
  }

  s3toggle(){
    this.s3 = !this.s3;
  }

  editToggle(){
    this.edit = !this.edit;

    if(this.edit){
      this.s1 = true;
      this.s2 = false;
      this.s3 = true;
    } else {
      this.s1 = false;
      this.s2 = false;
      this.s3 = false;
    }

  }

  changeSecurity(sec){
    this.securityPost = sec;
  }

  changeSecurity1(sec){
    this.securityPost1 = sec;
  }

  isValidEmail(email: string): boolean {
    // Regular expression to match a valid email address
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    // Use the test method of the regex to check if the email matches the pattern
    return emailRegex.test(email);
  }

}
