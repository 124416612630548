import {Component, ViewContainerRef} from '@angular/core';
import {FormGroup} from '@angular/forms';

import {Field} from '../../models/field.interface';
import {FieldConfig} from '../../models/field-config.interface';

@Component({
    selector: 'app-form-input',
    styleUrls: ['form-password.component.scss'],
    template: `
        <mat-form-field [formGroup]="group" color="{{config.color}}">
            <input type="password"
                   matInput
                   placeholder="{{config.placeholder}}"
                   autocomplete="new-password"
                   [formControlName]="config.name"
                   [required]="config.required"
                   [type]="hide ? 'password' : 'text'">
            <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
            <mat-hint>{{config.label}}</mat-hint>
        </mat-form-field>
    `
})
export class FormPasswordComponent implements Field {
    hide = true;
    config: FieldConfig;
    group: FormGroup;
}
