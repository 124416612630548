import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})

export class LoaderComponent implements OnInit {

    public _loading = true;
    @Input() public loaderMessage: string;
    constructor() {}

    ngOnInit() { }

}
