import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
    selector: 'app-list',
    template: `
        <ul>
            <li *ngFor=" let item of items;">
                {{item}}
            </li>
        </ul>
    `,
  changeDetection: ChangeDetectionStrategy.Default
})
export class ListComponent {
    @Input() items: string;

}
