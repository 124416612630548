import {AfterViewInit, ChangeDetectionStrategy, Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator, MatSort, MatTable} from '@angular/material';
import {StandingsTableDataSource, StandingsTableItem} from './standings-table-datasource';
import {FieldConfig} from '../../dynamic-form/models/field-config.interface';
import {SelectionModel} from '@angular/cdk/collections';
import {filter} from 'rxjs/operators';

@Component({
  selector: 'app-standings-table',
  templateUrl: './standings-table.component.html',
  styleUrls: ['./standings-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class StandingsTableComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatTable, {static: false}) table: MatTable<StandingsTableItem>;
  panelOpenState = false;
  public dataSource = new StandingsTableDataSource();
  selection = new SelectionModel<StandingsTableDataSource>(true, []);
  pickleballGroup: string[] = ['ORIPA Membership Group', 'ORIPA Men & Ladies Shootout'];
  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['select', 'position', 'firstName', 'lastName', 'gender', 'average', 'played', 'rating', 'group'];
  config: FieldConfig[] = [
    {
      type: 'select',
      label: 'Group',
      name: 'group',
      placeholder: 'Group',
      options: ['ORIPA Ladies Shootout', 'ORIPA Membership Group', 'ORIPA Men & Ladies Shootout'],
      required: false,
    },
    {
      label: 'Show Non-Members',
      name: 'showNonMembers',
      type: 'checkbox',
    },
    {
      type: 'select',
      label: 'Standings',
      name: 'standings',
      placeholder: 'View / Enter Scores',
      options: ['ORIPA Ladies Shootout', 'ORIPA Membership Group', 'ORIPA Men & Ladies Shootout'],
      required: false,
    },
    {
      type: 'select',
      label: 'Scorecard',
      name: 'scorecard',
      placeholder: 'Scorecard',
      options: ['ORIPA Ladies Shootout', 'ORIPA Membership Group', 'ORIPA Men & Ladies Shootout'],
      required: false,
    },
  ];

    /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
   // this.isAllSelected() ?
      //  this.selection.clear() :
       // this.dataSource.data.forEach(row => this.selection.select( row ));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: StandingsTableDataSource): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }
  // applyFilter(filterValue: string) {
  //   this.dataSource.filter = filterValue.trim().toLowerCase();
  //
  //   if (this.dataSource.paginator) {
  //     this.dataSource.paginator.firstPage();
  //   }
  // }
  ngOnInit() {
    this.dataSource = new StandingsTableDataSource();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;
  }
}
