import {
  ChangeDetectionStrategy,
  Inject,
  Component,
  OnInit,
  ViewChild,
  HostListener,
  AfterViewInit, OnDestroy
} from '@angular/core';
import { MatPaginator, MatSort, MatTable, MatTableDataSource } from '@angular/material';
import { SelectionModel} from '@angular/cdk/collections';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { AccountService} from '../../common/services/account.service';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import { ToastService} from '../../common/toast';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { BottomSheetService } from '../../common/services/bottom-sheet.service';
import { DashboardEmailGroupSheetComponent} from '../index';
import { LoaderOverlayService } from '../../common/services/loader-overlay.service';
import {ObserversModule} from '@angular/cdk/observers';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Account} from "../../common/interfaces/account";
import {ProfileComponent} from "../../profile/profile.component";


export interface RosterElement {
  id: number;
  email: string;
  player_email: string;
  player_name: string;
  is_creator: string;
  group_id: number;
  group_name: string;
  group_owner_id: number;
  group_owner: string;
  approved: string;
  admin: string;
  user_id: number;
  first_name: string;
  last_name: string;
  gender: string;
  rating: string;
  override: string;
  ipf_rating: string;
  groupping: string;
  played: string;
  filter?: any;
}


export interface DialogData {
  is_team: any;
  event: any;
  summary: any;
  atd: any;
  rp: any;
  invitelist: any;
  animal: string;
  name: string;
  list: any;
  group_list: any;
}

@Component({
  selector: 'app-roster-table',
  templateUrl: './roster-table.component.html',
  styleUrls: ['./roster-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})

export class RosterTableComponent implements OnDestroy, OnInit, AfterViewInit {

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatTable, {static: false}) table: MatTable<RosterElement>;

  env: any;

  s1 = null;
  lasturl = null;

  start_group_id = null;

  club_card_id = null;

  archived = false;

  smallScreen: boolean;
  bigScreen: boolean;
  archivedGroup = false;

  nonMembers = false;
  noSub = false;

  grouptopull = null;

  selectedgroup = null;

  dataSource = new MatTableDataSource();
  selection = new SelectionModel<RosterElement>(true, []);

  data = [];
  is_team = 0;
  account;
  active_group = null;
  group_type = 'club';
  param = null;
  group_obj = {
    id: '0',
    name: '',
    owner: 0,
    league: 0,
    permissions: 0,
    approved: 0,
    organization: '',
    membership: 0,
    paypal: '',
    currency: '',
    avatar: '',
    avatar_icon: '',
    ownerid: '',
    average: '',
    num: '',
    win: '',
    standings: '',
    archive: 0,
    gender: '',
    minrate: '',
    maxrate: '',
    dues: '',
    chip1: '',
    chip2: '',
    chip3: '',
    chip4: '',
    label: '',
    primary_location: '',
    loc_as_group: false,
    league_total: 0,
    approvals: true,
    title: '',
    description: '',
    params: {
      wallnotification: true
    },
    limitgroup: [],
    members: '0',
    clubcard: false,
    is_team: '0'
};

  constructor(private router: Router, public dialog: MatDialog, private bottomSheetService: BottomSheetService, private breakpointObserver: BreakpointObserver, private http: HttpClient, private accountService: AccountService, private route: ActivatedRoute, private toastService: ToastService, private LoaderOverlayService: LoaderOverlayService) {
    breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small
    ]).subscribe(result => {
      this.smallScreen = result.matches;
      this.bigScreen = !result.matches;
    });

    this.env = environment;

    this.LoaderOverlayService.overlayRef = null;

    this.currentWindowWidth = window.innerWidth;


  }

  public currentWindowWidth: number;
  displayedColumns = [];


  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  nonmember($event) {
    this.noSub = false;
    this.pulldata(this.group_obj);
  }

  nosub($event) {
    this.nonMembers = false;
    this.pulldata(this.group_obj);
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(<RosterElement>row));
  }

  onResize() {
    this.currentWindowWidth = window.innerWidth;
    this.redraw(this.data, this.group_obj);
  }

   applyFilter(filterValue: string) {
     this.dataSource.filter = filterValue.trim().toLowerCase();
     if (this.dataSource.paginator) {
       this.dataSource.paginator.firstPage();
     }
   }

  openProfile(profile_id, profile_type) {
    if(this.env.service.profile == true) {
      this.http.get<Account>(environment.service.trackithubapi + '/api/inside/loadprofile?clean_group=yes&profile=' + profile_id).subscribe((profileData: any) => {
        this.bottomSheetService.openBottomSheetWithDataCustomClass(ProfileComponent, 'ProfileComponent',{
          'profileData': profileData,
          'type': profile_type
        }, 'custom-bottom-p' );
      });
    }
  }

  openContactSheet(event) {
    if(this.selection.selected.length === 0) {
      this.toastService.show({
        text: `You need to select at least one player.`,
        type: 'warn',
      });
    } else {
      event.preventDefault();
      this.bottomSheetService.openBottomSheetWithData(DashboardEmailGroupSheetComponent, 'DashboardEmailGroupSheetComponent',{
        list: this.selection.selected,
        group: this.group_obj,
        id: this.account.current_profile.profile_id,
        current_profile: this.account.current_profile
      });
    }
  }

  openDialogAdd(): void {
    if(this.selection.selected.length === 0){
      this.toastService.show({
        text: `You need to select at least one player.`,
        type: 'warn',
      });
    } else {
      let fail = false;
      for (let i = 0, len = this.selection.selected.length; i < len; i++) {
        if(this.selection.selected[i].admin === '1'){
          fail = true;
        }
      }
      if(fail === true){
        this.toastService.show({
          text: `You are not able to assign an admin role to a current group admin.`,
          type: 'warn',
        });
      } else {
        const dialogRef = this.dialog.open(DialogRosterAdminAdd, {
          data: {
            group_list: this.account.current_profile.groups,
            list: this.selection.selected,
            group_obj: this.group_obj
          }
        });
        dialogRef.afterClosed().subscribe(result => {
          if(result.event == 'Success'){
            this.http.post<RosterElement>(environment.service.trackithubapi + '/api/inside/updateadmin', {
              'type': 'add',
              'group_id': this.group_obj.id,
              'value': JSON.stringify(this.selection.selected)}, { }).subscribe((data: any) => {
              this.toastService.show({
                text: `Your request has been processed.`,
                type: 'success',
              });
              this.pulldata(this.group_obj);
              this.selection.clear();
            });
          }
        });
      }
    }
  }

  openDialogRemove(): void {
    if (this.selection.selected.length === 0) {
      this.toastService.show({
        text: `You need to select at least one player.`,
        type: 'warn',
      });
    } else {
      let fail = false;
      for (let i = 0, len = this.selection.selected.length; i < len; i++) {
        if (this.selection.selected[i].admin === '0') {
          fail = true;
        }
      }
      if (fail === true) {
        this.toastService.show({
          text: `To remove admin, a group admin must be selected.`,
          type: 'warn',
        });
      } else {
        const dialogRef = this.dialog.open(DialogRosterAdminRemove, {
          data: {
            group_list: this.account.current_profile.groups,
            list: this.selection.selected,
            group_obj: this.group_obj
          }
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result.event == 'Success') {
            this.http.post<RosterElement>(environment.service.trackithubapi + '/api/inside/updateadmin', {
              'type': 'remove',
              'group_id': this.group_obj.id,
              'value': JSON.stringify(this.selection.selected)}, { }).subscribe((data: any) => {
              this.toastService.show({
                text: `Your request has been processed.`,
                type: 'success',
              });
              this.pulldata(this.group_obj);
              this.selection.clear();
            });
          } else if (result.event == 'Error') {
            this.toastService.show({
              text: `System error, please try again.   If you continue to get this error please contact support.`,
              type: 'warn',
            });
          }
        });
      }
    }
  }

  openDialogCopy(): void {
    if(this.selection.selected.length === 0){
      this.toastService.show({
        text: `You need to select at least one player.`,
        type: 'warn',
      });
    } else {
      const dialogRef = this.dialog.open(DialogRosterAdminCopy, {
        data: {
          group_list: this.account.current_profile.groups,
          list: this.selection.selected,
          group_obj: this.group_obj
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result.event == 'Success'){
          this.http.post<RosterElement>(environment.service.trackithubapi + '/api/inside/updateadmin', {
            'copygroup': result.group.id,
            'type': 'copy',
            'group_id': this.group_obj.id,
            'value': JSON.stringify(this.selection.selected)}, { }).subscribe((data: any) => {
            this.toastService.show({
              text: `Your request has been processed.`,
              type: 'success',
            });
            this.pulldata(this.group_obj);
            this.selection.clear();
          });
        } else if(result.event == 'Error'){
          this.toastService.show({
            text: `System error, please try again.   If you continue to get this error please contact support.`,
            type: 'warn',
          });
        }
      });
    }
  }

  openDialogDelete(): void {
    if(this.selection.selected.length === 0){
      this.toastService.show({
        text: `You need to select at least one player to delete.`,
        type: 'warn',
      });
    } else {
      const dialogRef = this.dialog.open(DialogRosterAdminDelete, {
        data: {group_list: this.account.current_profile.groups, list: this.selection.selected, group_obj: this.group_obj}
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result.event == 'Success'){
          this.http.post<RosterElement>(environment.service.trackithubapi + '/api/inside/updateadmin', {
            'type': 'delete',
            'group_id': this.group_obj.id,
            'value': JSON.stringify(this.selection.selected)}, { }).subscribe((data: any) => {
            this.toastService.show({
              text: `Your request has been processed.`,
              type: 'success',
            });
            this.pulldata(this.group_obj);
            this.selection.clear();
          });
        } else if(result.event == 'Error'){
          this.toastService.show({
            text: `System error, please try again.   If you continue to get this error please contact support.`,
            type: 'warn',
          });
        }
      });
    }
  }

  openDialogMove(): void {
    if(this.selection.selected.length === 0){
      this.toastService.show({
        text: `You need to select at least one player.`,
        type: 'warn',
      });
    } else {
      const dialogRef = this.dialog.open(DialogRosterAdminMove, {
        data: {
          group_list: this.account.current_profile.groups,
          list: this.selection.selected,
          group_obj: this.group_obj
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result.event == 'Success'){
          this.http.post<RosterElement>(environment.service.trackithubapi + '/api/inside/updateadmin', {
            'movegroup': result.group.id,
            'type': 'move',
            'group_id': this.group_obj.id,
            'value': JSON.stringify(this.selection.selected)}, { }).subscribe((data: any) => {
            this.toastService.show({
              text: `Your request has been processed.`,
              type: 'success',
            });
            this.pulldata(this.group_obj);
            this.selection.clear();
          });
        } else if(result.event == 'Error'){
          this.toastService.show({
            text: `System error, please try again.   If you continue to get this error please contact support.`,
            type: 'warn',
          });
        }
      });
    }
  }



  changevalue($type, $id, $event) {
    if (this.group_obj.id !== null) {
      this.http.get<RosterElement>(environment.service.trackithubapi + '/api/inside/pushroster?user_id=' + $id + '&type=' + $type + '&group_id=' + this.group_obj.id + '&value=' + $event).subscribe((data: any) => {
        this.toastService.show({
          text: `Information has been updated.`,
          type: 'success',
        });
      });
    }
  }

  calSelectGroup($event) {
    this.pulldata($event.value);
  }

  openExport() {
    this.toastService.show({
      text: `Check your download folder for your export.`,
      type: 'success',
    });
    window.location.href = this.lasturl + '&csv=true';
  }

  redraw(data, group) {

    if(this.LoaderOverlayService.overlayRef == null) {
      this.LoaderOverlayService.show();
    }

    this.group_obj = group;
    this.data = data;
    this.is_team = 0;
    if (group.clubcard === true) {
      if (this.currentWindowWidth > 1300) {
        this.displayedColumns = ['select', 'first_name', 'last_name', 'gender'];
      } else {
        this.displayedColumns = ['select', 'name', 'gender'];
      }
    } else {
      if (this.currentWindowWidth > 1300) {
        if(this.group_obj.is_team == '1') {
          this.is_team = 1;
          this.displayedColumns = ['select', 'player_name', 'player_email', 'is_creator', 'first_name', 'rating', 'override', 'played', 'ipf_rating', 'groupping'];
        } else {
          this.displayedColumns = ['select', 'first_name', 'last_name', 'gender', 'rating', 'override', 'played', 'ipf_rating', 'groupping'];
        }
      } else {
        this.displayedColumns = ['name',  'rating', 'ipf_rating', 'groupping'];
      }
    }

    this.dataSource.data = data.data;
    this.selection.clear();
    this.killoverlay();
  }


  killoverlay(){
    if(this.LoaderOverlayService.overlayRef != null) {
      this.LoaderOverlayService.hide();
    }
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;



  }

  ngOnInit() {
    this.s1 = this.accountService.cast.subscribe(data => {
      this.account = data;

      this.club_card_id = this.account.current_profile.groups[0];

      if (this.account.id !== null) {
        this.active_group = this.route.snapshot.queryParamMap.get('group_id');
        this.group_type = this.route.snapshot.queryParamMap.get('type');

        if (this.active_group == null) {
          this.group_type = 'club';
          this.active_group = this.account.current_profile.groups[0].id;
        }

        for (const group of this.account.current_profile.groups) {
          if(group.archive == 1){
            this.archived = true;
          }
          if (this.group_type === 'club') {
            if (group.id === this.active_group && group.clubcard === true) {
              this.pulldata(group);
            }
          } else {
            if (group.id === this.active_group && group.clubcard !== true) {
              this.pulldata(group);
            }
          }
        }

      }
    });
  }

  ngOnDestroy() {
    this.LoaderOverlayService.overlayRef = null;
    if (this.s1 != null) {
      this.s1.complete();
    }
  }

  pulldata(group) {
    if (group !== this.club_card_id) {
      if (this.group_type == 'club') {
        this.group_type = 'roundrobin';
      }
    } else {
      this.group_type = 'club';
    }

    if (this.LoaderOverlayService.overlayRef == null) {
      this.LoaderOverlayService.show('Loading Data...');
    }

    this.group_obj = group;

    this.lasturl = environment.service.trackithubapi + '/api/inside/pullroster?type=' + this.group_type + '&group_id=' + group.id + '&nonmembers=' + this.nonMembers + '&nosub=' + this.noSub;
    this.http.get<RosterElement>(this.lasturl).subscribe((data: any) => {
      this.redraw(data, group);
    });
  }
}

@Component({
  selector: 'roster-dialog-admin-move',
  templateUrl: 'roster-dialog-admin-move.html',
  changeDetection: ChangeDetectionStrategy.Default
})

export class DialogRosterAdminMove {
  move_group = {};
  move_check = true;
  constructor(
    public dialogRef: MatDialogRef<DialogRosterAdminMove>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  calSelectGroup($event){
    this.move_group = $event.value
    if($event.value === undefined){
      this.move_check = true;
    } else {
      this.move_check = false;
    }
  }

  onNoClick(): void {
    this.dialogRef.close({event:'Cancel'});
  }

  onYesClick(): void {
    this.dialogRef.close({event:'Success', group: this.move_group});
  }

}

@Component({
  selector: 'roster-dialog-admin-delete',
  templateUrl: 'roster-dialog-admin-delete.html',
  changeDetection: ChangeDetectionStrategy.Default
})
export class DialogRosterAdminDelete {
  constructor(
    public dialogRef: MatDialogRef<DialogRosterAdminDelete>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close({event:'Cancel'});
  }

  onYesClick(): void {
    this.dialogRef.close({event:'Success'});
  }
}

@Component({
  selector: 'roster-dialog-admin-copy',
  templateUrl: 'roster-dialog-admin-copy.html',
  changeDetection: ChangeDetectionStrategy.Default
})
export class DialogRosterAdminCopy {

  copy_group = {};
  copy_check = true;

  constructor(
    public dialogRef: MatDialogRef<DialogRosterAdminCopy>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  calSelectGroup($event){
    this.copy_group = $event.value
    if($event.value === undefined){
      this.copy_check = true;
    } else {
      this.copy_check = false;
    }
  }

  onNoClick(): void {
    this.dialogRef.close({event:'Cancel'});
  }

  onYesClick(): void {
    this.dialogRef.close({event:'Success', group: this.copy_group});
  }
}

@Component({
  selector: 'roster-dialog-admin-remove',
  templateUrl: 'roster-dialog-admin-remove.html',
  changeDetection: ChangeDetectionStrategy.Default
})
export class DialogRosterAdminRemove {
  constructor(
    public dialogRef: MatDialogRef<DialogRosterAdminRemove>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close({event:'Cancel'});
  }

  onYesClick(): void {
    this.dialogRef.close({event:'Success'});
  }
}

@Component({
  selector: 'roster-dialog-admin-add',
  templateUrl: 'roster-dialog-admin-add.html',
  changeDetection: ChangeDetectionStrategy.Default
})
export class DialogRosterAdminAdd {
  constructor(
    public dialogRef: MatDialogRef<DialogRosterAdminAdd>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close({event:'Cancel'});
  }

  onYesClick(): void {
    this.dialogRef.close({event:'Success'});
  }
}
