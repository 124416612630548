import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {RouterModule} from '@angular/router';
import {TourMatMenuModule} from 'ngx-tour-md-menu';
import {MaterialModule} from '../../material.module';
import {PrivacyModule} from '../settings/privacy/privacy.module';

import {DashboardCardModule} from '../common/dashboard/dashboard-card.module';
import {AccountService} from '../common/services/account.service';
import {autocompleteService} from '../common/services/autocomplete.service';
import {DashboardeventsService} from '../common/services/dashboardevents.service';
import {RenewallistService} from '../common/services/renewallist.service';
import { MygroupsService } from '../common/services/mygroups.service';
import {CalendarService} from '../common/services/calendar.service';
import {DynamicFormModule} from '../dynamic-form/dynamic-form.module';
import {AccountSettingsModule} from '../settings/account-settings/account-settings.module';
import {BottomSheetService} from '../common/services/bottom-sheet.service';
import {SettingsMenuComponent} from './dashboard-nav/settings-menu/settings-menu.component';
import {CreateLeagueDialogComponent} from './dashboard-create-league/create-league-dialog/create-league-dialog.component';
import { Ng5SliderModule } from 'ng5-slider';
import {PickelballRatingComponent} from '../pickelball-rating/pickelball-rating.component';
import {DashboardFrameComponent} from './dashboard-frame/dashboard-frame.component';
import {SafePipe} from '../common/pipes/safe.pipe';
import {RegisterProfileComponent} from '../register/profile_register/register-profile.component';
import {TalkService} from '../common/services/talk.service';

import {
  DashboardLeaguemodalComponent,
  DashboardPregamemodalComponent,
  LocationDelete,
  DashboardShowtrialComponent,
  DashboardShowSuccessComponent,
  DashboardLocationsComponent,
  DashboardCreateLeagueComponent,
  DashboardMobileFooterNavComponent,
  DashboardNotificationsComponent,
  DashboardActionCenterComponent,
  ProfileSwitcherComponent,
  DashboardJoinMarqueeComponent,
  DashboardSearchComponent,
  DynamicTabsComponent,
  DashboardGroupManagementComponent,
  DashboardJoinGroupsComponent,
  DashboardFriendrequestsComponent,
  DashboardGlobalNotificationsComponent,
  DashboardProfileComponent,
  DashboardGroupsComponent,
  DashboardShareGroupComponent,
  DashboardShareComponent,
  DashboardEventVideoComponent,
  DashboardContactSheetComponent,
  DashboardUploadAvatarComponent,
  DashboardTeamShareComponent,
  DashboardUploadImportComponent,
  DashboardCreateGroupComponent,
  DashboardStandingsComponent,
  LoaderComponent,
  ProgressComponent,
  DashboardVieweventComponent,
  DashboardViewrepeateventComponent,
  DashboardResetpasswordComponent,
  DashboardSubscribeComponent,
  DashboardTrialAdminComponent,
  DashboardSubAdminComponent,
  DialogTrialAdminremove,
  DialogSubAdminremove,
  DialogTrialAdminReset,
  DialogSubAdminadd,
  DashboardEventviewComponent,
  DashboardEventviewinvitegroupsComponent,
  DashboardEventviewsavelistComponent,
  DashboardEventviewsinvitelistComponent,
  DashboardEventviewdeclineComponent,
  DashboardEventviewcancelComponent,
  DashboardSearchplayersComponent,
  EmergencyComponent,
  DialogAcceptFriend,
  DialogRejectFriend,
  AddFriendComponent,
  DialogDeleteNotification,
  DashboardNewsComponent,
  DeletepostDialogComponent,
  ReportpostDialogComponent,
  UnfollowDialogComponent,
  DeletecommentDialogComponent,
  EditCommentDialogComponent,
  BadgeComponent,
  DashboardFriendsComponent,
  LocationsPanelComponent,
  removeMemberDialogComponent,
  DashboardComplainSheetComponent,
  removeclublocationDialogComponent,
  AboutGroupReadonlyComponent,
  ShareDirective,
  DashboardLeagueScoreComponent,
  DashboardMyleaguesComponent,
  AmbassadorTableComponent,
  DashboardEventviewnotesComponent,
  DashboardTeamLeaguemodalComponent,
  DashboardChatIconComponent
} from './index';
import { MenuItemComponent } from './dashboard-nav/menu-item/menu-item.component';
import { DashboardPurchaseSupportModalComponent } from './dashboard-purchase-support-modal/dashboard-purchase-support-modal.component';
import {DashboardEditgroupComponent} from './dashboard-editgroup/dashboard-editgroup.component';
import {DashboardPhotosComponent} from './dashboard-photos/dashboard-photos.component';
import {DashboardMapComponent} from './dashboard-map/dashboard-map.component';
import {DashboardViewprofileComponent} from './dashboard-viewprofile/dashboard-viewprofile.component';
import {DashboardAnnouncementsComponent} from './dashboard-announcements/dashboard-announcements.component';
import {DashboardEditeventComponent} from './dashboard-editevent/dashboard-editevent.component';
import {DashboardCopyeventComponent} from './dashboard-copyevent/dashboard-copyevent.component';
import {DashboardEditrepeatComponent} from './dashboard-editrepeat/dashboard-editrepeat.component';
import {DashboardMembershipadminComponent} from './dashboard-membershipadmin/dashboard-membershipadmin.component';
import {DashboardImportComponent} from './dashboard-import/dashboard-import.component';
import {StepperModule} from '../stepper/stepper.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { DashboardRenewalsSheetComponent } from './dashboard-renewals-sheet/dashboard-renewals-sheet.component';
import { DashboardUsersheetSheetComponent} from './dashboard-usersheet-sheet/dashboard-usersheet-sheet.component';
import { DashboardEmailSheetComponent } from './dashboard-email-sheet/dashboard-email-sheet.component';
import { DashboardReadEmailSheetComponent} from './dashboard-reademail-sheet/dashboard-reademail-sheet.component';
import { DashboardEmailGroupSheetComponent } from './dashboard-emailgroup-sheet/dashboard-emailgroup-sheet.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import {DndDirective} from '../common/directives/dnd.directive';
import {MapResultsModule} from '../frontend/map-results/map-results.module';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxPayPalModule } from 'ngx-paypal';
import { NgxMaskModule } from 'ngx-mask';
import { DebounceClickDirective } from '../common/directives/debounce';
import {PPLModule} from '../ppl/ppl.module';
import { DashboardSettingsComponent } from './dashboard-settings/dashboard-settings.component';
import { PersonalInfoComponent } from './dashboard-settings/personal-info/personal-info.component';
import { ChangeAvatarComponent } from './dashboard-settings/personal-info/change-avatar/change-avatar.component';
import { DeleteProfileComponent } from './dashboard-settings/personal-info/delete-profile/delete-profile.component';
import { SubscriptionComponent } from './dashboard-settings/subscription/subscription.component';
import { SecurityComponent } from './dashboard-settings/security/security.component';
import { PremiumFeaturesComponent } from './dashboard-settings/premium-features/premium-features.component';
import {RemoveFriendsComponent} from '../common/components/modals/remove-friends/remove-friends-component';
import { ChatService} from '../common/services/chat.service';


import { ProfileComponent } from '../profile/profile.component';
import { PostsComponent } from '../profile/posts/posts.component';
import { FriendsComponent } from '../profile/friends/friends.component';
import { GroupsComponent } from '../profile/groups/groups.component';
import { MediaComponent } from '../profile/media/media.component';
import { AboutComponent } from '../profile/about/about.component';
import { AboutReadonlyComponent } from '../profile/about-readonly/about-readonly.component';
import { ShareDialogComponent } from '../profile/posts/share-dialog/share-dialog.component';
import { CommentDialogComponent } from '../profile/posts/comment-dialog/comment-dialog.component';
import { CommentEditDialogComponent } from '../profile/posts/edit-dialog/edit-dialog.component';
import { CommentLocationDialogComponent } from '../profile/posts/location_dialog/location-dialog.component';
import { CommentUploadDialogComponent } from '../profile/posts/upload_dialog/upload-dialog.component';
import {AlbumDialogComponent} from '../profile/media/album-dialog/album-dialog.component';
import {AutoFocus} from '../common/directives/auto-focus.directive';
import {UploadPictureDialogComponent} from '../profile/media/uploadpicture-dialog/uploadpicture-dialog.component';
import {DeletealbumDialogComponent} from '../profile/media/deletealbum-dialog/deletealbum-dialog.component';
import {DeletepictureDialogComponent} from '../profile/media/deletepicture-dialog/deletepicture-dialog.component';
import {EditpictureDialogComponent} from '../profile/media/editpicture-dialog/editpicture-dialog.component';
import {ImageDialogComponent} from '../profile/posts/image-dialog/image-dialog.component';



@NgModule({
  declarations: [
    DashboardChatIconComponent,
    AmbassadorTableComponent,
    DashboardComplainSheetComponent,
    DashboardLeagueScoreComponent,
    ShareDirective,
    AutoFocus,
    RemoveFriendsComponent,
    AddFriendComponent,
    EmergencyComponent,
    DialogTrialAdminremove,
    DialogSubAdminremove,
    DialogTrialAdminReset,
    DialogSubAdminadd,
    DebounceClickDirective,
    DashboardSubscribeComponent,
    DashboardTrialAdminComponent,
    DashboardSubAdminComponent,
    DashboardShowtrialComponent,
    DashboardShowSuccessComponent,
    DashboardLocationsComponent,
    LocationDelete,
    RegisterProfileComponent,
    DashboardResetpasswordComponent,
    DndDirective,
    SafePipe,
    ProgressComponent,
    DashboardLeaguemodalComponent,
    DashboardTeamLeaguemodalComponent,
    DashboardPregamemodalComponent,
    DashboardCreateLeagueComponent,
    CreateLeagueDialogComponent,
    DashboardMobileFooterNavComponent,
    ProfileSwitcherComponent,
    SettingsMenuComponent,
    DashboardNotificationsComponent,
    DashboardActionCenterComponent,
    ProfileSwitcherComponent,
    DashboardSearchComponent,
    DashboardFrameComponent,
    DashboardEditgroupComponent,
    DashboardEditeventComponent,
    DashboardCopyeventComponent,
    DashboardEditrepeatComponent,
    DashboardVieweventComponent,
    DashboardViewrepeateventComponent,
    DashboardEventviewnotesComponent,
    DashboardStandingsComponent,
    DashboardImportComponent,
    DashboardMembershipadminComponent,
    DashboardJoinMarqueeComponent,
    DashboardGroupManagementComponent,
    DynamicTabsComponent,
    DashboardJoinGroupsComponent,
    DashboardFriendrequestsComponent,
    DashboardGlobalNotificationsComponent,
    DashboardSearchplayersComponent,
    DashboardGroupsComponent,
    DashboardMyleaguesComponent,
    DashboardProfileComponent,
    DashboardShareGroupComponent,
    DashboardShareComponent,
    DashboardEventVideoComponent,
    MenuItemComponent,
    DashboardContactSheetComponent,
    DashboardUploadAvatarComponent,
    DashboardTeamShareComponent,
    DashboardUploadImportComponent,
    DashboardPurchaseSupportModalComponent,
    DashboardCreateGroupComponent,
    DashboardResetpasswordComponent,
    LoaderComponent,
    DashboardRenewalsSheetComponent,
    DashboardUsersheetSheetComponent,
    DashboardEmailSheetComponent,
    DashboardReadEmailSheetComponent,
    DashboardEmailGroupSheetComponent,
    DashboardPhotosComponent,
    DashboardMapComponent,
    DashboardViewprofileComponent,
    DashboardAnnouncementsComponent,
    DashboardEventviewComponent,
    DashboardEventviewinvitegroupsComponent,
    DashboardEventviewsavelistComponent,
    DashboardEventviewsinvitelistComponent,
    DashboardEventviewdeclineComponent,
    DashboardEventviewcancelComponent,
    DashboardSettingsComponent,
    PersonalInfoComponent,
    ChangeAvatarComponent,
    DeleteProfileComponent,
    SubscriptionComponent,
    SecurityComponent,
    PremiumFeaturesComponent,
    DialogAcceptFriend,
    DialogRejectFriend,
    DialogDeleteNotification,
    DashboardNewsComponent,
    ProfileComponent,
    PostsComponent,
    FriendsComponent,
    GroupsComponent,
    MediaComponent,
    AboutComponent,
    AboutReadonlyComponent,
    AboutGroupReadonlyComponent,
    ShareDialogComponent,
    CommentEditDialogComponent,
    CommentLocationDialogComponent,
    CommentDialogComponent,
    CommentUploadDialogComponent,
    AlbumDialogComponent,
    UploadPictureDialogComponent,
    DeletealbumDialogComponent,
    DeletepictureDialogComponent,
    EditpictureDialogComponent,
    ImageDialogComponent,
    DeletepostDialogComponent,
    ReportpostDialogComponent,

    UnfollowDialogComponent,
    removeMemberDialogComponent,
    DeletecommentDialogComponent,
    EditCommentDialogComponent,
    BadgeComponent,
    DashboardFriendsComponent,
    LocationsPanelComponent,
    removeclublocationDialogComponent
  ],
  imports: [
    NgxMaskModule,
    NgxPayPalModule,
    ImageCropperModule,
    MapResultsModule,
    AngularEditorModule,
    CommonModule,
    MaterialModule,
    DynamicFormModule,
    AccountSettingsModule,
    PrivacyModule,
    DashboardCardModule,
    TourMatMenuModule,
    RouterModule,
    AccountSettingsModule,
    StepperModule,
    ReactiveFormsModule,
    FormsModule,
    Ng5SliderModule,
    PPLModule,
  ],
    exports: [
      AmbassadorTableComponent,
      DashboardComplainSheetComponent,
      DashboardLeagueScoreComponent,
      ShareDirective,
      RemoveFriendsComponent,
      AddFriendComponent,
      DebounceClickDirective,
      MapResultsModule,
      DndDirective,
      DynamicFormModule,
      DashboardCardModule,
      DashboardMobileFooterNavComponent,
      ProfileSwitcherComponent,
      SettingsMenuComponent,
      DashboardNotificationsComponent,
      DashboardActionCenterComponent,
      DashboardJoinMarqueeComponent,
      DashboardSearchComponent,
      DashboardGroupManagementComponent,
      MenuItemComponent,
      LoaderComponent,
      DialogTrialAdminremove,
      DialogSubAdminremove,
      DialogTrialAdminReset,
      DialogSubAdminadd,
      DialogAcceptFriend,
      DialogRejectFriend,
      DialogDeleteNotification,
      AutoFocus,
      ShareDirective

    ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    ChatService,
    AccountService,
    autocompleteService,
    RenewallistService,
    MygroupsService,
    CalendarService,
    DatePipe,
    BottomSheetService,
    DashboardeventsService,
    TalkService
  ],
  entryComponents: [
    DashboardChatIconComponent,
    AmbassadorTableComponent,
    DashboardComplainSheetComponent,
    DashboardLeagueScoreComponent,
    RemoveFriendsComponent,
    AddFriendComponent,
    EmergencyComponent,
    DialogTrialAdminremove,
    DialogSubAdminremove,
    DialogTrialAdminReset,
    DialogSubAdminadd,
    DashboardSubscribeComponent,
    DashboardTrialAdminComponent,
    DashboardSubAdminComponent,
    DashboardShowtrialComponent,
    DashboardShowSuccessComponent,
    DashboardLocationsComponent,
    LocationDelete,
    RegisterProfileComponent,
    LoaderComponent,
    DashboardLeaguemodalComponent,
    DashboardTeamLeaguemodalComponent,
    DashboardPregamemodalComponent,
    CreateLeagueDialogComponent,
    PickelballRatingComponent,
    DashboardGroupManagementComponent,
    DashboardJoinGroupsComponent,
    DashboardFriendrequestsComponent,
    DashboardGlobalNotificationsComponent,
    DashboardSearchplayersComponent,
    DashboardProfileComponent,
    DashboardGroupsComponent,
    DashboardMyleaguesComponent,
    DashboardShareGroupComponent,
    DashboardShareComponent,
    DashboardEventVideoComponent,
    DashboardEventviewnotesComponent,
    DashboardContactSheetComponent,
    DashboardUploadAvatarComponent,
    DashboardTeamShareComponent,
    DashboardUploadImportComponent,
    DashboardPurchaseSupportModalComponent,
    DashboardCreateGroupComponent,
    DashboardResetpasswordComponent,
    DashboardRenewalsSheetComponent,
    DashboardUsersheetSheetComponent,
    DashboardEmailSheetComponent,
    DashboardReadEmailSheetComponent,
    DashboardEmailGroupSheetComponent,
    DashboardViewprofileComponent,
    DashboardEventviewComponent,
    DashboardEventviewinvitegroupsComponent,
    DashboardEventviewsavelistComponent,
    DashboardEventviewsinvitelistComponent,
    DashboardEventviewdeclineComponent,
    DashboardEventviewcancelComponent,
    ChangeAvatarComponent,
    DeleteProfileComponent,
    DialogAcceptFriend,
    DialogRejectFriend,
    DialogDeleteNotification,
    DashboardNewsComponent,
    ProfileComponent,
    ShareDialogComponent,
    CommentEditDialogComponent,
    CommentLocationDialogComponent,
    CommentDialogComponent,
    CommentUploadDialogComponent,
    UploadPictureDialogComponent,
    AlbumDialogComponent,
    DeletealbumDialogComponent,
    DeletepictureDialogComponent,
    EditpictureDialogComponent,
    ImageDialogComponent,
    DeletepostDialogComponent,
    ReportpostDialogComponent,
    UnfollowDialogComponent,
    removeMemberDialogComponent,
    DeletecommentDialogComponent,
    EditCommentDialogComponent,
    BadgeComponent,
    DashboardFriendsComponent,
    LocationsPanelComponent,
    removeclublocationDialogComponent
  ]
})
export class DashboardModule {
}
