import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { FieldConfig } from '../../models/field-config.interface';
import { FormControl } from '@angular/forms';
@Component({
  exportAs: 'appDynamicFormRate',
  selector: 'app-dynamic-rate_form',
  styleUrls: ['dynamic-form.component.scss'],
  templateUrl: 'dynamic-rform.component.html',
})
export class DynamicFormRateComponent implements OnChanges, OnInit {
  @Input()
  config: FieldConfig[] = [];

  @Output()
  submit: EventEmitter<any> = new EventEmitter<any>();
  rform: FormGroup;

  get controls() { return this.config.filter(({type}) => type !== 'button'); }
  get changes() { return this.rform.valueChanges; }
  get valid() { return this.rform.valid; }
  get value() { return this.rform.value; }

  constructor(private fb: FormBuilder) {
  }

  ngOnInit() {
    this.rform = this.createGroup();


    this.rform = new FormGroup({
      pickleballrating: new FormControl(),
    });

  }

  ngOnChanges() {
    if (this.rform) {

      const controls = Object.keys(this.rform.controls);
      const configControls = this.controls.map((item) => item.name);

      controls
        .filter((control) => !configControls.includes(control))
        .forEach((control) => this.rform.removeControl(control));

      configControls
        .filter((control) => !controls.includes(control))
        .forEach((name) => {
          const config = this.config.find((control) => control.name === name);
          this.rform.addControl(name, this.createControl(config));
        });

    }
  }

  createGroup() {
    const group = this.fb.group({});
    this.controls.forEach(control => group.addControl(control.name, this.createControl(control)));
    return group;
  }

  createControl(config: FieldConfig) {
    const { disabled, validation, value } = config;
    return this.fb.control({ disabled, value }, validation);
  }

  handleSubmit(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.submit.emit(this.value);
  }

  setDisabled(name: string, disable: boolean) {
    if (this.rform.controls[name]) {
      const method = disable ? 'disable' : 'enable';
      this.rform.controls[name][method]();
      return;
    }

    this.config = this.config.map((item) => {
      if (item.name === name) {
        item.disabled = disable;
      }
      return item;
    });
  }

  setValue(name: string, value: any) {
    this.rform.controls[name].setValue(value, {emitEvent: true});
  }

}
