import {Injectable} from '@angular/core';
import { Subject, Observable } from 'rxjs';
import {PPLEvent} from './ppl.interface';

@Injectable()
export class PPLService {
    getPPLs(): Observable<PPLEvent[]> {
        const subject = new Subject<PPLEvent[]>();
        setTimeout(() => {
                subject.next(PPLS);
                subject.complete();
            },
            100);
        return subject;
    }

  //   getPPL(id: number): PPLEvent {
  //   return PPLS.find(event => event.id === id);
  // }
}

const PPLS: PPLEvent[] = [
    {
        id: 1,
        leagueName: 'Plattsmouth Pickleball 1',
        leagueType: 'Men\'s',
        skillRating: 3.0,
        clubName: 'TIH Demo Club',
        format: 'Ladder',
        stats: {
            average: 74,
            wins: 10,
            standings: 3,
            played: 5,
        },
        startDate: '11/1/18',
        endDate: '12/15/18',
        startTime: '6:30pm',
        endTime: '7:30pm',
        status: 'shootout',
        eventName: 'Pickleball Madness',
        registrationCloses: '12/15/18',
        location: {
            address: '555 N. Pickleball Circle',
            city: 'Phoenix',
            state: 'AZ',
            zip: 85034
        },
        contact: {
            firstName: 'Joe',
            lastName: 'Pickleball',
            email: 'joe@pickleball.com',
        }
    },
    {
        id: 2,
        leagueName: 'Plattsmouth Pickleball 1',
        leagueType: 'Men\'s',
        skillRating: 3.0,
        clubName: 'TIH Demo Club',
        format: 'Fixed Partner',
        stats: {
            average: 74,
            wins: 10,
            standings: 3,
            played: 4,
        },
        startDate: '11/1/18',
        endDate: '12/15/18',
        startTime: '6:30pm',
        endTime: '7:30pm',
        status: 'shootout',
        eventName: 'Pickleball Madness',
        registrationCloses: '12/15/18',
        location: {
            address: '555 N. Pickleball Circle',
            city: 'Phoenix',
            state: 'AZ',
            zip: 85034
        },
        contact: {
            firstName: 'Joe',
            lastName: 'Pickleball',
            email: 'joe@pickleball.com',
        }
    },
    {
        id: 3,
        leagueName: 'Plattsmouth Pickleball 1',
        leagueType: 'Men\'s',
        skillRating: 3.0,
        clubName: 'TIH Demo Club',
        format: 'Rotate Partner',
        stats: {
            average: 74,
            wins: 10,
            standings: 3,
            played: 5,
        },
        startDate: '11/1/18',
        endDate: '12/15/18',
        startTime: '6:30pm',
        endTime: '7:30pm',
        status: 'shootout',
        eventName: 'Pickleball Madness',
        registrationCloses: '12/15/18',
        location: {
            address: '555 N. Pickleball Circle',
            city: 'Phoenix',
            state: 'AZ',
            zip: 85034
        },
        contact: {
            firstName: 'Joe',
            lastName: 'Pickleball',
            email: 'joe@pickleball.com',
        }
    }
];
