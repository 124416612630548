import {Injectable, ComponentRef} from '@angular/core';
import {MatBottomSheet} from '@angular/material';
import {GoogleAnalyticsService} from "ngx-google-analytics";


@Injectable()
export class BottomSheetService {
  component: ComponentRef<any>;

  constructor(private bottomSheet: MatBottomSheet, private $gaService: GoogleAnalyticsService) {}

  openBottomSheet(component,name): void {
    console.log('7');
    this.$gaService.pageView('/dashboard/openBottomSheet/'+ name, name);
    console.log('8');
    this.bottomSheet.open(component, {
      data: { id: 1 }});
    console.log('9');

  }

  openBottomSheetWithData(component, name, data): void {
    console.log('6');
    //this.$gaService.pageView('/dashboard/openBottomSheetWithData/'+ name, name);
    console.log('1');
    this.bottomSheet.dismiss();
    this.bottomSheet.open(component, {data: { id: data, ref: this.bottomSheet }});
    console.log('2');
  }

  openBottomSheetWithDataCustomClass(component, name, data, panelClass): void {
    console.log('5');
    this.$gaService.pageView('/dashboard/openBottomSheetWithDataCustomClass/'+ name, name);
    console.log('3');
    this.bottomSheet.open(component, {panelClass: panelClass,  data: { id: data, ref: this.bottomSheet }});
    console.log('4');
  }

  openBottomSheetWithDataDisable(component, name, data): void {
    this.$gaService.pageView('/dashboard/openBottomSheetWithDataDisable/'+ name, name);
    this.bottomSheet.open(component, {data: { id: data, ref: this.bottomSheet }});
  }

}
