import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';


@Injectable({
  providedIn: 'root'
})
export class FormShareteamService {


  constructor() { }

  form: FormGroup = new FormGroup({
    teamname: new FormControl(),
    name: new FormControl(),
    teammate: new FormControl(),
    sms: new FormControl(),
    link: new FormControl(),
    email: new FormControl(),
  });

  disableForm() {
    this.form.disable();
  }

  addControl(field) {
    this.form.addControl(field, new FormControl({disabled: true}));
  }

  disableControl(field, status) {
    this.form.setControl(field, new FormControl({disabled: status}));
  }


  removeControl(field) {
    this.form.removeControl(field);
  }

  enableForm() {
    this.form.enable();
  }

  initializeFormGroup() {
    this.form.controls.teammate.setValue('');
    this.form.controls.sms.setValue('');
    this.form.controls.link.setValue('');
    this.form.controls.email.setValue('');
  }


}
