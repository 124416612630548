import {
  Component,
  ViewChild,
  AfterViewInit,
  OnDestroy,
  ChangeDetectionStrategy,
} from '@angular/core';

import {FieldConfig} from '../../../dynamic-form/models/field-config.interface';
import {
  DynamicFormcontactComponent,
} from '../../../dynamic-form/containers';

import { SearchAddressService } from '../../../common/services/searchaddress.service';





@Component({
  selector: 'app-league-search-filter',
  templateUrl: './league-search-filter.component.html',
  styleUrls: ['./league-search-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class LeagueSearchFilterComponent implements AfterViewInit, OnDestroy {
  smallScreen: boolean;
  bigScreen: boolean;

  address: Object;

  formConfig: FieldConfig[] = [
    {
      type: 'places',
      placeholder: 'Where?',
      name: 'addresstext',
    }
  ];

  @ViewChild(DynamicFormcontactComponent, {static: false}) cform: DynamicFormcontactComponent;



  constructor(private searchSubject: SearchAddressService) {



  }

  ngOnDestroy() {


  }

  ngAfterViewInit() {

  }
}
