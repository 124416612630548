import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule} from '../../material.module';
import {DashboardCardModule} from '../common/dashboard/dashboard-card.module';
import {
    ClubCardJoinRequestComponent,
    ClubCardNotificationComponent,
    ClubCardMemberDuesComponent,
    ClubCardComponent,
} from './index';
import {AccountSettingsModule} from "../settings/account-settings/account-settings.module";
import {RouterModule} from "@angular/router";
import { AngularEditorModule } from '@kolkov/angular-editor';

@NgModule({
  declarations: [
      ClubCardComponent,
      ClubCardJoinRequestComponent,
      ClubCardNotificationComponent,
      ClubCardMemberDuesComponent,
  ],
  imports: [
    AngularEditorModule,
    CommonModule,
    MaterialModule,
    DashboardCardModule,
    AccountSettingsModule,
    RouterModule
  ],
  exports: [
    ClubCardComponent,
    ClubCardJoinRequestComponent,
    ClubCardNotificationComponent,
  ]
})
export class ClubsModule { }
