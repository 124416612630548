import {Component, AfterViewInit, ChangeDetectionStrategy} from '@angular/core';
import {Validators, FormGroup} from '@angular/forms';
import {FieldConfig} from '../../dynamic-form/models/field-config.interface';

@Component({
  selector: 'app-scorecard-generate-round-robin',
  templateUrl: './scorecard-generate-round-robin.component.html',
  styleUrls: ['./scorecard-generate-round-robin.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class ScorecardGenerateRoundRobinComponent implements AfterViewInit  {
  stepLabel = ['Step One', 'Step Two', 'Step Three'];
  stepOneRoundRobin: FieldConfig[] = [
    {
      type: 'select',
      label: 'Group',
      name: 'pickleballrating',
      options: ['ORIPA Ladies Shootout', 'ORIPA Membership Group', 'ORIPA Men & Ladies Shootout'],
      placeholder: 'Select a Group',
      required: true
    },
    {
      type: 'select',
      label: 'Event',
      name: 'event',
      options: ['ORIPA Shootout - 02-04-2015', 'ORIPA Shootout  - 02-11-2015', 'ORIPA Shootout   - 02-18-2015'],
      placeholder: 'Select a Event',
      required: true
    },
     {
      type: 'checkbox',
      label: 'Show Past Events',
      name: 'pastEvents',
      required: false,
    },
    {
      type: 'input',
      label: 'example: 10/22/2019',
      name: 'name',
      placeholder: 'Name',
      validation: [Validators.required],
      required: true
    },
    {
      type: 'date',
      label: 'example: 10/22/2019',
      name: 'eventDate',
      mask: '00 - 00- 0000',
      placeholder: 'Date',
      validation: [Validators.required],
      required: true
    },
    {
      type: 'input',
      label: 'Start Time',
      name: 'startTime',
      mask: '08:00',
      placeholder: 'Start Time',
      color: 'primary',
      validation: [Validators.required],
      required: true
    },
    {
      type: 'input',
      label: 'End Time',
      name: 'endTime',
      mask: '00:00',
      placeholder: '12:00',
      color: 'primary',
      validation: [Validators.required],
      required: true
    },
  ];
  stepTwoRoundRobin: FieldConfig[] = [
    {
      type: 'input',
      label: 'Players',
      name: 'players',
      placeholder: 'Players',
      color: 'primary',
      required: true
    },
     {
      type: 'select',
      label: 'Number Players in Each Group',
      name: 'numberOfPlayersInGroup',
      placeholder: 'Number Players in Each Group',
      options: ['9', '4, 5', 'Custom Selection'],
      color: 'primary',
      required: true
    }
  ];
  stepThreeRoundRobin: FieldConfig[] = [
    {
      type: 'input',
      label: 'Max Courts Available',
      name: 'players',
      placeholder: 'Max Courts Available',
      color: 'primary',
      required: true
    },

    {
      type: 'input',
      label: 'Maximum Points/Game',
      name: 'maxPointsPerGame',
      placeholder: 'Maximum Points/Game',
      color: 'primary',
      validation: [Validators.required],
      required: true
    },
    {
      type: 'radio',
      label: 'Place Players on Courts',
      name: 'playersOnCourts',
      options: ['Randomly', 'Skill Level'],
      required: true
    },
    {
      type: 'checkbox',
      label: 'Include Scores in Standings Percentage',
      name: 'scoresInStandingPercentage',
    }
  ];
  ngAfterViewInit() {
  }
}
