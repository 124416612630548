import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject, Input, OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { Router } from '@angular/router';

import { PanelCardService } from '../../common/services/panelcard.service';


import { MAT_BOTTOM_SHEET_DATA } from '@angular/material';
import { SessionStorageService } from 'ngx-webstorage';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { PanelService} from '../../common/services/panel.service';
import {AccountService} from '../../common/services/account.service';
import { GroupSelectComponent } from '../group-select/group-select.component';
import {ModalService} from '../../common/services/modal.service';
import {environment} from "../../../environments/environment";
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {LeaguesComponent} from "../leagues/leagues.component";
import {BottomSheetService} from "../../common/services/bottom-sheet.service";

export interface Location {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-pickleball-place-details',
  templateUrl: './pickleball-place-details.component.html',
  styleUrls: ['./pickleball-place-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class PickleballPlaceDetailsComponent implements OnInit, OnDestroy {
  @Input() drawerRef: any;

  public modeselect = '';

  carddetails: any;
  cardlocation: any;
  isPublic: any;
  isInside: any;
  isClub: any;
  newdata: any;
  play_type: any;
  appDrawer: any;
  windowScrolling: any;
  picture: any;
  subscription: any;
  is_trial: any;

  s1 = null;
  s2 = null;

  siteurl = environment.service.trackithubapi;

  constructor(
    private router: Router,
    private modalService: ModalService,
    private ref: ChangeDetectorRef,
    private panelservice: PanelService,
    private sessionSt: SessionStorageService,
    public _router: Router,
    private SearchAddress: PanelCardService,
    private accountService: AccountService,
    public dialog: MatDialog,
    private bottomSheetService: BottomSheetService,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
  ) {
    this.newdata = null;
  }

  form: FormGroup = new FormGroup({
    currency: new FormControl(),
  });

  closePanel() {



      if( this.data.appDrawer === undefined) {
        this.drawerRef.close();

        if (this.sessionSt.retrieve('appDrawer') !== '') {
          this.appDrawer = this.sessionSt.retrieve('appDrawer');
          this.windowScrolling = this.sessionSt.retrieve('windowScrolling');
          this.appDrawer.close();
          this.windowScrolling.enable();
        }
      } else {

        this.data.appDrawer.dismiss();
        this.data.windowScrolling.enable();
      }
  }

  mapsSelector(lat, lng) {
    if ((navigator.platform.indexOf('iPhone') !== -1) ||
      (navigator.platform.indexOf('iPod') !== -1) ||
      (navigator.platform.indexOf('iPad') !== -1)) {
      window.open(`maps://maps.google.com/maps?daddr=${lat},${lng}&ll=`);
    } else {
      window.open(`https://maps.google.com/maps?daddr=${lat},${lng}&ll=`);
    }
  }

  changeLocation($event) {
    let index;
    for (index = 0; index <  this.carddetails.locations.length; index++) {
      if (this.carddetails.locations[index].title === $event.value) {
        this.cardlocation = this.carddetails.locations[index];
        this.modeselect = this.carddetails.locations[index].title;
      }
    }
  }


  ngOnDestroy() {
    if (this.s1 != null) {
      this.s1.complete();
    }
    if (this.s2 != null) {
      this.s2.complete();
    }
  }

  showleague(){
    this.bottomSheetService.openBottomSheetWithDataCustomClass(LeaguesComponent, 'LeaguesComponent',{}, 'custom-bottom-p');
    this.closePanel();
  }

  gotodues(group_id){
    window.location.replace('/dashboard/frame/dues?group_id=' + group_id);
    this.closePanel();
  }

  register(group_id) {
    this.sessionSt.store('gotodues', group_id);
    window.location.href = '/register/player';
    this.closePanel();
  }

  login(group_id) {
    this.sessionSt.store('gotodues', group_id);
    window.location.href = '/login';
    this.closePanel();
  }


  multigroup(ext) {

    if(ext === null) {
      this.modalService.openDialog(GroupSelectComponent, 'auto', 'auto', {location: this.cardlocation, next: null});
    }

    if(ext === 'multi') {
      const dialogRef = this.dialog.open(GroupSelectComponent, {
        width: 'auto',
        height: 'auto',
        data: {location: this.cardlocation, next: 'login', reflector: this.data},
        maxHeight: '80vh'
      });


      dialogRef.afterClosed().subscribe(result => {

        if (result === 'register') {
          this.closePanel();
        }

        if (result === 'login') {
          this.closePanel();
        }



      });
    }



  }

  subscribe(){
    window.location.href = '/dashboard/overview?trigger=showsub';
  }


  ngOnInit() {

    this.s1 = this.accountService.cast.subscribe(datax => {
      if (datax !== null) {

        this.s2 = this.SearchAddress.cast.subscribe(data => {
          if(data.card !== null) {
            if (datax.id === null) {
              this.isInside = false;
            } else {
              this.isInside = true;
            }

            this.subscription = datax.current_profile.subscription;
            this.is_trial = datax.current_profile.is_trial;

            if (datax.current_profile.group_id == 9 || datax.current_profile.group_id == 8) {
              this.isClub = false;
            } else if (datax.current_profile.league == 1) {
              this.isClub = true;
            } else {
              this.isClub = true;
            }

            if (data.card.scope !== undefined) {
              this.isPublic = true;
            } else {
              this.isPublic = false;
            }


            this.play_type = this.sessionSt.retrieve('play_type');

            this.carddetails = data.card;
            if (data.card !== null) {

              this.carddetails = data.card;


              if (data.location === null || data.location === undefined || data.location === '') {
                this.cardlocation = data.card.locations[0];
              } else {
                this.cardlocation = data.location;
              }


              if (data.location_title === null || data.location_title === undefined || data.location_title === '') {
                this.modeselect = data.card.locations[0].title;
              } else {
                this.modeselect = data.location_title;
              }

              this.ref.detectChanges();

            }
          }
        });
      }
    });
  }
}
