import {Component, Input} from '@angular/core';
import {Observable, Observer} from 'rxjs';

export interface Tab {
  label: string;
  headings?: string[];
  content?: string[];
  features?: any[];
  clubFeatures?: string[];
  image?: string;
  imageAlt?: string;
  subheadings?: string[];
  description?: string;
}


@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent {
  @Input() title: string;
  asyncTabs: Observable<Tab[]>;
  items: string[];

  constructor() {
    this.asyncTabs = new Observable((observer: Observer<Tab[]>) => {
      setTimeout(() => {
        observer.next([
          {
            label: 'Club Play',
            headings: [
              'Round-Robins, Flex Ladders, Shootouts',
            ],
            features: [
                  'Competitive or social',
                  'Rotating of partners each game',
                  'Unlimited court configuration flexibility',
                  'Scores are perpetually tracked',
                  'Substitutes are not needed',
                  'Standings are updated in real-time',
                  'Email courts schedules',
                  'Generate custom court layouts',
            ],
            image: '../../assets/angular-img/photos/oro-valley-pickleball.jpg',
            imageAlt: 'Pickleball Paddle',
          },
          {
            label: 'Membership',
            headings: [
              'Membership Simplified ',
              'Clubs',
              'Players',
            ],
            subheadings: [
              'Say goodbye to the countless hours of administrative work',
              'Paying for your club membership has never been this easy',
            ],
            features: [
                'Collect dues directly into the club’s PayPal account',
                'Transfer payments from PayPal to bank account with no fees',
                'Create individual, couple, and family plans',
                'Track payments',
                'New member notification',
                'Automated membership expiration emails',
                'Customized membership fields'
              ],
            clubFeatures: [
                'Seamless application and payment process',
                'Membership expiration notification',
                'Individual, couple, and family plans',
                'Flexible payment options',
              ],
          },
          {
            label: 'Event Scheduling',
            headings: ['Event Scheduling'],
            features: [
                  'Simple two-step registration process',
                  'Add guests to your registration',
                  'Track attendees',
                  'Associate venues with specific events',
                  'Automated email reminders',
                  'Event invitations',
                  'Access events on your personal calendar',
                  'Seamless payment process for fee-based events',
            ],
            image: '../assets/angular-img/mockups/space-grey-ipad-mockup.png',
            imageAlt: 'iPad Event Scheduling',
          }
        ]);
      }, 1000);
    });
  }
}


/**

 {
            label: 'Premier Pickleball League',
            headings: [
              'Premier Pickleball League',
            ],
            content: [
              'No matter if you are a pickleball player itching for some top-notch competitive play or are interested in making a profit from coordinating your own league, the Premier Pickleball League has it all! Meet new people, compete against friends, and play the game you love in an organized fashion.'
            ],
            features: [
              'Exclusive first-class experience',
              'Fun, social, and competitive',
              'Six to eight week leagues',
              'No more waiting between matches',
              'Rotate partner or fixed partner',
              'Track your individual or team standing',
              'Seamless communication between League Coordinator and players',
              '<strong>All-inclusive (PRO) approach:</strong> A TrackitHub PRO will create and manage your league'
            ],
            image: '../assets/angular-img/logos/premier-pickleball-league.svg',
            imageAlt: 'Premier Pickleball League',
            description: '<a href="#contact_top" mat-menu-item pageScroll [pageScrollEasing]="myEasing" [pageScrollOffset]="60"><u>Contact us</u></a> for pricing and package benefits.'
          },
 ,
 {
            label: 'Subscription',
            headings: ['Subscription Info'],
            features: [
              'Join multiple clubs',
              'Compete in Premier Pickleball Leagues',
              'Register for social or competitive club events',
              'Participate in club Round-Robins, Ladder-Leagues, and Shootouts',
              'Track league or club standings',
              'Instant communication with club admins or league coordinators',
              'Pay for club Membership Dues online',
              'Find courts to Play Pickleball',
              'Make new friends, post photos and socialize with fellow players',
              'Access to exclusive pickleball boot camps and merchandise',
              'Stay informed on the latest pickleball news',
              'Obtain accurate skill rating by participating in organized play',
            ],
            description: '<sup class="text-black">*</sup>Only players that would like to register for club events, participate in organized play, or compete in the Premier Pickleball League will be required to purchase a subscription.',
            image: '../assets/angular-img/graphics/pickleball-player.svg',
            imageAlt: 'Pickleball Player',
          },

 **/
