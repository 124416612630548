import {Component} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Field} from '../../models/field.interface';
import {FieldConfig} from '../../models/field-config.interface';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-form-file-input',
  styleUrls: ['./form-file-input.component.scss'],
  template: `
    <mat-form-field appearance="{{config.appearance}}" [formGroup]="group" floatLabel="{{config.floatLabel}}" [ngClass]="{'bottompad': this.cropReady === true}">
      <input type="file" (change)="fileChangeEvent($event)" #fileInput>
      <button mat-stroked-button color="primary" (click)="fileInput.click()" type="button">{{config.label}}</button>
      <br>
      <image-cropper style="max-width: 450px; margin-left: -5px; text-align: left!important;"
                     [imageChangedEvent]="imageChangedEvent"
                     [maintainAspectRatio]="true"
                     [aspectRatio]="3 / 3"
                     format="png"
                     (imageCropped)="imageCropped($event)"
                     (imageLoaded)="imageLoaded()"
                     (cropperReady)="cropperReady()"
                     (loadImageFailed)="loadImageFailed()"></image-cropper>
      <textarea matInput [formControlName]="config.name" style="display: none;" [(ngModel)]="this.croppedImage"></textarea>
      <mat-hint *ngIf="!cropReady">Select a file from your device or pick a avatar from the list.</mat-hint>
    </mat-form-field>
    <br>
    <br>
  `
})
export class FormFileInputComponent implements Field {
  config: FieldConfig;
  group: FormGroup;

  cropReady = false;
  imageChangedEvent: any = '';
  croppedImage: any = '';

  constructor() {

  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.croppedImage = '';
    this.cropReady = false;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded() {
    this.cropReady = true;
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }




}
