import {ChangeDetectionStrategy, Component, Input, OnInit, ViewChild, EventEmitter, Output} from '@angular/core';
import {MenuItems} from '../../../common/interfaces/menu-items';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class MenuItemComponent implements OnInit {
  @Input() MenuItems: MenuItems[];
  @ViewChild('childMenu', {static: true}) public childMenu;
  @Output('parentSub') parentSub: EventEmitter<any> = new EventEmitter();
  @Input() is_trial: boolean;
  @Input() is_sub_active: boolean;
  @Input() is_subscription: boolean;

  constructor() { }

  ngOnInit() {
  }

  goplaces(url) {

    if(this.is_trial === false && this.is_sub_active === true && this.is_subscription === false) {
      this.parentSub.emit();
    } else {
      document.location.href = url;
    }
  }

}
