import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Post } from '../posts.component';
import {environment} from "../../../../environments/environment";


interface Avatar {
  value: string;
  viewValue: string;
}

export interface Post {
  id: number,
  active: boolean,
  avatarImg: string,
  avatarPlaceholder: string,
  name: string,
  date: string,
  post: string,
  image?: string,
  video?: string,
  location?: string,
  likeCount: number,
  liked: boolean,
  comments: Post[],
  privacy: string,
  shareLink: string,
  visible: boolean
}


@Component({
  selector: 'app-editcomment-dialog',
  templateUrl: './edit-dialog.component.html',
  styleUrls: ['./edit-dialog.component.scss']
})
export class CommentEditDialogComponent implements OnInit {

  postPlaceholder: string = "What's New?";
  siteUrl: string = environment.service.trackithubapi + '/';
  postOpen: boolean = false;
  samplePosts: Post[];
  postValue: string;

  avatarImg
  avatarPlaceholder
  account
  rating

//  @Input() avatarImg: string;
//  @Input() avatarPlaceholder: string;
//  @Input() account: Account;
//  @Input() rating: string;
   @ViewChild('postField', {static: false}) postField;

  avatars: Avatar[] = [
    {value: 'club', viewValue: 'Club'},
    {value: 'grin', viewValue: 'Grin'},
    {value: 'slight-smile', viewValue: 'Slight Smile'},
    {value: 'smile', viewValue: 'Smile'},
    {value: 'smiling-face-eyes', viewValue: 'Smiling Face Eyes'},
    {value: 'smirking-face', viewValue: 'Smirking Face'},
    {value: 'tilted-grin', viewValue: 'Tilted-grin'},
    {value: 'winking-grin', viewValue: 'Winking-Grin'},
    {value: 'worried-smile', viewValue: 'Worried-Smile'},
  ];



  constructor(public dialogRef: MatDialogRef<CommentEditDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: {post: Post}) {
    //this.post = this.data.post.post;
   }

  ngOnInit() {


  }

  checkPlaceHolder() {
    if (this.postPlaceholder) {
      this.postPlaceholder = null;
      return;
    } else {
      this.postPlaceholder = "What's New?";
      return;
    }
  }

  togglePostInput(state: string) {
    if (state == "open") {
      this.postOpen = true;
      return;
    }
    else if (state == "close") {
      this.postOpen = false;
      return;
    }
  }

  toggleLike(state: boolean){

  }

  //sharePost(post: Object) {
  //  const dialogRef = this.dialog.open(ShareDialogComponent, {
  //    data: {post: post}
  //  })
  //}

  processPost(){
    alert(this.postValue);
    this.postValue = '';
    this.togglePostInput('close');
    this.postField.nativeElement.clearfocus();
  }

  close(){
    this.dialogRef.close();
  }

}
