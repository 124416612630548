import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-member-management-settings',
  templateUrl: './member-management-settings.component.html',
  styleUrls: ['./member-management-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class MemberManagementSettingsComponent implements OnInit {
  myclubs = 'My Clubs';
  constructor() { }

  ngOnInit() {
  }

}
