import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from "@angular/router";
import { MaterialModule } from "../../../material.module";
import { AgmCoreModule } from "@agm/core";
import {LeaguesComponent} from "./leagues.component";
import {LeagueSearchFilterComponent} from './league-search-filter/league-search-filter.component';
import {DynamicFormModule} from "../../dynamic-form/dynamic-form.module";
import {LeaguesEventsComponent} from "./leagues-events/league-events.component";
import {BottomSheetHeadingLeaguesComponent} from "./leagues-sheet-heading/leagues-sheet-heading.component";

@NgModule({
  declarations: [
    LeaguesComponent,
    LeaguesEventsComponent,
    LeagueSearchFilterComponent,
    BottomSheetHeadingLeaguesComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    DynamicFormModule,
    MaterialModule,
    AgmCoreModule.forRoot({
      apiKey: `AIzaSyAfRESSHmts4lPb0h-f74y4spV0L5P1jFA`
    }),
  ],
  exports: [
    LeaguesComponent,
    LeaguesEventsComponent,
    BottomSheetHeadingLeaguesComponent,
    LeagueSearchFilterComponent
  ],
  entryComponents: [
    LeaguesComponent,
    BottomSheetHeadingLeaguesComponent,
    LeaguesEventsComponent
  ]
})
export class LeaguesModule { }
