import {AfterViewInit, ChangeDetectionStrategy, Component, OnInit, ViewChild} from '@angular/core';
import { MatPaginator, MatSort, MatTable } from '@angular/material';
import { StandingsResultsDataSource, StandingsResultsItem } from './standings-results-datasource';

@Component({
  selector: 'app-standings-results',
  templateUrl: './standings-results.component.html',
  styleUrls: ['../standings-table/standings-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class StandingsResultsComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatTable, {static: false}) table: MatTable<StandingsResultsItem>;
  dataSource: StandingsResultsDataSource;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['firstName', 'lastName', 'gender', 'average', 'played'];

  ngOnInit() {
    this.dataSource = new StandingsResultsDataSource();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;
  }
}
