import {Component} from '@angular/core';
import { FormGroup, FormControl} from '@angular/forms';

import { Field } from '../../models/field.interface';
import { FieldConfig } from '../../models/field-config.interface';

@Component({
  selector: 'app-form-datepicker',
  styleUrls: ['./form-datepicker.component.scss'],
  template: `
      <mat-form-field [formGroup]="group">
          <input matInput [placeholder]="config.placeholder" [type]="config.type" [formControl]="serializedDate" [formControlName]="config.name" [value]="serializedDate">
          <mat-datepicker-toggle matSuffix [for]="config.name"></mat-datepicker-toggle>
          <mat-datepicker [ngModel]="config.name"></mat-datepicker>
      </mat-form-field>
  `
})
export class FormDatepickerComponent implements Field  {
  config: FieldConfig;
  group: FormGroup;
  date = new FormControl(new Date());
  serializedDate = new FormControl((new Date()).toISOString());
}
