import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {SnackbarService} from "../../../services/snackbar.service";
import {Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {ToastService} from "../../../toast";
import {AccountService} from "../../../services/account.service";
import {environment} from "../../../../../environments/environment";
import {SessionStorageService} from "ngx-webstorage";
import {MygroupsService} from "../../../services/mygroups.service";
import {LeagueEventviewComponent} from "../../../../frontend";
import {BottomSheetService} from "../../../services/bottom-sheet.service";

@Component({
  selector: 'app-group-add-group',
  styleUrls: ['./group-add-group.component.css'],
  templateUrl: './group-add-group.component.html',
  changeDetection: ChangeDetectionStrategy.Default
})
export class GroupAddGroupComponent {

  @Input() name: string;
  @Input() add: string;
  @Input() payment: string;
  @Input() membership: boolean;
  @Input() profile_type: string;
  @Input() ref: any;
  @Input() group_id: any;
  @Input() row: any;

  constructor(    private bottomSheetService: BottomSheetService,private sessionSt: SessionStorageService, private mygroup: MygroupsService, private http: HttpClient,private toastService: ToastService, private accountService: AccountService, private router: Router) { }

  addLeage(rp_id, event_id){
    this.bottomSheetService.openBottomSheetWithDataCustomClass(LeagueEventviewComponent, 'LeagueEventviewComponent',{
      event:event_id,
      repeat_id: rp_id,
      paid: false
    }, 'custom-bottom');
  }

  groupAdd(group) {
    this.http.get<any>(environment.service.trackithubapi + '/api/inside/groupadd?group_id=' + group + '&profile=' + this.sessionSt.retrieve('current_profile')).subscribe((data: any) => {
      this.accountService.reloadprofile();
      this.toastService.show({
        text: 'Your request to join the group has been submitted and will be reviewed by the club admin.',
        type: 'success',
        buttonText: '',
      });
      this.mygroup.load(this.sessionSt.retrieve('current_profile'), null);
    });
  }

  Dues(group) {
    this.router.navigate(['/dashboard/frame/dues'], { queryParams: { group_id: group } });
    this.ref.dismiss();
  }



}
