import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {SnackbarService} from '../../common/services/snackbar.service';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material/chips';
import {ToastService} from "../../common/toast";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";

export interface Fruit {
  name: string;
}


@Component({
  selector: 'app-share-group',
  templateUrl: './dashboard-share-group.component.html',
  styleUrls: ['./dashboard-share-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})

export class DashboardShareGroupComponent {
  groupname = '';
  groupid = '';
  club_id = '';
  club = false;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  siteurl = environment.service.trackithubapi;

    readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  fruits: Fruit[] = [];

  constructor(
    private snackBar: SnackbarService,
    public dialogRef: MatDialogRef<DashboardShareGroupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      club: boolean;
      club_id: string;
      id: string
    },
    private toastService: ToastService,
    private http: HttpClient,
  ) {
    this.groupid = data.id;
    this.club = data.club;
    this.club_id = data.club_id;
  }

  send(){
    this.http.get<any>(environment.service.trackithubapi + '/api/inside/sendshare?club_id=' + this.club_id + '&group_id=' + this.groupid + '&value=' + JSON.stringify(this.fruits)).subscribe((data: any) => {
      this.toastService.show({
        text: 'Share link has been sent.',
        type: 'success',
        buttonText: '',
      });
    });
  }

  paste(event: ClipboardEvent): void {
    event.preventDefault();
    event.clipboardData
      .getData('Text')
      .split(/;|,|\n/)
      .forEach(value => {
        if (value.trim()) {
          this.fruits.push({ name: value.trim() });
        }
      });
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.fruits.push({name: value.trim()});
    }

    if (input) {
      input.value = '';
    }
  }

  remove(fruit: Fruit): void {
    const index = this.fruits.indexOf(fruit);

    if (index >= 0) {
      this.fruits.splice(index, 1);
    }
  }

  copyInputMessage(inputElement) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
    this.snackBar.success('Link Copied to Clipboard', '');
    this.dialogRef.close();
  }

}
