import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ElementRef,
  ChangeDetectionStrategy,
  ChangeDetectorRef, OnDestroy, AfterViewInit
} from '@angular/core';
import { MatDialog } from '@angular/material';
import { Account } from 'src/app/common/interfaces/account';
import { environment } from 'src/environments/environment';
import { ShareDialogComponent } from './share-dialog/share-dialog.component';
import {CommentDialogComponent} from "./comment-dialog/comment-dialog.component";
import {CommentLocationDialogComponent} from "./location_dialog/location-dialog.component";
import {CommentUploadDialogComponent} from "./upload_dialog/upload-dialog.component";
import {HttpClient} from "@angular/common/http";
import {ToastService} from "../../common/toast";
import {ProfileComponent} from "../profile.component";
import {BottomSheetService} from "../../common/services/bottom-sheet.service";
import {ImageDialogComponent} from "./image-dialog/image-dialog.component";
import {PickleballPlaceDetailsComponent} from "../../frontend";
import {PanelService} from "../../common/services/panel.service";
import {PanelCardService} from "../../common/services/panelcard.service";
import {AccountService} from "../../common/services/account.service";
import {DeletepostDialogComponent} from "./deletepost-dialog/deletepost-dialog.component";
import {ReportpostDialogComponent} from "./reportpost-dialog/reportpost-dialog.component";
import {UnfollowDialogComponent} from "./unfollow-dialog/unfollow-dialog.component";
import {ChatService} from "../../common/services/chat.service";
import {DeletecommentDialogComponent} from "./deletecomment-dialog/deletecomment-dialog.component";
import {EditCommentDialogComponent} from "./editcomment-dialog/editcomment-dialog.component";
import imagesLoaded from 'imagesloaded';


interface Avatar {
  value: string;
  viewValue: string;
}

export interface Post {
  online: boolean;
  parent_id: number;
  security: number;
  location_place_id: any;
  location_name: string;
  target_id: string;
  created_by: string;
  id: number,
  active: boolean,
  avatarImg: string,
  avatarPlaceholder: string,
  name: string,
  date: string,
  post: string,
  image?: string,
  video?: string,
  location?: string,
  likeCount: number,
  liked: boolean,
  comments: Post[],
  privacy: string,
  shareLink: string,
  visible: boolean
}

@Component({
  selector: 'app-posts',
  templateUrl: './posts.component.html',
  styleUrls: ['./posts.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})

export class PostsComponent implements OnInit, OnDestroy, AfterViewInit {

  postPlaceholder: string = "What's New?";
  siteUrl: string = environment.service.trackithubapi + '/';
  postOpen: boolean = false;
  samplePosts: Post[];
  postValue: string;
  securityPost: number;
  location_name: string;
  location_place_id: string;
  image: string;
  env: any;
  s1: any;
  is_edit: boolean = false;
  edit_id: string;
  is_post: boolean;
  offset: 100

  @Input() avatarImg: string;
  @Input() avatarPlaceholder: string;
  @Input() account: Account;
  @Input() rating: string;
  @Input() profile_id: string;
  @Input() types: string;
  @Input() pulltype: string;
  @Input() post_id: string;
  @Input() comment_id: string;

  @ViewChild('postField', {static: false}) postField;

  avatars: Avatar[] = [
    {value: 'club', viewValue: 'Club'},
    {value: 'grin', viewValue: 'Grin'},
    {value: 'slight-smile', viewValue: 'Slight Smile'},
    {value: 'smile', viewValue: 'Smile'},
    {value: 'smiling-face-eyes', viewValue: 'Smiling Face Eyes'},
    {value: 'smirking-face', viewValue: 'Smirking Face'},
    {value: 'tilted-grin', viewValue: 'Tilted-grin'},
    {value: 'winking-grin', viewValue: 'Winking-Grin'},
    {value: 'worried-smile', viewValue: 'Worried-Smile'},
  ];


  constructor(  private chatService: ChatService,
                private accountService: AccountService,
                private panelService: PanelService,
                private panelCardService: PanelCardService,
                private bottomSheetService: BottomSheetService,
                private toastService: ToastService,
                private http: HttpClient,
                public dialog: MatDialog,
                private changeDetectorRef: ChangeDetectorRef) { }


  scrollToElement(elementId: string) {
    const element = document.getElementById(elementId);

    const handleScroll = () => {

      element.scrollIntoView({ behavior: 'smooth', block: 'start'});
    };

    if (element) {
      imagesLoaded(element, { background: true }, handleScroll);
      //element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }


  ngAfterViewInit() {

  }

  ngOnInit() {
    this.is_post = true;
    this.edit_id = '';
    this.location_name = '';
    this.securityPost = 1;
    this.image = null;
    this.env = environment;
    this.getPosts(null);
    this.samplePosts = [];

    this.s1 = this.accountService.cast.subscribe(data => {
      this.getPosts(null);
    });

  }

  ngOnDestroy() {
    if (this.s1 != null) {
      this.s1.complete();
    }
  }

  checkPlaceHolder() {
    if (this.postPlaceholder) {
      this.postPlaceholder = null;
      return;
    } else {
      this.postPlaceholder = "What's New?";
      return;
    }
  }

  togglePostInput(state: string) {
    if (state == "open") {
      this.postOpen = true;
      return;
    }
    else if (state == "close") {
      this.postOpen = false;
      return;
    }
  }

  toggleLike(post){
    if(post.liked == true){
      post.liked = false;
      post.likeCount--;

      this.http.post<any>(environment.service.trackithubapi + '/api/inside/removelike', {
        post_id: post.id,
        from_id: this.account.current_profile.profile_id
      }).subscribe((data: any) => {
        //this.getPosts();
      });
    } else {
      post.liked = true;
      post.likeCount++;

      this.http.post<any>(environment.service.trackithubapi + '/api/inside/addlike', {
        post_id: post.id,
        from_id: this.account.current_profile.profile_id
      }).subscribe((data: any) => {
        //this.getPosts();
      });
    }
  }

  sharePost(post: Object) {
    const dialogRef = this.dialog.open(ShareDialogComponent, {
      data: {post: post}
    })
  }


  getPosts(id){
    this.http.post<any>(environment.service.trackithubapi + '/api/inside/getposts', {
      target_id: this.profile_id,
      from_id: this.account.current_profile.profile_id,
      type: this.pulltype,
      profile_type: this.pulltype
    }).subscribe((data: any) => {
      this.samplePosts = data.result;

      if(id !== null){
        for (let i = 0; i < this.samplePosts.length; i++) {
          if(this.samplePosts[i].id == id) {

            if(this.samplePosts[i].visible == true){
              this.samplePosts[i].visible = false;
            } else {
              this.samplePosts[i].visible = true;
            }


          }
        }
      }

      this.changeDetectorRef.detectChanges();


      if(this.post_id != ''){

        if(this.comment_id === undefined) {
          this.scrollToElement(this.post_id);
        } else {



          this.toggleOpenID(this.post_id);
          this.scrollToElement(this.comment_id);

        }

      }

    });
  }

  editPost(id){
    this.http.post<any>(environment.service.trackithubapi + '/api/inside/editpost', {
      profile_type: this.pulltype,
      post: encodeURIComponent(this.postValue),
      location_name: this.location_name,
      location_place_id: this.location_place_id,
      security: this.securityPost,
      to_profile_id: this.profile_id,
      from_profile_id: this.account.current_profile.profile_id,
      image: this.image,
      post_id: id
    }).subscribe((data: any) => {
      this.toastService.show({
        text: `Post has been updated.`,
        type: 'success',
      });
      this.edit_id = '';
      this.is_edit = false;
      this.postValue = '';
      this.securityPost = 1;
      this.location_place_id = '';
      this.location_name = '';
      this.clearPicture();
      this.getPosts(null);
      this.togglePostInput('close');
    });
  }

  processPost(){
    this.is_post = false;
    this.http.post<any>(environment.service.trackithubapi + '/api/inside/savepost', {
      profile_type: this.pulltype,
      post: encodeURIComponent(this.postValue),
      location_name: this.location_name,
      location_place_id: this.location_place_id,
      security: this.securityPost,
      to_profile_id: this.profile_id,
      from_profile_id: this.account.current_profile.profile_id,
      image: this.image
    }).subscribe((data: any) => {
      this.toastService.show({
        text: `Post has been added.`,
        type: 'success',
      });
      this.is_post = true;
      this.postValue = '';
      this.securityPost = 1;
      this.location_place_id = '';
      this.location_name = '';
      this.clearPicture();
      this.getPosts(null);
      this.togglePostInput('close');
    });
  }

  setLocation(){
    const dialogRef = this.dialog.open(CommentLocationDialogComponent, {
      data: {name: name}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result.place.name !== null){
        this.location_name = result.place.name;
        this.location_place_id = result.place;
      } else {
        this.location_name = '';
        this.location_place_id = '';
      }
      this.changeDetectorRef.detectChanges();
    });
  }

  deletePost(id){
    const dialogRef = this.dialog.open(DeletepostDialogComponent, {
      data: {post: id}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result.status == 'delete'){
        this.http.post<any>(environment.service.trackithubapi + '/api/inside/deletepost', {
          profile_id: this.account.current_profile.profile_id,
          post: result.post.post
        }).subscribe((data: any) => {
          this.toastService.show({
            text: `Post has been deleted.`,
            type: 'success',
          });
          this.postValue = '';
          this.securityPost = 1;
          this.location_place_id = '';
          this.location_name = '';
          this.clearPicture();
          this.getPosts(null);
          this.togglePostInput('close');
        });
      }
    });
  }

  Unfollow(id){
    let name = '';
    let name_id = '';
    let profile_id = '';
    for (let i = 0; i < this.samplePosts.length; i++) {
      if(this.samplePosts[i].id == id) {
        name = this.samplePosts[i].name;
        name_id = this.samplePosts[i].created_by;
        profile_id = this.samplePosts[i].target_id;
      }
    }

    const dialogRef = this.dialog.open(UnfollowDialogComponent, {
      data: {name: name, name_id: name_id, profile_id: profile_id}
    });

    dialogRef.afterClosed().subscribe(result => {

      if(result.status == 'unfollow'){
        //result.post.name, result.post.name_id, result.post.profile_id

        this.http.post<any>(environment.service.trackithubapi + '/api/inside/unfollow', {
          profile_type: this.pulltype,
          profile_id1: result.post.name_id,
          profile_id2: result.post.profile_id,
        }).subscribe((data: any) => {
          this.toastService.show({
            text: `User has been unfollowed.`,
            type: 'success',
          });
          this.postValue = '';
          this.securityPost = 1;
          this.location_place_id = '';
          this.location_name = '';
          this.clearPicture();
          this.getPosts(null);
          this.togglePostInput('close');
        });

      }

    });
  }

  clearLocation(){
    this.location_name = '';
    this.location_place_id = null;
    this.changeDetectorRef.detectChanges();
  }

  clearPicture(){
    this.image = null;
    this.changeDetectorRef.detectChanges();
  }

  showPhoto(post){
    const dialogRef = this.dialog.open(ImageDialogComponent, {
      data: {post: post}
    });

    dialogRef.afterClosed().subscribe(result => {
    });

  }

  setPhoto(){
    const dialogRef = this.dialog.open(CommentUploadDialogComponent, {
      data: {name: name}
    });

    dialogRef.afterClosed().subscribe(result => {
        this.image = result.image
        this.changeDetectorRef.detectChanges();
    });

  }

  openPanel(card) {
    this.panelCardService.selectCard(card, null, null);
    this.panelService.openPanel(PickleballPlaceDetailsComponent,'PickleballPlaceDetailsComponent');
  }

  toggleOpen(post){

    for (let i = 0; i < this.samplePosts.length; i++) {
      if(this.samplePosts[i].id == post.id) {
        if(this.samplePosts[i].visible == true){
          this.samplePosts[i].visible = false;
        } else {
          this.samplePosts[i].visible = true;
        }
      }
    }

    this.changeDetectorRef.detectChanges();
  }


  toggleOpenID(post_id){

    post_id = post_id.substring(5);


    for (let i = 0; i < this.samplePosts.length; i++) {
      if(this.samplePosts[i].id == post_id) {
        if(this.samplePosts[i].visible == true){
          this.samplePosts[i].visible = false;
        } else {
          this.samplePosts[i].visible = true;
        }
      }
    }
    this.changeDetectorRef.detectChanges();
  }

  openComment(post){
    const dialogRef = this.dialog.open(CommentDialogComponent, {
      data: {post: post}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.http.post<any>(environment.service.trackithubapi + '/api/inside/commentpost', {
        profile_type: this.pulltype,
        post: encodeURIComponent(result.comment.comment),
        post_id: result.comment.id,
        to_profile_id: this.profile_id,
        from_profile_id: this.account.current_profile.profile_id,
      }).subscribe((data: any) => {
        this.toastService.show({
          text: `Comment has been added.`,
          type: 'success',
        });
        this.postValue = '';
        this.securityPost = 1;
        this.location_place_id = '';
        this.location_name = '';
        this.clearPicture();
        this.getPosts(post.id);
        this.togglePostInput('close');
      });
    });
  }

  changeSecurity(sec){
    this.securityPost = sec;
  }

  ep(id){
    this.edit_id = id;
    this.is_edit = true;
    this.postOpen = true;

    for (let i = 0; i < this.samplePosts.length; i++) {
      if(this.samplePosts[i].id == id) {
        this.postValue = decodeURIComponent(this.samplePosts[i].post);

          this.http.get(this.samplePosts[i].image, { responseType: 'blob' }).subscribe((response: any) => {
            const reader = new FileReader();
            reader.readAsDataURL(response);
            reader.onloadend = () => {
              this.image = reader.result as string;
              this.changeDetectorRef.detectChanges();
            };
          });

          this.securityPost = this.samplePosts[i].security;
          if(this.samplePosts[i].location_name != '') {
            this.location_name = this.samplePosts[i].location_name;
            this.location_place_id = this.samplePosts[i].location_place_id;
          }
      }
    }
  }

  resetEdit(){
    this.postValue = '';
    this.securityPost = 1;
    this.location_place_id = '';
    this.location_name = '';
    this.clearPicture();
    this.togglePostInput('close');
    this.is_edit = false;
    this.edit_id = '';
  }

  cancelEdit(){
    this.postValue = '';
    this.securityPost = 1;
    this.location_place_id = '';
    this.location_name = '';
    this.clearPicture();
    this.getPosts(null);
    this.togglePostInput('close');

    this.edit_id = '';
    this.is_edit = false;
  }

  reportPost(id){

    for (let i = 0; i < this.samplePosts.length; i++) {
      if(this.samplePosts[i].id == id) {



        const dialogRef = this.dialog.open(ReportpostDialogComponent, {
          data: {data: this.samplePosts[i]}
        });

        dialogRef.afterClosed().subscribe(result => {



          this.http.post<any>(environment.service.trackithubapi + '/api/inside/reportpost', {
            complaint_name: this.account.current_profile.name,
            comment:  encodeURIComponent(result.report.comment),
            date: result.report.date,
            post_id: result.report.id,
            image: result.report.image,
            location_name: result.report.location_name,
            post: encodeURIComponent(result.report.post),
            name: result.report.post_name,
          }).subscribe((data: any) => {
            this.toastService.show({
              text: `Content has been reported.`,
              type: 'success',
            });
          });

        });

      }
    }
  }

  reportComment(id){
    for (let i = 0; i < this.samplePosts.length; i++) {
      for (let x = 0; x < this.samplePosts[i].comments.length; i++) {
        if (this.samplePosts[i].comments[x].id == id) {



          const dialogRef = this.dialog.open(ReportpostDialogComponent, {
            data: {data: this.samplePosts[i].comments[x]}
          });

          dialogRef.afterClosed().subscribe(result => {



            this.http.post<any>(environment.service.trackithubapi + '/api/inside/reportpost', {
              complaint_name: this.account.current_profile.name,
              comment: result.report.comment,
              date: result.report.date,
              post_id: result.report.id,
              image: result.report.image,
              location_name: result.report.location_name,
              post: encodeURIComponent(result.report.post),
              name: result.report.post_name,
            }).subscribe((data: any) => {
              this.toastService.show({
                text: `Content has been reported.`,
                type: 'success',
              });
            });

          });


        }
      }
    }
  }

  ec(id){
    for (let i = 0; i < this.samplePosts.length; i++) {
      for (let x = 0; x < this.samplePosts[i].comments.length; i++) {
        if (this.samplePosts[i].comments[x].id == id) {
          const dialogRef = this.dialog.open(EditCommentDialogComponent, {
              data: {post: decodeURIComponent(this.samplePosts[i].comments[x].post), post_id: id}
          });

          dialogRef.afterClosed().subscribe(result => {
            this.http.post<any>(environment.service.trackithubapi + '/api/inside/commentedit', {
              post: encodeURIComponent(result.comment.comment),
              post_id: result.comment.id
            }).subscribe((data: any) => {
              this.toastService.show({
                text: `Comment has been updated.`,
                type: 'success',
              });
              this.postValue = '';
              this.securityPost = 1;
              this.location_place_id = '';
              this.location_name = '';
              this.clearPicture();
              this.getPosts(this.samplePosts[i].parent_id);
              this.togglePostInput('close');
            });
          });
        }
      }
    }
  }

  deleteComment(id){
    const dialogRef = this.dialog.open(DeletecommentDialogComponent, {
      data: {comment: id}
    });


    dialogRef.afterClosed().subscribe(result => {
      if(result.status == 'delete'){
        this.http.post<any>(environment.service.trackithubapi + '/api/inside/deletepost', {
          profile_id: this.account.current_profile.profile_id,
          post: result.comment.comment
        }).subscribe((data: any) => {
          this.toastService.show({
            text: `Comment has been deleted.`,
            type: 'success',
          });
          this.postValue = '';
          this.securityPost = 1;
          this.location_place_id = '';
          this.location_name = '';
          this.clearPicture();
          this.getPosts(null);
          this.togglePostInput('close');
        });
      }
    });
  }

  openProfile(profile_id, profile_type) {
    if(this.env.service.profile == true) {
      this.http.get<Account>(environment.service.trackithubapi + '/api/inside/loadprofile?clean_group=yes&profile=' + profile_id).subscribe((profileData: any) => {

        this.bottomSheetService.openBottomSheetWithDataCustomClass(ProfileComponent, 'ProfileComponent',{
          'profileData': profileData,
          'type': profile_type
        }, 'custom-bottom-p' );

      });
    }
  }

  chatOpen(id){

    let name = '';
    let email = '';
    let profile_id = '';
    let avatar = '';
    let avatarProfile = '';

    for (let i = 0; i < this.samplePosts.length; i++) {
      if(this.samplePosts[i].id == id) {
        name = this.samplePosts[i].name;
        email = '';
        profile_id = this.samplePosts[i].created_by;
        avatar = this.samplePosts[i].avatarImg;
        avatarProfile = this.samplePosts[i].avatarPlaceholder;;
      }
    }



    this.chatService.openchatuser({
      profile_id: profile_id,
      name: name,
      avatarImg: avatar,
      avatarPlaceholder: avatarProfile,
      email: ''
    });
  }

}
