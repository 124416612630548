import {NgModule, ModuleWithProviders} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MaterialModule} from '../../../material.module';
import {ShareGroupDirective} from '../directives/share-group.directive';
import {HoldableDirective} from '../directives/holdable.directive';
import {DecodeURIComponentPipe} from '../../common/directives/uri-decode.directive';

import {
  GroupBlankComponent,
  DashboardCardMenuComponent,
  DashboardCardComponent,
  DashboardCardHeaderComponent,
  DashboardCardHeaderOverviewComponent,
  DashboardCardFooterComponent,
  DashboardAccordionComponent,
  DashboardCardStatsComponent,
  MemberManagementComponent,
  DashboardAvatarComponent,
  DashboardCardClubRatingRangeComponent,
  DashboardCardChipsComponent,
  GroupRenewalsComponent
} from './index';
import {DashboardPanelHeaderComponent} from '../../ppl';
import {DynamicComponentService} from '../services/dynamic-component.service';
import {RouterModule} from '@angular/router';
import { GroupJoinRequestComponent } from './member-management/group-join-request/group-join-request.component';
import { GroupArchiveComponent } from './member-management/group-archive/group-archive.component';
import {DialogGroupRemove, GroupMyGroupComponent} from './member-management/group-my-group/group-my-group.component';
import { GroupAddGroupComponent } from './member-management/group-add-group/group-add-group.component';
import { MygroupsPanelComponent } from './member-management/mygroups-panel/mygroups-panel.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import {FormsModule} from "@angular/forms";
import { DashboardCardJoinComponent } from './dashboard-card/dashboard-card-join/dashboard-card-join.component';
import { DashboardCardCreateComponent } from './dashboard-card/dashboard-card-create/dashboard-card-create.component';
@NgModule({
  declarations: [
    DecodeURIComponentPipe,
    DialogGroupRemove,
    GroupBlankComponent,
    DashboardCardComponent,
    DashboardAccordionComponent,
    DashboardCardHeaderComponent,
    DashboardCardHeaderOverviewComponent,
    DashboardCardFooterComponent,
    DashboardCardStatsComponent,
    MemberManagementComponent,
    DashboardCardMenuComponent,
    ShareGroupDirective,
    HoldableDirective,
    DashboardAvatarComponent,
    DashboardAvatarComponent,
    DashboardCardClubRatingRangeComponent,
    DashboardCardChipsComponent,
    GroupJoinRequestComponent,
    GroupArchiveComponent,
    GroupMyGroupComponent,
    GroupAddGroupComponent,
    MygroupsPanelComponent,
    GroupRenewalsComponent,
    DashboardCardJoinComponent,
    DashboardCardCreateComponent
  ],
  entryComponents: [
    DialogGroupRemove,
    DashboardPanelHeaderComponent,
    MemberManagementComponent,
  ],
    imports: [
        AngularEditorModule,
        CommonModule,
        MaterialModule,
        RouterModule,
        FormsModule,
    ],
  exports: [
    DecodeURIComponentPipe,
    GroupBlankComponent,
    DashboardCardComponent,
    DashboardAccordionComponent,
    DashboardCardStatsComponent,
    MemberManagementComponent,
    DashboardCardHeaderComponent,
    DashboardCardHeaderOverviewComponent,
    DashboardCardFooterComponent,
    DashboardCardMenuComponent,
    ShareGroupDirective,
    DashboardAvatarComponent,
    DashboardCardClubRatingRangeComponent,
    DashboardCardChipsComponent,
    GroupJoinRequestComponent,
    GroupMyGroupComponent,
    HoldableDirective,
    GroupArchiveComponent,
    GroupAddGroupComponent,
    MygroupsPanelComponent,
    GroupRenewalsComponent,
    DashboardCardJoinComponent,
    DashboardCardCreateComponent,
    DecodeURIComponentPipe
  ],
  providers: [
    DecodeURIComponentPipe,
    MemberManagementComponent,
  ]
})
export class DashboardCardModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: DashboardCardModule,
      providers: [DynamicComponentService]
    };
  }
}
