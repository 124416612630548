import {Component, AfterViewInit, ChangeDetectionStrategy} from '@angular/core';
import {Validators, FormGroup} from '@angular/forms';
import {FieldConfig} from '../../dynamic-form/models/field-config.interface';

@Component({
  selector: 'app-scorecard-generate-shootout',
  templateUrl: './scorecard-generate-shootout.component.html',
  styleUrls: ['./scorecard-generate-shootout.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class ScorecardGenerateShootoutComponent implements AfterViewInit {
  stepLabel = ['Step One', 'Step Two', 'Step Three'];

  stepOneForm: FieldConfig[] = [
    {
      type: 'select',
      label: 'Group',
      name: 'pickleballrating',
      options: ['ORIPA Ladies Shootout', 'ORIPA Membership Group', 'ORIPA Men & Ladies Shootout'],
      placeholder: 'Select a Group',
      required: true
    },
    {
      type: 'select',
      label: 'Event',
      name: 'event',
      options: ['ORIPA Shootout - 02-04-2015', 'ORIPA Shootout  - 02-11-2015', 'ORIPA Shootout   - 02-18-2015'],
      placeholder: 'Select a Event',
      required: true
    },
    {
      type: 'checkbox',
      label: 'Show Past Events',
      name: 'pastEvents',
      required: false,
    },
    {
      type: 'input',
      label: 'example: 10/22/2019',
      name: 'eventDate',
      mask: '00/00/0000',
      placeholder: 'Date',
      validation: [Validators.required],
      required: true
    },
    {
      type: 'input',
      label: 'Start Time',
      name: 'startTime',
      mask: '00:00',
      placeholder: 'Start Time',
      color: 'primary',
      validation: [Validators.required],
      required: true
    },
    {
      type: 'input',
      label: 'End Time',
      name: 'endTime',
      mask: '00:00',
      placeholder: 'End Time',
      color: 'primary',
      validation: [Validators.required],
      required: true
    },
    {
      type: 'input',
      label: 'Number of Players',
      name: 'numberOfPlayers',
      mask: '00/00/0000',
      placeholder: 'Number of Players',
      validation: [Validators.required],
      required: true
    },
    {
      type: 'select',
      label: 'Rounds',
      name: 'numberOfRounds',
      options: [' 2 Rounds - Move 1 Player Up / 1 Player Down', '2 Rounds - Same Players Each Round', '1 Round Only'],
      placeholder: 'Rounds',
      color: 'primary',
      required: true
    },
  ];
  stepTwoForm: FieldConfig[] = [
    {
      type: 'select',
      label: 'Players Per Court',
      name: 'players',
      options: [' 4 Players ONLY Per Court', '4 or 5 Players Per Court', '5 Players Per Court'],
      placeholder: 'Players Per Court',
      color: 'primary',
      required: true
    },
    {
      type: 'input',
      label: 'Interval Between Slots',
      name: 'players',
      placeholder: 'Interval Between Slots',
      color: 'primary',
      required: true
    },
    {
      type: 'input',
      label: 'Maximum Points/Game',
      name: 'maxPointsPerGame',
      placeholder: 'Maximum Points/Game',
      color: 'primary',
      validation: [Validators.required],
      required: true
    },
    {
      type: 'radio',
      label: 'Win by',
      name: 'winBy',
      options: [1, 2],
      placeholder: 'Win by',
      required: true
    },
    {
      type: 'input',
      label: 'Interval Between Slots',
      name: 'players',
      placeholder: 'Interval Between Slots',
      color: 'primary',
      required: true
    },
    {
      type: 'input',
      label: 'Courts Available',
      name: 'courtsAvailable',
      placeholder: 'Courts Available',
      color: 'primary',
      required: true
    },
    {
      type: 'input',
      label: 'Starting With Court',
      name: 'startingWith',
      placeholder: 'Starting With Court',
      color: 'primary',
      required: true
    }
  ];

  ngAfterViewInit() {
  }
}
