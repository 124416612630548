import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
  ViewChild
} from '@angular/core';
import { MatPaginator, MatSort, MatTable, MatTableDataSource } from '@angular/material';
import {MAT_BOTTOM_SHEET_DATA} from "@angular/material/bottom-sheet";
import {Router} from "@angular/router";
import {BottomSheetService} from "../../common/services/bottom-sheet.service";
import {Account} from "../../common/interfaces/account";
import {environment} from "../../../environments/environment";
import {ProfileComponent} from "../../profile/profile.component";
import {HttpClient} from "@angular/common/http";


export interface PlayerElement {
  id: number;
  name: string;
  avitar: string;
  avitaricon: string;
  is_friend: number;
  rating: string;
}

@Component({
  selector: 'app-dashboard-searchplayers',
  templateUrl: './dashboard-searchplayers.component.html',
  styleUrls: ['./dashboard-searchplayers.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})


export class DashboardSearchplayersComponent implements OnInit, AfterViewInit {

  env: any;
  siteUrl = environment.service.trackithubapi + '/';

  listdata: PlayerElement[] = this.data.id.list;
  displayedColumns: string[] = ['name','friend', 'chat'];
  dataSource = new MatTableDataSource();

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatTable, {static: false}) table: MatTable<PlayerElement>;

  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any, private http: HttpClient, public bottomSheetService: BottomSheetService) {}

  ngOnInit() {

    this.env = environment;
    this.dataSource.data = this.listdata;
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openProfile(profile_id, profile_type) {
    if(this.env.service.profile == true) {
      this.http.get<Account>(environment.service.trackithubapi + '/api/inside/loadprofile?clean_group=yes&profile=' + profile_id).subscribe((profileData: any) => {
        this.bottomSheetService.openBottomSheetWithDataCustomClass(ProfileComponent, 'ProfileComponent',{
          'profileData': profileData,
          'type': profile_type
        }, 'custom-bottom-p' );
      });
    }
  }

}
