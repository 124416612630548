import {ChangeDetectionStrategy, Component, Inject, Input} from '@angular/core';
import {SnackbarService} from "../../../services/snackbar.service";
import {HttpClient} from "@angular/common/http";
import {ToastService} from "../../../toast";
import {AccountService} from "../../../services/account.service";
import {MygroupsService} from "../../../services/mygroups.service";
import {environment} from "../../../../../environments/environment";
import {SessionStorageService} from "ngx-webstorage";
import {Router} from "@angular/router";
import {
  DialogData,
  RosterElement
} from "../../../../dashboard/dashboard-roster/roster-table.component";
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-group-my-group',
  templateUrl: './group-my-group.component.html',
  styleUrls: ['./group-my-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})


export class GroupMyGroupComponent {

  @Input() group_id: string;
  @Input() name: string;
  @Input() archive: boolean;
  @Input() dues: number;
  @Input() membership: boolean;
  @Input() profile_type: string;
  @Input() ref: any;
  @Input() sscount: any;
  @Input() rrcount: any;


  public  progress: number;
  // I would prefer we use hold and press to archive
  constructor(public dialog: MatDialog, private sessionSt: SessionStorageService, private mygroup: MygroupsService, private snackBar: SnackbarService, private http: HttpClient,private toastService: ToastService, private accountService: AccountService, private router: Router) {

  }

  GotoAdmin(group) {
    this.router.navigate(['/dashboard/membershipadmin'], { queryParams: { group_id: group } });
    this.ref.dismiss();
  }

  Dues(group) {
    this.router.navigate(['/dashboard/frame/dues'], { queryParams: { group_id: group } });
    this.ref.dismiss();
  }

  nostandings(): void {
    this.toastService.show({
      text: 'There are no standings associated with this group.',
      type: 'warn',
      buttonText: '',
    });
  }

  hello(): void {

  }



  Removed(group) {

    const dialogRef = this.dialog.open(DialogGroupRemove, {
      data: {group: group, profile: this.sessionSt.retrieve('current_profile')}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == 'Success') {
        this.http.get<any>(environment.service.trackithubapi + '/api/inside/groupremove?group_id=' + group + '&profile=' + this.sessionSt.retrieve('current_profile')).subscribe((data: any) => {
          this.accountService.reloadprofile();
          this.toastService.show({
            text: 'You have successfully left the group.',
            type: 'success',
            buttonText: '',
          });

          this.mygroup.load(this.sessionSt.retrieve('current_profile'), null);
        });
      } else if (result.event == 'Error') {
        this.toastService.show({
          text: `System error, please try again.   If you continue to get this error please contact support.`,
          type: 'warn',
        });
      }
    });



  }

  Standings(type, group) {
    this.router.navigate(['/dashboard/standings'], { queryParams: { type: type, group_id: group } });
    this.ref.dismiss();
  }

  Archived() {
    this.http.get<any>(environment.service.trackithubapi + '/api/inside/togglearchive?group_id=' + this.group_id + '&flag=1').subscribe((data: any) => {
      this.accountService.reloadprofile();
      this.toastService.show({
        text: 'Group has been archived. Archived groups can be found in the My Groups panel.',
        type: 'success',
        buttonText: '',
      });
      this.mygroup.load(this.sessionSt.retrieve('current_profile'), null);
    });
  }

}

  @Component({
    selector: 'app-dialog-group-remove',
    templateUrl: 'dialog-group-remove.html',
    changeDetection: ChangeDetectionStrategy.Default
  })
  export class DialogGroupRemove {
  constructor(
    public dialogRef: MatDialogRef<DialogGroupRemove>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close({event: 'Cancel'});
  }

  onYesClick(): void {
    this.dialogRef.close({event: 'Success'});
  }
}

