import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {Account} from "../../../interfaces/account";

@Component({
  selector: 'app-mygroups-panel',
  templateUrl: './mygroups-panel.component.html',
  styleUrls: ['./mygroups-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class MygroupsPanelComponent {
  @Input() name: string;
  @Input() avatarPlaceholder = 'player';
  @Input() profile_type = '';
  @Input() avatarImg: string;
  account: Account;
  constructor() { }

  ngOnInit() {
  }

}
