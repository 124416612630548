import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


export interface AddressLocationInterface {
  card: any;
  location: any;
  location_title: any;
}

@Injectable()

export class PanelCardService {

  private Card = new BehaviorSubject<AddressLocationInterface>({
    card: null,
    location_title: null,
    location: null,
  });

  cast = this.Card.asObservable();

  constructor() {}

  selectCard(card, location, location_title) {
    this.Card.next({
      card: card,
      location: location,
      location_title: location_title,
    });
  }
}
