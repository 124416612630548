import {Component, Inject, AfterViewInit, ViewChild, ChangeDetectionStrategy} from '@angular/core';
import {DashboardCreateLeagueComponent} from '../dashboard-create-league.component';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

import {FormControl, Validators, FormGroup} from '@angular/forms';
import {FieldConfig} from '../../../dynamic-form/models/field-config.interface';
import {DynamicFormComponent} from '../../../dynamic-form/containers/dynamic-form/dynamic-form.component';
import {RecordsService} from '../../../common/services/records.service';

export interface DialogData {
    animal: string;
    name: string;
}

@Component({
    selector: 'app-create-league-dialog',
    templateUrl: './create-league-dialog.component.html',
    styleUrls: ['./create-league-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class CreateLeagueDialogComponent implements AfterViewInit {
    records = {};

    @ViewChild(DynamicFormComponent, { static: true }) nform: DynamicFormComponent;

    config: FieldConfig[] = [
        {
            type: 'input',
            label: 'Event Name',
            name: 'eventname',
            placeholder: 'Event Name',
            required: true,
          maxlength: 65
        },
        {
            type: 'select',
            label: 'Categories',
            name: 'categories',
            placeholder: 'Skill Level',
            options: ['Category 1', 'Category 2', 'Category 3'],
            required: false,
        },
        {
            type: 'radio',
            label: 'Categories',
            name: 'categories',
            placeholder: 'What Label Do I Put here?',
            options: ['Rotate Partners ', 'Fixed Partner', 'Pro Package', 'Basic Package'],
        },
        {
            type: 'textarea',
            label: 'Description',
            name: 'descriptions',
            placeholder: 'Description',
            validation: [Validators.required, Validators.minLength(8)],
            required: true,
        },
        {
            type: 'state',
            label: 'Location',
            name: 'state',
            placeholder: 'Location',
            required: true
        },
        {
            type: 'input',
            label: 'Contact',
            name: 'contact',
            placeholder: 'Contact',
            required: true,
        },
        {
            label: 'Save',
            name: 'submit',
            type: 'button',
            color: 'primary',
            disabled: true,
        }
    ];

    ngAfterViewInit() {
        let previousValid = this.nform.valid;
        this.nform.changes.subscribe(() => {
            if (this.nform.valid !== previousValid) {
                previousValid = this.nform.valid;
                this.nform.setDisabled('submit', !previousValid);
            }
        });
    }

    constructor(
        public dialogRef: MatDialogRef<DashboardCreateLeagueComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

}
