import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Account } from 'src/app/common/interfaces/account';
import { AccountService } from 'src/app/common/services/account.service';
import { ToastService } from 'src/app/common/toast';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-premium-features',
  templateUrl: './premium-features.component.html',
  styleUrls: ['./premium-features.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class PremiumFeaturesComponent implements OnInit, OnDestroy {

  buttonText = [];
  buttonToggle = [];
  profileData: any;
  current_profile: any;
  s1 = null;

  premiumFeatures: any[] = [
        {
            feature: 'Event Varying Fee',
            status: true,
            description: 'Allow clubs to charge a varying registration fee for events if the player registering is a member or non-member.',
        },
        {
            feature: 'Event Reminders',
            status: false,
            description: 'Send an automatic email reminder to players that have registered for an event. Specify how long before the event, that you would like a notification sent.',
        },
        {
            feature: 'Merge New Members',
            status: true,
            description: 'Automatically merge new members into the existing events. This works well with the Automatic Invitations premium feature. ',
        },
                {
            feature: 'Auto Invites/Save',
            status: false,
            description: 'You can select the amount of days before the event takes place that you would like the invitations to be sent or saving of your members in the invitee list to activate.',
        }

    ];


    constructor(private http: HttpClient, private accountService: AccountService, private toastService: ToastService) {}

    ngOnInit() {
      this.s1 = this.accountService.cast.subscribe(data =>  {
        this.profileData = data.current_profile.premium;

        this.current_profile = data.current_profile.profile_id;

        for (const premium of this.profileData ){
            if (premium.id === '1') {
              if (premium.status === '1') {
                this.buttonText[0] = 'On';
                this.buttonToggle[0] = 'accent';
              } else {
                this.buttonText[0] = 'Off';
                this.buttonToggle[0] = 'warn';
              }
            }
            if (premium.id === '2') {
              if (premium.status === '1') {
                this.buttonText[1] = 'On';
                this.buttonToggle[1] = 'accent';
              } else {
                this.buttonText[1] = 'Off';
                this.buttonToggle[1] = 'warn';
              }
            }
            if (premium.id === '3') {
              if (premium.status === '1') {
                this.buttonText[2] = 'On';
                this.buttonToggle[2] = 'accent';
              } else {
                this.buttonText[2] = 'Off';
                this.buttonToggle[2] = 'warn';
              }
            }
            if (premium.id === '4') {
              if (premium.status === '1') {
                this.buttonText[3] = 'On';
                this.buttonToggle[3] = 'accent';
              } else {
                this.buttonText[3] = 'Off';
                this.buttonToggle[3] = 'warn';
              }
            }
        }
      });
    }

    premiumToggle(i) {

      if (this.buttonText[i] === 'Off') {

        this.http.get<Account>(environment.service.trackithubapi + '/api/inside/premiumupdate?row_id=' + (i + 1) + '&status=on&current_profile=' + this.current_profile).subscribe((data: any) => {
          this.toastService.show({
            text: 'Premium feature has been updated.',
            type: 'success',
            buttonText: '',
          });
          this.accountService.load();
        });

        this.buttonText[i] = 'On';
        this.buttonToggle[i] = 'accent';

      } else {

        this.http.get<Account>(environment.service.trackithubapi + '/api/inside/premiumupdate?row_id=' + (i + 1) + '&status=off&current_profile=' + this.current_profile).subscribe((data: any) => {
          this.toastService.show({
            text: 'Premium feature has been updated.',
            type: 'success',
            buttonText: '',
          });
          this.accountService.load();
        });

        this.buttonText[i] = 'Off';
        this.buttonToggle[i] = 'warn';

      }
    }

  ngOnDestroy() {
    if (this.s1 != null) {
      this.s1.complete();
    }
  }

}
