import {Router} from '@angular/router';
import {Component, AfterViewInit, ViewChild, ChangeDetectionStrategy} from '@angular/core';
import {Validators} from '@angular/forms';
import {FieldConfig} from '../../../dynamic-form/models/field-config.interface';
import {RecordsService} from '../../services/records.service';
import {Backplane} from '../../services/backplane.service';
import { DynamicFormResetPassComponent, DynamicFormUserPassComponent} from '../../../dynamic-form/containers';
import { ToastService } from '../../toast';

interface logindata {
    username;
    password;
}

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class LoginComponent implements AfterViewInit {

    login = <logindata>{};

    message = '';
    actionButtonLabel = '';
    action = false;
    setAutoHide = true;
    autoHide = 2000;
    pwdPattern: any = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{8,25}$/;

    records = {};
    isLeftVisible: boolean;
    myEasing;
    title: string[] = ['Login', 'Have an Account? Log In', 'Reset Password', 'Change Password'];

    constructor(
      public router: Router,
      private LoginService: RecordsService,
      private getter: Backplane,
      private toastService: ToastService
    ) {}

    loginforminfo: FieldConfig[] = [
        {
            type: 'input',
            label: 'Enter Email',
            name: 'emaillogin',
            placeholder: 'Enter Email',
            validation: [Validators.required, Validators.email],
            required: true,
        },
        {
            type: 'password',
            label: 'Enter Password',
            name: 'passwordlogin',
            placeholder: 'Enter Password',
            validation: [Validators.required],
            required: true,
        },
        {
            label: 'Login',
            name: 'submitlogin',
            type: 'button',
            color: 'primary',
            disabled: true,
        }
    ];
    forgotpasswordinfo: FieldConfig[] = [
        {
            type: 'input',
            label: 'Enter Email',
            name: 'emailreset',
            placeholder: 'Enter Email',
            validation: [Validators.required, Validators.email],
            required: true,
        },
        {
            label: 'Get New Password',
            name: 'resetpassword',
            type: 'button',
            color: 'primary',
            disabled: true,
        }
    ];
    changePassword: FieldConfig[] = [
        {
            type: 'password',
            label: 'Password must be at least 8 characters, including 1 uppercase letter, 1 lowercase letter, and 1 number',
            name: 'new-password',
            placeholder: 'New Password',
            validation: [Validators.required, Validators.pattern(this.pwdPattern)],
            required: true,
        },
        {
            type: 'password',
            label: 'Confirm Password',
            name: 'confirm-password',
            placeholder: 'Confirm Password',
            validation: [Validators.required, Validators.email],
            required: true,
        }
    ];

    @ViewChild(DynamicFormUserPassComponent, { static: false }) loginform: DynamicFormUserPassComponent;
    @ViewChild(DynamicFormResetPassComponent, { static: false }) resetform: DynamicFormResetPassComponent;


    ngAfterViewInit() {


        let previousresetValid = this.resetform.resetform.valid;
        this.resetform.changes.subscribe(() => {
            if (this.resetform.valid !== previousresetValid) {
                previousresetValid = this.resetform.valid;
                this.resetform.setDisabled('resetpassword', !previousresetValid);
            }
        });

        let previousValid = this.loginform.loginform.valid;
        this.loginform.changes.subscribe(() => {
            if (this.loginform.valid !== previousValid) {
                previousValid = this.loginform.valid;
                this.loginform.setDisabled('submitlogin', !previousValid);
            }
        });

        this.loginform.submit.subscribe(() => {
            this.login.username = this.loginform.loginform.controls.emaillogin.value;
            this.login.password = this.loginform.loginform.controls.passwordlogin.value;
            this.records = this.LoginService.createLogin(this.login)
                .subscribe(data => {
                    this.records = data;
                    if (this.records['result'] === true) {
                      window.location.href = '/dashboard/overview';
                    } else {
                      this.toastService.show({
                        text: `Username or Password incorrect, please try again.`,
                        type: 'warn',
                        buttonText: '',
                      });
                    }
                });
        });

        this.resetform.submit.subscribe(() => {
            this.records = this.LoginService.resetPassword(this.resetform.resetform.controls.emailreset.value)
                .subscribe(data => {
                    this.records = data;
                    if (this.records['result'] === true) {
                      this.toastService.show({
                        text: `Please check your email for instructions on how to reset your password.`,
                        type: 'warn',
                        buttonText: '',
                      });
                    } else {
                      this.toastService.show({
                        text: `We could not find this email in our database.`,
                        type: 'warn',
                        buttonText: '',
                      });
                    }
                });
        });

    }
}
