import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  state, style, trigger
} from '@angular/animations';

type PaneType = 'left' | 'right';

@Component({
  selector: 'app-slide-panel',
  templateUrl: './slide-panel.component.html',
  styleUrls: ['./slide-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
      trigger('slide', [
      state('left', style({ transform: 'translateX(0)' })),
      state('right', style({ transform: 'translateX(-50%)' }))
    ])
  ]
})
export class SlidePanelComponent {
 @Input() activePane: PaneType = 'left';
}
