import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import { BottomSheetService } from '../../common/services/bottom-sheet.service';
import { CreateLocationComponent } from '../create-location/create-location.component';
import { SearchAddressService } from '../../common/services/searchaddress.service';
import { PanelCardService } from '../../common/services/panelcard.service';
import { PickleballPlaceDetailsComponent } from '..';
import { PanelService } from '../../common/services/panel.service';
import {SessionStorageService} from "ngx-webstorage";
import {Subscription} from "rxjs";



@Component({
  selector: 'app-map-results',
  templateUrl: './map-results.component.html',
  styleUrls: ['./map-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class MapResultsComponent implements OnDestroy {

  @Input() nosearch: boolean;
  @Input() type: any;


  clubcount: any;
  locationcount: any;
  cards: any;
  modcards: any;
  locationtype: any;
  countstring: any;
  private suba: Subscription;

  constructor(
    private ref: ChangeDetectorRef,
    private sessionSt: SessionStorageService,
    private searchSubject: SearchAddressService,
    private bottomSheetService: BottomSheetService,
    private panelService: PanelService,
    private panelCardService: PanelCardService
  ) {

    //For now hard set play type


    this.sessionSt.store('play_type','Clubs');


    if (this.nosearch == null) {
      this.nosearch = false;
    }

    if (this.type !== null){
      this.sessionSt.store('play_type',this.type);
    }


    this.suba = this.searchSubject.cast.subscribe(data => {
      if (data.results !== null ) {
        this.cards = data.results;
      }

      this.locationtype = sessionSt.retrieve('play_type');

      if (data.results === null) {
        this.modcards = null;
        this.cards = null;
        this.clubcount = 0;
        this.locationcount = 0;
      } else {
        this.locationcount = this.cards.length;
        if(sessionSt.retrieve('play_type') === 'Public'){
          this.modcards = this.cards;
          this.clubcount = this.modcards.length;
        } else {
          this.modcards = this.removeDuplicates(this.cards, 'id');
          this.clubcount = this.modcards.length;
        }
      }
      if (this.locationtype === 'Public') {
        this.countstring = this.clubcount + ' locations';
      } else {
        this.countstring = this.clubcount + ' clubs | ' + this.locationcount + ' locations';
      }
    });

  }
  createLocation() {
    this.bottomSheetService.openBottomSheet(CreateLocationComponent,'CreateLocationComponent');
  }

  sendCard(card) {
    this.panelCardService.selectCard(card, null, null);
    this.panelService.openPanel(PickleballPlaceDetailsComponent,'PickleballPlaceDetailsComponent');
  }

  removeDuplicates(originalArray, objKey) {
    let trimmedArray = [];
    let values = [];
    let value;

    for(let i = 0; i < originalArray.length; i++) {
      value = originalArray[i][objKey];
      if ( values.indexOf(value) === -1 ) {
        trimmedArray.push(originalArray[i]);
        values.push(value);
      }
    }

    return trimmedArray;
  }

  ngOnDestroy() {
    if (this.suba !== undefined) {
      this.suba.unsubscribe();
    }
  }
}
