import { ComponentRef} from '@angular/core';
import { MatBottomSheet } from '@angular/material';
import { WindowScrolling } from './window-scrolling.service';
import { Router } from '@angular/router';
import {SessionStorageService} from "ngx-webstorage";
import {GoogleAnalyticsService} from "ngx-google-analytics";



export class PanelService {
  component: ComponentRef<any>;
  appDrawer: any;
  appDrawerS: any;
  windowScrolling: WindowScrolling;
  panel: any;
  constructor(
    private sessionSt: SessionStorageService,
    private router: Router,
    public bottomSheet: MatBottomSheet,
    protected $gaService: GoogleAnalyticsService,
    windowScrolling: WindowScrolling,
    ) {this.windowScrolling = windowScrolling;
  }

  openPanel(component,name) {
            this.$gaService.pageView('/dashboard/openPanel/' + name, name);
            this.appDrawer.open();
            this.panel = this.appDrawer;
            this.windowScrolling.enable();
  }

  openPanelS(component,name) {
    this.$gaService.pageView('/dashboard/openPanelS/' + name, name);
    localStorage.setItem('sub', 'true');
    this.appDrawer.open();
    this.panel = this.appDrawer;
    this.windowScrolling.enable();
  }



}


