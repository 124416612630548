import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  OnInit,
  AfterViewInit,
  ChangeDetectionStrategy
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Field } from '../../models/field.interface';
import { FieldConfig } from '../../models/field-config.interface';
import { SessionStorageService } from 'ngx-webstorage';
import { RetrioeveaddressService } from '../../../common/services/retrioeveaddress.service';
// @ts-ignore
import { } from '@types/googlemaps';
import {ToastService} from '../../../common/toast';


@Component({
  selector: 'app-form-input',
  styleUrls: ['form-autocomplete-reg.component.scss'],
  template: `
      <mat-form-field [formGroup]="group" appearance="{{config.appearance}}">
          <input autocomplete="off" matInput [type]="config.type" mask='{{config.mask}}' [placeholder]="config.placeholder"
                  [formControlName]="config.name" [required]="config.required" [email]="config.email" #addresstext (focusout)="mouseout()" (keypress)="noEnter($event)" (keydown)="noEnter($event)" >
          <mat-hint>{{config.label}}</mat-hint>
       </mat-form-field>`,
  changeDetection: ChangeDetectionStrategy.Default
})
export class FormAutocompleteRegComponent implements Field, OnInit, AfterViewInit {
  config: FieldConfig;
  group: FormGroup;
  @Input() adressType: string;
  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  @ViewChild('addresstext', {static: false}) addresstext: any;

  autocompleteInput: string;
  queryWait: boolean;
  IsplaceChange: boolean;
  previousInput: string;

  constructor(private sessionSt: SessionStorageService, private RetrieveAddress: RetrioeveaddressService, private toastService: ToastService) {
    this.sessionSt.store('address-reg', false);
    this.previousInput = null;
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.getPlaceAutocomplete();
  }

  private getPlaceAutocomplete() {
    this.sessionSt.store('address-reg', false);
    this.sessionSt.store('address-reg-text', this.addresstext.nativeElement.value);

    this.RetrieveAddress.update(
      null,
      null,
      null, null, null, null, null);

    const autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement,
      {
        componentRestrictions: { country: ['US', 'UK', 'CA', 'MX'] },
        fields: ['address_components', 'geometry'],
        types: ['address']  // 'establishment' / 'address' / 'geocode'
      });

    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      this.sessionSt.store('address-reg', true);
      this.previousInput = this.addresstext.nativeElement.value;
      const place = autocomplete.getPlace();


      let address1 = '';
      let postcode = '';
      let city = '';
      let state = '';
      let country = '';

       // Get each component of the address from the place details,
      // and then fill-in the corresponding field on the form.
      // place.address_components are google.maps.GeocoderAddressComponent objects
      // which are documented at http://goo.gle/3l5i5Mr
      for (const component of place.address_components) {
        const componentType = component.types[0];

        switch (componentType) {
          case 'street_number': {
            address1 = `${component.long_name} ${address1}`;
            break;
          }

          case 'route': {
            address1 += component.short_name;
            break;
          }

          case 'postal_code': {
            postcode = `${component.long_name}${postcode}`;
            break;
          }

          case 'postal_code_suffix': {
            postcode = `${postcode}-${component.long_name}`;
            break;
          }
          case 'locality':
            city = component.long_name;
            break;

          case 'administrative_area_level_1': {
            state = component.short_name;
            break;
          }
          case 'country':
            country = component.long_name;
            break;
        }
      }

      this.RetrieveAddress.update(
        place.geometry.location.lat(),
        place.geometry.location.lng(),
        address1,
        city,
        state,
        country,
        postcode);
      this.invokeEvent(place);
    });
  }

  invokeEvent(place: Object) {
    this.setAddress.emit(place);
  }

  mouseout() {
    this.delaycheck(this.sessionSt, this.addresstext.nativeElement, this.toastService, this.RetrieveAddress, this.previousInput);
  }

  delaycheck(a, b, c, d, e) {
    setTimeout(function() {

      if (b.value !== '' && a.retrieve('address-reg') === false) {
        b.value = '';

        d.update(
          null,
          null,
          null, null, null, null, null);

        this.errormessage = 'Please select an address from the list. Unit, apartment, or lot number can be added later in account settings.';
        c.show({
          text: this.errormessage,
          type: 'warn',
          buttonText: '',
        });
      }
    }, 1000);
  }

  noEnter(e) {
    this.sessionSt.store('address-reg', false);
    const key = e.key ;
    return key !== 13;
  }

}
