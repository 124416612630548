import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pricing-cards',
  templateUrl: './pricing-cards.component.html',
  styleUrls: ['./pricing-cards.component.scss']
})
export class PricingCardsComponent implements OnInit {
  title = 'Pricing';

  constructor() { }

  ngOnInit() {
  }

}
