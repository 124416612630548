import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
import {environment} from '../../../environments/environment';

@Injectable()
export class autocompleteService {

  constructor (private httpService: HttpClient) { }

  search(term) {
    const listOfUsers = this.httpService.get(environment.service.trackithubapi + '/api/inside/searchuser?term=' + term)
      .pipe(
        debounceTime(500),
        map(
          (data: any) => {
            return (
              data.length != 0 ? data as any[] : [{'name': 'No Records Found' , 'email': '', 'id': ''} as any]
            );
          }
        ));

    return listOfUsers;
  }
}
