import {ChangeDetectionStrategy, Component, ChangeDetectorRef, OnDestroy, Inject} from '@angular/core';
import { SessionStorageService} from 'ngx-webstorage';
import { MygroupsService} from '../../common/services/mygroups.service';
import {AccountService} from '../../common/services/account.service';
import {Account} from "../../common/interfaces/account";
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {ToastService} from "../../common/toast";
import {ModalService} from "../../common/services/modal.service";
import {DashboardLeagueScoreComponent} from "../dashboard-leaguescore/dashboard-league-score.component";
import {MatDialog} from "@angular/material/dialog";
import {BottomSheetService} from "../../common/services/bottom-sheet.service";
import {DashboardComplainSheetComponent} from "../dashboard-complain-sheet/dashboard-complain-sheet.component";
import {ChatService} from "../../common/services/chat.service";

@Component({
  selector: 'app-dashboard-myleagues',
  templateUrl: './dashboard-myleagues.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})


export class DashboardMyleaguesComponent implements OnDestroy {
  account: Account;
  profile_id: any;
  timer: any;

  variable1: any;
  variable2: any;

  week1: number;
  week2: number;
  week3: number;

  s1data: any;
  s2data: any;
  s3data: any;
  ldata: any;

  s2 = null;


  w1 = false;
  w2 = false;
  w3 = false;
  w4 = false;
  w5 = false;
  w6 = false;
  w7 = false;

  hate = false;


  constructor(
      private sessionSt: SessionStorageService,
      private mygroups: MygroupsService,
      private accountService: AccountService,
      private http: HttpClient,
      private toastService: ToastService,
      private ref: ChangeDetectorRef,
      private modalService: ModalService,
      public dialog: MatDialog,
      private bottomSheetService: BottomSheetService, private  chatService: ChatService,
      @Inject(MAT_BOTTOM_SHEET_DATA) public datax: any
  ) {

    this.week1 = 0;
    this.week2 = 0;
    this.week3 = 0;

    this.s2 = this.accountService.cast.subscribe(data => {
      this.profile_id = data.current_profile.profile_id;

      this.redrawscreen();

    });

    ref.detach();
    this.timer = setInterval(() => {
      this.ref.detectChanges();
    }, 750);

  }

  sheetRef: any = this.datax.ref;


  toggleitem(item){
    if(item.visible == true){
      item.visible = false;
    } else {
      item.visible = true;
    }
  }

  standings(week1, roundrobin){
    window.location.href = '/dashboard/standings?type=roundrobin';
  }

  report(week1, roundrobin) {

    this.bottomSheetService.openBottomSheetWithData(DashboardComplainSheetComponent, 'DashboardComplainSheetComponent',{
      week: week1,
      rr: roundrobin,
    });
  }
  contact(week1, roundrobin) {

    this.bottomSheetService.openBottomSheetWithData(DashboardComplainSheetComponent, 'DashboardComplainSheetComponent',{
      week: week1,
      rr: roundrobin,
    });
  }


  click(week, rr) {



    this.http.get<any>(environment.service.trackithubapi + '/api/inside/loadleaguescore?&profile_id=' + this.profile_id + '&roundrobin_id=' + rr).subscribe((data: any) => {





      const dialogRef = this.dialog.open(DashboardLeagueScoreComponent, {
        maxWidth: 500,
        minWidth: 370,
        data: {data: data}
      });

      dialogRef.afterClosed().subscribe(result => {
        this.redrawscreen();
      });











    });


  }


  setCurrentRow(week1, item){
    this.variable1 = week1;
    this.variable2 = item;
  }

  redrawscreen(){
    this.http.get<Account>(environment.service.trackithubapi + '/api/inside/get_league_info?profile_id=' + this.profile_id).subscribe((leagueData: any) => {

      this.ldata = leagueData;



      this.w1 = true;
      this.w2 = true;
      this.w3 = true;
      this.w4 = true;
      this.w5 = true;
      this.w6 = true;
      this.w7 = true;

      this.week1 = 0;

      /**
      if(leagueData.league_info[0] !== undefined && leagueData.league_info[0].groups !== undefined) {
        for (let i = 0; i < leagueData.league_info[1].groups.length; i++) {
          if (leagueData.league_info[0].groups[i].visible == true) {
            if (leagueData.league_info[0].groups[i].players.length == 4 || leagueData.league_info[0].groups[i].players.length == 2) {
              this.w1 = true;
              if(this.week1 == 0) {
                this.week1 = 1;
              }
            }
          }
        }
      }
      **/




      if(leagueData.league_info.length != 0) {
        this.s1data = leagueData.league_info[this.week1].groups;


        console.log(this.s1data);


        this.s2data = null;
        this.s3data = null;
        this.ref.detectChanges();
      } else {

        this.hate = true;

        //this.toastService.show({
        //  html: '<a style="color: blue !important;" href="https://trackithub.com/leagues">You are not a member of the Premier Pickleball League. To join a league, click Here.</a>',
        //  type: 'warn'
        //});
        //this.sheetRef.dismiss();
      }

    });
  }

  redraw() {
    this.s1data = this.ldata.league_info[this.week1].groups;
  }

  gotoChat(convo_id){

    console.log(convo_id);

    this.http.get<any>(environment.service.trackithubapi + '/api/inside/lastchatcheck?from_id=' + this.profile_id).subscribe((data: any) => {
      if(data.result == 'fail') {
        this.toastService.show({
          text: 'You have not chatted with anyone yet.',
          type: 'warn',
          buttonText: '',
        });
      } else {

        this.chatService.openchatconvo({
          conversationID: convo_id,
          profile_id: data.profile_id,
          name: data.name,
          avatarImg: data.avatarImg,
          avatarPlaceholder: data.avatarPlaceholder,
          email: data.email,

        });
      }
    });
  }




  ngOnDestroy() {
    clearInterval(this.timer);
    if (this.s2 != null) {
      this.s2.complete();
    }
  }

}
