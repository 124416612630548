import { HttpClient } from '@angular/common/http';
import { AfterViewInit, ChangeDetectionStrategy, Component, Input} from '@angular/core';
import { FormControl, FormGroup, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import { ToastService } from 'src/app/common/toast';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-security',
  templateUrl: './security.component.html',
  styleUrls: ['./security.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class SecurityComponent implements AfterViewInit {

  hide1 = true;
  hide2 = true;
  hide3 = true;
  runonce = true;
  disable = true;

  pwdPattern: RegExp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

  @Input() accountdata = null;

  constructor(private router: Router, private http: HttpClient, private toastService: ToastService) {}

  /** Todo: Robert -- Need to change this api call from a get to a post **/

  passwordForm: FormGroup = new FormGroup({
    oldPassword: new FormControl('', [
      Validators.required,
      Validators.pattern(this.pwdPattern),
      Validators.minLength(8)
    ]),
    newPassword: new FormControl('', [
      Validators.required,
      Validators.pattern(this.pwdPattern),
      Validators.minLength(8)
    ]),
    confirmPassword: new FormControl('', [
      Validators.required,
      Validators.pattern(this.pwdPattern),
      Validators.minLength(8)
    ]),
  });

  validation_messages = {
    'oldPassword': [
      { type: 'required', message: 'Old password is required' },
      { type: 'minlength', message: 'Password must be at least 8 characters long' },
      { type: 'pattern', message: 'Your password must contain at least one uppercase, one lowercase, and one number' }
    ],
    'newPassword': [
      { type: 'required', message: 'New password is required' },
      { type: 'minlength', message: 'Password must be at least 8 characters long' },
      { type: 'pattern', message: 'Your password must contain at least one uppercase, one lowercase, and one number' }
    ],
    'confirmPassword': [
      { type: 'required', message: 'Confirm password is required' },
      { type: 'minlength', message: 'Password must be at least 8 characters long' },
      { type: 'pattern', message: 'Your password must contain at least one uppercase, one lowercase, and one number' },
      { type: 'notSame', message: 'Confirm password does not match' }
    ]
  }


  submitForm() {
      if (this.runonce === true){
        this.disable = true;
        this.runonce = false;

        this.http.get<any>(environment.service.trackithubapi + '/api/inside/setpassword?password=' + this.passwordForm.controls.oldPassword.value + '&npassword=' + this.passwordForm.controls.newPassword.value).subscribe((datax: any) => {
          if (datax.result === true) {
            this.toastService.show({
              text: `Password has been updated.`,
              type: 'success',
            });
            this.router.navigate(['/dashboard/overview'], {});
          } else {
            this.toastService.show({
              text: `Your account password could not be verified`,
              type: 'warn',
            });
            this.router.navigate(['/dashboard/overview'], {});
          }
        });
      }
  }

get oldPassword() {
  return this.passwordForm.get('oldPassword');
}

get newPassword() {
  return this.passwordForm.get('newPassword');
}

get confirmPassword() {
  return this.passwordForm.get('confirmPassword');
}

  ngAfterViewInit() {
    this.passwordForm.valueChanges.subscribe(data => {

      /**
       *  if validation = true
       *  if new password is not = old password
       *  if new password = confirm password
       */
      if (this.passwordForm.invalid === false && data.newPassword === data.confirmPassword && data.oldPassword !== data.newPassword) {
        this.disable = false;
      } else {
        this.disable = true;
      }
    });
  }

}
