import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-add-friend',
  templateUrl: './add-friend-component.html',
  styleUrls: ['./add-friend-component.scss']
})
export class AddFriendComponent implements OnInit {

  msg = '';

  constructor(
    public dialogRef: MatDialogRef<AddFriendComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }


  onNoClick(): void {
    this.dialogRef.close({event: 'Cancel'});
  }

  onYesClick(): void {
    this.dialogRef.close({event: 'Success', 'msg': this.msg});
  }

  ngOnInit() {
  }

}
