import {AfterViewInit, ChangeDetectionStrategy, Component, OnInit, ViewChild} from '@angular/core';
import { MatPaginator, MatSort, MatTable } from '@angular/material';
import {FormGroup, FormControl, FormArray} from '@angular/forms';
import {FieldConfig} from '../../dynamic-form/models/field-config.interface';

export interface Standings {
  position: number;
  firstName: string;
  lastName: string;
  played: number;
  group: string;
  average: number;
}

// Would prefer to use Datasource file
const ELEMENT_DATA: Standings[] = [
  {
    'position': 1,
    'firstName': 'Al',
    'lastName': 'Dadswell',
    'average': 72.42,
    'played': 14,
    'group': 'C'
  },
  {
    'position': 2,
    'firstName': 'Bob',
    'lastName': 'Seguy',
    'average': 77.75,
    'played': 18,
    'group': 'C'
  },
  {
    'position': 3,
    'firstName': 'Alan',
    'lastName': 'Riley',
    'average': 50.66,
    'played': 6,
    'group': 'E'
  },
  {
    'position': 4,
    'firstName': 'Barbara',
    'lastName': 'Campbel',
    'average': 68.20,
    'played': 0.2,
    'group': 'E'
  },
];


@Component({
  selector: 'app-standings-scorecard',
  templateUrl: './standings-scorecard.component.html',
  styleUrls: ['../standings-table/standings-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class StandingsScorecardComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  // @ViewChild(MatTable, {static: false}) table: MatTable<StandingsScorecardItem>;
  scoreCard: FieldConfig[] = [
    {
      type: 'input',
      label: 'Date',
      name: 'date',
      placeholder: 'Date',
      required: true,
    },
    {
      type: 'input',
      label: 'Time',
      name: 'Time',
      placeholder: 'Time',
      required: true,
    },
    {
      type: 'input',
      label: 'Court',
      name: 'court',
      placeholder: 'Court',
      required: true,
    },
    {
      type: 'input',
      label: 'Type',
      name: 'type',
      placeholder: '4 Player Round Robin',
      required: true,
    },
    {
      type: 'input',
      label: 'Play To',
      name: 'playTo',
      placeholder: 'Play To',
      required: true,
    },
    {
      type: 'input',
      label: 'Win By',
      name: 'winBy',
      placeholder: 'Win By',
      required: true,
    },
    {
      type: 'input',
      label: 'Rotation',
      name: 'rotation',
      placeholder: 'Rotation',
      required: true,
    },
  ];
  // dataSource: StandingsScorecardDataSource;
  columnsArray: any[] = [];
  columnsBefore: any = [
    {name: 'position', title: 'Position'},
    {name: 'firstName', title: 'Player'},
    {name: 'average', title: 'Average'},
    {name: 'group', title: 'Group'},
    ];
  columnsAfter: any = [
    {name: 'total', title: 'Total'},
    {name: 'percent', title: 'Percent'}
    ];
  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  // displayedColumns = ['id', 'player', 'average', 'group'];
    dataSource = ELEMENT_DATA;
 formArray = new FormArray(ELEMENT_DATA.map(x => new FormGroup({})));

  displayedColumns = this.columnsBefore.map(x => x.name)
    .concat(this.columnsArray.map(x => x.name))
    .concat(this.columnsArray.map(x => x.name));

  addColumn() {
    const count = this.columnsArray.length;
    this.formArray.controls.forEach((group: FormGroup, index) => {
      group.addControl('col' + count, new FormControl());
      this.dataSource[index] ['col' + count] = this.formArray.at(index).get( 'col' + count );
    });
    this.columnsArray.push({name: 'col' + count, title: 'Round ' + (count + 1)});
    this.displayedColumns = this.columnsBefore.map(x => x.name)
                        .concat(this.columnsArray.map(x => x.name))
                        .concat(this.columnsAfter.map(x => x.name));
  }

  removeColumn() {
    const count = this.columnsArray.length - 1;
    if (count >= 0) {
      this.columnsArray.pop();
      this.formArray.controls.forEach(group => {
        (group as FormGroup).removeControl('col' + count);
      });
      this.displayedColumns =  (this.columnsBefore.map(x => x.name))
        .concat(this.columnsArray.map(x => x.name))
        .concat(this.columnsAfter.map(x => x.name));
    }
  }

  ngOnInit() {
    // this.dataSource = new StandingsScorecardDataSource();
  }

  ngAfterViewInit() {
    // this.dataSource.sort = this.sort;
    // this.dataSource.paginator = this.paginator;
    // this.table.dataSource = this.dataSource;
  }
}
