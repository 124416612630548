import {ChangeDetectionStrategy, Component, ChangeDetectorRef, OnDestroy, Inject, Input} from '@angular/core';
import {AccountService} from '../../common/services/account.service';
import {Account} from "../../common/interfaces/account";
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {ToastService} from "../../common/toast";
import {MatDialog} from "@angular/material/dialog";
import {ChatService} from "../../common/services/chat.service";

@Component({
  selector: 'app-dashboard-chat-icon',
  templateUrl: './dashboard-chat-icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class DashboardChatIconComponent implements OnDestroy {

  @Input() convo: string;

  account: Account;
  profile_id: any;
  timer: any;
  s2: any;
  messages = "2";

  constructor(
      private accountService: AccountService,
      private http: HttpClient,
      private toastService: ToastService,
      private ref: ChangeDetectorRef,
      public dialog: MatDialog,
      private  chatService: ChatService,
      @Inject(MAT_BOTTOM_SHEET_DATA) public datax: any
  ) {
    this.s2 = this.accountService.cast.subscribe(data => {
      this.profile_id = data.current_profile.profile_id;
      this.http.get<any>(environment.service.trackithubapi + '/api/inside/ppl_messages_unread?profile_id=' + this.profile_id + '&convo_id=' + this.convo).subscribe((data: any) => {
        this.messages = data;
        this.ref.detectChanges();
      });
    });

    this.timer = setInterval(() => {
      this.http.get<any>(environment.service.trackithubapi + '/api/inside/ppl_messages_unread?profile_id=' + this.profile_id + '&convo_id=' + this.convo).subscribe((data: any) => {
        this.messages = data;
        this.ref.detectChanges();
      });
    }, 60000);
  }



  gotoChat(convo_id){

    console.log(convo_id);

    this.http.get<any>(environment.service.trackithubapi + '/api/inside/lastchatcheck?from_id=' + this.profile_id).subscribe((data: any) => {
      if(data.result == 'fail') {
        this.toastService.show({
          text: 'You have not chatted with anyone yet.',
          type: 'warn',
          buttonText: '',
        });
      } else {

        this.chatService.openchatconvo({
          conversationID: convo_id,
          profile_id: data.profile_id,
          name: data.name,
          avatarImg: data.avatarImg,
          avatarPlaceholder: data.avatarPlaceholder,
          email: data.email,

        });
      }
    });
  }




  ngOnDestroy() {
    clearInterval(this.timer);
    if (this.s2 != null) {
      this.s2.complete();
    }
  }

}
