import {Component, OnInit, AfterViewInit, ViewChild, Inject, ChangeDetectionStrategy} from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA} from '@angular/material';
import { MatPaginator, MatSort, MatTable, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';

export interface RenewalElement {
  name: string;
  id: string;
  created: string;
  enddate: string;
  group_id: string;
}

@Component({
  selector: 'app-dashboard-renewals-sheet',
  templateUrl: './dashboard-renewals-sheet.component.html',
  styleUrls: ['./dashboard-renewals-sheet.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})

export class DashboardRenewalsSheetComponent implements OnInit, AfterViewInit {

  listdata: RenewalElement[] = this.data.id.list.data;
  sheetRef: any = this.data.ref;
  displayedColumns: string[] = ['name', 'created'];
  dataSource = new MatTableDataSource();

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatTable, {static: false}) table: MatTable<RenewalElement>;



  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any, private router: Router) {}

  ngOnInit() {
    this.dataSource.data = this.listdata;
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  admin(group, name) {
    this.router.navigate(['/dashboard/membershipadmin'], { queryParams: { group_id: group, search: name } });
    this.sheetRef.dismiss();
  }
}
