import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component, Inject,
  OnDestroy,
  OnInit, ViewChild,
} from '@angular/core';

import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MatDialog} from '@angular/material/dialog';
import {ToastService} from "../../common/toast";
import { MatPaginator, MatSort, MatTable, MatTableDataSource } from '@angular/material';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {AccountService} from "../../common/services/account.service";


export interface PlayerElements {
  court_id: number;
  max: number;
  max_games: number;
  name: string;
  player_id: number;
  player_name: string;
  roundrobin_id: string;
  total: number;
  avg: string;
}

@Component({
  selector: 'dashboard-league-score',
  templateUrl: './dashboard-league-score.component.html',
  styleUrls: ['./dashboard-league-score.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

})



export class DashboardLeagueScoreComponent implements OnInit, OnDestroy, AfterViewInit {


  rrname: string;
  total: number;
  s1 = null;
  account: any;

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatTable, {static: false}) table: MatTable<PlayerElements>;

  constructor(  @Inject(MAT_DIALOG_DATA) public data: any,  private http: HttpClient, public dialogRef: MatDialogRef<DashboardLeagueScoreComponent>, private accountService: AccountService, public dialog: MatDialog,private toastService: ToastService) {}

  listdata: PlayerElements[] = this.data.data.data;
  displayedColumns: string[] = ['player_name','total', 'avg'];
  dataSource = new MatTableDataSource();

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit() {

    this.rrname = this.data.data.data[1].name;
    this.total = this.data.data.data[1].max * this.data.data.data[1].max_games;

    this.dataSource.data = this.listdata;

    this.s1 = this.accountService.cast.subscribe(data => {
      this.account = data;
    });

  }

  ngOnDestroy() {
    if (this.s1 != null) {
      this.s1.complete();
    }
  }

  onInputChange(row){


    if(row.total > (row.max*row.max_games)){
      row.total = 0;
      this.toastService.show({
        text: 'Total is to large.',
        type: 'warn',
        buttonText: '',
      });
    }

  }

  save() {
    this.http.post<any>(environment.service.trackithubapi + '/api/inside/saveleaguetotal', {profile_id: this.account.current_profile.profile_id, data: this.dataSource.data }).subscribe((data: any) => {
      this.toastService.show({
        text: 'Scores have been updated.',
        type: 'success',
        buttonText: '',
      });
      this.dialogRef.close();
    });
  }


  cancel() {
    this.dialogRef.close();
  }


}
