import {Component, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';

@Component({
  selector: 'app-welcome-dialog',
  templateUrl: './player-join-dialog.component.html',
  styleUrls: ['./player-join-dialog.component.scss'],
})


export class PlayerJoinDialogComponent implements OnInit {

constructor(
    private dialogRef: MatDialogRef<PlayerJoinDialogComponent>) { }


  ngOnInit() {
  }


}
