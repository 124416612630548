import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'app-dashboard-card-stats',
  templateUrl: './dashboard-card-stats.component.html',
  styleUrls: ['./dashboard-card-stats.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class DashboardCardStatsComponent {
    @Input() average: string;
    @Input() wins: string;
    @Input() standings: string;
    @Input() played: string;
    @Input() league_total: string;
}
