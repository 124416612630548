import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';

import {AccountService} from '../../common/services/account.service';
import {ActivatedRoute} from "@angular/router";
import { TalkService } from '../../common/services/talk.service';
import {ChatService} from "../../common/services/chat.service";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import Talk from "talkjs";

@Component({
  selector: 'app-dashboard-chatpanel',
  templateUrl: './dashboard-chatpanel.component.html',
  styleUrls: ['./dashboard-chatpanel.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})

export class DashboardChatPanelComponent implements OnInit, OnDestroy {

  is_visible = false;
  datax: any;
  otherProfile: any;
  suba = null;
  subb = null;
  account: any;
  other_user: {
    profile_id: any,
    name: any,
    avatarImg: any,
    avatarPlaceholder: any,
    email: any
  };
  private chat: any;

  private inbox: Talk.Inbox;
  private session: Talk.Session;


  constructor(private http: HttpClient, public talkService: TalkService, private route: ActivatedRoute, private accountService: AccountService, private  chatService: ChatService) {

  }




  ngOnDestroy() {
    if (this.suba !== undefined) {
      this.suba.unsubscribe();
    }
    if (this.subb !== undefined) {
      this.subb.unsubscribe();
    }
  }

  ngOnInit() {


    this.other_user = {
      profile_id: '',
      name: '',
      avatarImg: '',
      avatarPlaceholder: '',
      email: ''
    }



    this.suba = this.accountService.cast.subscribe(data => {
      this.account = data;
      if(this.account.id !== null) {



        this.subb = this.chatService.cast.subscribe(data => {

          this.datax = data;




          if((this.is_visible != data.is_visible) || (this.other_user.profile_id != data.user.profile_id)) {

            this.is_visible = data.is_visible;




            if (data.is_visible == true) {
              this.otherProfile = data.user;


              if (this.other_user != data.user) {
                this.other_user = data.user;




                if(data.conversationID != ''){
                  this.createInboxConvo({
                    name: this.account.current_profile.name,
                    id: this.account.current_profile.profile_id,
                    avatar: null,
                    email: this.account.current_profile.email
                  }, data.conversationID);
                } else {
                  this.createInbox({
                    name: this.account.current_profile.name,
                    id: this.account.current_profile.profile_id,
                    avatar: null,
                    email: this.account.current_profile.email
                  });
                }




                this.http.get<any>(environment.service.trackithubapi + '/api/inside/lastchat?from_id=' + this.account.current_profile.profile_id + '&to_id=' + this.otherProfile.profile_id).subscribe((data: any) => {

                });
              }
            } else {

            }

          }




        });



      }
    });
  }

  closepop(){
    this.is_visible = false;
    this.datax.is_visible = false;
    this.chatService.closeBox(this.datax);
  }


  private async createInboxConvo(user,convo) {

    if(this.chat == null || this.chat == undefined){
    } else {
      this.chat.destroy();
    }

    const session = await this.talkService.createCurrentSession(user);
    this.chat = await this.talkService.createInboxFromConversation(session, convo);
    this.chat.mount(document.getElementById("talkjs-container"));

  }


  private async createInbox(user) {

    if(this.chat == null || this.chat == undefined){
    } else {
      this.chat.destroy();
    }

    const session = await this.talkService.createCurrentSession(user);
    this.chat = await this.talkService.createInbox(session, {name: this.otherProfile.name, id: this.otherProfile.profile_id, avatar: this.otherProfile.avatarImg, email: this.otherProfile.email} );
    this.chat.mount(document.getElementById("talkjs-container"));

  }

}
