import { Injectable } from "@angular/core";
import Talk from "talkjs";
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: "root"
})
export class TalkService {
  constructor() {
  }

  private currentTalkUser: Talk.User;

  async createTalkUser(applicationUser) {
    await Talk.ready;

    if(applicationUser.id == 0){
      applicationUser.username = 'System Message';
      applicationUser.profilePictureUrl = ' ';
    }
    return new Talk.User({
      id: applicationUser.id,
      name: applicationUser.username,
      photoUrl: applicationUser.profilePictureUrl
    });
  }

  async createCurrentSession(info) {
    await Talk.ready;
    const user = {
      id: info.id,
      username: info.name,
      email: info.email,
      photoUrl: "https://demo.talkjs.com/img/alice.jpg",
    };
    const currentTalkUser = await this.createTalkUser(user);
    const session = new Talk.Session({
      appId: environment.service.talkjs,
      me: currentTalkUser
    });
    this.currentTalkUser = currentTalkUser;
    return session;
  }


  private async getOrCreateConversationFromID(
    session: Talk.Session,
    conversationID
  ) {
    const conversationBuilder = session.getOrCreateConversation(
      conversationID
    );
    return conversationBuilder;
  }

  private async getOrCreateConversation(
    session: Talk.Session,
    otherApplicationUser
  ) {
    const otherTalkUser = await this.createTalkUser(otherApplicationUser);
    const conversationBuilder = session.getOrCreateConversation(
      Talk.oneOnOneId(this.currentTalkUser, otherTalkUser)
    );
    conversationBuilder.setParticipant(this.currentTalkUser);
    conversationBuilder.setParticipant(otherTalkUser);
    return conversationBuilder;
  }

  async createInbox(session: Talk.Session, other) {
    const otherApplicationUser = {
      id: other.id,
      username: other.name,
      email: other.email,
    };
    const conversationBuilder = await this.getOrCreateConversation(
      session,
      otherApplicationUser
    );
    return session.createInbox({selected: conversationBuilder});
  }


  async createInboxFromConversation(session: Talk.Session, conversationId) {
    const conversationBuilder = await this.getOrCreateConversationFromID(
      session,
      conversationId
    );
    return session.createInbox({selected: conversationBuilder});
  }


  createGroup(value){

  }

}
