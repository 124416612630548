import { DataSource } from '@angular/cdk/collections';
import { MatPaginator, MatSort } from '@angular/material';
import { map } from 'rxjs/operators';
import { Observable, of as observableOf, merge } from 'rxjs';
import {filter} from 'rxjs/operators';

// TODO: Replace this with your own data model type
export interface StandingsTableItem {
  position: number;
  firstName: string;
  lastName: string;
  average: number;
  gender: string;
  played: number;
  rating: number;
  group: string;
  filter?: any;
  row?: any;
}


// TODO: replace this with real data from your application
const EXAMPLE_DATA: StandingsTableItem[] = [
  {
    'position': 1,
    'firstName': 'Al',
    'lastName': 'Dadswell',
    'gender': 'Male',
    'average': 72.42,
    'played': 14,
    'rating': 2.5,
    'group': 'C'
  },
  {
    'position': 2,
    'firstName': 'Al',
    'lastName': 'Seguy',
    'gender': 'Male',
    'average': 77.75,
    'played': 18,
    'rating': 3.5,
    'group': 'C'
  },
  {
    'position': 3,
    'firstName': 'Alan',
    'lastName': 'Riley',
    'gender': 'Male',
    'average': 50.66,
    'played': 6,
    'rating': 2.5,
    'group': 'E'
  },
  {
    'position': 4,
    'firstName': 'Barbara',
    'lastName': 'Campbel',
    'gender': 'Female',
    'average': 68.20,
    'played': 0.2,
    'rating': 4,
    'group': 'E'
  },
  {
    'position': 5,
    'firstName': 'Barbara',
    'lastName': 'Hecker',
    'gender': 'Female',
    'average': 62.42,
    'played': 5,
    'rating': 2.5,
    'group': 'E'
  },
  {
    'position': 6,
    'firstName': 'Bill',
    'lastName': 'Bell',
    'gender': 'Male',
    'average': 76.20,
    'played': 81,
    'rating': 4,
    'group': 'E'
  },
  {
    'position': 7,
    'firstName': 'Bill',
    'lastName': 'Halter',
    'gender': 'Male',
    'average': 76.40,
    'played': 14,
    'rating': 3,
    'group': 'B'
  },
  {
    'position': 8,
    'firstName': 'Blaine',
    'lastName': 'Bertram',
    'gender': 'Male',
    'average': 43.94,
    'played': 1,
    'rating': 2.5,
    'group': 'E'
  },
  {
    'position': 9,
    'firstName': 'Bob',
    'lastName': 'Dausman',
    'gender': 'Male',
    'average': 79.97,
    'played': 13,
    'rating': 4,
    'group': 'l9'
  },
  {
    'position': 10,
    'firstName': 'Bob',
    'lastName': 'EUiott',
    'gender': 'Male',
    'average': 75.02,
    'played': 14,
    'rating': 3,
    'group': 'C'
  },
  {
    'position': 11,
    'firstName': 'Bob',
    'lastName': 'Hansen',
    'gender': 'Male',
    'average': 0.7186,
    'played': 6,
    'rating': 2.5,
    'group': 'D'
  },
  {
    'position': 12,
    'firstName': 'Bob',
    'lastName': 'Little',
    'gender': 'Male',
    'average': 0.8,
    'played': 26,
    'rating': 4,
    'group': 'D'
  },
  {
    'position': 13,
    'firstName': 'Bonnie',
    'lastName': 'laul',
    'gender': 'Female',
    'average': 71.86,
    'played': 25,
    'rating': 2.5,
    'group': 'E'
  },
  {
    'position': 14,
    'firstName': 'Bonnie',
    'lastName': 'Senatore',
    'gender': 'Female',
    'average': 74.24,
    'played': 44,
    'rating': 3,
    'group': 'E'
  }
];

/**
 * Data source for the StandingsTable view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
export class StandingsTableDataSource extends DataSource<StandingsTableItem> {
  data: StandingsTableItem[] = EXAMPLE_DATA;
  paginator: MatPaginator;
  sort: MatSort;
  filter: any;
  position: any;
  row: any;
  constructor() {
    super();
  }

  /**
   * Connect this data source to the table. The table will only update when
   * the returned stream emits new items.
   * @returns A stream of the items to be rendered.
   */
  connect(): Observable<StandingsTableItem[]> {
    // Combine everything that affects the rendered data into one update
    // stream for the data-table to consume.
    const dataMutations = [
      observableOf(this.data),
      // this.paginator.page,
      // this.sort.sortChange,
      // this.filter.page
    ];

    return merge(...dataMutations).pipe(map(() => {
      return this.getPagedData(this.getSortedData([...this.data]));
    }));
  }

  /**
   *  Called when the table is being destroyed. Use this function, to clean up
   * any open connections or free any held resources that were set up during connect.
   */
  disconnect() {}

  /**
   * Paginate the data (client-side). If you're using server-side pagination,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getPagedData(data: StandingsTableItem[]) {
    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    return data.splice(startIndex, this.paginator.pageSize);
  }

  /**
   * Sort the data (client-side). If you're using server-side sorting,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getSortedData(data: StandingsTableItem[]) {
    if (!this.sort.active || this.sort.direction === '') {
      return data;
    }

    return data.sort((a, b) => {
      const isAsc = this.sort.direction === 'asc';
      switch (this.sort.active) {
        case 'position': return compare(+a.position, +b.position, isAsc);
        case 'firstName': return compare(a.firstName, b.firstName, isAsc);
        case 'lastName': return compare(a.lastName, b.lastName, isAsc);
        case 'gender': return compare(a.gender, b.gender, isAsc);
        case 'average': return compare(a.average, b.average, isAsc);
        case 'played': return compare(a.played, b.played, isAsc);
        case 'rating': return compare(a.rating, b.rating, isAsc);
        case 'group': return compare(a.group, b.group, isAsc);
        default: return 0;
      }
    });
  }
}

/** Simple sort comparator for example ID/Name columns (for client-side sorting). */
function compare(a, b, isAsc) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
