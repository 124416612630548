import {Component, Input, OnInit, Output} from '@angular/core';
import {DatePipe} from '@angular/common';
import {CalendarService} from '../../common/services/calendar.service';
import {AccountService} from '../../common/services/account.service';
import { OutlookCalendar, YahooCalendar, GoogleCalendar } from 'datebook'
import {Router} from "@angular/router";
import {DashboardEventviewComponent, DashboardLocationsComponent} from "../../dashboard";
import {BottomSheetService} from "../../common/services/bottom-sheet.service";


@Component({
  selector: 'app-events-details',
  templateUrl: './events-details.component.html',
  styleUrls: ['./events-details.component.scss']
})

export class EventsDetailsComponent implements OnInit {
  status = 'pickleball';
  @Input() isRegistered: boolean;
  @Input() eventdetail: any;
  url: any;

  selectedDate: any;
  account: any;
  events: any;

  yahooCalendar: any;
  googleCalendar: any;
  outlookCalendar: any;

  yahoourl: any;
  googleurl: any;
  outlookurl: any;

  constructor(
    private datePipe: DatePipe,
    private calendarService: CalendarService,
    private accountService: AccountService,
    private bottomSheetService: BottomSheetService,
    private router: Router) {
  }

  ngOnInit(): void {

     this.yahooCalendar = new YahooCalendar({
       title: this.eventdetail.summary,
       location: this.eventdetail.title,
       description: this.eventdetail.description,
       start: new Date(this.eventdetail.startrepeat),
       end: new Date(this.eventdetail.endrepeat),
    })

    this.yahoourl = this.yahooCalendar.render();

    this.googleCalendar = new GoogleCalendar({
      title: this.eventdetail.summary,
      location: this.eventdetail.title,
      description: this.eventdetail.description,
      start: new Date(this.eventdetail.startrepeat),
      end: new Date(this.eventdetail.endrepeat),
    })

    this.googleurl = this.googleCalendar.render();

    this.outlookCalendar = new OutlookCalendar({
      title: this.eventdetail.summary,
      location: this.eventdetail.title,
      description: this.eventdetail.description,
      start: new Date(this.eventdetail.startrepeat),
      end: new Date(this.eventdetail.endrepeat),
    })

    this.outlookurl = this.outlookCalendar.render();

  }

  registerevent(rpt){
    this.bottomSheetService.openBottomSheetWithDataCustomClass(DashboardEventviewComponent, 'DashboardEventviewComponent',{eventdetail: this.eventdetail}, 'custom-bottom' );
    //this.router.navigate(['/dashboard/viewrepeat/' + rpt]);
  }

  viewevent(rpt){
    this.bottomSheetService.openBottomSheetWithDataCustomClass(DashboardEventviewComponent, 'DashboardEventviewComponent',{eventdetail: this.eventdetail}, 'custom-bottom' );
  }

  onClickDefault(event: any){
    event.stopPropagation();
  }

}
