import {
  AfterViewInit,
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component, Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';

import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MatDialog} from '@angular/material/dialog';
import {ToastService} from "../../common/toast";
import {HttpClient} from "@angular/common/http";
import {SessionStorageService} from 'ngx-webstorage';
import {RecordsService} from "../../common/services/records.service";
import {AccountService} from "../../common/services/account.service";
import {FormRegisterleagueService} from "../../common/services/form-registerleague.service";
import {Account} from "../../common/interfaces/account";
import {environment} from "../../../environments/environment";
import {DashboardPregamemodalComponent} from "../../dashboard";





@Component({
  selector: 'term-register-component',
  templateUrl: './term-register.component.html',
  styleUrls: ['./term-register.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

})



export class TermRegisterComponent implements OnInit, OnDestroy, AfterViewInit {





  public lasterror = null;
  public errormessage = null;
  public stepLabel = ['Login Info', 'Contact Info', 'Skill Rating'];
  public pwdPattern: any = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{8,25}$/;
  public records = {};
  public message = '';
  public _loading = false;
  public loaderMessage = 'Loading...';
  public loginFormValid = true;
  public contactFormValid = true;
  public rateFormValid = true;
  public finalValid = true;
  public loginerror_valid = false;
  public loginerror;
  account_id: any;



  s1 = false;
  s2 = false;
  s3 = false;


  status: boolean;

  email: string;
  phone: string;
  disabled: boolean;
  terms: boolean;


  constructor(        private changeDetectorRef: ChangeDetectorRef, private validationService: RecordsService,     public formService: FormRegisterleagueService,   private LoginService: RecordsService,     private accountService: AccountService,  @Inject(MAT_DIALOG_DATA) public data: any,  private http: HttpClient, public dialogRef: MatDialogRef<TermRegisterComponent>,  public dialog: MatDialog,private toastService: ToastService, private sessionSt: SessionStorageService) {

  }



  ngAfterViewInit() {

  }




  ngOnInit() {


    this.formService.initializeFormGroup();
    this.disabled = true;
    this.changeDetectorRef.detectChanges();
    this.formService.form.valueChanges.subscribe(data => {





      this.disabled = true;
      this.s1 = false;
      this.s2 = false;
      this.s3 = false;



      this.email = data.em;
      this.phone = data.phone;
      this.terms = data.terms;




      if(data.em != null) {
          this.validationService.checkEmail(data.em).subscribe(ret => {

            if (ret.found === 1) {

              this.formService.form.controls.em.setErrors(null);




              this.s1 = true;
              this.check(data);
            } else {

              this.formService.form.controls.em.setErrors({
                empty: true
              });


              //this.errormessage = 'The email address entered is not currently associated with an existing TrackitHub account.';
              //this.toastService.show({
              //  text: this.errormessage,
              //  type: 'warn',
              //  buttonText: '',
              //});

              this.s1 = false;
              this.check(data);
            }
          });
      } else {
        this.s1 = false;
        this.check(data);
      }
      this.changeDetectorRef.detectChanges();

    });


  }

  ngOnDestroy() {

  }

check(data){











  if(data.phone != null) {
    if(data.phone.length < 2){
      this.formService.form.controls.phone.setErrors({
        empty: true
      });
      this.s2 = false;
    } else {
      this.s2 = true;
    }
  } else {
    this.s2 = false;
  }

  this.s3 = this.terms;



  if(this.s1 == true && this.s2 == true && this.s3 == true){
    this.disabled = false;
  } else {
    this.disabled = true;
  }


  this.changeDetectorRef.detectChanges();
}




  showterms(){
    window.open('/terms.php', "_blank");
  }

  save() {


    this.disabled = true;

    interface sModal {
      email: string;
      phonenumber: string;
      termsandconditions: string;
    }

    const storage = <sModal>{};


    storage.email = this.email;
    storage.phonenumber = this.phone;
    storage.termsandconditions = '1';






    this.http.get<Account>(environment.service.trackithubapi + '/api/outside/verifyterms?email=' + this.email + '&phone=' + this.phone).subscribe((data: any) => {
      this.dialogRef.close();
      const dialogRef = this.dialog.open(DashboardPregamemodalComponent, {
        maxWidth: 500,
        minWidth: 370,
        maxHeight: '90vh', // Adjust this based on your requirements
        data: {}
      });
    });




  }


  cancel() {
    this.dialogRef.close();
  }


}
