import {AfterViewInit, ChangeDetectionStrategy, Component, Inject, OnDestroy, ViewChild} from '@angular/core';
import {Validators} from '@angular/forms';
import {FieldConfig} from '../../dynamic-form/models/field-config.interface';
import {HttpClient} from '@angular/common/http';
import {AccountService} from '../../common/services/account.service';
import {Router} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DynamicFormStep1Component, DynamicFormStep2Component, DynamicFormStep3Component} from '../../dynamic-form/containers';
import {RetrioeveaddressService} from '../../common/services/retrioeveaddress.service';
import {ToastService} from '../../common/toast';
import {SessionStorageService} from "ngx-webstorage";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-create-location',
  templateUrl: './create-location.component.html',
  styleUrls: ['./create-location.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class CreateLocationComponent implements AfterViewInit, OnDestroy {

  id: number;
  group_id: any;
  profile_id: any;
  clubcard: boolean;
  isValid = false;
  addressobj: any;
  accountser: any;
  thetitle: string;
  disableclick: boolean;

  s1 = null;

  progress: number;
  stepLabel = ['Location', 'Contact', 'Courts'];
  stepOneCreateLocation: FieldConfig[] = [
    {
      type: 'input',
      label: 'Location Name',
      name: 'locationName',
      placeholder: 'Location Name',
      validation: [Validators.required],
      maxlength: 50,
      required: true
    },

    {
      type: 'file',
      label: 'Upload image',
      name: 'uploadimage',
      required: false
    },
    {
      type: 'input',
      label: 'Website URL (https://yourwebsite.com)',
      name: 'website',
      placeholder: 'Website URL',
      validation: [Validators.required],
      required: false
    },
    {
      type: 'regaddress',
      name: 'location',
      label: 'Address',
      placeholder: 'Address',
      required: true
    },
    {
      type: 'select',
      label: 'Venue Type',
      name: 'venueType',
      options: ['Public Facility', 'Club'],
      placeholder: 'Venue Type',
      required: true
    },
  ];
  stepTwoCreateLocation: FieldConfig[] = [
    {
      type: 'input',
      label: 'Contact First Name',
      name: 'contactFirstName',
      placeholder: 'Contact First Name',
      validation: [Validators.required],
      required: true
    },
    {
      type: 'input',
      label: 'Contact Last Name',
      name: 'contactLastName',
      placeholder: 'Contact Last Name',
      validation: [Validators.required],
      required: true
    },
    {
      type: 'input',
      label: '(555) 555-5555',
      mask: '(000) 000-0000',
      name: 'phonenumber',
      placeholder: 'Phone Number',
      validation: [Validators.required],
      required: true
    },
    {
      type: 'input',
      label: 'Email',
      name: 'email',
      placeholder: 'Email',
      validation: [Validators.email],
      required: true
    },
  ];
  courts: FieldConfig[] = [
    {
      type: 'input',
      label: 'Number of courts',
      name: 'numberofcourts',
      placeholder: 'Number of courts',
      validation: [Validators.required],
      required: true
    },
    {
      type: 'checkbox',
      name: 'courttype',
      nogroup: true,
      direction: 'vert',
      options: [
        {name: 'Outdoor'},
        {name: 'Indoor'},
        {name: 'Permanent'},
        {name: 'Temporary'},
      ],
    },
    {
      type: 'checkbox',
      name: 'amenities',
      title: 'Amenities',
      nogroup: true,
      options: [
        {name: 'restroom', svgIcon: 'restroom'},
        {name: 'water', svgIcon: 'water'},
        {name: 'shower', svgIcon: 'shower'},
        {name: 'accessible', icon: 'accessible'},
        {name: 'wifi', icon: 'wifi'},
        {name: 'lights', svgIcon: 'light_bulb'}
      ],
    },
  ];



  constructor(@Inject(MAT_DIALOG_DATA) public data: {
    clubcard: boolean;
    group_id: string;
    location: any;
    profile_id: any;
    disableclick: boolean;
  }, private sessionSt: SessionStorageService, private toastService: ToastService, private getaddress: RetrioeveaddressService, private http: HttpClient, private accountService: AccountService, private router: Router, public dialogRef: MatDialogRef<CreateLocationComponent>) {
    this.s1 = this.getaddress.cast.subscribe(datax => {
      this.addressobj = datax;
    });
  }

  @ViewChild(DynamicFormStep1Component, {static: false}) l1form: DynamicFormStep1Component;
  @ViewChild(DynamicFormStep2Component, {static: false}) l2form: DynamicFormStep2Component;
  @ViewChild(DynamicFormStep3Component, {static: false}) l3form: DynamicFormStep3Component;

  closemodal() {
    this.dialogRef.close();
  }

  ngAfterViewInit() {

    this.disableclick = this.data.disableclick;

    if (this.l1form !== undefined) {
      this.l1form.l1form.valueChanges.subscribe(data => {
        this.formChanges(data);
      });
    }
    if (this.l2form !== undefined) {
      this.l2form.l2form.valueChanges.subscribe(data => {
        this.formChanges(data);
      });
    }
    if (this.l3form !== undefined) {
      this.l3form.l3form.valueChanges.subscribe(data => {
        this.formChanges(data);
      });
    }


    if (this.data.location !== undefined) {
      this.l1form.l1form.controls.locationName.setValue(this.data.location.title);
      this.l1form.l1form.controls.website.setValue(this.data.location.url);
      this.l1form.l1form.controls.location.setValue(this.data.location.street + ' ' + this.data.location.city + ', ' + this.data.location.state);
      this.l1form.l1form.controls.venueType.setValue( this.data.location.venuetype);
      this.l2form.l2form.controls.contactFirstName.setValue(this.data.location.contact_first);
      this.l2form.l2form.controls.contactLastName.setValue(this.data.location.contact_last);
      this.l2form.l2form.controls.phonenumber.setValue(this.data.location.phone);
      this.l2form.l2form.controls.email.setValue( this.data.location.email);
      this.l3form.l3form.controls.numberofcourts.setValue(this.data.location.numberofcourts);
      this.l3form.l3form.controls.courttype.setValue(this.data.location.courttype);
      this.l3form.l3form.controls.Outdoor.setValue(this.data.location.Outdoor);
      this.l3form.l3form.controls.Indoor.setValue(this.data.location.Indoor);
      this.l3form.l3form.controls.Permanent.setValue(this.data.location.Permanent);
      this.l3form.l3form.controls.Temporary.setValue(this.data.location.Temporary);
      this.l3form.l3form.controls.restroom.setValue(this.data.location.restroom);
      this.l3form.l3form.controls.water.setValue( this.data.location.water);
      this.l3form.l3form.controls.shower.setValue(this.data.location.shower);
      this.l3form.l3form.controls.accessible.setValue(this.data.location.accessible);
      this.l3form.l3form.controls.wifi.setValue(this.data.location.wifi);
      this.l3form.l3form.controls.lights.setValue( this.data.location.lights);
      this.l3form.l3form.controls.Outdoor.setValue(this.data.location.Outdoor);
      this.l3form.l3form.controls.Indoor.setValue(this.data.location.Indoor);
      this.l3form.l3form.controls.Permanent.setValue(this.data.location.Permanent);
      this.l3form.l3form.controls.Temporary.setValue( this.data.location.Temporary);
    }

    if (this.data.location === undefined) {
      this.thetitle = 'Create Location';
    } else {
      this.thetitle = 'Edit Location';
    }

  }

  formChanges(data) {
    if (this.l1form.l1form.valid === true && this.l2form.l2form.valid === true && this.l3form.l3form.valid === true) {
      this.isValid = true;
    } else {
      this.isValid = false;
    }
  }

  finishLocation($event) {
    interface sModal {
      id: number;
      profile_id: any;
      clubcard: boolean;
      group_id: number;
      locationName: string;
      uploadimage: string;
      website: string;
      location: string;
      lat: string;
      lng: string;
      street: string;
      city: string;
      state: string;
      zip: string;
      country: string;
      venueType: string;
      contactFirstName: string;
      contactLastName: string;
      phonenumber: string;
      email: any;
      numberofcourts: any;
      courttype: string;
      restroom: string;
      water: string;
      shower: string;
      accessible: string;
      wifi: string;
      lights: string;
      Outdoor: string;
      Indoor: string;
      Permanent: string;
      Temporary: string;
    }

    if (this.data.location !== undefined) {
      this.id = this.data.location.loc_id;
      this.group_id = null;
      this.clubcard = false;
    } else {
      this.id = 0;
      this.group_id = this.data.group_id;
      this.clubcard = this.data.clubcard;
    }

    this.profile_id = this.data.profile_id;



    const storage = <sModal>{};
    storage.id = this.id;
    storage.clubcard = this.clubcard;
    storage.group_id = this.group_id;
    storage.profile_id = this.profile_id;
    storage.locationName = this.l1form.l1form.controls.locationName.value;
    storage.uploadimage = this.l1form.l1form.controls.uploadimage.value;
    storage.website = this.l1form.l1form.controls.website.value;
    storage.location = this.l1form.l1form.controls.location.value;
    storage.lat = this.addressobj.address_lat;
    storage.lng = this.addressobj.address_lng;
    storage.street = this.addressobj.address_street;
    storage.city = this.addressobj.address_city;
    storage.state = this.addressobj.address_state;
    storage.zip = this.addressobj.address_zip;
    storage.country = this.addressobj.address_country;
    storage.venueType = this.l1form.l1form.controls.venueType.value;
    storage.contactFirstName = this.l2form.l2form.controls.contactFirstName.value;
    storage.contactLastName = this.l2form.l2form.controls.contactLastName.value;
    storage.phonenumber = this.l2form.l2form.controls.phonenumber.value;
    storage.email = this.l2form.l2form.controls.email.value;
    storage.numberofcourts = this.l3form.l3form.controls.numberofcourts.value;
    storage.courttype = this.l3form.l3form.controls.courttype.value;
    storage.restroom = this.l3form.l3form.controls.restroom.value;
    storage.shower = this.l3form.l3form.controls.shower.value;
    storage.water = this.l3form.l3form.controls.water.value;
    storage.accessible = this.l3form.l3form.controls.accessible.value;
    storage.wifi = this.l3form.l3form.controls.wifi.value;
    storage.lights = this.l3form.l3form.controls.lights.value;
    storage.Outdoor = this.l3form.l3form.controls.Outdoor.value;
    storage.Indoor = this.l3form.l3form.controls.Indoor.value;
    storage.Permanent = this.l3form.l3form.controls.Permanent.value;
    storage.Temporary = this.l3form.l3form.controls.Temporary.value;

    this.http.post(environment.service.trackithubapi + '/api/inside/makeeditlocation', storage, {}).subscribe((data: any) => {

      if (storage.id === 0 && storage.group_id === 0) {
        this.sessionSt.store('add_location', data.location_id);
      }

      if (storage.id === 0) {
        this.toastService.show({
          text: 'Location has been created.',
          type: 'success',
        });
      } else {
        this.toastService.show({
          text: 'Location has been updated.',
          type: 'success',
        });
      }

      this.accountService.reloadprofile();
      this.dialogRef.close();

    });


  }

  ngOnDestroy() {
    if (this.s1 != null) {
      this.s1.complete();
    }
  }

}
