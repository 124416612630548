// tslint:disable-next-line:class-name
export class groupCard {
  id: string;
  name: string;
  owner: number;
  ownerid: string;
  league: number;
  permissions: number;
  approved: number;
  organization: string;
  membership: number;
  paypal: string;
  currency: string;
  avatar: string;
  avatar_icon: string;
  archive: number;
  average: string;
  num: string;
  win: string;
  standings: string;
  gender: string;
  minrate: string;
  is_leage: any;
  maxrate: string;
  dues: string;
  chip1: string;
  chip2: string;
  chip3: string;
  chip4: string;
  label: string;
  primary_location: string;
  params: any;
  renewals: [{
    user_id: string,
    name: string,
    group_id: string,
    current_id: string,
    created: string,
    end_date: string
  }];
  emails: [{
    email_id: string,
    subject: string,
    sent: string,
  }];
  members: string;
  clubcard: boolean;
}
