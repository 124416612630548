import { Pipe, PipeTransform } from '@angular/core';

// From https://gist.github.com/bTreePress/31eb8029494e6f85fd6ef6d6697b2aaf
@Pipe({name: 'trim'})
export class TrimPipe implements PipeTransform {
  transform(value: any) {
    if (!value) {
      return '';
    }
    return value.trim();
  }
}
