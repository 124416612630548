import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewChild,
  AfterViewInit, OnDestroy, Inject
} from '@angular/core';
import { MatPaginator, MatSort, MatTable, MatTableDataSource } from '@angular/material';
import { SelectionModel} from '@angular/cdk/collections';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { AccountService} from '../../common/services/account.service';
import {ActivatedRoute, Router} from '@angular/router';
import { ToastService} from '../../common/toast';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { BottomSheetService } from '../../common/services/bottom-sheet.service';
import { LoaderOverlayService } from '../../common/services/loader-overlay.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DialogData} from "../dashboard-roster/roster-table.component";
import {DialogTrialAdminremove, RosterElement} from "..";
import {FormResetpasswordService} from '../../common/services/form-resetpassword.service';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {FormControl} from '@angular/forms';
import {autocompleteService} from '../../common/services/autocomplete.service';

export interface Opt {
  name: string;
}

export interface Search {
  name: string;
  id: number;
}

export interface RosterElementss {
  id: number;
  profile_id: number;
  name: string;
  email: string;
  phone: string;
  type: string;
  paypal_id: string;
  plan: string;
  next_rebill: string;
  created: string;
  status: string;
  site_status: string;
}

@Component({
  selector: 'app-subadmin',
  templateUrl: './subadmin.component.html',
  styleUrls: ['./subadmin.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})

export class DashboardSubAdminComponent implements OnDestroy, OnInit, AfterViewInit {
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatTable, {static: false}) table: MatTable<RosterElementss>;

  s1 = null;

  stat1 = 0;
  stat2 = 0;
  stat3 = 0;
  stat4 = 0;

  lasturl = null;

  smallScreen: boolean;
  bigScreen: boolean;

  allbill = true;
  activebill = false;
  inactivebill = false;

  siteall = false;
  siteactive = true;
  siteinactive = false;

  billall = true;
  billmonth = false;
  billyear = false;

  typeall = true;
  typepay = false;
  typimport = false;

  type = 0;
  bill = 0;
  site = 0;
  status = 0;

  dataSource = new MatTableDataSource();
  selection = new SelectionModel<RosterElementss>(true, []);

  data = [];

  account;

  constructor(private router: Router, public dialog: MatDialog, private bottomSheetService: BottomSheetService, private breakpointObserver: BreakpointObserver, private http: HttpClient, private accountService: AccountService, private route: ActivatedRoute, private toastService: ToastService, private LoaderOverlayService: LoaderOverlayService) {
    breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small
    ]).subscribe(result => {
      this.smallScreen = result.matches;
      this.bigScreen = !result.matches;
    });

    this.LoaderOverlayService.overlayRef = null;
    this.currentWindowWidth = window.innerWidth;

  }

  public currentWindowWidth: number;
  displayedColumns = [];


  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  allbills($event) {
    this.allbill = true;
    this.activebill = false;
    this.inactivebill = false;
    this.pulldata();
  }

  activebills($event) {
    this.allbill = false;
    this.activebill = true;
    this.inactivebill = false;
    this.pulldata();
  }

  inactivebills($event) {
    this.allbill = false;
    this.activebill = false;
    this.inactivebill = true;
    this.pulldata();
  }

  sitealls($event) {
    this.siteall = true;
    this.siteactive = false;
    this.siteinactive = false;
    this.pulldata();
  }

  siteactives($event) {
    this.siteall = false;
    this.siteactive = true;
    this.siteinactive = false;
    this.pulldata();
  }

  siteinactives($event) {
    this.siteall = false;
    this.siteactive = false;
    this.siteinactive = true;
    this.pulldata();
  }

  billalls($event) {
    this.billall = true;
    this.billmonth = false;
    this.billyear = false;
    this.pulldata();
  }

  billmonths($event) {
    this.billall = false;
    this.billmonth = true;
    this.billyear = false;
    this.pulldata();
  }

  billyears($event) {
    this.billall = false;
    this.billmonth = false;
    this.billyear = true;
    this.pulldata();
  }

  typealls($event) {
    this.typeall = true;
    this.typepay = false;
    this.typimport = false;
    this.pulldata();
  }

  typepays($event) {
    this.typeall = false;
    this.typepay = true;
    this.typimport = false;
    this.pulldata();
  }

  typeimports($event) {
    this.typeall = false;
    this.typepay = false;
    this.typimport = true;
    this.pulldata();
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(<RosterElementss>row));
  }

  onResize() {
    this.currentWindowWidth = window.innerWidth;
    this.redraw(this.data);
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openExport() {
    this.toastService.show({
      text: `Check your download folder for your export.`,
      type: 'success',
    });
    window.location.href = this.lasturl + '&csv=true';
  }

  redraw(data) {
    if (this.LoaderOverlayService.overlayRef == null) {
      this.LoaderOverlayService.show();
    }
    this.data = data;
    this.displayedColumns = ['select', 'name', 'email','phone','type', 'paypal_id', 'plan', 'next_rebill', 'status','site_status'];
    this.dataSource.data = data;
    this.selection.clear();
    this.killoverlay();
  }

  killoverlay() {
    if (this.LoaderOverlayService.overlayRef != null) {
      this.LoaderOverlayService.hide();
    }
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit() {
    this.s1 = this.accountService.cast.subscribe(data => {
      this.account = data;
      this.pulldata();
    });
  }

  ngOnDestroy() {
    this.LoaderOverlayService.overlayRef = null;
    if (this.s1 != null) {
      this.s1.complete();
    }
  }





  openDialogDelete(): void {
    if (this.selection.selected.length === 0) {
      this.toastService.show({
        text: `You need to select at least one player.`,
        type: 'warn',
      });
    } else {

      const dialogRef = this.dialog.open(DialogSubAdminremove, {
        data: {
          list: this.selection.selected
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result.event == 'Success') {
          this.http.post<RosterElement>(environment.service.trackithubapi + '/api/inside/subremove', {
            'value': JSON.stringify(this.selection.selected)}, { }).subscribe((data: any) => {
            this.toastService.show({
              text: `Your request has been processed.`,
              type: 'success',
            });
            this.pulldata();
            this.selection.clear();
          });
        } else if (result.event == 'Error') {
          this.toastService.show({
            text: `System error, please try again.   If you continue to get this error please contact support.`,
            type: 'warn',
          });
        }
      });
    }
  }



  openDialogAdd(): void {
    const dialogRef = this.dialog.open(DialogSubAdminadd, {
      data: {
        list: this.selection.selected
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.event == 'Success') {
        this.http.post<RosterElement>(environment.service.trackithubapi + '/api/inside/addsubscription', {
          id: result.data.id, date: result.data.date, cycle: result.data.cycle.name }, { }).subscribe((data: any) => {
          this.toastService.show({
            text: `Your request has been processed.`,
            type: 'success',
          });
          this.pulldata();
          this.selection.clear();
        });
      } else if (result.event == 'Error') {
        this.toastService.show({
          text: `System error, please try again.   If you continue to get this error please contact support.`,
          type: 'warn',
        });
      }
    });
  }

  pulldata() {
    if (this.LoaderOverlayService.overlayRef == null) {
      this.LoaderOverlayService.show('Loading Data...');
    }


    if (this.allbill === true) {
      this.status = 1;
    }

    if (this.activebill === true) {
      this.status = 2;
    }

    if (this.inactivebill === true) {
      this.status = 3;
    }

    if (this.siteall === true) {
      this.site = 1;
    }

    if (this.siteactive === true) {
      this.site = 2;
    }

    if (this.siteinactive === true) {
      this.site = 3;
    }

    if (this.billall === true) {
      this.bill = 1;
    }

    if (this.billmonth === true) {
      this.bill = 2;
    }

    if (this.billyear === true) {
      this.bill = 3;
    }

    if (this.typeall === true) {
      this.type = 1;
    }

    if (this.typepay === true) {
      this.type = 2;
    }

    if (this.typimport === true) {
      this.type = 3;
    }


    this.lasturl = environment.service.trackithubapi + '/api/inside/pullsubinfo?type=' + this.type + '&bill=' + this.bill + '&site=' + this.site + '&status=' + this.status;
    this.http.get<RosterElementss>(this.lasturl).subscribe((data: any) => {

      this.stat1 = data.data.s1;
      this.stat2 = data.data.s2;
      this.stat3 = data.data.s3;
      this.stat4 = data.data.s4;

      this.redraw(data.data.list);
    });
  }





}

@Component({
  selector: 'subadmin-dialog-add',
  templateUrl: 'subadmin-dialog-add.html',
  changeDetection: ChangeDetectionStrategy.Default,
  styleUrls: ['./subadmin-dialog-add.scss']
})
export class DialogSubAdminadd implements OnInit {

  mySearch = new FormControl();
  myControl = new FormControl();
  myDate = new FormControl();

  status = true;
  options: Opt[] = [{name: 'Monthly'}, {name: 'Yearly'}];

  filteredOptions: Observable<Opt[]>;
  mySearched = <any>[];

  tags: string[] = [];
  allTags: string[] = ['Singles', 'Doubles', 'Summer', 'Winter', 'Fall', 'Spring', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday', 'Weekends', 'Weekdays', 'Mornings', 'Afternoons', 'Evenings', 'Challenge Play', 'Tennis', 'Indoor', 'Outdoor', 'Recreation', 'Competitive', 'Weekly', 'Bi-Weekly', 'Bi-Monthly', 'Monthly', 'Social'];

  constructor(
    private service: autocompleteService,
    public dialogRef: MatDialogRef<DialogSubAdminadd>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
  }

  ngOnInit() {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : value.name)),
      map(name => (name ? this._filter(name) : this.options.slice())),
    );



    this.mySearch.valueChanges.subscribe(
      term => {
        if (term != '') {
          this.service.search(term).subscribe(
            data => {
              this.mySearched = data as any[];
            });
        }
      });
  }

  addchips(chip){
      this.tags.push(chip);
  }

  displayFn(user: Opt): string {
    return user && user.name ? user.name : '';
  }

  private _filter(name: string): Opt[] {
    const filterValue = name.toLowerCase();

    return this.options.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  onNoClick(): void {
    this.dialogRef.close({event:'Cancel'});
  }

  onYesClick(): void {
    this.dialogRef.close({event:'Success', data: {'id': this.tags, 'cycle': this.myControl.value, 'date': this.myDate.value}});
  }
}


@Component({
  selector: 'subadmin-dialog-admin-remove',
  templateUrl: 'subadmin-dialog-admin-remove.html',
  changeDetection: ChangeDetectionStrategy.Default
})
export class DialogSubAdminremove {
  constructor(
    public dialogRef: MatDialogRef<DialogSubAdminremove>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close({event:'Cancel'});
  }

  onYesClick(): void {
    this.dialogRef.close({event:'Success'});
  }
}
