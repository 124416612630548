import {ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {AccountService} from '../../common/services/account.service';


@Component({
  selector: 'app-event-video',
  templateUrl: './dashboard-event-video.component.html',
  styleUrls: ['./dashboard-event-video.component.scss'],
  host: {
    "(window:resize)":"onWindowResize($event)"
  },
  changeDetection: ChangeDetectionStrategy.Default,
})

export class DashboardEventVideoComponent implements OnInit, OnDestroy {

  s1 = null;
  account = null;

  isMobile: boolean = false;
  width: number = window.innerWidth;
  mobileWidth  = 590;
  xwidth = 600;

  constructor(
    public dialogRef: MatDialogRef<DashboardEventVideoComponent>,
    private accountService: AccountService,
    @Inject(MAT_DIALOG_DATA) public data: {
    },
  ) {
    this.s1 = this.accountService.cast.subscribe(data => {
      this.account = data;
    });
  }

  ngOnInit(): void {
    this.isMobile = this.width < this.mobileWidth;
    if(this.isMobile == true){
      this.xwidth = this.width * .80;
    }
  }

  Dismiss(): void {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    if (this.s1 != null) {
      this.s1.complete();
    }
  }

  onWindowResize(event) {
    this.width = event.target.innerWidth;
    this.isMobile = this.width < this.mobileWidth;
    if(this.isMobile == true){
      this.xwidth = this.width * .80;
    }
  }

}
