import {Component} from '@angular/core';
import {FormGroup} from '@angular/forms';

import {Field} from '../../models/field.interface';
import {FieldConfig} from '../../models/field-config.interface';

@Component({
    selector: 'app-form-selectspecial',
    styleUrls: ['form-selectspecial.component.scss'],
    template: `<mat-form-field appearance="{{config.appearance}}" [formGroup]="group">
                <mat-select [formControlName]="config.name" change="config.change" [required]="config.required" [multiple]="config.multiple" placeholder="{{config.label}}">
                    <mat-option *ngFor="let option of config.options" [value]="option.value">
                        {{ option.viewValue }}
                    </mat-option>
                </mat-select>
            </mat-form-field>`
})
export class FormSelectSpecialComponent implements Field {
    config: FieldConfig;
    group: FormGroup;
}
