import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';


@Component({
  selector: 'app-club-card',
  templateUrl: './club-card.component.html',
  styleUrls: ['./club-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class ClubCardComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
