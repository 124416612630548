import {Directive, HostListener, Input} from '@angular/core';
import {ModalService} from '../services/modal.service';
import { DashboardShareGroupComponent} from '../../dashboard';

@Directive({
  selector: '[appShareGroup]'
})

export class ShareGroupDirective {

  @Input() group_id = '';
  @Input() club = false;
  @Input() club_id = '';

  constructor(private modalService: ModalService) { }

  @HostListener('click', ['$event.target.id']) onClick(id: any) {
    this.modalService.openDialog(DashboardShareGroupComponent, 'auto', 'auto', {'id': this.group_id, 'club': this.club, 'club_id': this.club_id});
  }
}
