import { Component, Inject, OnInit } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Post } from '../posts.component';

@Component({
  selector: 'app-share-dialog',
  templateUrl: './share-dialog.component.html',
  styleUrls: ['./share-dialog.component.scss']
})
export class ShareDialogComponent implements OnInit {
  value = "examplelink.com";
  pageUrl = ""
  post = "";

  constructor(public dialogRef: MatDialogRef<ShareDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: {post: Post}) {
    this.post = this.data.post.post;
   }

  ngOnInit() {
  }

  socialShare(social: string) {
    switch(social) {
      case 'twitter':
        const twitterurl = "https://twitter.com/intent/tweet?url=" + this.pageUrl + "&text=" + this.post;
        window.open(twitterurl, "NewWindow");
        break;
      case 'facebook' :
        const facebookurl = "https://www.facebook.com/sharer.php?u=" + this.pageUrl;
        window.open(facebookurl, "NewWindow");
        break;
      };
  }

  copyLink(value: string) {
    navigator.clipboard.writeText(value);
  }

  close(){
    this.dialogRef.close();
  }

}
