import {
  Injectable,
  ComponentFactoryResolver,
  ComponentFactory,
  ViewContainerRef, ComponentRef,

} from '@angular/core';
import {DashboardPanelHeaderComponent} from '../../ppl';

@Injectable()
export class DynamicComponentService {
    rootViewContainer: ViewContainerRef;

    constructor(private factoryResolver: ComponentFactoryResolver) {
    }

    setRootViewContainerRef(view: ViewContainerRef): void {
        this.rootViewContainer = view;
    }

    reset(): void {
        this.rootViewContainer.clear();
    }

    insertPPLHeaderComponent() {
        this.insertComponent(DashboardPanelHeaderComponent, null);
    }

    insertComponent(componentType, ref): void {
        const factory = this.factoryResolver.resolveComponentFactory(componentType);
        const componentRef = this.rootViewContainer.createComponent(factory);
        // @ts-ignore
        componentRef.instance.drawerRef = ref;

    }
}
