import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  Inject,
  ChangeDetectionStrategy,
  Input,
  OnDestroy
} from '@angular/core';
import { MatPaginator, MatSort, MatTable, MatTableDataSource } from '@angular/material';
import {environment} from '../../../environments/environment';
import {AccountService} from '../../common/services/account.service';
import {ToastService} from '../../common/toast';
import {HttpClient} from '@angular/common/http';

export interface RenewalElement {
  name: string;
  id: string;
  groupid: string;
  has_paid: string;
}

@Component({
  selector: 'app-dashboard-usersheet-sheet',
  templateUrl: './dashboard-usersheet-sheet.component.html',
  styleUrls: ['./dashboard-usersheet-sheet.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})

export class DashboardUsersheetSheetComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() panel: string;
  @Input() paneldata: any;

  s1 = null;

  displayedColumns = ['name', 'action'];
  dataSource = new MatTableDataSource();
  account: any;
  listdata: any;

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatTable, {static: false}) table: MatTable<RenewalElement>;




  constructor(private http: HttpClient,private toastService: ToastService, private accountService: AccountService) {}

  ngOnInit() {

    this.s1 = this.accountService.cast.subscribe(data => {
      this.account = data.current_profile.groups;

      for(let i = 0; i < this.account.length; i++){
        if(this.account[i].id == this.paneldata){
          if(this.panel == 'pending'){
            this.listdata = this.account[i].pendinglist;
          }
          if(this.panel == 'approved'){
            this.listdata = this.account[i].approvedlist;
          }
          if(this.panel == 'rejected'){
            this.listdata = this.account[i].rejectedlist;
          }
          this.redraw();
        }
      }

    });
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  redraw() {
    this.dataSource.data  = this.listdata;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  acceptConfirm(element) {
    this.http.get<any>(environment.service.trackithubapi + '/api/inside/confirmuser?group_id=' + element.groupid + '&player_id=' + element.id).subscribe((data: any) => {
      this.accountService.reloadprofile();
      this.redraw();
      this.toastService.show({
        text: element.name + ' has been Accepted.',
        type: 'success',
        buttonText: '',
      });
    });
  }

  rejectConfirm(element) {
    this.http.get<any>(environment.service.trackithubapi + '/api/inside/rejectuser?group_id=' + element.groupid + '&player_id=' + element.id).subscribe((data: any) => {
      this.accountService.reloadprofile();
      this.redraw();
      this.toastService.show({
        text: element.name + ' has been Rejected.',
        type: 'success',
        buttonText: '',
      });
    });
  }

  ngOnDestroy() {
    if (this.s1 != null) {
      this.s1.complete();
    }
  }


  getCellColor(status: string): string {
    switch (status) {
      case '1':
        return '#82d654';
      default:
        return 'black'; // Default color
    }
  }

}
