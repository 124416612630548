import {AfterViewInit, Component, EventEmitter, Inject, OnInit, Output, ViewChild} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Post } from '../posts.component';
import {environment} from "../../../../environments/environment";
import {FormControl, FormGroup} from "@angular/forms";
import {Account} from "../../../common/interfaces/account";
import {AccountService} from "../../../common/services/account.service";
import {HttpClient} from "@angular/common/http";
import {ToastService} from "../../../common/toast";
import {Router} from "@angular/router";
import {ImageCroppedEvent} from "ngx-image-cropper";


interface Avatar {
  value: string;
  viewValue: string;
}

export interface Post {
  id: number,
  active: boolean,
  avatarImg: string,
  avatarPlaceholder: string,
  name: string,
  date: string,
  post: string,
  image?: string,
  video?: string,
  location?: string,
  likeCount: number,
  liked: boolean,
  comments: Post[],
  privacy: string,
  shareLink: string,
}


@Component({
  selector: 'app-uploadcomment-dialog',
  templateUrl: './upload-dialog.component.html',
  styleUrls: ['./upload-dialog.component.scss']
})
export class CommentUploadDialogComponent implements OnInit, AfterViewInit {

  group = new FormGroup({
    avatarupload: new FormControl()
  });

  sheetRef: any = this.data.ref;
  cropReady : boolean = false;
  imageChangedEvent: string = '';
  croppedImage: string = '';
  account: Account;
  avatarImg: string;
  avatarPlaceholder: string;
  selectedAvatar: string = "";
  tempCroppedImage: string = "";

  siteUrl = environment.service.trackithubapi + '/';

  s1 = null;

  avatars: Avatar[] = [
    {value: 'club', viewValue: 'Club'},
    {value: 'grin', viewValue: 'Grin'},
    {value: 'slight-smile', viewValue: 'Slight Smile'},
    {value: 'smile', viewValue: 'Smile'},
    {value: 'smiling-face-eyes', viewValue: 'Smiling Face Eyes'},
    {value: 'smirking-face', viewValue: 'Smirking Face'},
    {value: 'tilted-grin', viewValue: 'Tilted-grin'},
    {value: 'winking-grin', viewValue: 'Winking-Grin'},
    {value: 'worried-smile', viewValue: 'Worried-Smile'},
  ];


  constructor( @Inject(MAT_DIALOG_DATA) public data: any, private accountService: AccountService, private http: HttpClient, public dialogRef: MatDialogRef<CommentUploadDialogComponent>, private toastService: ToastService, private router: Router,) {
    this.s1 = this.accountService.cast.subscribe(data => {
      this.account = data;

      this.avatarImg = this.account.current_profile.avatar;
      this.avatarPlaceholder = this.account.current_profile.avataricon;
    });
  }

  ngOnInit() {
  }

  ngAfterViewInit() {

  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.selectCustomOption();
  }
  imageLoaded() {
    this.cropReady = true;
  }
  cropperReady() {

  }
  loadImageFailed() {
    // show message
  }



  selectedDefaultOption(avatar){
    if(this.selectedAvatar === avatar.value){
      this.selectedAvatar = "";
    } else{
      this.selectedAvatar = avatar.value;
      this.avatarPlaceholder = this.selectedAvatar;
      this.avatarImg = "";
    }
  }

  selectCustomOption(){
    this.selectedAvatar = this.croppedImage;
    this.tempCroppedImage = this.selectedAvatar;
    this.avatarImg = this.selectedAvatar;
    this.avatarPlaceholder = "";
  }

  saveavatar() {
    if (this.avatarPlaceholder != "") {
      this.http.get<any>(environment.service.trackithubapi + '/api/inside/setavataricon?profile_id=' + this.account.current_profile.profile_id + '&icon=' + this.avatarPlaceholder).subscribe((data: any) => {
        this.accountService.load();
        this.toastService.show({
          text: 'Avatar has been updated.',
          type: 'success',
          buttonText: '',
        });
      });
    }

    else if (this.avatarImg != "") {
      this.http.post<any>(environment.service.trackithubapi + '/api/inside/uploadavataricon', {profile_id: this.account.current_profile.profile_id, data: this.avatarImg}).subscribe((data: any) => {
        this.accountService.load();
        this.toastService.show({
          text: `Profile avatar has been updated.`,
          type: 'success',
        });
        this.sheetRef.dismiss();
      });
    }

    this.dialogRef.close();
  }

  cancel() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    if (this.s1 != null) {
      this.s1.complete();
    }
  }

  onNoClick(): void {
    this.dialogRef.close({image: null});
  }

  onYesClick(): void {
    this.dialogRef.close({image: this.selectedAvatar});
  }


}
