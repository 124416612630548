import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject, Input, OnDestroy,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';

import { PanelCardService } from '../../common/services/panelcard.service';


import { MAT_BOTTOM_SHEET_DATA } from '@angular/material';
import { SessionStorageService } from 'ngx-webstorage';
import { FormGroup, FormControl } from '@angular/forms';
import { PanelService} from '../../common/services/panel.service';
import {AccountService} from '../../common/services/account.service';
import {ModalService} from '../../common/services/modal.service';
import {environment} from "../../../environments/environment";
import {MatDialog} from '@angular/material/dialog';



@Component({
  selector: 'app-league-share',
  templateUrl: './league-share.component.html',
  styleUrls: ['./league-share.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class LeagueShareComponent implements OnInit, OnDestroy {
  @Input() drawerRef: any;

  appDrawer: any;
  windowScrolling: any;

  s1 = null;

  siteurl = environment.service.trackithubapi;

  constructor(
    private router: Router,
    private modalService: ModalService,
    private ref: ChangeDetectorRef,
    private panelservice: PanelService,
    private sessionSt: SessionStorageService,
    public _router: Router,
    private SearchAddress: PanelCardService,
    private accountService: AccountService,
    public dialog: MatDialog,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
  ) {

  }

  form: FormGroup = new FormGroup({
    currency: new FormControl(),
  });

  closePanel() {



      if( this.data.appDrawer === undefined) {
        this.drawerRef.close();

        if (this.sessionSt.retrieve('appDrawer') !== '') {
          this.appDrawer = this.sessionSt.retrieve('appDrawer');
          this.windowScrolling = this.sessionSt.retrieve('windowScrolling');
          this.appDrawer.close();
          this.windowScrolling.enable();
        }
      } else {

        this.data.appDrawer.dismiss();
        this.data.windowScrolling.enable();
      }
  }






  ngOnDestroy() {
    if (this.s1 != null) {
      this.s1.complete();
    }
  }









  ngOnInit() {

    this.s1 = this.accountService.cast.subscribe(datax => {

    });
  }
}
