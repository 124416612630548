import {ChangeDetectionStrategy, Component, Input, TemplateRef, EventEmitter, Output} from '@angular/core';
import {Router} from "@angular/router";
import {ToastService} from "../../toast";
import {DashboardCreateGroupComponent} from "../../../dashboard";
import {BottomSheetService} from "../../services/bottom-sheet.service";

@Component({
  selector: 'app-dashboard-card',
  templateUrl: './dashboard-card.component.html',
  styleUrls: ['./dashboard-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class DashboardCardComponent  {

  constructor(private router: Router, private toastService: ToastService, private bottomSheetService: BottomSheetService) {}


  @Input() cardClass: string;
  @Input() heading: string;
  @Input() cardTitle: TemplateRef<any>;
  @Input() cardHeader: TemplateRef<any>;
  @Input() cardBody: TemplateRef<any>;
  @Input() cardFooter: TemplateRef<any>;
  @Input() isDark;
  @Input() isDisabled;
  @Input() isMembership;
  @Input() group_id;
  @Input() is_player: string;
  @Input() subscriber: boolean;
  @Output('overviedwSub') overviewSub: EventEmitter<any> = new EventEmitter();

  disableclick(clickDisabled, group_id, ismembership){
    if (clickDisabled && this.subscriber === true) {
      if (group_id > 0) {
        if(ismembership == 1){
          this.router.navigate(['/dashboard/frame/dues'], {queryParams: {'group_id': group_id}});
        } else {
          this.toastService.show({
            text: 'Please wait for an admin to accept your request',
            type: 'warn',
            buttonText: '',
          });
        }
      } else {
        if (this.is_player === 'player') {
          this.toastService.show({
            text: 'Finding a group to join is easy! Utilize the search engine to explore thousands of clubs and facilities.',
            type: 'success',
            buttonText: '',
          });
          this.router.navigate(['/dashboard/map'], {queryParams: {'group_id': group_id}});
        } else {
          this.bottomSheetService.openBottomSheetWithData(DashboardCreateGroupComponent, 'DashboardCreateGroupComponent',{'id': 0});
        }
      }
    } else {
      if (this.subscriber === false) {
        this.router.navigate(['/dashboard/overview'], {queryParams: {'trigger': 'showsub'}}).then(() => {
          window.location.reload();
        });
      }
    }
  }
}
