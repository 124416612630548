import {Injectable, OnDestroy} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Mygroups } from '../interfaces/mygroups';

@Injectable()

export class MygroupsService implements OnDestroy {

  timer: any;
  test = true;

  private mygroups = new BehaviorSubject<Mygroups>({
    approved: [{
      name: null,
      id: null,
      value: [{
        id: null,
        name: null,
        resp: null,
        partof: null,
        admin: null,
        membership: null,
        approved: null,
        rrcount: null,
        sscount: null
      }]
    }],
    notapproved: [{
      name: null,
      id: null,
      value: [{
        id: null,
        name: null,
        resp: null,
        partof: null,
        admin: null,
        membership: null,
        approved: null,
        rrcount: null,
        sscount: null
      }]
    }],
    archived: [{
      name: null,
      id: null,
      value: [{
        id: null,
        name: null,
        resp: null,
        partof: null,
        admin: null,
        membership: null,
        approved: null,
        rrcount: null,
        sscount: null
      }]
    }]
  });

  cast = this.mygroups.asObservable();
  approved: any;
  notapproved: any;
  archived: any;

  oapproved: any;
  onotapproved: any;
  oarchived: any;

  constructor(private http: HttpClient) {}

  killtimer(){
    clearInterval(this.timer);
  }

  load(profile_id, group_id) {

    this.timer = setInterval(() => {
      if(group_id != null) {
        this.http.get<Mygroups>(environment.service.trackithubapi + '/api/inside/mygroups?profile_id=' + profile_id + '&group_id=' + group_id).subscribe((data: any) => {
          this.approved = data.approved;
          this.notapproved = data.notapproved;

          if (this.oapproved !== data.approved.length){
            this.test = true;
            this.oapproved = data.approved.length;
          }

          if (this.onotapproved !== data.notapproved.length){
            this.test = true;
            this.onotapproved = data.notapproved.length;
          }

          if (this.oarchived !== data.archived.length){
            this.test = true;
            this.oarchived = data.archived.length;
          }

          if(this.test == true) {
            this.mygroups.next({
              approved: data.approved,
              notapproved: data.notapproved,
              archived: data.archived
            });
            this.test = false;
          }
        });
      } else {
        this.http.get<Mygroups>(environment.service.trackithubapi + '/api/inside/mygroups?profile_id=' + profile_id + '&group_id=').subscribe((data: any) => {
          this.approved = data.approved;
          this.notapproved = data.notapproved;

          if (this.oapproved !== data.approved.length){
            this.test = true;
            this.oapproved = data.approved.length;
          }

          if (this.onotapproved !== data.notapproved.length){
            this.test = true;
            this.onotapproved = data.notapproved.length;
          }

          if (this.oarchived !== data.archived.length){
            this.test = true;
            this.oarchived = data.archived.length;
          }

          if(this.test == true) {
            this.mygroups.next({
              approved: data.approved,
              notapproved: data.notapproved,
              archived: data.archived
            });
            this.test = false;
          }

        });
      }
    }, 1000);
  }

  ngOnDestroy() {
    clearInterval(this.timer);
  }
}

