import {ChangeDetectionStrategy, Component, Input, TemplateRef} from '@angular/core';
import {ModalService} from '../../common/services/modal.service';
import { Router, RouterModule } from '@angular/router';
import { DashboardEventVideoComponent } from '../dashboard-event-video/dashboard-event-video.component';
import { Account } from 'src/app/common/interfaces/account';
import { BottomSheetService } from 'src/app/common/services/bottom-sheet.service';
import { DashboardCreateGroupComponent } from '../dashboard-create-group/dashboard-create-group.component';
import { SessionStorageService} from 'ngx-webstorage';

@Component({
    selector: 'app-dashboard-panel',
    templateUrl: './dashboard-panel.component.html',
    styleUrls: ['./dashboard-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class DashboardPanelComponent {
  @Input() dashboardHeaderActions: TemplateRef<any>;
  @Input() panelTitle: string;
  @Input() scroller = false;
  @Input() createPPLButton: boolean;
  @Input() currentProfile: Account["current_profile"];

  constructor(      private sessionSt: SessionStorageService,private modalService: ModalService, private router: Router, private bottomSheetService: BottomSheetService) {
  }

  openvideo(){
    if(window.innerWidth < 590) {
      this.modalService.openDialog(DashboardEventVideoComponent, window.innerWidth + 'px', window.innerWidth + 'px', {});

    } else {

      this.modalService.openDialog(DashboardEventVideoComponent, 'auto', 'auto', {});
    }
  }

  openMap(){
    this.router.navigate(["/dashboard/map/Clubs"]);
  }
  createGroup() {
    this.bottomSheetService.openBottomSheetWithData(DashboardCreateGroupComponent, 'DashboardCreateGroupComponent',{'id': 0});
  }
}
function Account(currentProfile: any, Account: any) {
  throw new Error('Function not implemented.');
}

