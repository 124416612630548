import {Component, Inject, ViewChild} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';


@Component({
  selector: 'app-reportpost-dialog',
  templateUrl: './reportpost-dialog.component.html',
  styleUrls: ['./reportpost-dialog.component.scss']
})
export class ReportpostDialogComponent  {

  postPlaceholder: string = "Please describe the issue with this content?";
  postOpen: boolean = true;
  postValue: string;
  post_id: number;
  post_name: string;
  post: string;
  date: string;
  location_name: string;
  image: string;

  @ViewChild('postField', {static: false}) postField;

  constructor(public dialogRef: MatDialogRef<ReportpostDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: {
    data: any}) {




    this.post_id = data.data.id;
    this.post_name = data.data.name;
    this.post = data.data.post;
    this.date = data.data.date;
    this.location_name = data.data.location_name;
    this.image = data.data.image;
  }

  processPost(){
    this.dialogRef.close({report: {comment: this.postValue, id: this.post_id, post_name: this.post_name, post: this.post, date: this.date, location_name: this.location_name, image: this.image}});
  }

  onNoClick(): void {
    this.dialogRef.close({report: null});
  }
}
