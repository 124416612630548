import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material';
import { AccountService } from '../../common/services/account.service';
import { Account } from '../../common/interfaces/account';
import { Data } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { trigger, transition, style, animate } from '@angular/animations';
import {HttpClient} from "@angular/common/http";
import {BottomSheetService} from "../../common/services/bottom-sheet.service";
import {MatDialog} from '@angular/material/dialog';
import {ToastService} from "../../common/toast";
import {ChatService} from "../../common/services/chat.service";


interface Avatar {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-dashboard-news',
  templateUrl: './dashboard-news.component.html',
  styleUrls: ['./dashboard-news.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  animations: [
    trigger('grow', [
      transition('void <=> *', []),
      transition('* <=> *', [
        style({height: '{{startHeight}}px', opacity: 0}),
        animate('.5s ease'),
      ], {params: {startHeight: 0}})
    ])
  ]
})
export class DashboardNewsComponent implements OnInit {

  profile_id: Account["current_profile"]["profile_id"];
  subscription: Subscription;
  friendSubscription: Subscription;
  account: Account;
  avatarImg: string;
  avatarPlaceholder: string;

  name: string;
  rating: string;
  friends: any;
  friendCount: number;
  groupCount: number;
  groups: {name:string, avatar:string, avataricon:string, id:string, members:string}[] = [];
  current_profile: number;
  siteUrl = environment.service.trackithubapi + '/';
  is_friend: number;
  is_owner: number;
  email: string;

  s1 = null;

  avatars: Avatar[] = [
    {value: 'club', viewValue: 'Club'},
    {value: 'grin', viewValue: 'Grin'},
    {value: 'slight-smile', viewValue: 'Slight Smile'},
    {value: 'smile', viewValue: 'Smile'},
    {value: 'smiling-face-eyes', viewValue: 'Smiling Face Eyes'},
    {value: 'smirking-face', viewValue: 'Smirking Face'},
    {value: 'tilted-grin', viewValue: 'Tilted-grin'},
    {value: 'winking-grin', viewValue: 'Winking-Grin'},
    {value: 'worried-smile', viewValue: 'Worried-Smile'},
  ];

  constructor(
    private chatService: ChatService,   private bottomSheetService: BottomSheetService,private changeDetectorRef: ChangeDetectorRef, private http: HttpClient, bottomSheetRef: MatBottomSheetRef<DashboardNewsComponent>, private accountService: AccountService, @Inject(MAT_BOTTOM_SHEET_DATA) public data: Data,  public dialog: MatDialog,private toastService: ToastService) {

    this.name = '';
    this.rating = '0.0';
    this.groupCount = 0;
    this.friendCount = 0;
    this.current_profile = 0;
    this.is_friend = 0;
    this.is_owner = 0;
    this.email = '';





      this.profile_id = 1011;








    this.subscription = this.accountService.cast.subscribe(data => {
      this.account = data;
      this.current_profile = this.account.current_profile.profile_id;
      if(this.profile_id == this.current_profile){
        this.is_owner = 1;
      } else {
        this.is_owner = 0;
      }
    });

  }

  ngOnInit() {
  }



  openChat(){
        this.chatService.openchatuser({
          profile_id: this.profile_id,
          name: this.name,
          avatarImg: this.avatarImg,
          avatarPlaceholder: this.avatarPlaceholder,
          email: this.email
        });
  }
}
