import {AgmCoreModule} from '@agm/core';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MAT_BOTTOM_SHEET_DATA, MatCardModule} from '@angular/material';
import {MaterialModule} from '../../material.module';
import {DynamicFormModule} from '../dynamic-form/dynamic-form.module';
import {DashboardCardModule} from '../common/dashboard/dashboard-card.module';
import {LazyLoadDirective} from '../common/directives/lazy-load.directive';
import {SlidePanelComponent} from '../slide-panel/slide-panel.component';
import {StepperModule} from '../stepper/stepper.module';
import {AboutComponent} from './about/about.component';
import {TruncatePipe} from '../common/pipes/truncate.pipe';
import {SearchAddressService} from '../common/services/searchaddress.service';
import {PanelCardService} from '../common/services/panelcard.service';
import {RetrioeveaddressService} from '../common/services/retrioeveaddress.service';
import {RouterModule} from '@angular/router';
import {TermRegisterComponent} from './term-register/term-register.component';
import { NgxMaskModule } from 'ngx-mask';
import {
  BannerAdComponent,
  HowWorksComponent,
  PickleballPlaceDetailsComponent,
  HeroComponent,
  DonationPortalComponent,
  HomeComponent,
  GroupSelectComponent,
  CreateLocationComponent,
  BlogPostsComponent,
  TestimonialComponent,
  AffiliationComponent,
  LeagueShareComponent,
  LeagueEventviewComponent,
  LeagueRegisterComponent,
  LeagueRegisterTeamComponent,
  LeagueRegisterTeammateComponent,
  LeagueRegisterTeamInsideComponent
} from './index';
import { PickleballTimetableGridComponent } from './pickleball-place-details/pickleball-timetable-grid/pickleball-timetable-grid.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AccountSettingsModule} from '../settings/account-settings/account-settings.module';
import {HorizontalscrollModule} from "./horizontal-infinite-scroll/horizontalscroll.module";
import {NgxPayPalModule} from "ngx-paypal";
import {VerifyTermsModalComponent} from "./verifyterms/verifyterms.component";


@NgModule({
  declarations: [
    VerifyTermsModalComponent,
    LeagueRegisterComponent,
    LeagueRegisterTeamComponent,
    LeagueRegisterTeammateComponent,
    LeagueRegisterTeamInsideComponent,
    HowWorksComponent,
    PickleballPlaceDetailsComponent,
    LeagueShareComponent,
    LeagueEventviewComponent,
    SlidePanelComponent,
    BannerAdComponent,
    HeroComponent,
    AboutComponent,
    BlogPostsComponent,
    TruncatePipe,
    CreateLocationComponent,
    GroupSelectComponent,
    HomeComponent,
    DonationPortalComponent,
    TestimonialComponent,
    AffiliationComponent,
    PickleballTimetableGridComponent,
    LazyLoadDirective,
    TermRegisterComponent
  ],
    imports: [
        HorizontalscrollModule,
        RouterModule,
        CommonModule,
        MaterialModule,
        ScrollingModule,
        DynamicFormModule,
        NgxMaskModule.forRoot().ngModule,
        AgmCoreModule.forRoot({
            apiKey: `AIzaSyAfRESSHmts4lPb0h-f74y4spV0L5P1jFA`
        }),
        MatCardModule,
        StepperModule,
        DashboardCardModule,
        FormsModule,
        AccountSettingsModule,
        ReactiveFormsModule,
        NgxPayPalModule
    ], providers: [
    SearchAddressService,
    PanelCardService,
    RetrioeveaddressService,
    { provide: MAT_BOTTOM_SHEET_DATA, useValue: {} }
  ],
    exports: [
      VerifyTermsModalComponent,
      LeagueRegisterComponent,
      LeagueRegisterTeamComponent,
      LeagueRegisterTeammateComponent,
      LeagueRegisterTeamInsideComponent,
        HowWorksComponent,
        PickleballPlaceDetailsComponent,
        LeagueShareComponent,
      LeagueEventviewComponent,
        SlidePanelComponent,
        BannerAdComponent,
        HeroComponent,
        BlogPostsComponent,
    ],
  entryComponents: [
    VerifyTermsModalComponent,
    TermRegisterComponent,
    LeagueRegisterComponent,
    LeagueRegisterTeamComponent,
    LeagueRegisterTeammateComponent,
    LeagueRegisterTeamInsideComponent,
    LeagueShareComponent,
    LeagueEventviewComponent,
    PickleballPlaceDetailsComponent,
    CreateLocationComponent,
    GroupSelectComponent
  ]
})
export class FrontendModule {}
