import {Component, Input, AfterViewInit, TemplateRef, ChangeDetectionStrategy} from '@angular/core';

@Component({
  selector: 'app-dashboard-panel-header',
  templateUrl: './dashboard-panel-header.component.html',
  styleUrls: ['./dashboard-panel-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class DashboardPanelHeaderComponent implements AfterViewInit {
  @Input() dashboardHeaderActions: TemplateRef<any>;
  @Input() dashboardPanelHead: TemplateRef<any>;
  @Input() panelTitle: string;
  constructor() { }

  ngAfterViewInit() {}

}
