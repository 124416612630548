import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatTabsModule} from '@angular/material/tabs';
import {PrivacyModule} from '../privacy/privacy.module';
import {DashboardCardModule} from '../../common/dashboard/dashboard-card.module';
import {DynamicFormModule} from '../../dynamic-form/dynamic-form.module';
import {MaterialModule} from '../../../material.module';
import { ReactiveFormsModule } from '@angular/forms';

import {
  AccountSettingsComponent,
  MemberManagementSettingsComponent,
  PasswordSettingsComponent,
  PaymentSettingsComponent,
  PremiumFeatureSettingsComponent,
  UserDetailsEditSettingsComponent,
  SubscriptionSettingsComponent, BottomSheetHeadingComponent, DialogSettingsConfirm
} from './index';
import {CreateProfileMenuComponent} from '../../dashboard';
import {NgxMaskModule} from 'ngx-mask';
import {CreateLocationDirective} from '../../common/directives/create-location.directive';

@NgModule({
  declarations: [
    AccountSettingsComponent,
    MemberManagementSettingsComponent,
    PasswordSettingsComponent,
    PaymentSettingsComponent,
    PremiumFeatureSettingsComponent,
    UserDetailsEditSettingsComponent,
    SubscriptionSettingsComponent,
    DialogSettingsConfirm,
    CreateProfileMenuComponent,
    CreateLocationDirective,
    BottomSheetHeadingComponent,
  ],
    imports: [
        ReactiveFormsModule,
        CommonModule,
        MatTabsModule,
        PrivacyModule,
        DashboardCardModule,
        DynamicFormModule,
        MaterialModule,
        NgxMaskModule,
    ],
  entryComponents: [
    AccountSettingsComponent,
    UserDetailsEditSettingsComponent,
    DialogSettingsConfirm
  ],
  exports: [
    CreateProfileMenuComponent,
    CreateLocationDirective,
    BottomSheetHeadingComponent,
  ]
})
export class AccountSettingsModule {
}
