import {ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccountService } from '../../common/services/account.service';
import { Account } from '../../common/interfaces/account';
import { DomSanitizer } from '@angular/platform-browser';
import {Subscription} from "rxjs";
import { LoaderOverlayService } from '../../common/services/loader-overlay.service';
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-dashboard-standings',
  templateUrl: './dashboard-standings.component.html',
  styles: [`
    :host {
      width: max-content;
      position: relative;
      display: block;
      overflow: hidden;
    }
    iframe {
      overflow: hidden;
      width: 1100px;
      height: 100vh;
    }
  `],
  changeDetection: ChangeDetectionStrategy.Default
})

export class DashboardStandingsComponent  implements OnDestroy, OnInit {

  @ViewChild('framer', {static: false}) framer: ElementRef;

  account: Account;
  returnurl = null;
  username = null;
  epassw = null;
  url = null;
  safe = false;
  param = null;

  type = null;
  group_id = null;

  private suba: Subscription;

  constructor(private LoaderOverlayService: LoaderOverlayService, private route: ActivatedRoute, private accountService: AccountService, private domSanitizer: DomSanitizer) {
    this.url = '#';
  }



  ngOnInit() {

    this.suba = this.accountService.cast.subscribe(data => {
      this.account = data;
      if(this.account.id !== null) {
        if(this.suba !== undefined) {
          this.suba.unsubscribe();
          this.type = this.route.snapshot.queryParamMap.get('type');
          if (this.route.snapshot.queryParamMap.get('group_id') !== null) {
            this.group_id = this.route.snapshot.queryParamMap.get('group_id');
          } else {
            // assign the first group id you find
            let grid = data.current_profile.groups.pop();
            this.group_id = '';
          }
          this.reloadiframe();
        }
      }
    });



    this.type = this.route.snapshot.queryParamMap.get('type');
    this.group_id = this.route.snapshot.queryParamMap.get('group_id');




  }


  reloadiframe() {



    if(this.LoaderOverlayService.overlayRef == null) {

      setTimeout(() => {
        this.killoverlay();

      }, 3000);

      this.LoaderOverlayService.show();
    }

    if (this.group_id !== undefined) {
      this.returnurl = environment.service.trackithubapi + '/index.php?option=com_shootout&view=handicaps&type=' + this.type + '&hub_id=' + this.group_id + '&t=t';
    }

    if (this.group_id === undefined) {
      this.returnurl = environment.service.trackithubapi + '/index.php?option=com_shootout&view=handicaps&type=' + this.type;
    }



    this.epassw = this.account.current_profile.seckey;
    this.username = this.account.current_profile.username;
    this.returnurl = btoa(this.returnurl);
    this.url = this.domSanitizer.bypassSecurityTrustResourceUrl(environment.service.trackithubapi + '/index.php?user=' + this.username + '&epassw=' + this.epassw + '&redirect=' + this.returnurl);




    if (this.username !== null && this.username !== null && this.returnurl !== null) {
      this.safe = true;
    } else {
      this.safe = false;
    }
  }

  killoverlay(){
    if(this.LoaderOverlayService.overlayRef != null) {
      this.LoaderOverlayService.hide();
    }
  }

  ngOnDestroy() {
    this.killoverlay();
    this.LoaderOverlayService.overlayRef = null;
    if (this.suba !== undefined) {
      this.suba.unsubscribe();
    }
  }

}
