import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { SessionStorageService } from 'ngx-webstorage';
import { environment } from '../../../environments/environment';
import { Account } from '../interfaces/account';
import {AccountService} from './account.service';
import {Router} from '@angular/router';
import { LoaderOverlayService } from '../../common/services/loader-overlay.service';

export interface AddressLocationInterface {
  address_field: any;
  address_lat: any;
  address_lng: any;
  results: any;
  card: any;
  location: any;
  location_title: any;
}

@Injectable()

export class SearchAddressService {

  private AddressLocation = new BehaviorSubject<AddressLocationInterface>({
    address_field: null,
    address_lat: null,
    address_lng: null,
    results: null,
    card: null,
    location_title: null,
    location: null,
  });

  cast = this.AddressLocation.asObservable();

  constructor(private http: HttpClient, private sessionSt: SessionStorageService,       private accountService: AccountService,
              private router: Router, private LoaderOverlayService: LoaderOverlayService ) {}

  initialize() {
      this.AddressLocation.next({
        address_field: this.sessionSt.retrieve('address_field'),
        address_lat: this.sessionSt.retrieve('address_lat'),
        address_lng: this.sessionSt.retrieve('address_lng'),
        results: this.sessionSt.retrieve('markers'),
        card: null,
        location_title: null,
        location: null,
      });

  }

  forcesearch() {
    let add = this.sessionSt.retrieve('address_field');
    let lat = this.sessionSt.retrieve('address_lat');
    let lng = this.sessionSt.retrieve('address_lng');

    if(lat === null && lng === null){
      lat = parseFloat(this.sessionSt.retrieve('current_lat'));
      lng = parseFloat(this.sessionSt.retrieve('current_lng'));
    }

    if (lat !== null) {

      if(this.sessionSt.retrieve('play_type') === 'Public') {
        this.LoaderOverlayService.show();
        this.http.get<[]>(environment.service.trackithubapi + '/api/outside/placespublicdata?lat=' + lat + '&lng=' + lng).subscribe((data: any) => {
          this.AddressLocation.next({
            address_field: add,
            address_lat: lat,
            address_lng: lng,
            results: data,
            card: null,
            location: null,
            location_title: null,
          });
          this.sessionSt.store('markers', data);
          this.LoaderOverlayService.hide();
        });
      } else {
        this.LoaderOverlayService.show();
        this.http.get<Account>(environment.service.trackithubapi + '/api/outside/clubscloseby?lng=' + lng + '&lat=' + lat).subscribe((data: any) => {
          this.AddressLocation.next({
            address_field: add,
            address_lat: lat,
            address_lng: lng,
            results: data.result,
            card: null,
            location: null,
            location_title: null,
          });
          this.sessionSt.store('markers', data.result);
          this.LoaderOverlayService.hide();
        });
      }
    } else {
      this.AddressLocation.next({
        address_field: add,
        address_lat: lat,
        address_lng: lng,
        results: null,
        card: null,
        location: null,
        location_title: null,
      });
      this.LoaderOverlayService.hide();
    }

  }

  showclub(data, lat, lon) {
    this.AddressLocation.next({
        address_field: null,
        address_lat: lat,
        address_lng: lon,
        results: data.result,
        card: null,
        location: null,
        location_title: null,
      });
      this.sessionSt.store('markers', data.result);
    this.router.navigate(['/dashboard/map'], {queryParams: {}});
  }

  update(lat, lng, add) {
    if (lat !== null) {
      if(this.sessionSt.retrieve('play_type') === 'Public') {
        this.http.get<[]>(environment.service.trackithubapi + '/api/outside/placespublicdata?lat=' + lat + '&lng=' + lng).subscribe((data: any) => {
          this.AddressLocation.next({
            address_field: add,
            address_lat: lat,
            address_lng: lng,
            results: data,
            card: null,
            location: null,
            location_title: null,
          });
          this.sessionSt.store('markers', data);
        });
      } else {
        this.http.get<Account>(environment.service.trackithubapi + '/api/outside/clubscloseby?lng=' + lng + '&lat=' + lat).subscribe((data: any) => {

          this.AddressLocation.next({
            address_field: add,
            address_lat: lat,
            address_lng: lng,
            results: data.result,
            card: null,
            location: null,
            location_title: null,
          });
          this.sessionSt.store('markers', data.result);
        });
      }
    } else {
      this.AddressLocation.next({
        address_field: add,
        address_lat: lat,
        address_lng: lng,
        results: null,
        card: null,
        location: null,
        location_title: null,
      });
    }
    this.sessionSt.store('address_field', add);
    this.sessionSt.store('address_lat', lat);
    this.sessionSt.store('address_lng', lng);
  }


}
