import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MaterialModule} from '../../material.module';

import { StepperVerticalComponent } from './stepper-vertical/stepper-vertical.component';
import { StepperResponsiveComponent} from './stepper-responsive/stepper-responsive.component';

@NgModule({
  declarations: [
    StepperVerticalComponent,
    StepperResponsiveComponent
  ],
  imports: [
    CommonModule,
    MaterialModule
  ],
  exports: [
    StepperVerticalComponent,
    StepperResponsiveComponent
  ],
  entryComponents: [
    StepperVerticalComponent,
    StepperResponsiveComponent
  ]
})
export class StepperModule { }
