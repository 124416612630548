import { Component, Inject, OnInit } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {FormControl, FormGroup} from "@angular/forms";



@Component({
  selector: 'app-editpicture-dialog',
  templateUrl: './editpicture-dialog.component.html',
  styleUrls: ['./editpicture-dialog.component.scss']
})
export class EditpictureDialogComponent implements OnInit {

  picture: any;
  myForm: any;

  constructor(public dialogRef: MatDialogRef<EditpictureDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: {}) {
    this.picture = data;
   }

  ngOnInit() {
    this.myForm = new FormGroup({
      picturetitle: new FormControl()
    });

    this.myForm.controls.picturetitle.setValue(this.picture.picture.title);

  }



  onNoClick(): void {
    this.dialogRef.close({});
  }

  onYesClick(): void {
    this.dialogRef.close({'status': 'update', 'picture_id': this.picture.picture.id, 'title': this.myForm.controls.picturetitle.value});
  }

}
