import {Component, AfterViewInit, ViewChild, OnInit, OnDestroy, ChangeDetectionStrategy} from '@angular/core';
import { Validators } from '@angular/forms';
import { FieldConfig } from '../../dynamic-form/models/field-config.interface';
import {
  DynamicFormInfoComponent,
  DynamicFormloginComponent,
  DynamicFormcontactComponent,
  DynamicFormNameComponent,
  DynamicFormRateComponent } from '../../dynamic-form/containers';

import { RecordsService } from '../../common/services/records.service';
import { Backplane } from '../../common/services/backplane.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import {MatBottomSheet, MatDialog, MatDialogConfig} from '@angular/material';
import { TermsDialogComponent } from '../../terms-dialog/terms-dialog.component';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ClubPplWelcomeDialogComponent} from '../../club-ppl-welcome-dialog/club-ppl-welcome-dialog.component';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material';
import {PickelballRatingComponent} from '../../pickelball-rating/pickelball-rating.component';
@Component({
  selector: 'app-register-league',
  templateUrl: './register-league.component.html',
  styleUrls: ['./register-league.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class RegisterLeagueComponent implements AfterViewInit, OnInit, OnDestroy {
 pwdPattern: any = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{8,25}$/;
    records = {};
    message = '';
    actionButtonLabel = '';
    action = false;
    setAutoHide = true;
    autoHide = 2000;
    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'bottom';
    _loading = false;
    config = new MatSnackBarConfig();
    loaderMessage = 'Loading...';
    public statelist;
    public citylist;
    public title;
    public type;
    public IsClub = false;
    public IsPpl = false;
    public IsPlayer = false;
    public skipfield = false;
    public contactarray;
    public isMobile = false;
    public oEmail = null;
    public mainObj = [];
    public countrylist;

    public nameFormValid = true;
    public loginFormValid = true;
    public contactFormValid = true;
    public rateFormValid = true;
    public finalValid = true;
    public loginerror_valid = false;
    public loginerror;



    constructor(
        public snackBar: MatSnackBar,
        private http: HttpClient,
        private validationService: RecordsService,
        private getters: Backplane,
        private LoginService: RecordsService,
        public dialog: MatDialog,
        public wdialog: MatDialog,
        private route: ActivatedRoute,
        private bottomSheet: MatBottomSheet,
    ) {
            this.route.paramMap.subscribe((params: ParamMap) => {
                const type = params.get('id');
                this.type = type;
                if (this.type === 'club') {
                    this.title = 'Club Registration';
                    this.IsClub = true;
                }

                if (this.type === 'ppl') {
                    this.title = 'Premier Pickleball League Registration';
                    this.IsPpl = true;
                }

                if (this.type === 'player') {
                    this.title = 'Player Registration';
                    this.IsPlayer = true;
                }
            });

        }

    clubInfo: FieldConfig[] = [
        {
            type: 'input',
            label: '',
            name: 'nameofclub',
            placeholder: 'Club Name',
            // Check against the database to see if the name exists
            validation: [Validators.minLength(8)],
            required: true,
        }
    ];


    pplInfo: FieldConfig[] = [
        {
            type: 'input',
            label: 'e.g. Southwest Slammers or Durango Park Dinkers',
            name: 'nameofppl',
            placeholder: 'Name of Organization',
            // Check against the database to see if the name exists
            validation: [Validators.required, Validators.minLength(8), Validators.maxLength(21)],
            required: true,
            maxlength: 21
        },
        {
            type: 'input',
            label: '(555) 555-5555',
            mask: '(000) 000-0000',
            name: 'phonenumber',
            placeholder: 'Phone Number',
            validation: [Validators.required],
            required: true
        },
        {
            type: 'country',
            label: 'Country',
            name: 'country',
            placeholder: 'Country',
            color: 'primary',
            validation: [Validators.required],
            required: true
        },
        {
            type: 'state',
            label: 'State/Province',
            name: 'state',
            placeholder: 'State/Province',
            color: 'primary',
            validation: [Validators.required],
            required: true
        },
        {
            type: 'input',
            label: 'City',
            name: 'city',
            placeholder: 'City',
            validation: [Validators.required, Validators.minLength(2)],
            required: true
        },
        {
            type: 'input',
            label: 'Zip/Postal Code',
            name: 'zipcode',
            placeholder: 'Zip/Postal Code',
            validation: [Validators.required, Validators.minLength(2)],
            required: true
        },
        {
            type: 'checkbox',
            label: 'Accept Terms and Conditions',
            name: 'termsandconditions',
            value: '',
            validation: [Validators.required, Validators.requiredTrue],
            required: true
        },
    ];

    clubcontactInfo: FieldConfig[] = [
        {
            type: 'input',
            label: 'Club Admin - First Name',
            name: 'firstname',
            placeholder: 'Club Admin - First Name',
            validation: [Validators.required, Validators.minLength(2)],
            required: true
        },
        {
            type: 'input',
            label: 'Club Admin - Last Name',
            name: 'lastname',
            placeholder: 'Club Admin - Last Name',
            validation: [Validators.required, Validators.minLength(2)],
            required: true
        },
        {
            type: 'input',
            label: '(555) 555-5555',
            mask: '(000) 000-0000',
            name: 'phonenumber',
            placeholder: 'Phone Number',
            validation: [Validators.required],
            required: true
        },
        {
            type: 'country',
            label: 'Country',
            name: 'country',
            placeholder: 'Country',
            color: 'primary',
            validation: [Validators.required],
            required: true
        },
        {
            type: 'state',
            label: 'State/Province',
            name: 'state',
            placeholder: 'State/Province',
            color: 'primary',
            validation: [Validators.required],
            required: true
        },
        {
            type: 'input',
            label: 'City',
            name: 'city',
            placeholder: 'City',
            validation: [Validators.required, Validators.minLength(2)],
            required: true
        },
        {
            type: 'input',
            label: 'Zip/Postal Code',
            name: 'zipcode',
            placeholder: 'Zip/Postal Code',
            validation: [Validators.required, Validators.minLength(2)],
            required: true
        },
        {
            type: 'checkbox',
            label: 'Accept Terms and Conditions',
            name: 'termsandconditions',
            value: '',
            validation: [Validators.required, Validators.requiredTrue],
            required: true
        },
    ];

    rateForm: FieldConfig[] = [
         {
            type: 'select',
            label: 'Pickleball Skill Rating',
            name: 'pickleballrating',
            options: ['No Rating', '1.0', '1.5', '2.0', '2.5', '3.0', '3.5', '4.0', '4.5', '5.0'],
            placeholder: 'Select an option',
            validation: [Validators.required],
            required: true
        },
    ];

    @ViewChild(DynamicFormNameComponent, { static: false }) nform: DynamicFormNameComponent;
    @ViewChild(DynamicFormInfoComponent, { static: false }) infoform: DynamicFormInfoComponent;
    @ViewChild(DynamicFormloginComponent, { static: false }) lform: DynamicFormloginComponent;
    @ViewChild(DynamicFormcontactComponent, { static: false }) cform: DynamicFormcontactComponent;
    @ViewChild(DynamicFormRateComponent, { static: false }) rform: DynamicFormRateComponent;
    openBottomSheet() {
    this.bottomSheet.open(PickelballRatingComponent);
  }

    finishReg() {

        this._loading = true;
        this.finalValid = true;

        window.scroll(0, 0);

        interface sModal {
            type: string;
            nameofclub: string;
            nameofppl: string;
            email: string;
            password: string;
            firstname: string;
            lastname: string;
            phonenumber: string;
            gender: string;
            birthyear: string;
            country: string;
            state: string;
            city: string;
            zipcode: string;
            termsandconditions: string;
            rating: string;
            packages: string;
        }

        const storage = <sModal>{};
        storage.type = this.type;

        if (this.type === 'club') {
            storage.nameofclub = this.nform.nform.controls.nameofclub.value;
            storage.email = this.lform.lform.controls.email.value;
            storage.password = this.lform.lform.controls.password.value;
            storage.firstname = this.cform.cform.controls.firstname.value;
            storage.lastname = this.cform.cform.controls.lastname.value;
            storage.phonenumber = this.cform.cform.controls.phonenumber.value;
            storage.country = this.cform.cform.controls.country.value;
            storage.state = this.cform.cform.controls.state.value;
            storage.city = this.cform.cform.controls.city.value;
            storage.zipcode = this.cform.cform.controls.zipcode.value;
            storage.termsandconditions = this.cform.cform.controls.termsandconditions.value;
        }

        if (this.type === 'ppl') {
            storage.nameofppl = this.nform.nform.controls.nameofppl.value;
            storage.email = this.lform.lform.controls.email.value;
            storage.password = this.lform.lform.controls.password.value;
            storage.firstname = this.cform.cform.controls.firstname.value;
            storage.lastname = this.cform.cform.controls.lastname.value;
            storage.phonenumber = this.cform.cform.controls.phonenumber.value;
            storage.country = this.cform.cform.controls.country.value;
            storage.state = this.cform.cform.controls.state.value;
            storage.city = this.cform.cform.controls.city.value;
            storage.zipcode = this.cform.cform.controls.zipcode.value;
            storage.termsandconditions = this.cform.cform.controls.termsandconditions.value;
            storage.packages = this.infoform.infoform.controls.package.value;

            if (storage.packages === 'Basic') {
                storage.packages = 'basic';
            }

            if (storage.packages === 'Pro') {
                storage.packages = 'pro';
            }

        }

        if (this.type === 'player') {

            storage.email = this.lform.lform.controls.email.value;
            storage.password = this.lform.lform.controls.password.value;
            storage.firstname = this.cform.cform.controls.firstname.value;
            storage.lastname = this.cform.cform.controls.lastname.value;
            storage.gender = this.cform.cform.controls.gender.value;
            storage.birthyear = this.cform.cform.controls.birthyear.value;
            storage.phonenumber = this.cform.cform.controls.phonenumber.value;
            storage.country = this.cform.cform.controls.country.value;
            storage.state = this.cform.cform.controls.state.value;
            storage.city = this.cform.cform.controls.city.value;
            storage.zipcode = this.cform.cform.controls.zipcode.value;
            storage.termsandconditions = this.cform.cform.controls.termsandconditions.value;
            storage.rating = this.rform.rform.controls.pickleballrating.value;

            if (storage.gender === 'Male' ) {
                storage.gender = 'COM_COMMUNITY_MALE';
            } else {
                storage.gender = 'COM_COMMUNITY_FEMALE';
            }

        }

        this.records = this.LoginService.createccount(storage)
            .subscribe(data => {
                this.records = data;
                if (this.records['result'] === true) {
                    if (storage.type === 'club' || storage.type === 'ppl') {
                        this._loading = false;
                        this.welcomeDialog(storage.type);
                    } else {
                        setTimeout(() => {    window.location.href = '/dashboard/overview/first'; }, 5000);
                    }
                } else {
                    this._loading = false;
                    this.finalValid = true;
                    this.snackBar.open('System error, please try again.', this.action ? this.actionButtonLabel : undefined, this.config);
                }
            });

    }

    ngAfterViewInit() {

        /** Update statelist based on country ID */
        this.cform.cform.controls.country.valueChanges.subscribe(change => {
            this.getState().subscribe(data => {
                this.statelist = data.filter((item) => item.country_id === change);
                this.getters.changeStateList(this.statelist);
            });
        });

        if (this.lform.lform.controls.email.valueChanges !== undefined) {
            this.lform.lform.controls.email.valueChanges.subscribe( data => {


                this.loginerror_valid = false;
                this.loginerror = '';

                if (this.lform.lform.controls.email.invalid === false) {
                    this.validationService.checkEmail(data).subscribe( ret => {
                        if (this.lform.lform.controls.email.invalid === false) {
                            if ( ret.found === 1 ) {
                                this.lform.lform.controls.email.setErrors({
                                    notUnique: true
                                });

                                this.lform.lform.updateValueAndValidity();

                                this.loginerror_valid = true;
                                this.loginerror = 'The email address entered is currently associated with an existing TrackitHub account. Log into your account or use a different email address to create your new TrackitHub account.';

                            }
                        }
                    });
                }
            });
        }

        /** Password  */
        if (this.lform.lform.controls.password !== undefined) {
            this.lform.lform.controls.password.valueChanges.subscribe( data => {



                this.lform.lform.controls.password_confirm.setValue(null);


                if (data === '') {
                    this.loginerror_valid = true;
                    this.loginerror = '';

                } else {
                    if (this.pwdPattern.test(data) === false) {
                        this.lform.lform.controls.password.setErrors({
                            empty: true

                        });

                        this.loginerror_valid = true;
                        this.loginerror = 'The password entered does not meet the required criteria.';

                    } else {
                        this.loginerror_valid = true;
                        this.loginerror = '';
                    }
                }
            });
        }

        /** Password confirm */
        if (this.lform.lform.controls.password_confirm !== undefined) {
            this.lform.lform.controls.password_confirm.valueChanges.subscribe( data => {


                if(data === '') {
                    this.loginerror_valid = true;
                    this.loginerror = '';
                } else {

                    if ( data !== this.lform.lform.controls.password.value) {
                        this.lform.lform.controls.password_confirm.setErrors({
                            empty: true
                        });

                        this.loginerror_valid = true;
                        this.loginerror = 'The confirm password does not match.';

                    } else {
                        this.loginerror_valid = true;
                        this.loginerror = '';
                    }
                }
            });
        }

        /** Phone */
        if (this.cform.cform.controls.phonenumber !== undefined) {
            this.cform.cform.controls.phonenumber.valueChanges.subscribe( data => {
                if (data.length !== 10) {
                    this.cform.cform.controls.phonenumber.setErrors({
                        empty: true
                    });
                }
            });
        }

        /** Club name uniqueness */
        if ( this.nform !== undefined ) {
            /** Check PPL name */

            if (this.nform.nform.controls.nameofppl !== undefined) {
                this.nform.nform.controls.nameofppl.valueChanges.subscribe( data => {
                    if (this.nform.nform.controls.nameofppl.invalid === false) {
                        this.validationService.checkName(data).subscribe( ret => {
                            if (this.nform.nform.controls.nameofppl.invalid === false) {
                                if ( ret.found === 1 ) {
                                    this.nform.nform.controls.nameofppl.setErrors({
                                        notUnique: true
                                    });

                                    this.config.verticalPosition = this.verticalPosition;
                                    this.config.horizontalPosition = this.horizontalPosition;
                                    this.config.duration = this.setAutoHide ? this.autoHide : 0;


                                    this.snackBar.open('A League with this name already exists.', this.action ? this.actionButtonLabel : undefined, this.config);

                                    this.nform.nform.updateValueAndValidity();
                                }
                            }
                        });
                    }
                });
            }

            /** check Club name */
            if (this.nform.nform.controls.nameofclub !== undefined) {
                this.nform.nform.controls.nameofclub.valueChanges.subscribe( data => {
                    if ( data.length > 4 ) {
                    if (this.nform.nform.controls.nameofclub.invalid === false) {
                        this.validationService.checkName(data).subscribe( ret => {
                            if (this.nform.nform.controls.nameofclub.invalid === false) {
                                if ( ret.found === 1 ) {
                                    this.nform.nform.controls.nameofclub.setErrors({
                                        notUnique: true
                                    });
                                    this.nform.nform.updateValueAndValidity();
                                    this.config.verticalPosition = this.verticalPosition;
                                    this.config.horizontalPosition = this.horizontalPosition;
                                    this.config.duration = this.setAutoHide ? this.autoHide : 0;


                                    this.snackBar.open('A Club with this name already exists.', this.action ? this.actionButtonLabel : undefined, this.config);

                                }
                            }
                        });
                    }
                    } else {
                        this.nform.nform.controls.nameofclub.setErrors({
                            empty: true
                        });
                    }
                });
            }

        }

        if (this.rform !== undefined) {
            this.rform.rform.valueChanges.subscribe( data => {
                this.rateFormValid = !this.rform.rform.valid;
                this.checkformvalid();
            });
        }

        this.cform.cform.valueChanges.subscribe( data => {
            this.contactFormValid = !this.cform.cform.valid;
            this.checkformvalid();
        });

        this.lform.lform.valueChanges.subscribe( data => {
            this.loginFormValid = !this.lform.lform.valid;
            this.checkformvalid();
        });

        if (this.nform !== undefined) {
            this.nform.nform.valueChanges.subscribe( data => {
                this.nameFormValid = !this.nform.nform.valid;
                this.checkformvalid();
            });
        }

        if (this.infoform !== undefined) {
            this.infoform.infoform.valueChanges.subscribe( data => {
                this.checkformvalid();
            });
        }

    }

    ngOnInit() { }

    ngOnDestroy() { }

    welcomeDialog(e): void {
            const dialogConfig = new MatDialogConfig();
            dialogConfig.disableClose = true;
            dialogConfig.autoFocus = true;
            dialogConfig.data = { };
            this.wdialog.open(ClubPplWelcomeDialogComponent, dialogConfig);
            if (e === 'player') {
                setTimeout(() => {    this._loading = true; window.location.href = '/dashboard/overview/first'; }, 5000);
            } else if (e === 'club') {
                setTimeout(() => {    this._loading = true; window.location.href = '/dashboard/overview/club'; }, 5000);
            } else {
                setTimeout(() => {    this._loading = true; window.location.href = '/dashboard/overview/firsttime'; }, 5000);
            }
    }


    scrollup(): void {
        window.scroll(0, 0);
    }

    openDialog(): void {
        const dialogRef = this.dialog.open(TermsDialogComponent, {
            data: {
                myVar: 'myVar',
            }
        });
        this.cform.cform.controls.termsandconditions.setValue(true);
    }

    getState(): Observable<any[]> {
        return this.http.get<any[]>('/assets/angular-data/states.json');
    }

    getCity(): Observable<any[]> {
        return this.http.get<any[]>('/assets/angular-data/cities.json');
    }

    checkformvalid(): void {

        if (this.type === 'club') {
            if (this.nform.nform.valid === true && this.lform.lform.valid === true && this.cform.cform.valid === true ) {
                this.finalValid = false;
            } else {
                this.finalValid = true;
            }
        }

        if (this.type === 'ppl') {
            if (this.nform.nform.valid === true && this.lform.lform.valid === true && this.cform.cform.valid === true && this.infoform.infoform.valid === true ) {
                this.finalValid = false;
            } else {
                this.finalValid = true;
            }
        }

        if (this.type === 'player') {
            if (this.lform.lform.valid === true && this.cform.cform.valid  === true && this.rform.rform.valid  === true ) {
                this.finalValid = false;
            } else {
                this.finalValid = true;
            }
        }
    }
}

async function delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
}
