import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-banner-ad',
  templateUrl: './banner-ad.component.html',
  styleUrls: ['./banner-ad.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class BannerAdComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
