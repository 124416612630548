import {EventEmitter, Component, OnInit, Output, ChangeDetectionStrategy, OnDestroy} from '@angular/core';
import { SessionStorageService } from 'ngx-webstorage';
import { SearchAddressService } from '../../common/services/searchaddress.service';
import {ActivatedRoute} from "@angular/router";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class MapComponent implements OnInit, OnDestroy {
  @Output() sendCard = new EventEmitter<any>();

  map: any;
  zoom = 10;
  lat: any;
  lng: any;
  markers: any;

  private suba: Subscription;

  constructor( private sessionSt: SessionStorageService, private SearchAddress: SearchAddressService, private route: ActivatedRoute) {
    this.lng = this.sessionSt.retrieve('address_lng');
    this.lat = this.sessionSt.retrieve('address_lat');

    if(this.lng === null && this.lat === null){
      this.lng = parseFloat(this.sessionSt.retrieve('current_lng'));
      this.lat = parseFloat(this.sessionSt.retrieve('current_lat'));
    }

    this.markers = this.sessionSt.retrieve('markers');
  }

  ngOnInit() {
    if(this.sessionSt.retrieve('mapover') === true) {
      if (this.map) {
        this.map.setCenter();
      }
      this.sessionSt.store('mapover', false);
    } else {
      this.suba = this.SearchAddress.cast.subscribe(data => {
        this.lat = data.address_lat;
        this.lng = data.address_lng;
        if (data.results !== null) {
          if (this.map) {



            this.map.setCenter();
          }
          this.markers = data.results;
        }
      });
    }
  }

  ngOnDestroy() {
    if (this.suba !== undefined) {
      this.suba.unsubscribe();
    }
  }

  public getMapInstance(map) {
    this.map = map;
    map.setOptions({
      zoomControl: 'true',
      zoomControlOptions: {
        position: google.maps.ControlPosition.BOTTOM_LEFT
      }
    });
  }

  openPanel(card) {
    this.sendCard.emit(card);
  }

}

