import { Injectable } from "@angular/core";
import Talk from 'talkjs';
import {AccountService} from "./account.service";
import {environment} from "../../../environments/environment";



@Injectable({providedIn: 'root'})
export class TalkService {
  private currentUser: Talk.User;
  user$;
  constructor(private accountService: AccountService) {

    this.accountService.cast.subscribe(data => {
      this.user$ = data.current_profile;
    });

  }

  async createUser(applicationUser: any) {
    await Talk.ready;
    return new Talk.User({
      id: applicationUser.id,
      name: applicationUser.username,
      photoUrl: applicationUser.photoUrl,
      role: applicationUser.role
    });
  }

  async createCurrentSession() {
    await Talk.ready;
    const user = {
      id: this.user$.profile_id,
      username: this.user$.name,
      email: this.user$.email,
      //photoUrl: this.user$.avatar,
      role: 'default'
    };
    this.currentUser = await this.createUser(user);
    const session = new Talk.Session({
      appId: environment.service.talkjs,
      me: this.currentUser
    });
    return session;
  }

  private async getOrCreateConversation(session: Talk.Session, otherApplicationUser: any) {
    const otherUser = await this.createUser(otherApplicationUser);
    const conversation = session.getOrCreateConversation(Talk.oneOnOneId(this.currentUser, otherUser));
    conversation.setParticipant(this.currentUser);
    conversation.setParticipant(otherUser);
    return conversation;
  }

  async createPopup(session: Talk.Session) {
    const supportUser = {
      id: 5,
      username: 'Brodie Kruse',
      email: 'sebastian@allparts.com',
      role: 'default'
    };

    const conversation = await this.getOrCreateConversation(session, supportUser);
    return session.createPopup(conversation, { keepOpen: false });
  }


  createGroup(value){



    const botUser = new Talk.User({
      id: 'group' + value[0].group_id, // Replace with a unique ID for the bot
      name: value[0].group_name,
      email: 'bot@trackithub.com',
      role: 'default'
    });


    const chatUsers = [];

    for (const key in value) {
      const v = value[key];
      chatUsers.push({id: v.player_id, email: v.email, name: v.name, role: 'default'});
    }


      const chatSession = new Talk.Session({
        appId: 'mGJOuEKK',
        me: botUser // Use the bot user here instead of the current user (you)
      });



    const conversationId = 'GROUP_CHAT_CONVERSATION_ID'; // Replace with a unique ID for the group chat
    const conversation = chatSession.getOrCreateConversation(conversationId);
    conversation.setAttributes({
      photoUrl: "https://demo.talkjs.com/img/11.jpg",
      subject: "Beautiful Steel Preserve for rent!"
    });
    // Add other participants to create a group chat
    chatUsers.forEach(user => {
      if (user.id !== chatSession.me.id) {
        conversation.setParticipant(new Talk.User(user));
      }
    });

    const inbox = chatSession.createChatbox(conversation);









  }


}
