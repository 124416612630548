import {ChangeDetectionStrategy, Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';

import { LocalDataPublicService } from '../../services/local-data-public.service';
import { LocalDataService, Localdata } from '../../services/local-data.service';
import { EasingLogic } from 'ngx-page-scroll-core';
import { Router } from '@angular/router';
import { PanelService } from '../../services/panel.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    providers: [
        {
            provide: LocalDataService,
            useClass: LocalDataPublicService
        }
    ],
  changeDetection: ChangeDetectionStrategy.Default
})
export class HeaderComponent implements OnInit {
  @ViewChild('appDrawer', {static: true}) appDrawer: ElementRef;
    localdatas: Localdata[];
    constructor(
        private localDataService: LocalDataService,
        public _router: Router,
        private panelService: PanelService,
    ) {
    }

    public isMobile: any;
    public panelScrolling: any;
    public panelRef: any;

    public currentWindowWidth: number;

    public myEasing: EasingLogic = (t: number, b: number, c: number, d: number): number => {
        if (t === 0) {
            return b;
        }
        if (t === d) {
            return b + c;
        }
        if ((t /= d / 2) < 1) {
            return c / 2 * Math.pow(2, 10 * (t - 1)) + b;
        }
        return c / 2 * (-Math.pow(2, -10 * --t) + 2) + b;
    };

    @HostListener('window:resize')

    onResize() {
        this.currentWindowWidth = window.innerWidth;
    }

    ngOnInit() {
        this.panelService.appDrawer = this.appDrawer;
        this.currentWindowWidth = window.innerWidth;
        this.localdatas = this.localDataService.getLocalDatas();
    }

    triggerClose() {
      this.panelService.appDrawer.close();
      this.panelService.windowScrolling.enable();
    }
}
