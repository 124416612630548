import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormGroupeditService {


  constructor() { }

  form: FormGroup = new FormGroup({
    primary_location: new FormControl(),
    loc_as_group: new FormControl(),
    title: new FormControl(),
    label: new FormControl(),
    minrate: new FormControl(),
    maxrate: new FormControl(),
    gender: new FormControl(),
    description: new FormControl(),
    wallnotification: new FormControl(),
    newmembernotification: new FormControl(),
    joinrequestnotification: new FormControl(),
    approvals: new FormControl(),
    allmembers: new FormControl(),
    membership: new FormControl(),
    limitgroups: new FormControl(),
    selectavatar: new FormControl(),
    uploadavatar: new FormControl()
  });

  disableForm() {
    this.form.disable();
  }

  addControl(field) {
    this.form.addControl(field, new FormControl({disabled: true}));
  }

  removeControl(field) {
    this.form.removeControl(field);
  }

  enableForm() {
    this.form.enable();
  }

  initializeFormGroup(userData) {
    this.form.controls.primary_location.setValue(userData.primary_location);
    this.form.controls.title.setValue(userData.title);
    this.form.controls.loc_as_group.setValue(userData.loc_as_group);
    this.form.controls.minrate.setValue(userData.minrate);
    this.form.controls.maxrate.setValue(userData.maxrate);
    this.form.controls.gender.setValue(userData.gender);
    this.form.controls.description.setValue(userData.description);
    this.form.controls.wallnotification.setValue(userData.params.wallnotification);
    this.form.controls.newmembernotification.setValue(userData.params.newmembernotification);
    this.form.controls.joinrequestnotification.setValue(userData.params.joinrequestnotification);
    this.form.controls.approvals.setValue(userData.approvals);
    this.form.controls.allmembers.setValue(userData.params.allmembers);
    this.form.controls.membership.setValue(userData.params.membership);
    this.form.controls.limitgroups.setValue(userData.params.limitgroups);
    this.form.controls.selectavatar.setValue(userData.avatar_icon);
  }


}
