import {Component, EventEmitter, Input, OnChanges, OnInit, Output, TemplateRef} from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { FieldConfig } from '../../models/field-config.interface';
@Component({
  exportAs: 'appDynamicFormSearchfilter6',
  selector: 'app-dynamic-form-searchfilter6',
  styleUrls: ['dynamic-form-searchfilter6.component.scss'],
  templateUrl: 'dynamic-form-searchfilter6.component.html',
})
export class DynamicFormSearchfilter6Component implements OnChanges, OnInit {
  @Input()
  config: FieldConfig[] = [];
  @Output()
  submit: EventEmitter<any> = new EventEmitter<any>();
  @Input() isLight;
  sform6: FormGroup;

  get controls() { return this.config.filter(({type}) => type !== 'button'); }
  get changes() { return this.sform6.valueChanges; }
  get valid() { return this.sform6.valid; }
  get value() { return this.sform6.value; }

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.sform6 = this.createGroup();
  }

  ngOnChanges() {
    if (this.sform6) {
      const controls = Object.keys(this.sform6.controls);
      const configControls = this.controls.map((item) => item.name);

      controls
        .filter((control) => !configControls.includes(control))
        .forEach((control) => this.sform6.removeControl(control));

      configControls
        .filter((control) => !controls.includes(control))
        .forEach((name) => {
          const config = this.config.find((control) => control.name === name);
          this.sform6.addControl(name, this.createControl(config));
        });

    }
  }

  createGroup() {
    const group = this.fb.group({});
    this.controls.forEach(control => group.addControl(control.name, this.createControl(control)));
    return group;
  }

  createControl(config: FieldConfig) {
    const { disabled, validation, value } = config;
    return this.fb.control({ disabled, value }, validation);
  }

  handleSubmit(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.submit.emit(this.value);
  }

  setDisabled(name: string, disable: boolean) {
    if (this.sform6.controls[name]) {
      const method = disable ? 'disable' : 'enable';
      this.sform6.controls[name][method]();
      return;
    }

    this.config = this.config.map((item) => {
      if (item.name === name) {
        item.disabled = disable;
      }
      return item;
    });
  }

  setValue(name: string, value: any) {
    this.sform6.controls[name].setValue(value, {emitEvent: true});
  }
}
