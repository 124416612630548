import {Component, OnInit} from '@angular/core';

export interface PplComparison {
    feature: string;
    basic: any;
    pro: any;
}

const ELEMENT_DATA: PplComparison[] = [
    {feature: 'Creation and Management of PPL', basic: '', pro: 'pickleball-yellow'},
    {feature: 'Designated PPL PRO', basic: '', pro: 'pickleball-yellow'},
    {feature: 'Unlimited Support', basic: '', pro: 'pickleball-yellow'},
    {feature: 'Newsletter Advertisement', basic: '', pro: 'pickleball-yellow'},
    {feature: 'Marketing PPL on TrackitHub\'s Social-Media Outlets', basic: '', pro: 'pickleball-yellow'},
    {feature: 'Marketing PPL on TrackitHub\'s Blog', basic: '', pro: 'pickleball-yellow'},
    {feature: 'Access to Local Clubs and Players', basic: '', pro: 'pickleball-yellow'},
    {feature: 'PPL Playbook', basic: '', pro: 'pickleball-yellow'},
    {feature: 'PPL Marketing Emails', basic: '', pro: 'pickleball-yellow'},
    {feature: 'Instant Chat Support', basic: '', pro: 'pickleball-yellow'},
    {feature: 'Custom Branded Experience', basic: '', pro: 'pickleball-yellow'},
    {feature: 'Listing on PPL Page', basic: 'pickleball', pro: 'pickleball-yellow'},
    {feature: 'Customizable Scheduling and Registration Options', basic: 'pickleball', pro: 'pickleball-yellow'},
    {feature: 'Online Payment Registration', basic: 'pickleball', pro: 'pickleball-yellow'},
    {feature: 'Mobile App', basic: 'pickleball', pro: 'pickleball-yellow'},
    {feature: 'Flexible Scoring System', basic: 'pickleball', pro: 'pickleball-yellow'},
    {feature: 'Unlimited Web Storage', basic: 'pickleball', pro: 'pickleball-yellow'},
    {feature: 'Player Standings', basic: 'pickleball', pro: 'pickleball-yellow'},
    {feature: 'Player Profiles', basic: 'pickleball', pro: 'pickleball-yellow'},
    {feature: 'Download League Data', basic: 'pickleball', pro: 'pickleball-yellow'},
    {feature: 'Mass Emailing', basic: 'pickleball', pro: 'pickleball-yellow'},
];

@Component({
    selector: 'app-pricing-table',
    templateUrl: './pricing-table.component.html',
    styleUrls: ['./pricing-table.component.scss']
})
export class PricingTableComponent implements OnInit {
    title = 'Package Comparison';
    displayedColumns: string[] = ['feature', 'basic', 'pro'];
    dataSource = ELEMENT_DATA;

    constructor() {
    }

    ngOnInit() {
    }
}
