import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material';
import { AccountService } from '../common/services/account.service';
import { Account } from '../common/interfaces/account';
import { Data } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import {HttpClient} from "@angular/common/http";
import {BottomSheetService} from "../common/services/bottom-sheet.service";
import {MatDialog} from '@angular/material/dialog';
import {ToastService} from "../common/toast";
import {ChatService} from "../common/services/chat.service";
import {MatTabGroup} from "@angular/material/tabs";



interface Avatar {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

})



export class ProfileComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild(MatTabGroup, { static: true }) tabGroup!: MatTabGroup;

  profile_id: Account["current_profile"]["profile_id"];
  subscription: Subscription;
  friendSubscription: Subscription;
  account: Account;
  avatarImg: string;
  avatarPlaceholder: string;

  name: string;
  rating: string;
  friends: any;
  friendCount: number;
  groupCount: number;
  groups: {name:string, avatar:string, avataricon:string, id:string, members:string}[] = [];
  current_profile: number;
  siteUrl = environment.service.trackithubapi + '/';
  is_friend: number;
  is_owner: number;
  email: string;
  timerId: any;
  is_online: boolean;
  profile_type: string;
  profile: any;
  locations: any;
  organization: any;
  ownerid: any;
  realid: any;
  approved: any;
  groupprofile: any;
  post_id: any;
  comment_id: any;
   media_id: any;
   folder_id: any;

  s1 = null;

  avatars: Avatar[] = [
    {value: 'club', viewValue: 'Club'},
    {value: 'grin', viewValue: 'Grin'},
    {value: 'slight-smile', viewValue: 'Slight Smile'},
    {value: 'smile', viewValue: 'Smile'},
    {value: 'smiling-face-eyes', viewValue: 'Smiling Face Eyes'},
    {value: 'smirking-face', viewValue: 'Smirking Face'},
    {value: 'tilted-grin', viewValue: 'Tilted-grin'},
    {value: 'winking-grin', viewValue: 'Winking-Grin'},
    {value: 'worried-smile', viewValue: 'Worried-Smile'},
  ];
  private memberCount: any;
  private owner: any;



  constructor(
    private chatService: ChatService,   private bottomSheetService: BottomSheetService,private changeDetectorRef: ChangeDetectorRef, private http: HttpClient, bottomSheetRef: MatBottomSheetRef<ProfileComponent>, private accountService: AccountService, @Inject(MAT_BOTTOM_SHEET_DATA) public data: Data,  public dialog: MatDialog,private toastService: ToastService) {

    this.post_id = '';
    this.folder_id = '';
    this.comment_id = '';
    this.media_id = '';
    this.name = '';
    this.rating = '0.0';
    this.groupCount = 0;
    this.friendCount = 0;
    this.current_profile = 0;
    this.is_friend = 0;
    this.is_owner = 0;
    this.email = '';
    this.is_online = false;



      this.loadProfileData();


      this.subscription = this.accountService.cast.subscribe(data => {
        this.account = data;
        this.current_profile = this.account.current_profile.profile_id;

        if(this.profile_type == 'group'){
          if (this.ownerid == this.current_profile) {
            this.is_owner = 1;
          } else {
            this.is_owner = 0;
          }
        } else {
          if (this.profile_id == this.current_profile) {
            this.is_owner = 1;
          } else {
            this.is_owner = 0;
          }
        }
      });





  }

  @ViewChild(MatTabGroup, {static: false}) matTabGroup: MatTabGroup;

  parentFunction(){



    this.http.get<any>(environment.service.trackithubapi + '/api/inside/loadprofile?clean_group=yes&profile=' + this.data.id.profileData.profile.profile_id).subscribe((profileData: any) => {

      this.data.id.profileData.profile = profileData.profile;
      this.changeDetectorRef.detectChanges();

      this.loadProfileData();

    });
  }

  openTab(tabIndex: number) {
    this.tabGroup.selectedIndex = tabIndex;
  }


  scrollToElement(elementId: string) {
    const element = document.getElementById('66');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  ngAfterViewInit() {




    if(this.data.id.post_id != '' && this.data.id.post_id != null){
      this.post_id = 'post_' + this.data.id.post_id;
    }

    if(this.data.id.comment_id != '' && this.data.id.comment_id != null){
      this.comment_id = 'comment_' + this.data.id.comment_id;
    }

    if(this.data.id.tab == 'media'){
      if(this.data.id.type == 'group'){
        this.openTab(2)
      } else {
        this.openTab(3)
      }
    }
  }

  loadProfileData(){






    if(this.data.id.type == 'group'){

      this.post_id = this.data.id.post_id;
      this.comment_id = this.data.id.comment_id;
      this.folder_id = this.data.id.folder_id;
      this.media_id = this.data.id.media_id;
      this.profile_type = 'group';
      this.profile_id = this.data.id.profileData.profile[0].id;
      this.profile = this.data.id.profileData;
      this.groupprofile = this.data.id.profileData.profile[0];
      this.name = this.data.id.profileData.profile[0].name;
      this.organization = this.data.id.profileData.profile[0].organization;
      this.ownerid = this.data.id.profileData.profile[0].ownerid;
      this.avatarImg = this.data.id.profileData.profile[0].avatar;
      this.avatarPlaceholder = this.data.id.profileData.profile[0].avatar_icon;
      this.memberCount = this.data.id.profileData.profile[0].members;
      this.friends = this.data.id.profileData.profile[0].friends;
      this.current_profile = this.realid;
      this.owner = this.data.id.profileData.profile[0].owner;
      this.approved = this.data.id.profileData.profile[0].approved;
      this.locations = this.data.id.profileData.profile[0].locations;


      if(this.ownerid == this.realid){
        this.is_owner = 1;
      } else {
        this.is_owner = 0;
      }



    } else {


      this.profile_id = this.data.id.profileData.profile.profile_id;

      this.post_id = this.data.id.post_id;
      this.comment_id = this.data.id.comment_id;
      this.folder_id = this.data.id.folder_id;
      this.media_id = this.data.id.media_id;
      this.profile = this.data.id.profileData;
      this.name = this.data.id.profileData.profile.name;
      this.email = this.data.id.profileData.email;
      this.rating = this.data.id.profileData.profile.profile_rating;

      this.profile_type = this.data.id.profileData.profile.profile_type;

      this.is_online = this.data.id.profileData.profile.online;
      this.friendCount = this.data.id.profileData.profile.friend_count;
      this.friends = this.data.id.profileData.profile.friends;
      this.avatarImg = this.data.id.profileData.profile.avatar;
      this.avatarPlaceholder = this.data.id.profileData.profile.avataricon;
      this.is_friend = this.data.id.profileData.profile.is_friend
      this.groups = this.data.id.profileData.profile.groups;
      this.locations = this.data.id.profileData.profile.locations;

      if (this.groups === null) {
        this.groupCount = 0;
      } else {
        this.groupCount = this.groups.length;
      }

    }

  }

  ngOnInit() {

    this.s1 = this.accountService.cast.subscribe(data => {

      this.realid = data.current_profile.profile_id;

      if(this.profile_id == data.current_profile.profile_id){



        this.avatarImg =  data.current_profile.avatar;  //this.data.id.profileData.profile.avatar;
        this.avatarPlaceholder = data.current_profile.avataricon;   //this.data.id.profileData.profile.avataricon;




        this.changeDetectorRef.detectChanges();
      }



    });

    this.timerId = setInterval(() => this.resetBar(), 2000);
  }

  ngOnDestroy() {
    clearTimeout(this.timerId);
    if (this.s1 != null) {
      this.s1.complete();
    }
  }

  resetBar() {
    this.matTabGroup._tabHeader._alignInkBarToSelectedTab();
  }

  AddFriend(profile_id, name) {
    this.http.get<any>(environment.service.trackithubapi + '/api/inside/addfriend?connection_to=' + profile_id + '&connection_from=' + this.account.current_profile.profile_id ).subscribe((data: any) => {
      this.toastService.show({
        text: 'You have successfully added your new friend.  Please wait for them to accept your invitation.',
        type: 'success',
        buttonText: '',
      });
    });
  }

  openChat(){
        this.chatService.openchatuser({
          profile_id: this.profile_id,
          name: this.name,
          avatarImg: this.avatarImg,
          avatarPlaceholder: this.avatarPlaceholder,
          email: this.email
        });
  }

  viewProfile(ownerid){
    this.http.get<Account>(environment.service.trackithubapi + '/api/inside/loadprofile?clean_group=yes&profile=' + ownerid).subscribe((profileData: any) => {
      this.bottomSheetService.openBottomSheetWithDataCustomClass(ProfileComponent, 'ProfileComponent',{
        'profileData': profileData,
      }, 'custom-bottom-p' );

    });
  }

}
