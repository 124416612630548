import {
  Component,
  Inject,
  OnInit,
  ViewChild,
  ElementRef,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';

import { ToastService} from '../../common/toast';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material';
import { FormGroup } from  '@angular/forms';
import { FormEmailGroupService } from '../../common/services/form-groupemail.service';
import {
  HttpClient,
  HttpEventType,
} from "@angular/common/http";

import { map, catchError } from "rxjs/operators";
import { throwError } from "rxjs";
import {environment} from "../../../environments/environment";



@Component({
  selector: 'app-dashboard-emailgroup-sheet',
  templateUrl: './dashboard-emailgroup-sheet.component.html',
  styleUrls: ['./dashboard-emailgroup-sheet.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class DashboardEmailGroupSheetComponent implements OnInit {

  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;

  sheetRef: any = this.data.ref;

  form: FormGroup;


  body = '';
  subject = ' ';
  bodyct = 0;
  initialstate = true;

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter email body here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [ ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };

  files: any[] = [];
  cansend = true;
  list=1;

  constructor(private toastService: ToastService, private cdRef:ChangeDetectorRef, public FormEmailGroup: FormEmailGroupService, @Inject(MAT_BOTTOM_SHEET_DATA) public data: any, private http: HttpClient) {}

  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  deleteFile(index: number) {
    this.files.splice(index, 1);
  }

  makeRandom(lengthOfCode: number, possible: string) {
    let text = "";
    for (let i = 0; i < lengthOfCode; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  cancelsend(){
    this.sheetRef.dismiss();
  }

  sendemail(){

    this.cansend = true;

    var players = [];
    var player_id = [];
    var file_keys = [];



    for (let i = 0; i < this.data.id.list.length; i++) {
      players.push(this.data.id.list[i].email);
      player_id.push(this.data.id.list[i].user_id);
    }

    for (let i = 0; i < this.files.length; i++) {
      file_keys.push(this.files[i].name);
    }

    if(this.data.id.list.length > 0){
      this.list = 0;
    }

    this.http.post(environment.service.trackithubapi + "/api/inside/send_email", {group: this.data.id.group.id, owner: this.data.id.current_profile, body: this.body, subject: this.subject, distro: this.list, ids: player_id, to: players, keys: file_keys, club: this.data.id.group.clubcard}, {}).subscribe((data: any) => {

      var ct = 0;

      if (players.length === 0) {
        ct = data.result;
      } else {
        ct = players.length;
      }

      this.toastService.show({
        text: 'Email sent to ' + ct + ' members.',
        type: 'success',
      });
      this.sheetRef.dismiss();
    });


  }

  uploadFiles(index) {

    let reader = new FileReader();
    this.files[index].key = this.makeRandom(10, 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789');
    let file = this.files[index];

    reader.readAsDataURL(file);
    reader.onload = () => {
      this.http
        .post(environment.service.trackithubapi + "/api/inside/attachment_upload", {file_name: file.name, file_type: file.type, file_data: reader.result, file_key: file.key, file_owner: this.data.id.id}, {
          reportProgress: true,
          observe: "events"
        })
        .pipe(
          map((event: any) => {
            if (event.type == HttpEventType.UploadProgress) {
              this.files[index].progress = Math.round((100 / event.total) * event.loaded);
              this.cdRef.detectChanges();
            } else if (event.type == HttpEventType.Response) {
              this.files[index].progress = null;
            }
          }),

          catchError((err: any) => {
            this.files[index].progress = null;
            alert(err.message);
            return throwError(err.message);
          })
        )
        .toPromise().then(res=>{
          this.toastService.show({
            text: `Attachment has been uploaded.`,
            type: 'success',
          });
      });
    };
  }

  prepareFilesList(files: Array<any>) {
    this.files = [];
    for (const item of files) {
      item.progress = 0;
      this.files.push(item);
      this.uploadFiles(this.files.length - 1);
    }
  }

  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  changed($event){

  }

  ngOnInit() {

  }

  descriptionCharCount() {
    const dummy = document.getElementById("emailbody");
    dummy.innerHTML = this.body;
    this.bodyct = dummy.innerText.length;
  }

  checkform() {
    this.initialstate = false;
    this.descriptionCharCount();
    if (this.subject != '' && this.body != '') {
      if (this.subject.length > 3) {
        if (this.bodyct > 19) {
          this.cansend = false;
        } else {
          this.cansend = true;
        }
      } else {
        this.cansend = true;
      }
    } else {
      this.cansend = true;
    }
  }
}
