import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";


@Component({
  selector: 'app-dashboard-pregamemodal',
  templateUrl: './dashboard-pregamemodal.component.html',
  styleUrls: ['./dashboard-pregamemodal.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})

export class DashboardPregamemodalComponent implements OnInit, OnDestroy {




  constructor(public dialogRef: MatDialogRef<DashboardPregamemodalComponent>) {

  }




  ngOnDestroy() {

  }

  ngOnInit() {

  }

  closepop(){
    this.dialogRef.close();
  }



}
