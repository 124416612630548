import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SessionStorageService } from 'ngx-webstorage';

// SessionStorageService -- https://github.com/PillowPillow/ng2-webstorage

export interface CurrentLocation {
  lat: string,
  lng: string,
}

@Injectable()

export class LocationService {

  lat = null;
  lng = null;

  private location = new BehaviorSubject<CurrentLocation>({
    lng: this.lng,
    lat: this.lat
  });

  cast = this.location.asObservable();

  constructor(private sessionSt: SessionStorageService ) {}

  load() {

    this.lat = this.sessionSt.retrieve('current_lat');
    this.lng = this.sessionSt.retrieve('current_lng');

    this.location.next({
      lat: this.lat,
      lng: this.lng
    });
  }
}
