import {
  AfterViewInit,
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component, Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';

import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatDialog} from '@angular/material/dialog';
import {ToastService} from '../../common/toast';
import {HttpClient} from '@angular/common/http';
import {SessionStorageService} from 'ngx-webstorage';
import {RecordsService} from '../../common/services/records.service';
import {AccountService} from '../../common/services/account.service';
import {PickelballRatingComponent} from '../../pickelball-rating/pickelball-rating.component';
import {FormRegisterleagueTeamService} from '../../common/services/form-registerleague-team.service';
import {environment} from "../../../environments/environment";
import {DashboardTeamShareComponent} from "../../dashboard";


@Component({
  selector: 'app-league-register-teammate-component',
  templateUrl: './league-register-teammate.component.html',
  styleUrls: ['./league-register-teammate.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

})


export class LeagueRegisterTeammateComponent implements OnInit, OnDestroy, AfterViewInit {





  public lasterror = null;
  public errormessage = null;
  public stepLabel = ['Login Info', 'Contact Info', 'Skill Rating'];
  public pwdPattern: any = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{8,25}$/;
  public records = {};
  public message = '';
  public _loading = false;
  public loaderMessage = 'Loading...';
  public loginFormValid = true;
  public contactFormValid = true;
  public rateFormValid = true;
  public finalValid = true;
  public loginerror_valid = false;
  public loginerror;
  account_id: any;



  s1 = false;
  s2 = false;
  s3 = false;
  s4 = false;
  s5 = false;
  s6 = false;
  s7 = false;
  s8 = false;
  s9 = false;
  s10 = false;

  status: boolean;
  tname: string;
  lname: string;
  fname: string;
  email: string;
  oemail: string;
  gender: string;
  phone: string;
  skill: string;
  password: string;
  cpassword: string;
  disabled: boolean;
  zipcode: string;
  terms: boolean;
  emailvar: boolean;
  teamname: string;
  team_id: string;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private validationService: RecordsService,
    public formService: FormRegisterleagueTeamService,
    private LoginService: RecordsService,
    private accountService: AccountService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<LeagueRegisterTeammateComponent>,
    public dialog: MatDialog,
    private toastService: ToastService,
    private sessionSt: SessionStorageService,
    private http: HttpClient) {
  }



  ngAfterViewInit() {

  }




  ngOnInit() {

    this.team_id = this.sessionSt.retrieve('team_id');

    this.formService.initializeFormGroup();

    this.http.post<any>(environment.service.trackithubapi + '/api/outside/getteamname', {
      'team_id': this.team_id
    }).subscribe((datax: any) => {
      this.teamname = datax.name;
      this.formService.form.controls.tname.setValue(this.teamname);
      this.formService.form.controls.tname.disable();
    });

    this.disabled = true;
    this.formService.form.valueChanges.subscribe(data => {

      this.disabled = true;
      //this.s1 = false;
      this.s2 = false;
      this.s3 = false;
      this.s4 = false;
      this.s5 = false;
      this.s6 = false;
      this.s7 = false;
      this.s8 = false;
      this.s10 = false;
      //this.emailvar = false;


      this.fname = data.fname;
      this.lname = data.lname;
      this.email = data.em;
      this.password = data.password;
      this.cpassword = data.cpassword;
      this.skill = data.skill;
      this.gender = data.gender;
      this.zipcode = data.zipcode;
      this.phone = data.phone;
      this.terms = data.terms;
      this.tname = data.tname;


      console.log(data.em, this.oemail, this.s1);

      if(data.em != null && data.em != this.oemail) {

        this.oemail = data.em;

        this.validationService.checkEmail(data.em).subscribe(ret => {

          if (ret.found === 1) {
            this.formService.form.controls.em.setErrors({
              empty: true
            });

            this.emailvar = true;

            this.errormessage = 'The email address entered is currently associated with an existing TrackitHub account. Log into your account or use a different email address to create your new TrackitHub account.';

            this.s1 = false;
            this.check(data);
          } else {
            this.emailvar = false;
            this.s1 = true;
            this.check(data);
          }
        });
      } else {
        //this.s1 = false;
        this.check(data);
      }
      this.changeDetectorRef.detectChanges();

    });


  }

  ngOnDestroy() {

  }

  check(data){
    if (this.pwdPattern.test(data.password) === false) {
      this.formService.form.controls.password.setErrors({
        empty: true
      });
      this.s2  = false;
    } else {
      this.s2 = true;
    }

    if(data.password != '' && this.s2 == true) {
      if (data.password == data.cpassword) {
        this.s10 = true;
      } else {
        this.formService.form.controls.cpassword.setErrors({
          empty: true
        });
        this.s10 = false;
      }
    }



    if(data.fname != null) {
      if (data.fname.length < 2) {
        this.formService.form.controls.fname.setErrors({
          empty: true
        });
        this.s3 = false;
      } else {
        this.s3 = true;
      }
    } else {
      this.s3 = false;
    }

    if(data.lname != null) {
      if(data.lname.length < 2){
        this.formService.form.controls.lname.setErrors({
          empty: true
        });
        this.s4 = false;
      } else {
        this.s4 = true;
      }
    } else {
      this.s4 = false;
    }



    if(data.gender != null) {
      if (data.gender.length > 0) {

        this.s5 = true;

      } else {
        this.formService.form.controls.gender.setErrors({
          empty: true
        });
        this.s5 = false;
      }
    } else {
      this.s5 = false;
    }

    if(data.skill != null) {
      if(data.skill.length > 2) {
        this.s6 = true;
      } else {
        this.formService.form.controls.skill.setErrors({
          empty: true
        });
        this.s6 = false;
      }
    } else {
      this.s6 = false;
    }

    if(data.phone != null) {
      if(data.phone.length < 2){
        this.formService.form.controls.phone.setErrors({
          empty: true
        });
        this.s7 = false;
      } else {
        this.s7 = true;
      }
    } else {
      this.s7 = false;
    }

    this.s8 = this.terms;


    console.log(this.s1,this.s2,this.s3 ,this.s4, this.s5 , this.s6,this.s7 ,this.s8,this.s10);

    if(this.s1 == true && this.s2 == true && this.s3 == true && this.s4 == true && this.s5 == true && this.s6 == true && this.s7 == true && this.s8 == true  && this.s10 == true){
      this.disabled = false;
    } else {
      this.disabled = true;
    }


    this.changeDetectorRef.detectChanges();
  }




  rating(){
    const dialogRef = this.dialog.open(PickelballRatingComponent, {
      height: '450px',
      data: {is_blue: true}
    });
  }

  showterms(){
    window.open('/terms.php', "_blank");
  }


  gotoLogin(){
    if(this.formService.form.controls.tname.value == '' || this.formService.form.controls.phone.value == ''){
      this.toastService.show({
        text: 'You must enter a Team name and your phone number before you can continue.',
        type: 'warn',
        buttonText: '',
      });
    } else {
      this.sessionSt.clear('team_group_id');
      this.sessionSt.clear('team_event');
      this.sessionSt.clear('leagueeventteam');
      this.sessionSt.clear('joinoutsideteam');
      this.sessionSt.clear('leagueevent');
      this.sessionSt.store('team_name', this.formService.form.controls.tname.value);
      this.sessionSt.store('team_phone', this.formService.form.controls.phone.value);
      this.sessionSt.store('team_id', this.team_id);
      this.sessionSt.store('trigteam', 1);
      this.sessionSt.store('player_two', 1);
      this.dialogRef.close();
      document.location.href = '/login';
    }
  }


  save() {

    this.disabled = true;

    interface sModal {
      team_id: string;
      type: string;
      email: string;
      name: string;
      firstname: string;
      lastname: string;
      phonenumber: string;
      gender: string;
      birthyear: string;
      lat: any;
      long: any;
      street: string;
      country: string;
      state: string;
      city: string;
      zipcode: string;
      termsandconditions: string;
      rating: string;
      avataricon: string;
      avatarupload: string;
      account_id: number;
      nameofclub: string;
      timezone: string;
      password: string;
      cpassword: string;
      rp_id: string;
      start_date: string;
    }

    const storage = <sModal>{};

    storage.type = 'player';
    storage.email = this.email;




    storage.team_id = this.team_id;
    storage.password = this.password;
    storage.firstname = this.fname.trim();
    storage.lastname = this.lname.trim();
    storage.gender = this.gender;
    storage.birthyear = '01/01/1970';
    storage.phonenumber = this.phone;
    storage.street = '';
    storage.country = '';
    storage.lat = '';
    storage.long = '';
    storage.state = '';
    storage.city = '';
    storage.zipcode = this.zipcode;
    storage.termsandconditions = '1';

    storage.rating = this.skill;

    storage.avataricon = '';
    storage.avatarupload = '';
    storage.account_id = null;
    storage.nameofclub = '';

    if (storage.gender === 'M') {
      storage.gender = 'COM_COMMUNITY_MALE';
    } else {
      storage.gender = 'COM_COMMUNITY_FEMALE';
    }

    storage.rp_id = this.sessionSt.retrieve('leagueeventteam');
    storage.start_date = this.sessionSt.retrieve('last_start_date');

    this.sessionSt.clear('leagueeventteam');
    this.sessionSt.clear('joinoutsideteam');
    this.sessionSt.clear('leagueevent');
    this.sessionSt.clear('teammate');
    this.sessionSt.clear('last_start_date');
    this.sessionSt.store('team_ids', this.sessionSt.retrieve('team_id'));

    this.sessionSt.clear('team_id');

    this.toastService.show({
      text: 'Please hold tight while we load your profile.',
      type: 'success',
      buttonText: '',
    });

    this.records = this.LoginService.createccount(storage).subscribe(data => {
      this.records = data;
      if (this.records['result'] === true) {
        setTimeout(() => {
          if ( this.account_id !== undefined) {

            this.accountService.load();
            this.accountService.pullprofile(this.records['profile_id']);
            // @ts-ignore
            this.sheetRef.dismiss();
          }
          this.sessionSt.store('trigteam', 1);
          document.location.href = '/dashboard/overview';
        }, 3000);
      } else {
        this._loading = false;
        this.finalValid = true;
        this.errormessage = 'System error, please try again.';
        if (this.lasterror !== this.errormessage) {
          this.toastService.show({
            text: this.errormessage,
            type: 'warn',
            buttonText: '',
          });
          this.lasterror = this.errormessage;
        }
      }
    });

  }


  cancel() {
    this.dialogRef.close();
  }


}
