import {ChangeDetectionStrategy, Component, Input, ViewChild, OnDestroy, OnInit} from '@angular/core';
import {BottomSheetService} from '../../../common/services/bottom-sheet.service';
import {DashboardReadEmailSheetComponent} from '../../../dashboard/dashboard-reademail-sheet/dashboard-reademail-sheet.component';
import {environment} from "../../../../environments/environment";
import {ProfileComponent} from "../../../profile/profile.component";
import {HttpClient} from "@angular/common/http";
import {AccountService} from "../../services/account.service";


@Component({
  selector: 'app-member-management',
  templateUrl: './member-management.component.html',
  styleUrls: ['./member-management.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class MemberManagementComponent implements OnInit, OnDestroy {
  @Input() name: string;
  @Input() body: string;
  @Input() group_id: string;
  @Input() created: string;
  @Input() paneltype: string;
  @Input() paid: string;

  public show = true;
  public  progress: number;
  s1: any;
  account: any;


  constructor(
    private accountService: AccountService,
    public bottomSheetService: BottomSheetService,
    private http: HttpClient,
  ) {

    if(this.paid != '1'){
      this.paid = '0';
    }

  }

  ngOnInit() {
    this.s1 = this.accountService.cast.subscribe(data => {
      this.account = data;
    });
  }

  ngOnDestroy() {
    if (this.s1 != null) {
      this.s1.complete();
    }
  }

  displayemail(){
    this.bottomSheetService.openBottomSheetWithData(DashboardReadEmailSheetComponent, 'DashboardReadEmailSheetComponent',{list: {subject: this.name, body: this.body}});
  }

  viewgroup(group, name) {
      this.http.get<any>(environment.service.trackithubapi + '/api/inside/loadgroup?profile_id=' + this.account.current_profile.profile_id + '&clean_group=yes&group=' + group).subscribe((profileData: any) => {
        this.bottomSheetService.openBottomSheetWithDataCustomClass(ProfileComponent, 'ProfileComponent',{
          'profileData': profileData,
          'type': 'group'
        }, 'custom-bottom-p' );
      });
  }
}
