import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-dashbaord-card-club-rating-range',
  templateUrl: './dashboard-card-club-rating-range.component.html',
  styleUrls: ['./dashboard-card-club-rating-range.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class DashboardCardClubRatingRangeComponent implements OnInit {
  @Input() clubLabel: string;
  @Input() notshare: boolean;
  @Input() group_id: string;
  @Input() club_id: string;

  constructor() { }

  ngOnInit() {

  }

}
