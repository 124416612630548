import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormEmailGroupService {

  constructor() { }

  form: FormGroup = new FormGroup({
    subject: new FormControl(),
    list: new FormControl(),
    body: new FormControl()
  });

}
