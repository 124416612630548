import {Component, OnInit, HostListener, ChangeDetectionStrategy} from '@angular/core';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class EventComponent implements OnInit {
  public currentWindowWidth: number;

  title = 'Event Scheduling';
  description = 'Tracking your player\’s attendance has never been easier with TrackitHub\’s Event registration software. With the ability to track attendance, assign group admins, create custom events, manage waiting lists, and collecting event fees, our pickleball management software will take your events to the next level. Have full control of the event registration and tailor your club’s schedule to each event.';
  image = 'assets/angular-img/mockups/space-grey-ipad-mockup.png';
  phoneImage = 'assets/angular-img/mockups/iphone-calendar-mockup.png';

  constructor() { }

  @HostListener('window:resize')
    onResize() {
        this.currentWindowWidth = window.innerWidth;
    }

    ngOnInit() {
        this.currentWindowWidth = window.innerWidth;
    }

}
