import {Component, EventEmitter, OnInit, Input, Output, ChangeDetectionStrategy} from '@angular/core';
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-basic-card',
  templateUrl: './basic-card.component.html',
  styleUrls: ['./basic-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class BasicCardComponent implements OnInit {
  @Input() item: any;
  @Output() selectCard = new EventEmitter<any>();
  photourl: any;
  siteurl = environment.service.trackithubapi;

    constructor() { }
  ngOnInit() {


  }

  openPanel() {
    this.selectCard.emit(this.item);
  }

}
