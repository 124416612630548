import {Component} from '@angular/core';
import {FormGroup} from '@angular/forms';

import {Field} from '../../models/field.interface';
import {FieldConfig} from '../../models/field-config.interface';

interface Avatar {
  value: string;
  viewValue: string;
}

@Component({
    selector: 'app-form-select-avatar',
    styleUrls: ['form-select-avatar.component.scss'],
    template: `<mat-form-field appearance="{{config.appearance}}" [formGroup]="group" floatLabel="{{config.floatLabel}}">
          <span style="width: 32px; height: 32px;" class="avatar mat-elevation-z3 {{selectedAvatar}}" [attr.data-avatarBadge]="selectedAvatar" [attr.mat-elevation-z3]="true"></span>
          <mat-select [formControlName]="config.name" [(ngModel)]="selectedAvatar" style="width:60px;" (selectionChange)="updatedavatar($event)">
            <mat-option value=""></mat-option>
            <mat-option *ngFor="let avatar of avatars" [value]="avatar.value" >
              <span style="width: 32px; height: 32px;" class="avatar mat-elevation-z3 {{avatar.value}}" [attr.data-avatarBadge]="avatar.value" [attr.mat-elevation-z3]="true"></span>
            </mat-option>
          </mat-select>
            <br>
      <mat-hint>Select a default avatar or upload your own avatar below.</mat-hint>
    </mat-form-field>`
})

export class FormSelectAvatarComponent implements Field {
    config: FieldConfig;
    group: FormGroup;

    selectedAvatar: any = '';
    avatars: Avatar[] = [
      {value: 'club', viewValue: 'Club'},
      {value: 'grin', viewValue: 'Grin'},
      {value: 'slight-smile', viewValue: 'Slight Smile'},
      {value: 'smile', viewValue: 'Smile'},
      {value: 'smiling-face-eyes', viewValue: 'Smiling Face Eyes'},
      {value: 'smirking-face', viewValue: 'Smirking Face'},
      {value: 'tilted-grin', viewValue: 'Tilted-grin'},
      {value: 'winking-grin', viewValue: 'Winking-Grin'},
      {value: 'worried-smile', viewValue: 'Worried-Smile'},
    ];

    updatedavatar($event) {
      this.selectedAvatar = $event.value;
    }

}
