import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

interface State {
    id;
    name;
    country_id;
    country_code;
}

interface City {
    id;
    name;
    state_id;
    country_id;
    country_code;
}

@Injectable()
export class Backplane {

  private stateListSource = new BehaviorSubject(<any>[]);
  stateList = this.stateListSource.asObservable();

  private cityListSource = new BehaviorSubject(<any>[]);
  cityList = this.cityListSource.asObservable();


  constructor() {

    }

  changeStateList(message: State) {
    this.stateListSource.next(message);
  }

  changeCityList(message: City) {
    this.cityListSource.next(message);
  }


}
