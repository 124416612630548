import {Injectable} from '@angular/core';

export interface Localdata {
    icon?: string;
    name: string;
    route: string;
    url: string;
    fragment: string;
}

@Injectable()
export abstract class LocalDataService {
    abstract getLocalDatas(): Localdata[];
    abstract getMenuItems(): Localdata[];
}
