import {ChangeDetectionStrategy, Component, Input, ElementRef, ViewChild} from '@angular/core';
import {DashboardeventsService} from '../../../common/services/dashboardevents.service';
import {BottomSheetService} from '../../../common/services/bottom-sheet.service';
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {ProfileComponent} from "../../../profile/profile.component";
import {Account} from "../../../common/interfaces/account";

@Component({
  selector: 'app-club-card-notification',
  templateUrl: './club-card-notification.component.html',
  styleUrls: ['./club-card-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class ClubCardNotificationComponent {

  @Input() clubAdmin: boolean;
  @Input() paymentStatus: number;
  @Input() messageStatus: number;
  @Input() announcements: number;
  @Input() photoStatus: number;
  @Input() groupStatus: number;
  @Input() dataBadge: boolean;

  @Input() membership:  number;
  @Input() owner: number;
  @Input() ownerid: string;
  @Input() permissions: number;
  @Input() group_id: string;
  @Input() clubcard: boolean;
  @Input() playerclubcard: boolean;
  @Input() admin: boolean;
  @Input() profile_id: number;
  @Input() locations: any;
  @Input() club: any;
  @Input() loc_fix: number;

  @ViewChild('calendargroup', {static: false}) myDiv: ElementRef;


  constructor(private http: HttpClient, private dashboardevents: DashboardeventsService, public bottomSheetService: BottomSheetService) {

  }

  gotoevents(groupid){
    this.dashboardevents.updategroup(groupid);
  }

  viewgroup(group) {
    this.http.get<Account>(environment.service.trackithubapi + '/api/inside/loadgroup?profile_id=' + this.profile_id + '&clean_group=yes&group=' + group).subscribe((profileData: any) => {
      this.bottomSheetService.openBottomSheetWithDataCustomClass(ProfileComponent, 'ProfileComponent',{
        'profileData': profileData,
        'type': 'group',
      }, 'custom-bottom-p' );
    });
  }

  viewProfile(profile_id){
    this.http.get<Account>(environment.service.trackithubapi + '/api/inside/loadprofile?clean_group=yes&profile=' + profile_id).subscribe((profileData: any) => {
      this.bottomSheetService.openBottomSheetWithDataCustomClass(ProfileComponent, 'ProfileComponent',{
        'profileData': profileData,
      }, 'custom-bottom-p' );

    });
  }

  viewProfileMedia(profile_id){
    this.http.get<Account>(environment.service.trackithubapi + '/api/inside/loadprofile?clean_group=yes&profile=' + profile_id).subscribe((profileData: any) => {
      this.bottomSheetService.openBottomSheetWithDataCustomClass(ProfileComponent, 'ProfileComponent',{
        'profileData': profileData,
        'tab': 'media'
      }, 'custom-bottom-p' );

    });
  }

  viewGroupMedia(group) {
    this.http.get<Account>(environment.service.trackithubapi + '/api/inside/loadgroup?profile_id=' + this.profile_id + '&clean_group=yes&group=' + group).subscribe((profileData: any) => {
      this.bottomSheetService.openBottomSheetWithDataCustomClass(ProfileComponent, 'ProfileComponent',{
        'profileData': profileData,
        'type': 'group',
        'tab': 'media'
      }, 'custom-bottom-p' );
    });
  }

}
