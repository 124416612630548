import {Component} from '@angular/core';
import {FormGroup} from '@angular/forms';

import {Field} from '../../models/field.interface';
import {FieldConfig} from '../../models/field-config.interface';

@Component({
    selector: 'app-radio',
    styleUrls: ['./radio.component.scss'],
    template: `<ng-container *ngIf="config.label">
        <label>{{config.label}}</label>
        </ng-container>
        <label for="{{config.name}}">{{config.placeholder}}</label>
    <mat-radio-group  (change)="onChange($event)">
        <ng-container *ngFor="let option of config.options">
          <mat-radio-button [name]="option" [value]="option">{{option}}</mat-radio-button>
          <br *ngIf="config.direction==='vert'">
        </ng-container>

    </mat-radio-group>

    <mat-form-field style="display: none;" [formGroup]="group" appearance="{{config.appearance}}" floatLabel="{{config.floatLabel}}">
      <input [(ngModel)]="this.value" matInput type="input" mask='{{config.mask}}' [placeholder]="config.placeholder"
             [formControlName]="config.name" [required]="config.required" [email]="config.email"
             maxlength="{{config.maxlength}}" #inputField>
    </mat-form-field>

    <br>`
})
export class RadioComponent implements Field {
    config: FieldConfig;
    group: FormGroup;
    value: any;

    onChange($event) {
      this.value = $event.value;
    }
}
