import { Component, Inject, OnInit } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';



@Component({
  selector: 'app-deletealbum-dialog',
  templateUrl: './deletealbum-dialog.component.html',
  styleUrls: ['./deletealbum-dialog.component.scss']
})
export class DeletealbumDialogComponent implements OnInit {

  album: any;

  constructor(public dialogRef: MatDialogRef<DeletealbumDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: {}) {
    this.album = data;
   }

  ngOnInit() {

  }



  onNoClick(): void {
    this.dialogRef.close({});
  }

  onYesClick(): void {
    this.dialogRef.close({'status': 'delete', 'album': this.album});
  }

}
