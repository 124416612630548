import {Component, ViewChild, AfterViewInit, ChangeDetectionStrategy} from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { FieldConfig } from '../../../dynamic-form/models/field-config.interface';
import { DynamicFormComponent } from '../../../dynamic-form/containers';
import { RecordsService} from '../../services/records.service';
import { ToastService } from '../../toast';


@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})

export class FooterComponent implements AfterViewInit {
    year: number = Date.now();
    records = {};

    @ViewChild(DynamicFormComponent, { static: true }) nform: DynamicFormComponent;

    formConfig: FieldConfig[] = [
      {
        type: 'input',
        label: 'Full Name',
        name: 'fname',
        placeholder: 'Full Name',
        validation: [Validators.required, Validators.minLength(2)],
        required: true,
      },
      {
        type: 'input',
        label: 'Email',
        name: 'email',
        placeholder: 'Enter your email',
        validation: [Validators.required, Validators.email],
        required: true,
      },
      {
        type: 'select',
        label: 'How can we help?',
        name: 'howcanwe',
        placeholder: 'How can we help?',
        validation: [Validators.required],
        required: true,
        options: ['Premier Pickleball Leagues', 'Club Play', 'General Info'],
      },
      {
        type: 'textarea',
        label: 'Message',
        name: 'message',
        placeholder: 'Enter your message',
        validation: [Validators.required, Validators.minLength(8)],
        required: true,
      },
      /** {
        type: 'recaptcha',
        label: '',
        name: '',
        placeholder: '',
        validation: [Validators.required],
        required: true,
      }, **/
      {
        label: 'Send',
        name: 'submit',
        type: 'button',
        color: 'primary',
        disabled: true,
      }
    ];

    title = 'Contact';
    description = 'TrackitHub\’s comprehensive program makes it easy to run Premier Pickleball Leagues and for admins to manage Pickleball Club activities.';

    /**
     * Todo: Robert
     * Need to add this back in once we go to v3
     * recaptchaReactive: new FormControl(null, Validators.required)
     * **/


    contactForm = new FormGroup({
        email: new FormControl('Enter your email', [Validators.required, Validators.email]),
        name: new FormControl('Enter your Full Name', [Validators.required]),
    }, {});

    getErrorMessage() {
      /**
       * Todo: Robert or Dave
       *
       /**  return this.email.hasError('required') ? 'You must enter a value' :
            this.email.hasError('email') ? 'Not a valid email' : ''; **/
    }


    constructor(private LoginService: RecordsService, private toastService: ToastService) {
    }

    ngAfterViewInit() {
        let previousValid = this.nform.valid;
        this.nform.changes.subscribe(() => {
          if (this.nform.valid !== previousValid) {
              previousValid = this.nform.valid;
              this.nform.setDisabled('submit', !previousValid);
          }
        });
        this.nform.submit.subscribe(et => {
            this.records = this.LoginService.sendMessage({'email': et.email, 'fname': et.fname, 'message': et.message, 'howcanwe': et.howcanwe}).subscribe(d => {
              this.toastService.show({
                text: `Message Sent. Please check your email.`,
                type: 'success',
                buttonText: '',
              });
              this.nform.nform.reset();
            });
        });
    }

}
