import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {FormControl, FormGroup} from "@angular/forms";
import {ImageCroppedEvent} from "ngx-image-cropper";





@Component({
  selector: 'app-uploadpicture-dialog',
  templateUrl: './uploadpicture-dialog.component.html',
  styleUrls: ['./uploadpicture-dialog.component.scss']
})
export class UploadPictureDialogComponent implements OnInit, AfterViewInit {

  myForm = new FormGroup({
    avatarupload: new FormControl(),
    albumname: new FormControl()
  });

  cropReady : boolean = false;
  imageChangedEvent: string = '';
  croppedImage: string = '';
  avatarImg: string;
  avatarPlaceholder: string;
  selectedAvatar: string = "";
  tempCroppedImage: string = "";







  constructor( @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<UploadPictureDialogComponent>) {

  }

  ngOnInit() {
  }

  ngAfterViewInit() {

  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.selectCustomOption();
  }
  imageLoaded() {
    this.cropReady = true;
  }
  cropperReady() {

  }
  loadImageFailed() {

  }

  selectCustomOption(){
    this.selectedAvatar = this.croppedImage;
    this.tempCroppedImage = this.selectedAvatar;
    this.avatarImg = this.selectedAvatar;
    this.avatarPlaceholder = "";
  }

  cancel() {
    this.dialogRef.close();
  }

  ngOnDestroy() {

  }

  onNoClick(): void {
    this.dialogRef.close({image: null});
  }

  onYesClick(): void {
    this.dialogRef.close({status: 'add', image: this.selectedAvatar, title: this.myForm.controls.albumname.value});
  }


}
