import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";


@Component({
  selector: 'app-verifyterms-modal',
  templateUrl: './verifyterms.component.html',
  styleUrls: ['./verifyterms.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})

export class VerifyTermsModalComponent implements OnInit, OnDestroy {




  constructor(public dialogRef: MatDialogRef<VerifyTermsModalComponent>) {

  }




  ngOnDestroy() {

  }

  ngOnInit() {

  }

  closepop(){
    this.dialogRef.close();
  }



}
