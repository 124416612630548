import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { DatePipe } from '@angular/common';
import {SessionStorageService} from 'ngx-webstorage';

export interface CalendarInterface {
  days: [
    {
      days: string
    }
  ];
  events: [
    {
      id: number,
      name: string
    }
  ];
  group_id: any;
  profile_id: any;
  datestring: any;
}

@Injectable()

export class CalendarService {

  private caltemp;

  private _url = '';

  private calendar = new BehaviorSubject<CalendarInterface>({
    days: [
      {
        days: null
      }
    ],
    events: null,
    group_id: null,
    profile_id: null,
    datestring: null,
  });

  cast = this.calendar.asObservable();
  dateTime = new Date();
  todaysDate = '';

  constructor(private http: HttpClient, private datePipe: DatePipe) {}

  pulldays(group_id, profile_id) {
    this.http.get<CalendarService>(environment.service.trackithubapi + '/api/inside/calendar_days?profile_id=' + profile_id + '&group_id=' + group_id).subscribe((data: any) => {

      this.todaysDate = this.datePipe.transform(this.dateTime, 'MM-dd-yyyy');

      this.http.get<CalendarService>(environment.service.trackithubapi + '/api/inside/calendar_events?profile_id=' + profile_id + '&group_id=' + group_id + '&datestring=' + this.todaysDate).subscribe((datas: any) => {
        this.calendar.next({
          days: data,
          events: datas,
          group_id: group_id,
          profile_id: profile_id,
          datestring: null,
        });
      });
    });
  }

  pullevents(group_id, profile_id, datestring) {


    localStorage.setItem('cal_group', group_id);
    localStorage.setItem('cal_profile', profile_id);
    localStorage.setItem('cal_datestring', datestring);


    this.cast.subscribe(data => {
      this.caltemp = data;
    });

    this.http.get<CalendarService>(environment.service.trackithubapi + '/api/inside/calendar_events?profile_id=' + profile_id + '&group_id=' + group_id + '&datestring=' + datestring).subscribe((data: any) => {
      this.calendar.next({
        days: this.caltemp.days,
        events: data,
        group_id: group_id,
        profile_id: profile_id,
        datestring: datestring,
      });
    });
  }

  reload(group_id, profile_id, datestring) {

    localStorage.setItem('cal_group', group_id);
    localStorage.setItem('cal_profile', profile_id);
    localStorage.setItem('cal_datestring', datestring);

    if (datestring != '') {
      this.pullevents(group_id, profile_id, datestring);
    } else {
      this.pulldays(group_id, profile_id);
    }
  }

  fastreload() {
    this.reload(localStorage.getItem('cal_group'), localStorage.getItem('cal_profile'), localStorage.getItem('cal_datestring'));
  }


}
