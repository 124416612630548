import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {BottomSheetService} from '../../../services/bottom-sheet.service';
import {Account} from "../../../interfaces/account";
import {environment} from "../../../../../environments/environment";
import {ProfileComponent} from "../../../../profile/profile.component";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-dashboard-card-header-overview',
  templateUrl: './dashboard-card-header-overview.component.html',
  styleUrls: ['./dashboard-card-header-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class DashboardCardHeaderOverviewComponent implements OnInit {
  @Input() card: any;
  @Input() isAdmin = false;
  @Input() organization: string;
  @Input() groupName: string;
  @Input() permissions: string;
  @Input() membership: number;
  @Input() rating: string;
  @Input() gender: string;
  @Input() ratingMin: string;
  @Input() ratingMax: string;
  @Input() group_id: string;
  @Input() event_id: string;
  @Input() isLeague: boolean;
  @Input() ownerid: string;
  @Input() avatarPlaceholder: string;
  @Input() avatarImg: string;
  @Input() clubcard: boolean;
  @Input() members: string;
  @Input() isPlayer: boolean;
  @Input() profile_id: any;



  constructor(private http: HttpClient, public bottomSheetService: BottomSheetService) {
    if (this.ratingMax == '.5') {
      this.ratingMax = '0.5';
    }
    if (this.ratingMax == '1') {
      this.ratingMax = '1.0';
    }
    if (this.ratingMax == '2') {
      this.ratingMax = '2.0';
    }
    if (this.ratingMax == '3') {
      this.ratingMax = '3.0';
    }
    if (this.ratingMax == '4') {
      this.ratingMax = '4.0';
    }
    if (this.ratingMax == '5') {
      this.ratingMax = '5.0';
    }

    if (this.ratingMin == '.5') {
      this.ratingMin = '0.5';
    }
    if (this.ratingMin == '1') {
      this.ratingMin = '1.0';
    }
    if (this.ratingMin == '2') {
      this.ratingMin = '2.0';
    }
    if (this.ratingMin == '3') {
      this.ratingMin = '3.0';
    }
    if (this.ratingMin == '4') {
      this.ratingMin = '4.0';
    }
    if (this.ratingMin == '5') {
      this.ratingMin = '5.0';
    }
  }

  viewgroup(group, name) {
      this.http.get<Account>(environment.service.trackithubapi + '/api/inside/loadgroup?profile_id=' + this.profile_id + '&clean_group=yes&group=' + group).subscribe((profileData: any) => {
        this.bottomSheetService.openBottomSheetWithDataCustomClass(ProfileComponent, 'ProfileComponent',{
          'profileData': profileData,
          'type': 'group'
        }, 'custom-bottom-p' );
      });
  }
  viewClub(profile_id) {
    if(environment.service.profile == true) {
      this.http.get<Account>(environment.service.trackithubapi + '/api/inside/loadprofile?clean_group=yes&profile=' + profile_id).subscribe((profileData: any) => {
        this.bottomSheetService.openBottomSheetWithDataCustomClass(ProfileComponent, 'ProfileComponent',{
          'profileData': profileData,
          'type': 'player'
        }, 'custom-bottom-p' );
      });
    }
  }

  ngOnInit() {

  }

}
