import {Directive, ElementRef, HostListener} from '@angular/core';


@Directive({
    selector: '[appInputExpand]'
})
export class InputExpandDirective {
    elWidth = '160px';

    constructor(private el: ElementRef) {
        this.el.nativeElement.style.width = this.elWidth;
    }

    @HostListener('mouseenter') onMouseEnter() {
       this.expand();
    }

    @HostListener('mouseleave') onMouseLeave() {
        this.contract();
    }

    private expand() {
        this.el.nativeElement.style.width = '920px';
        this.el.nativeElement.style.transition = 'width 0.8s ease';
        this.el.nativeElement.style.zIndex = 16777271;
        this.el.nativeElement.style.justifySelf = 'flex-end';
    }

    private contract() {
        this.el.nativeElement.style.width = this.elWidth;
        this.el.nativeElement.style.transition = 'width 0.8s ease';
    }
}








