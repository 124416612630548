import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { CreateLeagueDialogComponent } from './create-league-dialog/create-league-dialog.component';

@Component({
  selector: 'app-dashboard-create-league',
  templateUrl: './dashboard-create-league.component.html',
  styleUrls: ['./dashboard-create-league.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class DashboardCreateLeagueComponent implements OnInit {
  name: string;
  constructor(public dialog: MatDialog) { }
  openDialog(): void {
      const dialogRef = this.dialog.open(CreateLeagueDialogComponent, {
        minWidth: '350px',
      });
  }
  ngOnInit() {
  }

}


