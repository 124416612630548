import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material';
import {ActivatedRoute, Router} from "@angular/router";
import {LeagueEventviewComponent} from "../../league-eventview/league-eventview.component";
import {HttpClient} from "@angular/common/http";
import {BottomSheetService} from "../../../common/services/bottom-sheet.service";


@Component({
  selector: 'app-leagues-events',
  templateUrl: './league-events.component.html',
  changeDetection: ChangeDetectionStrategy.Default
})

export class LeaguesEventsComponent implements OnInit {

  sheetRef: any;

  datax: {
    city: null,
    id: {city: null, events: null, bottomref: any},
    ref: null
  }

  constructor(
    private _router: Router,
    private http: HttpClient,
    private route: ActivatedRoute,
    private bottomSheetService: BottomSheetService,
    private ref: ChangeDetectorRef,
      @Inject(MAT_BOTTOM_SHEET_DATA) public datar: any
  ) {  }

  leagueinfo() {
    window.open('https://blog.trackithub.com/premier-pickleball-league/', '_blank');
  }

  openleague(rp_id, event_id, is_team){
      //this.datax.id.bottomref.dismiss();
      this.bottomSheetService.openBottomSheetWithDataCustomClass(LeagueEventviewComponent, 'LeagueEventviewComponent',{
        event:event_id,
        repeat_id: rp_id,
        paid: false,
        is_team: is_team
      }, 'custom-bottom');
  }

  ngOnInit(): void {
    this.datax = this.datar;
    this.sheetRef  = this.datax.ref;
    this.ref.detectChanges();
  }

}
