import {Component, NgZone, ChangeDetectionStrategy, OnInit, Inject, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {RecordsService} from '../../common/services/records.service';
import {Backplane} from '../../common/services/backplane.service';
import { MAT_BOTTOM_SHEET_DATA} from '@angular/material';
import {ActivatedRoute} from '@angular/router';
import {ToastService} from '../../common/toast';
import {AccountService} from '../../common/services/account.service';
import {FormResetpasswordService} from '../../common/services/form-resetpassword.service';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-dashboard-resetpassword',
  templateUrl: './dashboard-resetpassword.component.html',
  styleUrls: ['./dashboard-resetpassword.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})

export class DashboardResetpasswordComponent implements OnInit, OnDestroy {

  storage: any;
  pwdPattern: any = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{8,25}$/;

  constructor(
    private http: HttpClient,
    private validationService: RecordsService,
    private getters: Backplane,
    private _ngZone: NgZone,
    private route: ActivatedRoute,
    private toastService: ToastService,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private accountService: AccountService,
    public formService: FormResetpasswordService,
  ) {
  }

  sheetRef: any = this.data.ref;
  id = this.data.id.id;
  status = false;

  ngOnInit() {

    // tslint:disable-next-line:class-name
    interface sModal {
      id: any;
      password: any;
      confirm_password: string;
    }

    this.storage = <sModal>{};


    this.formService.initializeFormGroup();
    this.formService.form.valueChanges.subscribe(data => {
      this.status = false;
      this.storage.id = this.id;
      this.storage.password = data.password;
      this.storage.confirm_password = data.confirm_password;
      if (this.pwdPattern.test(this.storage.password) === false) {
        this.formService.form.controls.password.setErrors({
          empty: true
        });
      }
      if (this.storage.password !== this.storage.confirm_password) {
        this.formService.form.controls.confirm_password.setErrors({
          empty: true
        });
      } else {
        if (this.pwdPattern.test(this.storage.password) === true) {
          this.status = true;
        }
      }
    });
  }

  finishReg() {
    this.http.post(environment.service.trackithubapi + '/api/inside/setnewpassword', this.storage, {}).subscribe((data: any) => {
      this.toastService.show({
        text: 'Password has been reset.',
        type: 'success',
      });
      this.accountService.reloadprofile();
      this.sheetRef.dismiss();
    });
  }

  ngOnDestroy() {

  }

}
