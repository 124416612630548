import {
  ChangeDetectionStrategy,
  Inject,
  Component,
  OnInit,
  ViewChild,
  HostListener,
  AfterViewInit, OnDestroy
} from '@angular/core';
import { MatPaginator, MatSort, MatTable, MatTableDataSource } from '@angular/material';
import { SelectionModel} from '@angular/cdk/collections';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { AccountService} from '../../common/services/account.service';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import { ToastService} from '../../common/toast';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { BottomSheetService } from '../../common/services/bottom-sheet.service';
import { DashboardEmailGroupSheetComponent} from '../index';
import { LoaderOverlayService } from '../../common/services/loader-overlay.service';
import {ObserversModule} from '@angular/cdk/observers';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DialogData, DialogRosterAdminAdd, DialogRosterAdminRemove} from "../dashboard-roster/roster-table.component";


export interface RosterElement {
  id: number;
  first_name: string;
  last_name: string;
  date: string;
  end_date: string;
  is_active: string;
  days_left: number
}




@Component({
  selector: 'app-trialadmin',
  templateUrl: './trialadmin.component.html',
  styleUrls: ['./trialadmin.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})



export class DashboardTrialAdminComponent implements OnDestroy, OnInit, AfterViewInit {
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatTable, {static: false}) table: MatTable<RosterElement>;

  s1 = null;

  stat1 = 0;
  stat2 = 0;
  stat3 = 0;
  stat4 = 0;

  lasturl = null;

  start_group_id = null;

  club_card_id = null;

  archived = false;

  smallScreen: boolean;
  bigScreen: boolean;
  archivedGroup = false;

  alltrials = false;
  inactivetrials = false;
  activetrials = true;

  grouptopull = null;

  selectedgroup = null;

  dataSource = new MatTableDataSource();
  selection = new SelectionModel<RosterElement>(true, []);

  data = [];

  account;
  active_group = null;
  group_type = 'club';
  param = null;


  constructor(private router: Router, public dialog: MatDialog, private bottomSheetService: BottomSheetService, private breakpointObserver: BreakpointObserver, private http: HttpClient, private accountService: AccountService, private route: ActivatedRoute, private toastService: ToastService, private LoaderOverlayService: LoaderOverlayService) {
    breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small
    ]).subscribe(result => {
      this.smallScreen = result.matches;
      this.bigScreen = !result.matches;
    });

    this.LoaderOverlayService.overlayRef = null;

    this.currentWindowWidth = window.innerWidth;




  }

  public currentWindowWidth: number;
  displayedColumns = [];


  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  alltrialss($event) {
    this.alltrials = true;
    this.inactivetrials = false;
    this.activetrials = false;
    this.pulldata();
  }

  activetrialss($event) {
    this.activetrials = true;
    this.alltrials = false;
    this.inactivetrials = false;
    this.pulldata();
  }

  inactivetrialss($event) {
    this.inactivetrials = true;
    this.activetrials = false;
    this.alltrials = false;
    this.pulldata();
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(<RosterElement>row));
  }

  onResize() {
    this.currentWindowWidth = window.innerWidth;
    this.redraw(this.data);
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


















  openExport() {
    this.toastService.show({
      text: `Check your download folder for your export.`,
      type: 'success',
    });
    window.location.href = this.lasturl + '&csv=true';
  }

  redraw(data) {
    if (this.LoaderOverlayService.overlayRef == null) {
      this.LoaderOverlayService.show();
    }
    this.data = data;
    this.displayedColumns = ['select', 'first_name', 'last_name', 'date', 'end_date', 'is_active', 'days_left'];
    this.dataSource.data = data;
    this.selection.clear();
    this.killoverlay();
  }

  killoverlay() {
    if (this.LoaderOverlayService.overlayRef != null) {
      this.LoaderOverlayService.hide();
    }
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit() {
    this.s1 = this.accountService.cast.subscribe(data => {
      this.account = data;
      this.pulldata();
    });
  }


  ngOnDestroy() {
    this.LoaderOverlayService.overlayRef = null;
    if (this.s1 != null) {
      this.s1.complete();
    }
  }


  pulldata() {
    if (this.LoaderOverlayService.overlayRef == null) {
      this.LoaderOverlayService.show('Loading Data...');
    }
    if (this.alltrials === true) {
      this.group_type = '1';
    }
    if (this.activetrials === true) {
      this.group_type = '2';
    }
    if (this.inactivetrials === true) {
      this.group_type = '3';
    }
    this.lasturl = environment.service.trackithubapi + '/api/inside/pulltrialinfo?type=' + this.group_type ;
    this.http.get<RosterElement>(this.lasturl).subscribe((data: any) => {

      this.stat1 = data.data.s1;
      this.stat2 = data.data.s2;
      this.stat3 = data.data.s3;
      this.stat4 = data.data.s4;



      this.redraw(data.data.list);
    });
  }

  openDialogReset(): void {
    if(this.selection.selected.length === 0){
      this.toastService.show({
        text: `You need to select at least one player.`,
        type: 'warn',
      });
    } else {

        const dialogRef = this.dialog.open(DialogTrialAdminReset, {
          data: {
            list: this.selection.selected,
          }
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result.event == 'Success') {
            this.http.post<RosterElement>(environment.service.trackithubapi + '/api/inside/trialreset', {
              'value': JSON.stringify(this.selection.selected)}, { }).subscribe((data: any) => {
              this.toastService.show({
                text: `Your request has been processed.`,
                type: 'success',
              });
              this.pulldata();
              this.selection.clear();
            });
          }
        });

    }
  }

  openDialogRemove(): void {
    if (this.selection.selected.length === 0) {
      this.toastService.show({
        text: `You need to select at least one player.`,
        type: 'warn',
      });
    } else {

        const dialogRef = this.dialog.open(DialogTrialAdminremove, {
          data: {
            list: this.selection.selected
          }
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result.event == 'Success') {
            this.http.post<RosterElement>(environment.service.trackithubapi + '/api/inside/trialremove', {
              'value': JSON.stringify(this.selection.selected)}, { }).subscribe((data: any) => {
              this.toastService.show({
                text: `Your request has been processed.`,
                type: 'success',
              });
              this.pulldata();
              this.selection.clear();
            });
          } else if (result.event == 'Error') {
            this.toastService.show({
              text: `System error, please try again.   If you continue to get this error please contact support.`,
              type: 'warn',
            });
          }
        });
      }
    }


}


@Component({
  selector: 'trialadmin-dialog-admin-reset',
  templateUrl: 'trialadmin-dialog-admin-reset.html',
  changeDetection: ChangeDetectionStrategy.Default
})
export class DialogTrialAdminReset {
  constructor(
    public dialogRef: MatDialogRef<DialogTrialAdminReset>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close({event:'Cancel'});
  }

  onYesClick(): void {
    this.dialogRef.close({event:'Success'});
  }
}

@Component({
  selector: 'trialadmin-dialog-admin-remove',
  templateUrl: 'trialadmin-dialog-admin-remove.html',
  changeDetection: ChangeDetectionStrategy.Default
})
export class DialogTrialAdminremove {
  constructor(
    public dialogRef: MatDialogRef<DialogTrialAdminremove>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close({event:'Cancel'});
  }

  onYesClick(): void {
    this.dialogRef.close({event:'Success'});
  }
}
