import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {FieldConfig} from '../../../dynamic-form/models/field-config.interface';
import {DynamicFormComponent} from '../../../dynamic-form/containers';
import {RecordsService} from '../../../common/services/records.service';

@Component({
    selector: 'app-privacy-settings',
    templateUrl: './privacy-settings.component.html',
    styleUrls: ['./privacy-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class PrivacySettingsComponent implements OnInit {
    privacyOptions: FieldConfig[] = [
        {
            type: 'checkbox',
            label: 'Email',
            name: 'emailPrivacy',
            required: false,
            checked: true
        },
        {
            type: 'checkbox',
            label: 'Phone',
            name: 'phonePrivacy',
            required: false,
            checked: true
        },
        {
            type: 'checkbox',
            label: 'Address',
            name: 'addressPrivacy',
            required: false,
            checked: true
        },
        {
            type: 'checkbox',
            label: 'Birthday',
            name: 'birthdayPrivacy',
            required: false,
            checked: true
        },
    ];

    constructor() {
    }

    ngOnInit() {
    }

}
