import {Component, Input, OnInit} from '@angular/core';
import {PPLEvent} from '../../shared/ppl.interface';

@Component({
  selector: 'app-ppl-card-details-expansion',
  templateUrl: './ppl-card-details-expansion.component.html',
  styleUrls: ['./ppl-card-details-expansion.component.scss']
})
export class PplCardDetailsExpansionComponent implements OnInit {
  @Input() event: PPLEvent;
  constructor() { }

  ngOnInit() {
  }
  mapsSelector() {

  }
}
