import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Field } from '../../models/field.interface';
import { FieldConfig } from '../../models/field-config.interface';

@Component({
  selector: 'app-form-input',
  styleUrls: ['form-recaptcha.component.scss'],
  template: `
    <div>
        <re-captcha siteKey='6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI' name="captcha" required></re-captcha>
        <br>
    </div>`
})
export class FormRecaptchaComponent implements Field {
  config: FieldConfig;
  group: FormGroup;
}
