import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  OnInit,
  AfterViewInit,
  ChangeDetectionStrategy
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Field } from '../../models/field.interface';
import { FieldConfig } from '../../models/field-config.interface';
import { SessionStorageService } from 'ngx-webstorage';
import { SearchAddressService } from '../../../common/services/searchaddress.service';
// @ts-ignore
import { } from '@types/googlemaps';


@Component({
  selector: 'app-form-input',
  styleUrls: ['form-autocomplete-address.component.scss'],
  template: `
      <mat-form-field [formGroup]="group" appearance="{{config.appearance}}">
          <input matInput [type]="config.type" mask='{{config.mask}}' [placeholder]="config.placeholder"
                  [formControlName]="config.name" [required]="config.required" [email]="config.email" #addresstext (keypress)="noEnter($event)" autocomplete="off">
          <mat-hint>{{config.label}}</mat-hint>
       </mat-form-field>`,
  changeDetection: ChangeDetectionStrategy.Default
})
export class FormAutocompleteAddressComponent implements Field, OnInit, AfterViewInit {
  config: FieldConfig;
  group: FormGroup;
  @Input() adressType: string;
  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  @ViewChild('addresstext', {static: false}) addresstext: any;

  autocompleteInput: string;
  queryWait: boolean;

  constructor(private sessionSt: SessionStorageService, private SearchAddress: SearchAddressService) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.getPlaceAutocomplete();
  }

  // Prevent search from submitting if user hits return
   noEnter(e) {
    const key = e.key ;
    return key !== 13;
  }

  private getPlaceAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement,
      {
        componentRestrictions: { country: ['US', 'UK', 'CA', 'MX'] },
        types: []  // 'establishment' / 'address' / 'geocode'
      });
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();
      this.SearchAddress.update(place.geometry.location.lat(), place.geometry.location.lng(), place.formatted_address);
      this.invokeEvent(place);
    });
  }

  invokeEvent(place: Object) {
    this.setAddress.emit(place);
  }

}
