import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-how-works',
  templateUrl: './how-works.component.html',
  styleUrls: ['./how-works.component.scss']
})
export class HowWorksComponent {
  @Input() heading: string;
  steps: any[] = [
    {
      image: '../../assets/angular-img/graphics/undraw_social_update.svg',
      caption: 'Create an Account'
    },
    {
      image: '../../assets/angular-img/graphics/undraw_following.svg',
      caption: 'Join a Club or League'
    },
    {
      image: '../../assets/angular-img/graphics/undraw_schedule.svg',
      caption: 'Sign up for Events'
    },
    {
      image: '../../assets/angular-img/graphics/undraw_connected.svg',
      caption: 'Connect with Players'
    },
  ];
  constructor() {}
}
