import {Component, OnInit} from '@angular/core';
import {PPLService} from '../shared/ppl.service';
import {PPLEvent} from '../shared/ppl.interface';
import {Observable} from 'rxjs';

@Component({
    selector: 'app-ppl-card',
    templateUrl: './ppl-card.component.html',
})
export class PplCardComponent implements OnInit {
    events: Observable<PPLEvent[]>;

    constructor(private eventService: PPLService) {
    }

    ngOnInit() {
        this.events = this.eventService.getPPLs();
    }

}
