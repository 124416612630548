import {Component, OnInit, ViewChild, Input, AfterViewInit, OnDestroy} from '@angular/core';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {FieldConfig} from '../../dynamic-form/models/field-config.interface';
import { DynamicFormSearchfilter1Component } from '../../dynamic-form/containers';
import { DynamicFormSearchfilter2Component } from '../../dynamic-form/containers';
import { DynamicFormSearchfilter3Component } from '../../dynamic-form/containers';
import { DynamicFormSearchfilter4Component } from '../../dynamic-form/containers';
import { DynamicFormSearchfilter5Component } from '../../dynamic-form/containers';
import { DynamicFormSearchfilter6Component } from '../../dynamic-form/containers';
import { DynamicFormSearchfilter7Component } from '../../dynamic-form/containers';
import { DynamicFormSearchfilter8Component } from '../../dynamic-form/containers';
import {Location} from '@angular/common';
import { SessionStorageService } from 'ngx-webstorage';
import { SearchAddressService } from '../../common/services/searchaddress.service';
import {Subscription} from "rxjs";

export interface MapFilter {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-map-search-filter',
  templateUrl: './map-search-filter.component.html',
  styleUrls: ['./map-search-filter.component.scss']
})
export class MapSearchFilterComponent implements AfterViewInit, OnDestroy {
  smallScreen: boolean;
  bigScreen: boolean;

  rating: boolean;
  gender: boolean;

  private suba: Subscription;

  // remove {value: 'Public', viewValue: 'Public'}

  formConfig1: FieldConfig[] = [
    {
      type: 'selectspecial',
      name: 'play',
      label: 'Play',
      color: 'primary',
      required: false,
      options: [ {value: 'Clubs', viewValue: 'Clubs'}]}
        ];

  formConfig2: FieldConfig[] = [
    {
      type: 'places',
      name: 'location',
      placeholder: 'Where?',
    }  ];

  formConfig3: FieldConfig[] = [
    {
      type: 'selectspecial',
      name: 'skillrating',
      label: 'Skill Rating',
      required: false,
      options: [      {value: 'noRating', viewValue: 'No Rating'},
        {value: '1.0', viewValue: '1.0'},
        {value: '1.5', viewValue: '1.5'},
        {value: '2.0', viewValue: '2.0'},
        {value: '2.5', viewValue: '2.5'},
        {value: '3.0', viewValue: '3.0'},
        {value: '3.5', viewValue: '3.5'},
        {value: '4.0', viewValue: '4.0'},
        {value: '4.5', viewValue: '4.5'},
        {value: '5.0', viewValue: '5.0'}],
    }  ];

  formConfig4: FieldConfig[] = [
    {
      type: 'selectspecial',
      name: 'playtype',
      label: 'Type',
      placeholder: 'Type',
      required: false,
      options: [      {value: 'men', viewValue: 'Men\'s'},
        {value: 'women', viewValue: 'Women\'s'},
        {value: 'mixed', viewValue: 'Mixed'},
        {value: 'genderless', viewValue: 'Genderless'}]
    }
  ];

  @ViewChild(DynamicFormSearchfilter1Component, {static: true}) sform1: DynamicFormSearchfilter1Component; // play
  @ViewChild(DynamicFormSearchfilter2Component, {static: true}) sform2: DynamicFormSearchfilter2Component; // where
  @ViewChild(DynamicFormSearchfilter3Component, {static: true}) sform3: DynamicFormSearchfilter3Component; // skill
  @ViewChild(DynamicFormSearchfilter4Component, {static: true}) sform4: DynamicFormSearchfilter4Component; // type
  @ViewChild(DynamicFormSearchfilter5Component, {static: true}) sform5: DynamicFormSearchfilter5Component; // play
  @ViewChild(DynamicFormSearchfilter6Component, {static: true}) sform6: DynamicFormSearchfilter6Component; // where
  @ViewChild(DynamicFormSearchfilter7Component, {static: true}) sform7: DynamicFormSearchfilter7Component; // skill
  @ViewChild(DynamicFormSearchfilter8Component, {static: true}) sform8: DynamicFormSearchfilter8Component; // type

  constructor(private _location: Location, private breakpointObserver: BreakpointObserver, private sessionSt: SessionStorageService,  private searchSubject: SearchAddressService) {
    breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small
    ]).subscribe(result => {
      this.smallScreen = result.matches;
      this.bigScreen = !result.matches;
    });

    this.rating = false;
    this.gender = false;

  }

  backClicked() {
    this._location.back();
  }


  ngOnDestroy() {
    if (this.suba !== undefined) {
      this.suba.unsubscribe();
    }
  }

  ngAfterViewInit() {




    this.suba = this.searchSubject.cast.subscribe(data => {



      if(this.sessionSt.retrieve('play_type') == 'Clubs') {
        this.sform1.sform1.controls.play.setValue('Clubs');
        this.sform5.sform5.controls.play.setValue('Clubs');
      } else {
        this.sform1.sform1.controls.play.setValue('Clubs');
        this.sform5.sform5.controls.play.setValue('Clubs');
      }

      if (data.results === null ) {

        this.sform1.sform1.controls.play.setValue('Clubs');
        this.sform5.sform5.controls.play.setValue('Clubs');
        this.searchSubject.forcesearch();
      }



    });

    if(this.sform2.sform2.controls.location !== undefined) {


      this.sform2.sform2.controls.location.setValue(this.sessionSt.retrieve('address_field'));
      //this.sform1.sform1.controls.play.setValue(this.sessionSt.retrieve('play_type'));
      this.sform1.sform1.controls.play.valueChanges.subscribe( data => {

        if ( data === 'leagues') {
          this.rating = true;
          this.gender = true;
        } else {
          this.rating = false;
          this.gender = false;
        }
        this.sessionSt.store('play_type', data);
        this.searchSubject.forcesearch();
      });

    }

    if(this.sform6.sform6.controls.location !== undefined) {




      this.sform6.sform6.controls.location.setValue(this.sessionSt.retrieve('address_field'));
      //this.sform5.sform5.controls.play.setValue(this.sessionSt.retrieve('play_type'));
      this.sform5.sform5.controls.play.valueChanges.subscribe( data => {
        if ( data === 'leagues') {
          this.rating = true;
          this.gender = true;
        } else {
          this.rating = false;
          this.gender = false;
        }
        this.sessionSt.store('play_type', data);
        this.searchSubject.forcesearch();
      });

    }



  }
}
