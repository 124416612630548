import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';



@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class PrivacyComponent implements OnInit {

  @Input() accountdata: any;

  constructor() { }

  ngOnInit() {
  }

}
