import {InjectionToken, TemplateRef} from '@angular/core';

export class ToastData {
  type: ToastType;
  text?: string;
  html?: any;
  buttonText?: string;
  buttonURL?: string;
  buttonColor?: any;
  template?: TemplateRef<any>;
  templateContext?: {};
}

export type ToastType = 'info' | 'alert' | 'warn' | 'success';

export interface ToastConfig {
  position?: {
    top: number,
    left: number,
  };
  animation?: {
    fadeOut: number;
    fadeIn: number
  };
  enableHtml?: boolean;
}

export const defaultToastConfig: ToastConfig = {
    position: {
    top: 0,
    left: 0,
  },
  animation: {
    fadeOut: 2500,
    fadeIn: 300,
  },
  enableHtml: true
};
export const TOAST_CONFIG_TOKEN = new InjectionToken('toast-config');
