import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-affiliation',
    templateUrl: './affiliation.component.html',
    styleUrls: ['./affiliation.component.scss']
})
export class AffiliationComponent implements OnInit {
    title = 'Affiliations and Endorsements';
    affiliates: any[] = [
        {
            'name': 'USAPA Pickleball',
            'image': '../../../assets/angular-img/affiliations/USAPickleball_Logo.png',
            'url': 'https://www.usapa.org/',
            'description': 'United States of America Pickleball Association has endorsed TrackitHub\'s Pickleball League &amp; Club Management Program since 2014.',
            'buttonText': 'Visit',
        },
        {
            'name': 'High Performance Pickleball',
            'image': '../../../assets/angular-img/affiliations/high-performance-pickelball.png',
            'url': 'https://trackithub.com/',
            'description': 'High Performance Pickleball provides intense pickleball bootcamps and clinics which focus on strategy, technique, skill development, positioning, and mental toughness. Hosted by three IPTPA certified professionals; Scott, Daniel, and Jon Moore.',
            'buttonText': 'Visit',
        },
        {
          'name': 'Slam Master Pro',
          'image': '../../../assets/angular-img/affiliations/slam_master_pro.jpg',
          'url': 'http://slammaster.net/',
          'description': 'New! Revolutionary! Slam Master Pickleball training/practice/drill paddles.  Bring your game to the next level with a Slam Master Paddle.',
          'buttonText': 'Visit',
        },
        {
          'name': 'Pickleball Live',
          'image': '../../../assets/angular-img/affiliations/PickleballBrand.PNG',
          'url': 'https://trackithub.com/',
          'description': 'Pickleball Live is made for the community from the community.',
          'buttonText': 'Visit',
        }
    ];


    constructor() {
    }

    ngOnInit() {
    }

}
