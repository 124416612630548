import {Component, OnInit, AfterViewInit, ViewChild, Inject, ChangeDetectionStrategy} from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA} from '@angular/material';
import { MatPaginator, MatSort, MatTable, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import {DashboardReadEmailSheetComponent} from "../dashboard-reademail-sheet/dashboard-reademail-sheet.component";
import {BottomSheetService} from "../../common/services/bottom-sheet.service";

export interface RenewalElement {
  name: string;
  id: string;
  created: string;
  enddate: string;
  group_id: string;
}

@Component({
  selector: 'app-dashboard-email-sheet',
  templateUrl: './dashboard-email-sheet.component.html',
  styleUrls: ['./dashboard-email-sheet.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})

export class DashboardEmailSheetComponent implements OnInit, AfterViewInit {

  title: any = this.data.id.title;
  listdata: RenewalElement[] = this.data.id.list.data;
  sheetRef: any = this.data.ref;
  displayedColumns: string[] = ['subject','group_name', 'sent'];
  dataSource = new MatTableDataSource();

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatTable, {static: false}) table: MatTable<RenewalElement>;



  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any, private router: Router,public bottomSheetService: BottomSheetService) {}

  ngOnInit() {
    this.dataSource.data = this.listdata;
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  show(email) {
    this.sheetRef.dismiss();
    this.bottomSheetService.openBottomSheetWithData(DashboardReadEmailSheetComponent, 'DashboardReadEmailSheetComponent',{list: {subject: email.subject, body: email.body}});
  }
}
