import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject, Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {AccountService} from '../../common/services/account.service';
import {Account} from '../../common/interfaces/account';
import {Subscription} from "rxjs";
import {environment} from "../../../environments/environment";
import {MatPaginator, MatSort, MatTable, MatTableDataSource } from '@angular/material';
import {SelectionModel} from "@angular/cdk/collections";
import {HttpClient} from "@angular/common/http";
import {renewfriends} from "../../common/interfaces/renewfriends";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ToastService} from "../../common/toast";
import {ProfileComponent} from "../../profile/profile.component";
import {BottomSheetService} from '../../common/services/bottom-sheet.service';
import {DashboardEventviewComponent} from "../dashboard-eventview/dashboard-eventview.component";
import {DashboardSettingsComponent} from "../dashboard-settings/dashboard-settings.component";
import {Router} from "@angular/router";
import {MatBottomSheetRef} from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-dashboard-globalnotifications',
  templateUrl: './dashboard-globalnotifications.component.html',
  styleUrls: ['./dashboard-globalnotifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})


export class DashboardGlobalNotificationsComponent implements OnInit, OnDestroy, AfterViewInit  {

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;


  notifications: any;

  dataSource = new MatTableDataSource();
  selection = new SelectionModel<renewfriends>(true, []);
  displayedColumns = [];
  siteUrl = environment.service.trackithubapi + '/';
  account: Account;

  private suba: Subscription;

  constructor(private bottomSheetRef: MatBottomSheetRef, private router: Router, private changeDetectorRef: ChangeDetectorRef, private accountService: AccountService, private http: HttpClient, public dialog: MatDialog,private toastService: ToastService, public bottomSheetService: BottomSheetService) {}

  ngOnDestroy() {
    if (this.suba !== undefined) {
      this.suba.unsubscribe();
    }
  }

  ngOnInit() {
    this.suba = this.accountService.cast.subscribe(data => {
      this.account = data;
      this.drawscreen();
    });
  }

  drawscreen(){
    this.http.get<renewfriends>(environment.service.trackithubapi + '/api/inside/get_globalnotifications?profile=' + this.account.current_profile.profile_id).subscribe((data: any) => {
      this.notifications = data;
      this.displayedColumns = ['notification','action'];
      this.dataSource.data = this.notifications;
      this.changeDetectorRef.detectChanges();
    });
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  viewProfile(id, profile_id){

    this.http.get<any>(environment.service.trackithubapi + '/api/inside/update_globalnotifications?profile_id=' + this.account.current_profile.profile_id + '&type=viewed&value=1&notification_id=' + id).subscribe((data: any) => {
      this.http.get<Account>(environment.service.trackithubapi + '/api/inside/loadprofile?clean_group=yes&profile=' + profile_id).subscribe((profileData: any) => {
        this.bottomSheetService.openBottomSheetWithDataCustomClass(ProfileComponent, 'ProfileComponent',{
          'profileData': profileData,
        }, 'custom-bottom-p' );

      });
    });

  }

  viewGroup(id, group) {

    this.http.get<any>(environment.service.trackithubapi + '/api/inside/update_globalnotifications?profile_id=' + this.account.current_profile.profile_id + '&type=viewed&value=1&notification_id=' + id).subscribe((data: any) => {
      this.http.get<any>(environment.service.trackithubapi + '/api/inside/loadgroup?profile_id=' + this.account.current_profile.profile_id + '&clean_group=yes&group=' + group).subscribe((profileData: any) => {
        this.bottomSheetService.openBottomSheetWithDataCustomClass(ProfileComponent, 'ProfileComponent', {
          'profileData': profileData,
          'type': 'group'
        }, 'custom-bottom-p');
      });
    });

  }


  viewevent(id, rpt){

    this.http.get<any>(environment.service.trackithubapi + '/api/inside/update_globalnotifications?profile_id=' + this.account.current_profile.profile_id + '&type=viewed&value=1&notification_id=' + id).subscribe((data: any) => {
      this.http.get<any>(environment.service.trackithubapi + '/api/inside/calendar_events?profile_id=' + this.account.current_profile.profile_id + '&rp_id=' + rpt).subscribe((data: any) => {
        this.bottomSheetService.openBottomSheetWithDataCustomClass(DashboardEventviewComponent, 'DashboardEventviewComponent',{eventdetail: data}, 'custom-bottom' );
      });
    });

  }

  viewscorecard(id, rr_id) {

    this.http.get<any>(environment.service.trackithubapi + '/api/inside/update_globalnotifications?profile_id=' + this.account.current_profile.profile_id + '&type=viewed&value=1&notification_id=' + id).subscribe((data: any) => {
      this.router.navigate(['/dashboard/frame/getscorecard'], { queryParams: { rr_id: rr_id } });
      this.bottomSheetRef.dismiss();
    });

  }


  viewsettings(id){

    this.http.get<any>(environment.service.trackithubapi + '/api/inside/update_globalnotifications?profile_id=' + this.account.current_profile.profile_id + '&type=viewed&value=1&notification_id=' + id).subscribe((data: any) => {
      this.bottomSheetService.openBottomSheetWithData(DashboardSettingsComponent, 'DashboardSettingsComponent', {'tab': 'settings'});
    });

  }

  delete(id) {

    const dialogRef = this.dialog.open(DialogDeleteNotification, {
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == 'Success') {
        this.http.get<any>(environment.service.trackithubapi + '/api/inside/update_globalnotifications?profile_id=' + this.account.current_profile.profile_id + '&type=hidden&value=1&notification_id=' + id).subscribe((data: any) => {
          this.toastService.show({
            text: 'Notification has been deleted.',
            type: 'success',
            buttonText: '',
          });
          this.drawscreen();
        });
      } else if (result.event == 'Error') {
        this.toastService.show({
          text: `System error, please try again.   If you continue to get this error please contact support.`,
          type: 'warn',
        });
      }
    });



  }

}


@Component({
  selector: 'app-dialog-delete-notification',
  templateUrl: 'dialog-delete-notification.html',
  styleUrls: ['dialog-delete-notification.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class DialogDeleteNotification {
  constructor(
    public dialogRef: MatDialogRef<DialogDeleteNotification>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  onNoClick(): void {
    this.dialogRef.close({event: 'Cancel'});
  }

  onYesClick(): void {
    this.dialogRef.close({event: 'Success'});
  }
}

