import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';


@Component({
    selector: 'app-pricing',
    templateUrl: './pricing.component.html',
    styleUrls: ['./pricing.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class PricingComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
