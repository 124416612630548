import {ChangeDetectionStrategy, Component, ViewChild} from '@angular/core';
import {TourService} from 'ngx-tour-md-menu';
import {MatMenuTrigger} from '@angular/material';
import { DashboardSettingsComponent } from '../../dashboard-settings/dashboard-settings.component';
import { BottomSheetService } from 'src/app/common/services/bottom-sheet.service';

@Component({
  selector: 'app-settings-menu',
  templateUrl: './settings-menu.component.html',
  styleUrls: ['./settings-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class SettingsMenuComponent {
  @ViewChild(MatMenuTrigger, {static: false}) trigger: MatMenuTrigger;
  constructor(public tourService: TourService, private bottomSheetService: BottomSheetService) { }

  logout(event) {
    window.location.replace('/index.php?logoutfast=yes');
  }

  beginTour() {
      this.trigger.closeMenu();
      this.tourService.start();
  }

  openSettings() {
    this.bottomSheetService.openBottomSheetWithData(DashboardSettingsComponent, 'DashboardSettingsComponent','');
  }
}
