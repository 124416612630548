import {Component} from '@angular/core';
import {FormGroup} from '@angular/forms';

import {Field} from '../../models/field.interface';
import {FieldConfig} from '../../models/field-config.interface';

@Component({
  selector: 'app-form-checkbox',
  styleUrls: ['./form-checkbox.component.scss'],
  template: `
      <div [formGroup]="group">
          <ng-container *ngIf="config.title">
              <p>{{config.title}}</p>
          </ng-container>
          <ng-container *ngIf="!config.options">
              <mat-checkbox [value]="config.label" (change)="onChkChange()" [checked]="config.checked" [(ngModel)]="config.checked"
                            [formControlName]="config.name" [required]="config.required" [aria-label]="config.label">
                  {{ config.label }}
              </mat-checkbox>
          </ng-container>
          <ng-container *ngIf="config.options && config.nogroup !== true">
              <mat-checkbox *ngFor="let option of config.options" [value]="option" (change)="onChkChange()"
                            [checked]="config.checked"
                            [formControlName]="config.name" [required]="config.required" [aria-label]="option">
                  {{ option.name }}
                  <ng-container *ngIf="option.svgIcon">
                  <mat-icon [svgIcon]="option.svgIcon" color="primary"></mat-icon>
                  </ng-container>
                  <ng-container *ngIf="option.icon">
                      <mat-icon aria-hidden="false" aria-label="Example home icon" color="primary">{{option.icon}}</mat-icon>
                  </ng-container>
              </mat-checkbox>
          </ng-container>
          <ng-container *ngIf="config.options && config.nogroup === true">
            <ng-container *ngFor="let option of config.options">
              <mat-checkbox [value]="option" (change)="onChkChange()"
                            [formControlName]="option.name" [required]="config.required" [aria-label]="option">
                {{ option.name }}
                <ng-container *ngIf="option.svgIcon">
                  <mat-icon [svgIcon]="option.svgIcon" color="primary"></mat-icon>
                </ng-container>
                <ng-container *ngIf="option.icon">
                  <mat-icon aria-hidden="false" aria-label="Example home icon" color="primary">{{option.icon}}</mat-icon>
                </ng-container>
              </mat-checkbox>
              <br *ngIf="config.direction === 'vert'">
                </ng-container>
          </ng-container>
      </div>`
})
export class FormCheckboxComponent implements Field {
  config: FieldConfig;
  group: FormGroup;
  checked = false;

  onChkChange() {
    this.checked = (this.checked !== true);
  }
}
