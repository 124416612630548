import {ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild} from '@angular/core';
import {MatBottomSheet, MatBottomSheetRef} from '@angular/material';

@Component({
  selector: 'app-bottom-sheet-heading',
  templateUrl: './bottom-sheet-heading.component.html',
  styleUrls: ['./bottom-sheet-heading.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class BottomSheetHeadingComponent {
  @Input() icon: string;
  @Input() svgIcon: string;
  @Input() headingTitle: string;
  @Input() stopclose: boolean;

  constructor(private bottomSheetRef: MatBottomSheetRef) {}

  bottomSheetDismiss() {
    this.bottomSheetRef.dismiss();
  }
}
