import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-title',
    styleUrls: ['./title.component.scss'],
    template: `
        <div>
            <h3 class="text-center text-white">{{heading}}</h3>
        </div>`,
  changeDetection: ChangeDetectionStrategy.Default
})
export class TitleComponent implements OnInit {
    @Input() heading: string;

    ngOnInit() {
    }

}
