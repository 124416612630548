import {ChangeDetectionStrategy, Component, Input, Output, EventEmitter} from '@angular/core';
import {SnackbarService} from "../../../services/snackbar.service";
import {environment} from "../../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {ToastService} from "../../../toast";
import {AccountService} from "../../../services/account.service";
import {SessionStorageService} from "ngx-webstorage";
import {MygroupsService} from "../../../services/mygroups.service";

@Component({
  selector: 'app-group-archive',
  styleUrls: ['./group-archive.component.css'],
  templateUrl: './group-archive.component.html',
  changeDetection: ChangeDetectionStrategy.Default
})
export class GroupArchiveComponent {
  @Input() groupName: string;
  @Input() group_id: string;
  @Output() tabs = new EventEmitter<any>();

  constructor(private sessionSt: SessionStorageService, private mygroup: MygroupsService, private snackBar: SnackbarService, private http: HttpClient,private toastService: ToastService, private accountService: AccountService) { }

  unArchived(group_id) {
    this.http.get<any>(environment.service.trackithubapi + '/api/inside/togglearchive?group_id=' + group_id + '&flag=0').subscribe((data: any) => {
      this.accountService.reloadprofile();
      this.toastService.show({
        text: 'Group has been unarchived.',
        type: 'success',
        buttonText: '',
      });
      this.mygroup.load(this.sessionSt.retrieve('current_profile'), null);

      this.tabs.emit((1 + 1) % 2);
    });
  }
}
