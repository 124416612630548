import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormAccountPaypalService {

  constructor() { }

  form: FormGroup = new FormGroup({
    currency: new FormControl(),
    paypal: new FormControl(),
    group: new FormControl(),
    allgroups: new FormControl()
  });

}
