import {
  Component,
  ViewEncapsulation,
  ViewChild,
  AfterViewInit,
  ChangeDetectionStrategy,
  OnInit,
  OnDestroy
} from '@angular/core';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { EventInput } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {AccountService} from "../../common/services/account.service";
import {Router} from "@angular/router";
import {DomSanitizer} from "@angular/platform-browser";
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DashboardEventviewComponent} from "src/app/dashboard/dashboard-eventview/dashboard-eventview.component";
import {BottomSheetService} from "../../common/services/bottom-sheet.service";
@Component({
  selector: 'app-events-calendar-full',
  templateUrl: './events-calendar-full.component.html',
  styleUrls: ['./events-calendar-full.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
  changeDetection: ChangeDetectionStrategy.Default
})
export class EventsCalendarFullComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild('calendar', {static: true}) calendarComponent: FullCalendarComponent;

  calendarPlugins = [dayGridPlugin];
  calendarWeekends = true;
  calendarEvents: EventInput[] = [];
  account: any;
  s1 = null;

  constructor(private bottomSheetService: BottomSheetService, private http: HttpClient, private accountService: AccountService, private router: Router, public dialogRef: MatDialogRef<EventsCalendarFullComponent>,) { }

  ngOnInit() {
    this.s1 = this.accountService.cast.subscribe(data => {
      this.account = data;
    });
  }

  ngAfterViewInit() {

  }


  handleEventClick($event){

    var str = $event.event.id;

    if(str.indexOf('|') !== -1){
      var splitted = str.split('|', 2);
      this.dialogRef.close();
      this.bottomSheetService.openBottomSheetWithDataCustomClass(DashboardEventviewComponent, 'DashboardEventviewComponent',{
        event: splitted[0],
        repeat_id: splitted[1]
      }, 'custom-bottom');

    } else {
      this.bottomSheetService.openBottomSheetWithDataCustomClass(DashboardEventviewComponent, 'DashboardEventviewComponent',{
        event: str,
        repeat_id: 0
      }, 'custom-bottom');
    }


  }

  handleDateClick(arg) {
      this.http.get<EventInput>(environment.service.trackithubapi + '/api/inside/calendar_events_full?profile_id=' + this.account.current_profile.profile_id + '&datestring1=' + this.formatDate(arg.view.currentStart,0) + '&datestring2='+ this.formatDate(arg.view.currentEnd, -1)).subscribe((datas: any) => {
        if(datas != null) {
          this.calendarEvents = datas;
        }
      });
  }

  formatDate(date,addDay) {

    if(addDay != 0){
      var e = new Date(date);
      e.setDate(e.getDate() + addDay);
    } else {
      var e = new Date(date);
    }

    var d = new Date(e),
      month = '' + (d.getMonth() + 1 ),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  ngOnDestroy() {
    if (this.s1 != null) {
      this.s1.complete();
    }
  }

}
