import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import {MenuItems} from '../interfaces/menu-items';
import {SessionStorageService} from 'ngx-webstorage';
import {Account} from '../interfaces/account';
import {Router} from '@angular/router';
import { LoaderOverlayService } from './loader-overlay.service';
import {DomSanitizer} from "@angular/platform-browser";

@Injectable()

export class AccountService {
  tempobj = {};

  MenuItems = [];
  private _url = '';

  private account = new BehaviorSubject<Account>({
    id: null,
    reset_password: 0,
    current_profile: {
      relationship: null,
      splash: null,
      is_account: null,
      account_owner: null,
      profile_type: null,
      avatar: null,
      avataricon: null,
      email: null,
      group_id: null,
      username: null,
      lastvisitDate: null,
      league: null,
      name: null,
      profile_id: null,
      registerDate: null,
      profile_rating: null,
      birthday: null,
      seckey: null,
      default_profile: null,
      premium: null,
      is_trial: false,
      trial_expires: null,
      show_trial: false,
      group_count: 0,
      trial_left: 0,
      subscription: false,
      subscription_plan: null,
      subscription_transaction: null,
      next_rebill: null,
      ambassador: null,
      ambassador_perc: null,
      raw: [{
        name: null,
        firstName: null,
        lastName: null,
        email: null,
        altEmail: null,
        address: null,
        altAddress: null,
        phone: null,
        altphone: null,
        phonemobile: null,
        altmobile: null,
        splash: null,
        emailprim: null,
        emailaltprim: null,
        addressprim: null,
        altaddressprim: null,
        rating: null,
        gender: null,
        birthday: null,
        cfname: null,
        clname: null
      }],
      locations: [{
        Outdoor: null,
        Indoor: null,
        Permanent: null,
        Temporary: null,
        contact_first: null,
        contact_last: null,
        numberofcourts: null,
        courttype: null,
        venuetype: null,
        restroom: null,
        water: null,
        shower: null,
        accessible: null,
        wifi: null,
        lights: null,
        loc_id: null,
        title: null,
        alias: null,
        street: null,
        postcode: null,
        city: null,
        state: null,
        country: null,
        image: null,
        imagetitle: null,
        description: null,
        geolan: null,
        geoilat: null,
        geozoom: null,
        pcode_id: null,
        targetmenu: null,
        overlaps: null,
        phone: null,
        url: null,
        loccat: null,
        anonname: null,
        anonemail: null,
        mapicon: null,
        catid: null,
        global: null,
        priority: null,
        ordering: null,
        access: null,
        published: null,
        created: null,
        created_by: null,
        created_by_alias: null,
        modified: null,
        modified_by: null,
        checked_out: null,
        checked_out_time: null,
        params: null,
        contact_person: null,
        email: null,
        indoor_courts: null,
        outdoor_courts: null,
        groups: [],
        fix: null,
      }],
      groups: [{
        id: '0',
        name: '',
        owner: 0,
        league: 0,
        permissions: 0,
        approved: 0,
        organization: '',
        membership: 0,
        paypal: '',
        currency: '',
        avatar: '',
        avatar_icon: '',
        ownerid: '',
        average: '',
        num: '',
        win: '',
        standings: '',
        archive: 0,
        gender: '',
        minrate: '',
        is_league: 0,
        maxrate: '',
        dues: '',
        chip1: '',
        chip2: '',
        chip3: '',
        chip4: '',
        label: '',
        primary_location: '',
        loc_as_group: false,
        league_total: 0,
        approvals: true,
        title: '',
        description: '',
        params: {
          wallnotification: true
        },
        renewals: [{
          user_id: '',
          name: 'No new renewals',
          group_id: '',
          current_id: '',
          created: '',
          end_date: ''
        }],
        emails: [{
          email_id: '',
          subject: '',
          sent: '',
          hide: ''
        }],
        waiting: [{
          id: '',
          name: '',
          group_id: '',
        }],
        members: '0',
        clubcard: false,
        subgroup: '0',
        newmembers: '0',
        currentevents: '0',
        sscount: '0',
        rrcount: '0',
        fix: 0,
        loc_fix: 0,
        groupct: 1
      }],
      loc_fix: 0
    }, profiles: [
      {
        id: 0,
        name: ' ',
        default_profile: 0,
        avatar: '',
        avataricon: '',
        profile_type: '',
      }
    ], menuItems: []
  });

  cast = this.account.asObservable();

  constructor(private LoaderOverlayService: LoaderOverlayService, private http: HttpClient, private sessionSt: SessionStorageService,    private router: Router,  private domSanitizer: DomSanitizer ) {}

  load() {
    this.http.get<Account>(environment.service.trackithubapi + '/api/inside/accountload?current_profile=' + this.sessionSt.retrieve('current_profile')).subscribe((data: any) => {
      this.pullNavigation(data.current_profile.group_id, data.current_profile.league);
      this.http.get<MenuItems[]>(environment.service.trackithubapi + this._url).subscribe((jdata: any) => {
        if(data.current_profile.profile_id > 0) {
          this.sessionSt.store('current_profile', data.current_profile.profile_id);
        }

        if (data.current_profile.groups.length === 0 && data.current_profile.profile_type === 'player') {
          data.current_profile.groups = [{
            name: 'Example Group',
            organization: 'Example Club',
            approved: '1',
            archive: '0',
            avatar_icon: 'smile',
            clubcard: true,
            id: 0,
            membership: 0,
            permissions: 1,
            renewals: [{

            }],
            emails: [],
            waiting: [{
             name: 'Richard Smith'
            }],
            owner: '0',
            league: '0',
            average: '50',
            num: 5,
            members: 0,
            newmembers: 0,
            currentevents: 0,
            subgroup: 0,
            chip1: null,
            chip2: null,
            chip3: null,
            chip4: null,
            loc_fix: 0,
            fix: 0,
            groupct: 0
          }];
        }

        if (data.current_profile.groups.length === 0 && data.current_profile.profile_type !== 'player') {
          data.current_profile.groups = [{
            name: 'Example Group',
            organization: 'Example Club',
            approved: '1',
            archive: '0',
            avatar_icon: 'smile',
            clubcard: true,
            id: 0,
            membership: 0,
            permissions: 1,
            renewals: [{

            }],
            emails: [],
            waiting: [{
              name: 'Richard Smith'
            }],
            owner: '1',
            league: '0',
            average: '50',
            num: 5,
            members: 0,
            newmembers: 0,
            currentevents: 0,
            subgroup: 0,
            chip1: null,
            chip2: null,
            chip3: null,
            chip4: null,
            loc_fix: 0,
            fix: 0,
            groupct: 0
          }];
        }

          this.account.next({
          id: data.id,
            reset_password: data.reset_password,
            current_profile: {
              relationship: data.current_profile.relationship,
              splash: data.current_profile.splash,
              group_count: data.current_profile.group_count,
              show_trial: data.current_profile.show_trial,
            is_trial: data.current_profile.is_trial,
            trial_expires: data.current_profile.trial_expires,
            trial_left: data.current_profile.trial_left,
            subscription: data.current_profile.subscription,
            subscription_plan: data.current_profile.subscription_plan,
              subscription_transaction: data.current_profile.subscription_transaction,
            next_rebill: data.current_profile.next_rebill,
            is_account: data.current_profile.is_account,
            account_owner: data.current_profile.account_owner,
            locations: data.current_profile.locations,
            profile_type: data.current_profile.profile_type,
            avatar: data.current_profile.avatar,
            avataricon: data.current_profile.avataricon,
            email: data.current_profile.email,
            group_id: data.current_profile.group_id,
            lastvisitDate: data.current_profile.lastvisitDate,
            username: data.current_profile.username,
            league: data.current_profile.league,
            name: data.current_profile.name,
            profile_id: data.current_profile.profile_id,
            registerDate: data.current_profile.registerDate,
            profile_rating: data.current_profile.profile_rating,
            birthday: data.current_profile.birthday,
            seckey: data.current_profile.seckey,
            groups: data.current_profile.groups,
            premium: data.current_profile.premium,
            raw: data.current_profile.raw,
            default_profile: data.current_profile.default_profile,
            loc_fix: data.current_profile.loc_fix,
            ambassador: data.current_profile.ambassador,
            ambassador_perc: data.current_profile.ambassador_perc
          },
          profiles: data.profiles,
          menuItems: jdata.MenuItems
        });
      });
    });
  }


  pullprofile(profile_id) {
    this.sessionSt.store('current_profile', profile_id);
    this.http.get<Account>(environment.service.trackithubapi + '/api/inside/profileload?profile_id=' + profile_id).subscribe((data: any) => {


      // const headers = new HttpHeaders({       'Accept': 'text/html'});
      // this.http.get<any>(environment.service.trackithubapi + '/index.php?user=' + data.username + '&epassw=' + data.seckey, {headers}).subscribe((jxdata: any) => { }, error => {
        this.pullNavigation(data.group_id, data.league);
        this.http.get<MenuItems[]>(environment.service.trackithubapi + this._url).subscribe((jdata: any) => {
          this.account.next({
            id: this.account.value.id,
            reset_password: data.reset_password,
            current_profile: {
              relationship: data.relationship,
              splash: data.splash,
              is_trial: data.is_trial,
              show_trial: data.show_trial,
              group_count: data.group_count,
              trial_expires: data.trial_expires,
              trial_left: data.trial_left,
              subscription: data.subscription,
              subscription_plan: data.subscription_plan,
              subscription_transaction: data.subscription_transaction,
              next_rebill: data.next_rebill,
              is_account: data.is_account,
              account_owner: data.account_owner,
              locations: data.locations,
              avatar: data.avatar,
              avataricon: data.avataricon,
              profile_type: data.profile_type,
              email: data.email,
              username: data.username,
              group_id: data.group_id,
              lastvisitDate: data.lastvisitDate,
              league: data.league,
              name: data.name,
              profile_id: data.profile_id,
              registerDate: data.registerDate,
              profile_rating: data.profile_rating,
              birthday: data.birthday,
              seckey: data.seckey,
              groups: data.groups,
              default_profile: data.default_profile,
              premium: data.premium,
              raw: data.raw,
              loc_fix: data.loc_fix,
              ambassador: data.ambassador,
              ambassador_perc: data.ambassador_perc
            },
            profiles: this.account.value.profiles,
            menuItems: jdata.MenuItems});

          //this.router.navigate(['/dashboard/overview']);

          //this.load();

        });
      });
    // });
  }

  reloadprofile() {
    this.pullprofile(this.sessionSt.retrieve('current_profile'));
  }

  private pullNavigation(groupId, league) {
    if (groupId === '8') {
      this._url = '/api/inside/pullNav?navtype=admin';
    }
    // Member
    if (groupId === '9') {
      this._url = '/api/inside/pullNav?navtype=player';
    }
    // League
    if (groupId === '15' && league === '1') {
      this._url = '/api/inside/pullNav?navtype=league';
    } else {
      // Club
      if (groupId === '15') {
        this._url = '/api/inside/pullNav?navtype=club';
      }
    }
  }

}
