import {HttpClient} from '@angular/common/http';
import {AccountService} from '../../../common/services/account.service';
import {ToastService} from '../../../common/toast';
import {FormAccountPaypalService} from '../../../common/services/form-account-paypal.service';
import {Account} from '../../../common/interfaces/account';
import {environment} from '../../../../environments/environment';
import {Component, AfterViewInit, ChangeDetectionStrategy, OnDestroy} from '@angular/core';

@Component({
  selector: 'app-payment-settings',
  templateUrl: './payment-settings.component.html',
  styleUrls: ['./payment-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class PaymentSettingsComponent implements AfterViewInit, OnDestroy {

  activegroup: any;
  groups: any;
  currency: any;
  grouplist: any;
  s1 = null;

  constructor(private http: HttpClient, private accountService: AccountService, private toastService: ToastService, public formService: FormAccountPaypalService) {
    this.s1 = this.accountService.cast.subscribe(data => {
      this.groups = data.current_profile.groups;
      if (this.groups.length > 0) {
        this.updategroup(this.groups[0].id);
        this.grouplist = Array.prototype.map.call(this.groups, function(item) { return item.id; }).join(',');
      }
    });
  }

  ngAfterViewInit() {

  }

  public updategroup(newobj){
    for (let i = 0; i < this.groups.length; i++) {
      if (this.groups[i].id === newobj) {
        this.activegroup = newobj;
        this.currency = this.groups[i].currency;
        this.formService.form.controls.paypal.setValue(this.groups[i].paypal);
      }
    }
  }

  public saveinfo() {
    this.http.get<Account>(environment.service.trackithubapi + '/api/inside/updatepaypal?groupid=' + this.formService.form.controls.group.value + '&currency=' + this.formService.form.controls.currency.value + '&paypal=' + this.formService.form.controls.paypal.value + '&allgroups=' + this.formService.form.controls.allgroups.value + '&grouplist=' + this.grouplist).subscribe((data: any) => {
      this.toastService.show({
        text: 'Paypal settings have been updated.',
        type: 'success',
        buttonText: '',
      });
      this.accountService.load();
    });
  }

  ngOnDestroy() {
    if (this.s1 != null) {
      this.s1.complete();
    }
  }

}
