import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormResetpasswordService {


  constructor() { }

  form: FormGroup = new FormGroup({
    password: new FormControl(),
    confirm_password: new FormControl(),
  });

  disableForm() {
    this.form.disable();
  }

  addControl(field) {
    this.form.addControl(field, new FormControl({disabled: true}));
  }

  removeControl(field) {
    this.form.removeControl(field);
  }

  enableForm() {
    this.form.enable();
  }

  initializeFormGroup() {

  }


}
