import {Component, Inject, ViewChild} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';


@Component({
  selector: 'app-editcomment-dialog',
  templateUrl: './editcomment-dialog.component.html',
  styleUrls: ['./editcomment-dialog.component.scss']
})
export class EditCommentDialogComponent  {

  postPlaceholder: string = "What's New?";
  postOpen: boolean = true;
  postValue: string;
  post_id: number;

  @ViewChild('postField', {static: false}) postField;
  private post: any;

  constructor(public dialogRef: MatDialogRef<EditCommentDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: {
    post_id: number;
    post: any}) {

      this.post_id = data.post_id;
      this.post = data.post
      this.postValue = this.post;
   }

  processPost(){
    this.dialogRef.close({comment: {comment: this.postValue, id: this.post_id}});
  }

  onNoClick(): void {
    this.dialogRef.close({comment: null});
  }
}
