import {Component, OnInit, AfterViewInit, ViewChild, Inject, ChangeDetectionStrategy, OnDestroy} from '@angular/core';
import { Router } from '@angular/router';
import {BottomSheetService} from '../../common/services/bottom-sheet.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import {FormControl, FormGroup} from '@angular/forms';
import {ToastService} from '../../common/toast';
import {environment} from '../../../environments/environment';
import {AccountService} from '../../common/services/account.service';
import {HttpClient} from '@angular/common/http';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material';

@Component({
  selector: 'app-dashboard-upload-avatar',
  templateUrl: './dashboard-upload-avatar.component.html',
  styleUrls: ['./dashboard-upload-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})

export class DashboardUploadAvatarComponent implements OnInit, AfterViewInit, OnDestroy {

  group = new FormGroup({
    avatarupload: new FormControl()
  });

  sheetRef: any = this.data.ref;
  cropReady = false;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  account: any;

  s1 = null;

  constructor( @Inject(MAT_BOTTOM_SHEET_DATA) public data: any, private accountService: AccountService, private http: HttpClient, private toastService: ToastService, private router: Router, public bottomSheetService: BottomSheetService) {
    this.s1 = this.accountService.cast.subscribe(data => {
      this.account = data;
    });
  }

  ngOnInit() {
  }

  ngAfterViewInit() {

  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded() {
    this.cropReady = true;
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  saveavatar() {
    this.http.post<any>(environment.service.trackithubapi + '/api/inside/uploadavataricon', {profile_id: this.account.current_profile.profile_id, data: this.croppedImage}).subscribe((data: any) => {
      this.accountService.load();
      this.toastService.show({
        text: `Profile avatar has been updated.`,
        type: 'success',
      });
      this.sheetRef.dismiss();
    });
  }

  ngOnDestroy() {
    if (this.s1 != null) {
      this.s1.complete();
    }
  }

}
