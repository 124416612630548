import { HttpClient } from '@angular/common/http';
import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { AccountService } from 'src/app/common/services/account.service';
import { ToastService } from 'src/app/common/toast';
import { environment } from 'src/environments/environment';
import { Account } from 'src/app/common/interfaces/account';

interface Avatar {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-change-avatar',
  templateUrl: './change-avatar.component.html',
  styleUrls: ['./change-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class ChangeAvatarComponent implements OnInit, AfterViewInit, OnDestroy {

  group = new FormGroup({
    avatarupload: new FormControl()
  });

  sheetRef: any = this.data.ref;
  cropReady : boolean = false;
  imageChangedEvent: string = '';
  croppedImage: string = '';
  account: Account;
  avatarImg: string;
  avatarPlaceholder: string;
  selectedAvatar: string = "";
  tempCroppedImage: string = "";
  forcedImage: any;
  siteUrl = environment.service.trackithubapi + '/';
  base64String: any;

  s1 = null;

  avatars: Avatar[] = [
    {value: 'club', viewValue: 'Club'},
    {value: 'grin', viewValue: 'Grin'},
    {value: 'slight-smile', viewValue: 'Slight Smile'},
    {value: 'smile', viewValue: 'Smile'},
    {value: 'smiling-face-eyes', viewValue: 'Smiling Face Eyes'},
    {value: 'smirking-face', viewValue: 'Smirking Face'},
    {value: 'tilted-grin', viewValue: 'Tilted-grin'},
    {value: 'winking-grin', viewValue: 'Winking-Grin'},
    {value: 'worried-smile', viewValue: 'Worried-Smile'},
  ];


  constructor( @Inject(MAT_DIALOG_DATA) public data: any, private accountService: AccountService, private http: HttpClient, public dialogRef: MatDialogRef<ChangeAvatarComponent>, private toastService: ToastService, private router: Router,) {

    this.forcedImage = this.data.image;

    this.s1 = this.accountService.cast.subscribe(data => {
      this.account = data;
      this.avatarImg = this.account.current_profile.avatar;
      this.avatarPlaceholder = this.account.current_profile.avataricon;
    });
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    if(this.forcedImage != null){
      this.getBase64(this.forcedImage)
    }
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.selectCustomOption();
  }
  imageLoaded() {
    this.cropReady = true;
  }
  cropperReady() {

  }
  loadImageFailed() {
    // show message
  }

  getBase64(imgUrl) {
     const self = this;
     var xhr = new XMLHttpRequest();
     xhr.open("get", imgUrl, true);
     xhr.responseType = "blob";
     xhr.onload = function () {
       if (this.status == 200) {
         var blob = this.response;
         let oFileReader = new FileReader();
         oFileReader.onloadend = function (e) {
             let base64 = e.target;
             self.base64String = (<any>base64).result;
         };
         oFileReader.readAsDataURL(blob);
       }
     }
     xhr.send();
  }


  selectedDefaultOption(avatar){
    if(this.selectedAvatar === avatar.value){
      this.selectedAvatar = "";
    } else{
    this.selectedAvatar = avatar.value;
    this.avatarPlaceholder = this.selectedAvatar;
    this.avatarImg = "";
    }
  }

  selectCustomOption(){
    this.selectedAvatar = this.croppedImage;
    this.tempCroppedImage = this.selectedAvatar;
    this.avatarImg = this.selectedAvatar;
    this.avatarPlaceholder = "";
  }

  saveavatar() {
    if (this.avatarPlaceholder != "") {
      this.http.get<any>(environment.service.trackithubapi + '/api/inside/setavataricon?profile_id=' + this.account.current_profile.profile_id + '&icon=' + this.avatarPlaceholder).subscribe((data: any) => {
        this.accountService.load();
        this.toastService.show({
          text: 'Avatar has been updated.',
          type: 'success',
          buttonText: '',
        });
      });
    }

    else if (this.avatarImg != "") {
      this.http.post<any>(environment.service.trackithubapi + '/api/inside/uploadavataricon', {profile_id: this.account.current_profile.profile_id, data: this.avatarImg}).subscribe((data: any) => {
        this.accountService.load();
        this.toastService.show({
          text: `Profile avatar has been updated.`,
          type: 'success',
        });
        if(this.forcedImage == null) {
          this.sheetRef.dismiss();
        }
      });
    }

    this.dialogRef.close();
  }

  cancel() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    if (this.s1 != null) {
      this.s1.complete();
    }
  }

}
