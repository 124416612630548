import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {Waiting} from '../../../class/waiting';
import {AccountService} from '../../../services/account.service';
import {environment} from '../../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import { ToastService } from '../../../toast';

@Component({
  selector: 'app-group-join-request',
  templateUrl: './group-join-request.component.html',
  styleUrls: ['./group-join-requests.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})

export class GroupJoinRequestComponent {
  @Input() name: string;
  @Input() stat: string;
  @Input() waituser: Waiting;

  constructor(private accountService: AccountService, private http: HttpClient, private toastService: ToastService) { }

  acceptConfirm() {
    this.http.get<any>(environment.service.trackithubapi + '/api/inside/confirmuser?group_id=' + this.waituser.groupid + '&player_id=' + this.waituser.id).subscribe((data: any) => {
      this.accountService.reloadprofile();
      this.toastService.show({
        text: this.waituser.name + ' has been Accepted.',
        type: 'success',
        buttonText: '',
      });
    });
  }

  rejectConfirm() {
    this.http.get<any>(environment.service.trackithubapi + '/api/inside/rejectuser?group_id=' + this.waituser.groupid + '&player_id=' + this.waituser.id).subscribe((data: any) => {
      this.accountService.reloadprofile();
      this.toastService.show({
        text: this.waituser.name + ' has been Rejected.',
        type: 'success',
        buttonText: '',
      });
    });
  }
}
