import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MaterialModule} from '../../material.module';
import {DashboardCardModule} from '../common/dashboard/dashboard-card.module';
import {PPLService} from './shared/ppl.service';

import {
    DashboardPanelComponent,
    PplCardComponent,
    PPLCardDetailsComponent,
    DashboardPanelHeaderComponent
} from './index';
import { PplSearchComponent } from './ppl-search/ppl-search.component';
import { PplCardDetailsExpansionComponent } from './ppl-card/ppl-card-details-expansion/ppl-card-details-expansion.component';



@NgModule({
    declarations: [
        DashboardPanelComponent,
        PplCardComponent,
        PPLCardDetailsComponent,
        DashboardPanelHeaderComponent,
        PplSearchComponent,
        PplCardDetailsExpansionComponent,
    ],
    imports: [
        CommonModule,
        MaterialModule,
        DashboardCardModule
    ],
    exports: [
        DashboardPanelComponent,
        PPLCardDetailsComponent,
        PplSearchComponent,
        PplCardComponent,
        DashboardPanelHeaderComponent
    ],
    providers: [
        PPLService
    ]
})
export class PPLModule { }
