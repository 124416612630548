import {Inject, Injectable, Injector} from '@angular/core';
import {ComponentPortal, PortalInjector} from '@angular/cdk/portal';
import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {ToastData, TOAST_CONFIG_TOKEN, ToastConfig} from './toast-config';
import {ToastComponent} from './toast.component';
import {ToastRef} from './toast-ref';


@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private lastToast: ToastRef;
  constructor(
    private overlay: Overlay,
    private parentInjector: Injector,
    @Inject(TOAST_CONFIG_TOKEN) private toastConfig: ToastConfig
  ) { }

  show(data: ToastData) {
  const positionStrategy = this.getPositionStrategy();
  const overlayRef =  this.overlay.create();
  const toastRef = new ToastRef(overlayRef);
  this.lastToast =  toastRef;

  const injector = this.getInjector(data, toastRef, this.parentInjector);
  const toastPortal =  new ComponentPortal(ToastComponent, null, injector);
  overlayRef.attach(toastPortal);
  return toastRef;
}

  getPositionStrategy() {
    return this.overlay.position()
      .global()
      .top(this.getPosition())
      .left('0px');
  }
  getPosition() {
    const lastToastVisible = this.lastToast && this.lastToast.isVisible();
    const position = lastToastVisible ? this.lastToast.getPosition().bottom : '0';

    return position + 'px';
  }

 getInjector(data: ToastData, toastRef: ToastRef, parentInjector: Injector) {
    const tokens = new WeakMap();

    tokens.set(ToastData, data);
    tokens.set(ToastRef, toastRef);

    return new PortalInjector(parentInjector, tokens);
  }
}
