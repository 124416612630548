import { Component, Inject, OnInit } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';



@Component({
  selector: 'app-deletepicture-dialog',
  templateUrl: './deletepicture-dialog.component.html',
  styleUrls: ['./deletepicture-dialog.component.scss']
})
export class DeletepictureDialogComponent implements OnInit {

  picture: any;

  constructor(public dialogRef: MatDialogRef<DeletepictureDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: {}) {
    this.picture = data;
   }

  ngOnInit() {

  }



  onNoClick(): void {
    this.dialogRef.close({});
  }

  onYesClick(): void {
    this.dialogRef.close({'status': 'delete', 'picture': this.picture});
  }

}
