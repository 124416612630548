import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {ToastService} from '../../common/toast';
import {MAT_BOTTOM_SHEET_DATA} from '@angular/material';

@Component({
  selector: 'app-dashboard-group-management',
  templateUrl: './dashboard-group-management.component.html',
  styleUrls: ['./dashboard-group-management.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class DashboardGroupManagementComponent implements OnInit {

  constructor(private toastService: ToastService, @Inject(MAT_BOTTOM_SHEET_DATA) public data: any) {
  }

  ngOnInit() {

  }

}
