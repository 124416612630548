import {AfterViewInit,   ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AccountService} from '../../common/services/account.service';
import {Account} from '../../common/interfaces/account';
import {Subscription} from "rxjs";
import {environment} from "../../../environments/environment";
import { MatPaginator, MatSort, MatTable, MatTableDataSource } from '@angular/material';
import {SelectionModel} from "@angular/cdk/collections";
import {HttpClient} from "@angular/common/http";
import {renewfriends} from "../../common/interfaces/renewfriends";
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ToastService} from "../../common/toast";
import {ChatService} from "../../common/services/chat.service";



@Component({
  selector: 'app-dashboard-friendrequests',
  templateUrl: './dashboard-friendrequests.component.html',
  styleUrls: ['./dashboard-friendrequests.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})


export class DashboardFriendrequestsComponent implements OnInit, OnDestroy, AfterViewInit  {

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatTable, {static: false}) table: MatTable<renewfriends>;

  friends: any;
  dataSource = new MatTableDataSource();
  selection = new SelectionModel<renewfriends>(true, []);
  displayedColumns = [];
  siteUrl = environment.service.trackithubapi + '/';
  account: Account;

  private suba: Subscription;

  constructor(public chatService: ChatService, private changeDetectorRef: ChangeDetectorRef, private accountService: AccountService, private http: HttpClient, public dialog: MatDialog,private toastService: ToastService) {

  }



  ngOnDestroy() {
    if (this.suba !== undefined) {
      this.suba.unsubscribe();
    }
  }

  ngOnInit() {
    this.suba = this.accountService.cast.subscribe(data => {
      this.account = data;
      this.drawscreen();
    });
  }

  drawscreen(){
    this.http.get<renewfriends>(environment.service.trackithubapi + '/api/inside/get_waiting_connection_list?profile_id=' + this.account.current_profile.profile_id).subscribe((data: any) => {
      this.friends = data.friend_waiting;
      this.displayedColumns = ['name','chat','action'];
      this.dataSource.data = this.friends;
      this.changeDetectorRef.detectChanges();
    });
  }

  openChat(data){
    this.chatService.openchatuser({
      profile_id: data.profile_id,
      name: data.name,
      avatarImg: data.avatarImg,
      avatarPlaceholder: data.avatarPlaceholder,
      email: data.email
    });
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  Accept(connection_id, connection_from) {

    const dialogRef = this.dialog.open(DialogAcceptFriend, {
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == 'Success') {
        this.http.get<any>(environment.service.trackithubapi + '/api/inside/acceptfriend?connection_id=' + connection_id + '&profile_id=' + this.account.current_profile.profile_id + '&connection_from=' + connection_from).subscribe((data: any) => {
          this.toastService.show({
            text: 'You have successfully added your new friend.',
            type: 'success',
            buttonText: '',
          });
          this.drawscreen();
        });
      } else if (result.event == 'Error') {
        this.toastService.show({
          text: `System error, please try again.   If you continue to get this error please contact support.`,
          type: 'warn',
        });
      }
    });
  }

  Reject(connection_id, connection_from) {

    const dialogRef = this.dialog.open(DialogRejectFriend, {
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == 'Success') {
        this.http.get<any>(environment.service.trackithubapi + '/api/inside/rejectfriend?connection_id=' + connection_id + '&profile_id=' + this.account.current_profile.profile_id + '&connection_from=' + connection_from).subscribe((data: any) => {
          this.toastService.show({
            text: 'The friend request has been rejected.',
            type: 'success',
            buttonText: '',
          });
          this.drawscreen();
        });
      } else if (result.event == 'Error') {
        this.toastService.show({
          text: `System error, please try again.   If you continue to get this error please contact support.`,
          type: 'warn',
        });
      }
    });



  }

}

@Component({
  selector: 'app-dialog-accept-friend',
  templateUrl: 'dialog-accept-friend.html',
  styleUrls: ['dialog-accept-friend.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class DialogAcceptFriend {
  constructor(
    public dialogRef: MatDialogRef<DialogAcceptFriend>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  onNoClick(): void {
    this.dialogRef.close({event: 'Cancel'});
  }

  onYesClick(): void {
    this.dialogRef.close({event: 'Success'});
  }
}


@Component({
  selector: 'app-dialog-reject-friend',
  templateUrl: 'dialog-reject-friend.html',
  styleUrls: ['dialog-reject-friend.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class DialogRejectFriend {
  constructor(
    public dialogRef: MatDialogRef<DialogRejectFriend>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  onNoClick(): void {
    this.dialogRef.close({event: 'Cancel'});
  }

  onYesClick(): void {
    this.dialogRef.close({event: 'Success'});
  }
}

