import { Component, Inject, OnInit } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';



@Component({
  selector: 'app-deletecomment-dialog',
  templateUrl: './deletecomment-dialog.component.html',
  styleUrls: ['./deletecomment-dialog.component.scss']
})
export class DeletecommentDialogComponent implements OnInit {

  comment: any;

  constructor(public dialogRef: MatDialogRef<DeletecommentDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: {}) {
    this.comment = data;

   }

  ngOnInit() {

  }



  onNoClick(): void {
    this.dialogRef.close({});
  }

  onYesClick(): void {
    this.dialogRef.close({'status': 'delete', 'comment': this.comment});
  }

}
