import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-communication',
  templateUrl: './communication.component.html',
  styleUrls: ['./communication.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class CommunicationComponent implements OnInit {
  title = 'Social Media &amp; Mass Communication';
  description = 'TrackitHub provides a platform for all pickleball players from around the world to socialize with one another, post pictures, and videos. Premier Pickleball League coordinators and club admins also have the ability to send mass emails to their players and post announcements on their group page. The common hassle of communicating with players using your personal email is a thing of the past.';
  image = 'assets/angular-img/mockups/communication.png';
  constructor() { }

  ngOnInit() {
  }

}
