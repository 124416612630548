import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from '../../material.module';
import {HttpClientModule} from '@angular/common/http';
import {RecaptchaModule} from 'ng-recaptcha';
import {RecaptchaFormsModule} from 'ng-recaptcha/forms';
import {NgxMaskModule} from 'ngx-mask';
import {DynamicFieldDirective} from './components/dynamic-field/dynamic-field.directive';
import { ImageCropperModule } from 'ngx-image-cropper';

// Containers
import {
    DynamicChangepassComponent,
    DynamicFormComponent,
    DynamicFormcontactComponent,
    DynamicFormInfoComponent,
    DynamicFormloginComponent,
    DynamicFormUserPassComponent,
    DynamicFormResetPassComponent,
    DynamicFormNameComponent,
    DynamicFormRateComponent,
    DynamicFormSearchfilter1Component,
    DynamicFormSearchfilter2Component,
    DynamicFormSearchfilter3Component,
    DynamicFormSearchfilter4Component,
    DynamicFormSearchfilter5Component,
    DynamicFormSearchfilter6Component,
    DynamicFormSearchfilter7Component,
    DynamicFormSearchfilter8Component,
    DynamicFormStep1Component,
    DynamicFormStep2Component,
    DynamicFormStep3Component,
} from './containers';

// Components
import {
    FormButtonComponent,
    FormInputComponent,
    FormPasswordComponent,
    FormSelectComponent,
    RadioComponent,
    FormTextareaComponent,
    FormCheckboxComponent,
    FormRecaptchaComponent,
    FormCountryComponent,
    FormStateComponent,
    FormFileInputComponent,
    FormDatepickerComponent,
    FormChipsComponent,
    FormChipsUsersComponent,
    FormSelectAvatarComponent
} from './components';

import { FormSelectSpecialComponent } from './components/form-selectspecial/form-selectspecial.component';
import { FormAutocompleteAddressComponent } from './components/form-autocomplete-address/form-autocomplete-address.component';
import { FormAutocompleteRegComponent } from './components/form-autocomplete-reg/form-autocomplete-reg.component';
import { FormBusyTimesComponent } from './components/form-busy-times/form-busy-times.component';
import { FormCustomCheckboxComponent } from './components/form-custom-checkbox/form-custom-checkbox.component';

@NgModule({
  imports: [
    ImageCropperModule,
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    HttpClientModule,
    RecaptchaModule.forRoot(),
    RecaptchaFormsModule,
    NgxMaskModule.forRoot().ngModule,
    FormsModule
  ],
    declarations: [
        DynamicChangepassComponent,
        DynamicFieldDirective,
        DynamicFormComponent,
        DynamicFormInfoComponent,
        DynamicFormloginComponent,
        DynamicFormcontactComponent,
        DynamicFormUserPassComponent,
        DynamicFormResetPassComponent,
        DynamicFormNameComponent,
        DynamicFormRateComponent,
        DynamicFormSearchfilter1Component,
        DynamicFormSearchfilter2Component,
        DynamicFormSearchfilter3Component,
        DynamicFormSearchfilter4Component,
        DynamicFormSearchfilter5Component,
        DynamicFormSearchfilter6Component,
        DynamicFormSearchfilter7Component,
        DynamicFormSearchfilter8Component,
        DynamicFormStep1Component,
        DynamicFormStep2Component,
        DynamicFormStep3Component,
        FormButtonComponent,
        FormInputComponent,
        FormPasswordComponent,
        FormSelectComponent,
        FormSelectAvatarComponent,
        FormSelectSpecialComponent,
        RadioComponent,
        FormCheckboxComponent,
        FormTextareaComponent,
        FormRecaptchaComponent,
        FormCountryComponent,
        FormStateComponent,
        FormFileInputComponent,
        FormDatepickerComponent,
        FormAutocompleteAddressComponent,
        FormAutocompleteRegComponent,
        FormChipsComponent,
        FormChipsUsersComponent,
        FormBusyTimesComponent,
        FormCustomCheckboxComponent
    ],
    exports: [
        DynamicChangepassComponent,
        DynamicFormComponent,
        DynamicFormInfoComponent,
        DynamicFormloginComponent,
        DynamicFormcontactComponent,
        DynamicFormUserPassComponent,
        DynamicFormNameComponent,
        DynamicFormRateComponent,
        DynamicFormSearchfilter1Component,
        DynamicFormSearchfilter2Component,
        DynamicFormSearchfilter3Component,
        DynamicFormSearchfilter4Component,
        DynamicFormSearchfilter5Component,
        DynamicFormSearchfilter6Component,
        DynamicFormSearchfilter7Component,
        DynamicFormSearchfilter8Component,
        DynamicFormStep1Component,
        DynamicFormStep2Component,
        DynamicFormStep3Component,
        DynamicFormResetPassComponent,
        FormChipsComponent,
        FormChipsUsersComponent,
        FormCustomCheckboxComponent,
        FormBusyTimesComponent
    ],
    entryComponents: [
        FormButtonComponent,
        FormInputComponent,
        FormFileInputComponent,
        FormPasswordComponent,
        FormSelectComponent,
        FormSelectSpecialComponent,
        FormSelectAvatarComponent,
        RadioComponent,
        FormCheckboxComponent,
        FormTextareaComponent,
        FormRecaptchaComponent,
        FormCountryComponent,
        FormStateComponent,
        FormDatepickerComponent,
        FormAutocompleteAddressComponent,
        FormAutocompleteRegComponent
    ]
})
export class DynamicFormModule {}
