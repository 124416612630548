import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {isMetadataSymbolicExpression} from "@angular/compiler-cli";

export interface PickelballRating {
    rating: number;
    ratingDescription: any;
}


const ELEMENT_DATA: PickelballRating[] = [
    {
        rating: 1.0,
        ratingDescription:
            '<li>New and have only minimal knowledge of the game and the rules.</li>'
    },
    {
        rating: 1.5,
        ratingDescription:
        '<li>Limited to some rallies.</li>' +
        '<li>Learning how to serve.</li>' +
        '<li>Developing a forehand.</li>' +
        '<li>Fails to return easy balls frequently and occasionally misses the ball entirely.</li>' +
        '<li>Played a few games and is learning the court lines, scoring, and some basic rules of the game.</li>'
    },
    {
        rating: 2.0,
        ratingDescription:
        '<li>Sustains a short rally with players of equal ability. ' +
        '<li>Demonstrating the basic shot strokes – forehand, backhand, volley, overhead and the serve, but has obvious weaknesses in most strokes.</li>' +
        '<li>Familiar with court positioning in doubles play.'
    },
    {
        rating: 2.5,
        ratingDescription:
        '<li>Makes longer lasting slow-paced rallies.</li>' +
        '<li>Makes most easy volleys and uses some backhands, but needs more work on developing shot strokes.</li>' +
        '<li>Beginning to approach the non-volley zone to hit volleys.</li>' +
        '<li>Aware of the \“soft game.\”</li>' +
        '<li>Knowledge of the rules has improved.</li>' +
        '<li>Court coverage is weak but improving.</li>'
    },
    {
        rating: 3.0,
        ratingDescription:
        '<li>More consistent on the serve and service return and when returning medium-paced balls.</li>' +
        '<li>Demonstrates improved skills with all the basic shot strokes and shot placement but lacks control when trying for direction, depth, or power on their shots.</li>' +
        '<li>Beginning to attempt lobs and dinks with little success and doesn’t fully understand when and why they should be used.'
    },
    {
        rating: 3.5,
        ratingDescription:
        '<li>Demonstrates improved stroke dependability with directional control on most medium-paced balls and some faster-paced balls.</li>' +
        '<li>Demonstrates improved control when trying for direction, depth and power on their shots.</li>' +
        '<li>Needs to develop variety with their shots.</li>' +
        '<li>Exhibits some aggressive net play.</li>' +
        '<li>Beginning to anticipate opponent’s shots.</li>' +
        '<li>Learning about the importance of strategy and teamwork in doubles.</li>'
    },
    {
        rating: 4.0,
        ratingDescription:
        '<li>Consistent and dependable strokes, including directional control and depth on both forehand and backhand shots.</li>' +
        '<li>Reliable serves, lobs, overheads, approach shots and volleys and can use spin shots with some success.</li>' +
        '<li>Occasionally can force errors when serving.</li>' +
        '<li>Rallies may be lost due to impatience.</li>' +
        '<li>Uses the dink shot and drop shots to slow down or change the pace of the game.</li>' +
        '<li>Demonstrates 3<sup>rd</sup> shot strategies – drop shots, lobs, and fast-paced ground strokes.</li>' +
        '<li>Aggressive net play and teamwork in doubles is evident.</li>' +
        '<li>Fully understands the rules of the game and can play by them.</li>'
    },
    {
        rating: 4.5,
        ratingDescription:
        '<li>Beginning to master the use of power and spin, can successfully execute all shots, can control the depth of their shots, and can handle pace.</li>' +
        '<li>Beginning to master the dink shots and drop shots and their importance to the game.</li>' +
        '<li>Beginning to master 3<sup>rd</sup> shot choices.</li>' +
        '<li>Understands strategy and can adjust style of play and game plan according to the opponent’s strengths and weaknesses and court position.</li>' +
        '<li>Serves with power and accuracy and can also vary the speed and spin of the serve.</li>' +
        '<li>Making good choices in shot selection.</li>' +
        '<li>Anticipates the opponent’s shots resulting in good court positioning.</li>'
    },
    {
        rating: 5.0,
        ratingDescription:
        '<li>Mastered all the skills – all shot types, touch, spin, serves, with control and can use them as weapons.</li>' +
        '<li>Excellent shot anticipation, extremely accurate shot placement and regularly hit winning shots.</li>' +
        '<li>Forces opponents into making errors by “keeping the ball in play.”</li>' +
        '<li>Mastered the dink and drop shots.</li>' +
        '<li>Mastered the 3rd shot choices and strategies.</li>' +
        '<li>Uses soft shots, dinks and lobs to set up offensive situations.</li>' +
        '<li>Mastered pickleball strategies and can vary strategies and styles of play in competitive or tournament matches.</li>' +
        '<li>Dependable in stressful situations as in tournament match play.</li>' +
        '<li>Athletic ability, quickness, agility, and raw athleticism are also qualities that are sometimes what separates the top players from those near the top.</li>'
    }
];


@Component({
    selector: 'app-pickelball-rating',
    templateUrl: './pickelball-rating.component.html',
    styleUrls: ['./pickelball-rating.component.scss']
})


export class PickelballRatingComponent {
    title = 'Package Comparison';
    displayedColumns: string[] = ['rating', 'ratingDescription'];
    dataSource = ELEMENT_DATA;
    is_blue = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {



    if(this.data.is_blue == true){
      this.is_blue = true;
    }

  }


}
