import {Component, OnInit, AfterViewInit, Inject, ChangeDetectionStrategy} from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA} from '@angular/material';


export interface RenewalElement {
  name: string;
  id: string;
  created: string;
  enddate: string;
  group_id: string;
}

@Component({
  selector: 'app-dashboard-reademail-sheet',
  templateUrl: './dashboard-reademail-sheet.component.html',
  styleUrls: ['./dashboard-reademail-sheet.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})

export class DashboardReadEmailSheetComponent implements OnInit {
  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any) {}

  ngOnInit() {
  }


}
