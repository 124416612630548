import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormProfileService {

  date: any;

  constructor() { }

  form: FormGroup = new FormGroup({
    address: new FormControl(),    bio: new FormControl(),  rating: new FormControl(),
    birthday: new FormControl(),
    email: new FormControl(),
    altAddress: new FormControl(),
    relationship: new FormControl()
  });

  addControl(field) {
    this.form.addControl(field, new FormControl({disabled: true}));
  }

  removeControl(field) {
    this.form.removeControl(field);
  }

  enableForm() {
    this.form.enable();
  }

  initializeFormGroup(userData) {

    this.form.controls.address.setValue(userData.address);
    this.form.controls.altAddress.setValue(userData.altaddress);

    this.form.controls.email.setValue(userData.email);

    this.form.controls.bio.setValue(userData.bio);

    if (userData.birthday !== null) {
      this.date = new Date(userData.birthday);
      this.form.controls.birthday.setValue(this.date);
    }

    this.form.controls.rating.setValue(userData.rating);
    this.enableForm();
  }

  setemail(email) {
    this.form.controls.email.setValue(email);
  }


}
