import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Countries } from '../../dynamic-form/models/field-config.interface';
import { environment } from '../../../environments/environment';
interface myData {
  found;
  result: boolean;
  password_reset: number;
}

@Injectable({
  providedIn: 'root'
})
export class RecordsService {

  constructor(private http: HttpClient) {

  }

  getCountryList() {
    return this.http.get<Countries>(environment.service.trackithubapi + '/assets/angular-data/countries.json');
  }

  resetPassword(email) {
    return this.http.get<myData>(environment.service.trackithubapi + '/api/outside/resetpassword?email=' + email);
  }

  createccount(obj) {
    return this.http.post<myData>(environment.service.trackithubapi + '/api/outside/createaccount', obj);
  }

  createLogin(obj) {
    return this.http.post<myData>(environment.service.trackithubapi + '/api/outside/createlogin', obj);
  }

  checkauthstatus() {
    return this.http.get<myData>(environment.service.trackithubapi + '/api/outside/checkauthstatus');
  }

  checkName(name) {
    return this.http.get<myData>(environment.service.trackithubapi + '/api/outside/checkname?name=' + name);
  }

  checkEmailPrimary(email) {
    return this.http.get<myData>(environment.service.trackithubapi + '/api/outside/checkemailprimary?email=' + email);
  }

  checkEmail(email) {
    return this.http.get<myData>(environment.service.trackithubapi + '/api/outside/checkemail?email=' + email);
  }

  sendMessage(event) {
    return this.http.post<myData>(environment.service.trackithubapi + '/api/outside/sendmessage', event);
  }

  registerMessage(obj) {
    return this.http.post<myData>(environment.service.trackithubapi + '/api/outside/register', obj);
  }

}
