import {ChangeDetectionStrategy, Component, OnDestroy, OnInit, HostBinding} from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { AccountService } from '../../common/services/account.service';
import { Account } from '../../common/interfaces/account';
import { DomSanitizer } from '@angular/platform-browser';
import { LoaderOverlayService } from '../../common/services/loader-overlay.service';
import {Subscription} from "rxjs";
import {environment} from "../../../environments/environment";

@Component({

  selector: 'app-dashboard-frame',
  templateUrl: './dashboard-frame.component.html',
  styles: [`
    :host {
      width: max-content;
      position: relative;
      display: block;
      overflow: hidden;
    }
    iframe {
      overflow: hidden;
      height: 100vh;
    }
  `],
  changeDetection: ChangeDetectionStrategy.Default
})

export class DashboardFrameComponent implements OnInit, OnDestroy {

  @HostBinding('style.width') swidth = 'max-content';

  account: Account;
  public type;

  time = 0;
  interval;

  returnurl = null;
  username = null;
  epassw = null;
  url = null;
  safe = false;
  groupid = null;
  param = null;
  search = null;

  rr_id = null;
  group_id = null;
  owner_id = null;
  width = '100%';

  private suba: Subscription;
  private subb: Subscription;
  private subc: Subscription;

  constructor(private LoaderOverlayService: LoaderOverlayService, private route: ActivatedRoute, private accountService: AccountService, private domSanitizer: DomSanitizer) {
    this.url = '#';
    this.suba = this.accountService.cast.subscribe(data => {
      this.account = data;
      if(this.account.id !== null) {
        if(this.suba !== undefined) {
          this.suba.unsubscribe();
          this.subb = this.route.params.subscribe(routeParams => {
            this.type = routeParams.id;
            this.subc = this.route.queryParams.subscribe(queryParams => {
              this.owner_id = queryParams.owner_id;
              this.group_id = queryParams.group_id;
              this.rr_id = queryParams.rr_id;
              this.search = queryParams.search;
              this.reloadiframe();
            });
          });
        }
      }
    });
  }

  reloadiframe() {
    if(this.LoaderOverlayService.overlayRef == null) {

      setTimeout(() => {
        this.killoverlay();
      }, 3000);

      this.LoaderOverlayService.show();
    }
    switch(this.type) {
      case 'ppl': {
        this.returnurl = environment.service.trackithubapi + '/index.php?option=com_shootout&view=ppl';
        break;
      }
      case 'leaguegenerate': {
        this.returnurl = environment.service.trackithubapi + '/index.php?option=com_jevents&view=icalevent&layout=edit&goto=league';
        break;
      }
      case 'calendar': {
        this.returnurl = environment.service.trackithubapi + '/index.php?option=com_jevents&view=month&layout=calendar';
        break;
      }
      case 'mycreatedevents': {
        this.returnurl = environment.service.trackithubapi + '/index.php?option=com_jevents&view=myevent&layout=listevents';
        this.swidth = '100%';
        break;
      }
      case 'createevent': {
        this.returnurl = environment.service.trackithubapi + '/index.php?option=com_jevents&view=icalevent&layout=edit';
        this.width = '1000px';
        break;
      }
      case 'mygroups': {
        this.returnurl = environment.service.trackithubapi + '/index.php?option=com_shootout&view=clublist';
        break;
      }
      case 'photos': {
          if (this.owner_id !== undefined) {
            this.returnurl = environment.service.trackithubapi + '/index.php?option=com_community&view=photos&task=myphotos&userid=' + this.owner_id;
          }
        break;
      }
      case 'search': {

        if(this.search == undefined || this.search == null){
          this.search = '';
        }

        this.returnurl = environment.service.trackithubapi + '/index.php?q=' + this.search + '&Search=Search&option=com_community&view=search';

        break;

      }
      case 'test': {
        this.returnurl = environment.service.trackithubapi + '/index.php?option=com_shootout&view=clublist';
        break;
      }

      case 'dues': {
        if (this.group_id !== undefined) {
          this.returnurl = environment.service.trackithubapi + '/index.php?option=com_shootout&view=joingroup&group_id='+this.group_id+'&readonly=false';
        }
        break;
      }
      case 'generaterr': {
        this.swidth = '100%';
          if (this.group_id !== undefined) {
            this.returnurl = environment.service.trackithubapi + '/index.php?option=com_shootout&back=admin&view=createroundrobin&group_id=' + this.group_id;
          } else {
            this.returnurl = environment.service.trackithubapi + '/index.php?option=com_shootout&back=admin&view=createroundrobin&group_id';
          }
        break;
      }
      case 'getscorecard': {
        this.swidth = '100%';
        this.returnurl = environment.service.trackithubapi + '/index.php?option=com_shootout&view=renderroundrobin&id=' + this.rr_id;
        break;
      }
      case 'generatess': {
          if (this.group_id !== undefined) {
            this.returnurl = environment.service.trackithubapi + '/index.php?option=com_shootout&view=create&group_id=' + this.group_id;
          } else {
            this.returnurl = environment.service.trackithubapi + '/index.php?option=com_shootout&view=create';
          }




        break;
      }
      case 'creategroup': {
        this.returnurl = environment.service.trackithubapi + '/index.php?option=com_community&view=groups&task=create';
        break;
      }
    }

    this.epassw = this.account.current_profile.seckey;
    this.username = this.account.current_profile.username;
    this.returnurl = btoa(this.returnurl);
    this.url = this.domSanitizer.bypassSecurityTrustResourceUrl(environment.service.trackithubapi + '/index.php?user=' + this.username + '&epassw=' + this.epassw + '&redirect=' + this.returnurl);
    if (this.username !== null && this.username !== null && this.returnurl !== null) {
      this.safe = true;
    } else {
      this.safe = false;
    }
  }

  killoverlay(){
    if(this.LoaderOverlayService.overlayRef != null) {
      this.LoaderOverlayService.hide();
    }
  }

  ngOnDestroy() {
    this.killoverlay();
    this.LoaderOverlayService.overlayRef = null;
    if (this.suba !== undefined) {
      this.suba.unsubscribe();
    }
    if (this.subb !== undefined) {
      this.subb.unsubscribe();
    }
    if (this.subb !== undefined) {
      this.subc.unsubscribe();
    }
  }

  ngOnInit() {
  }

}
