import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DynamicFormModule} from '../dynamic-form/dynamic-form.module';
import {MaterialModule} from '../../material.module';
import { FullCalendarModule } from '@fullcalendar/angular';

import {
    EventsCalendarComponent,
    EventsDetailsComponent,
    EventsCalendarFullComponent
} from './index';
import {DashboardCardModule} from '../common/dashboard/dashboard-card.module';
import {TourMatMenuModule} from 'ngx-tour-md-menu';


@NgModule({
    imports: [
        CommonModule,
        DynamicFormModule,
        DashboardCardModule,
        MaterialModule,
        FullCalendarModule,
        TourMatMenuModule,
        // for FullCalendar!
    ],
    declarations: [
        EventsCalendarComponent,
        EventsDetailsComponent,
        EventsCalendarFullComponent,
    ],
    exports: [
        EventsCalendarComponent,
        EventsDetailsComponent
    ]
})
export class EventsModule {
}
