import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';

@Component({
  selector: 'app-player-welcome-dialog',
  templateUrl: './club-ppl-welcome-dialog.component.html',
  styleUrls: ['./club-ppl-welcome-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class ClubPplWelcomeDialogComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<ClubPplWelcomeDialogComponent>) { }

  ngOnInit() {
  }
  close() {
    this.dialogRef.close('Close');
  }

}
