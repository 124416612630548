import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component, Inject,
  OnInit,
} from '@angular/core';

import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatDialog} from '@angular/material/dialog';
import {HttpClient} from '@angular/common/http';
import {SessionStorageService} from 'ngx-webstorage';
import {AccountService} from '../../common/services/account.service';
import {FormRegisterleagueTeamService} from '../../common/services/form-registerleague-team.service';
import {environment} from '../../../environments/environment';
import {DashboardShowSuccessComponent, DashboardShowtrialComponent, DashboardSubscribeComponent} from "../../dashboard";
import {PickleballPlaceDetailsComponent} from "../pickleball-place-details/pickleball-place-details.component";

@Component({
  selector: 'app-league-register-teaminside-component',
  templateUrl: './league-register-teaminside.component.html',
  styleUrls: ['./league-register-teaminside.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,

})

export class LeagueRegisterTeamInsideComponent implements OnInit {
  public lasterror = null;
  public errormessage = null;
  public stepLabel = ['Login Info', 'Contact Info', 'Skill Rating'];
  public pwdPattern: any = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{8,25}$/;
  public records = {};
  public message = '';
  public _loading = false;
  public loaderMessage = 'Loading...';
  public finalValid = true;

  account_id: any;

  s1 = false;
  s2 = false;

  team: string;
  status: boolean;
  tname: string;
  phone: string;
  disabled: boolean;
  account: any;
  teamlist: any;
  the_team_name: string;
  the_team_id: any;
  is_dis: boolean;

  constructor(
      private changeDetectorRef: ChangeDetectorRef,
      public formService: FormRegisterleagueTeamService,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private http: HttpClient,
      public dialogRef: MatDialogRef<LeagueRegisterTeamInsideComponent>,
      public dialog: MatDialog,
      private sessionSt: SessionStorageService,
      private accountService: AccountService) {
  }

  ngOnInit() {

    this.is_dis = false;

    this.accountService.cast.subscribe(data => {
      this.account = data;


      this.http.post(environment.service.trackithubapi + '/api/outside/getteams?profile_id='+this.account.current_profile.profile_id + '&group_id='+this.sessionSt.retrieve('joinoutsideteam'), {}, {}).subscribe((data: any) => {
        this.teamlist = data.teams;
        if(this.teamlist.id != ''){
          this.the_team_id = this.teamlist.id;
          this.the_team_name = this.teamlist.name;
          if (this.the_team_id != '') {
            this.is_dis = true;
            this.formService.form.controls.tname.disable();
          }
          console.log(this.teamlist);
        }
      });


    });


    this.formService.initializeFormGroup();
    this.disabled = true;
    this.formService.form.valueChanges.subscribe(data => {
      this.disabled = true;
      this.s1 = false;
      this.s2 = false;
      this.phone = data.phone;
      if(this.the_team_id != '') {
        this.tname = data.tname;
      } else {
        this.tname = '';
      }
      this.team = this.the_team_id;
      this.check(data);
      this.changeDetectorRef.detectChanges();
      console.log(data);
    });
  }


  forcechange(){
    this.changeDetectorRef.detectChanges();
  }

  changeteam(e){

  }

  check(data) {
    if (data.tname != null) {
      if (data.tname.length < 2) {
        this.formService.form.controls.tname.setErrors({
          empty: true
        });
        this.s1 = false;
      } else {
        this.s1 = true;
      }
    } else {

      if(this.team != ''){
        this.s1 = true;
      } else {
        this.s1 = false;
      }


    }

    if (data.phone != null) {
      if (data.phone.length < 2) {
        this.formService.form.controls.phone.setErrors({
          empty: true
        });
        this.s2 = false;
      } else {
        this.s2 = true;
      }
    } else {
      this.s2 = false;
    }

    if (this.s1 === true && this.s2 === true) {
      this.disabled = false;
    } else {
      this.disabled = true;
    }
    this.changeDetectorRef.detectChanges();
  }

  save() {
    this.disabled = true;

    interface sModal {
      team: string;
      phone: string;
      name: string;
      rp_id: string;
      start_date: string;
    }

    const storage = <sModal>{};
    storage.team = this.team;
    storage.name = this.tname;
    storage.phone = this.phone;
    storage.rp_id = this.sessionSt.retrieve('leagueeventteam');
    storage.start_date = this.sessionSt.retrieve('last_start_date');


    this.sessionSt.clear('leagueeventteam');
    this.sessionSt.clear('joinoutsideteam');
    this.sessionSt.clear('leagueevent');
    this.sessionSt.store('teamshare', storage.rp_id);
    this.sessionSt.clear('last_start_date');


    console.log(storage);


    this.http.post(environment.service.trackithubapi + '/api/inside/maketeam', {
      team: storage.team,
      repeat_id: storage.rp_id,
      profile_id: this.account.current_profile.profile_id,
      phone: storage.phone,
      start_date: storage.start_date,
      name: storage.name}, {}).subscribe((data: any) => {
      document.location.href = '/dashboard/overview';
      this.sessionSt.store('team_id', data.team_id);
      this.sessionSt.store('team_name', storage.name);
      this.sessionSt.store('team_skip', true);
      this.sessionSt.clear('ambassador');
      this.sessionSt.clear('ambassador_row');
      this.cancel();
    });

  }

  trackByFn(index: number, item: any): number {
    return item.id; // or any unique identifier
  }


  cancel() {
    this.dialogRef.close();
  }


}
