import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {MatBottomSheetRef} from '@angular/material';
import {LeaguesComponent} from "../leagues.component";
import {BottomSheetService} from "../../../common/services/bottom-sheet.service";

@Component({
  selector: 'app-leagues-sheet-heading',
  templateUrl: './leagues-sheet-heading.component.html',
  styleUrls: ['./leagues-sheet-heading.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class BottomSheetHeadingLeaguesComponent {
  @Input() icon: string;
  @Input() backurl: string;
  @Input() svgIcon: string;
  @Input() headingTitle: string;
  @Input() stopclose: boolean;

  constructor(private bottomSheetRef: MatBottomSheetRef, private bottomSheetService: BottomSheetService) {
  }

  goto(url){
    this.bottomSheetRef.dismiss();

    this.bottomSheetService.openBottomSheetWithDataCustomClass(LeaguesComponent, 'LeaguesComponent',{}, 'custom-bottom-p');

    //window.location.href = url;
  }

  bottomSheetDismiss() {
    this.bottomSheetRef.dismiss();
  }
}
