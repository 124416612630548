import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {CdkVirtualScrollViewport} from "@angular/cdk/scrolling";
import { CookieService } from 'ngx-cookie-service';

type Position = 'start' | 'mid' | 'end';

@Component({
  selector: 'app-horizontal-infinite-scroll',
  templateUrl: './horizontal-infinite-scroll.component.html',
  styleUrls: ['./horizontal-infinite-scroll.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class HorizontalInfiniteScrollComponent {
    @Input() heading: string;
    @Input() reset: boolean;
    @Input() cards: any;
    @Output() sendCard = new EventEmitter<any>();
    @ViewChild(CdkVirtualScrollViewport, {static: false}) viewPort: CdkVirtualScrollViewport;
    constructor(public _router: Router, private cookieService: CookieService) {}

     selectCard(card) {
       this.sendCard.emit(card);
    }
  scroll(position: Position) {
    let scrollIndex: number;
    switch (position) {
      case 'start':
        scrollIndex = 0;
        break;
      case 'mid':
        scrollIndex = this.cards.length / 2;
        break;
      case 'end':
        scrollIndex = this.cards.length;
        break;
    }
    this.viewPort.scrollToIndex(scrollIndex, 'smooth');
  }

  resetlocation() {
    this.cookieService.delete('lat');
    this.cookieService.delete('long');
    window.location.href = '/';
  }

}
