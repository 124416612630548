import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

import {DashboardGroupManagementComponent} from '../../../dashboard';
import {SnackbarService} from '../../../common/services/snackbar.service';
import {BottomSheetService} from '../../../common/services/bottom-sheet.service';
import {DashboardRenewalsSheetComponent} from '../../../dashboard/dashboard-renewals-sheet/dashboard-renewals-sheet.component';
import {DashboardEmailSheetComponent} from '../../../dashboard/dashboard-email-sheet/dashboard-email-sheet.component';
import { groupCard } from '../../../common/class/group-card';
import { RenewallistService } from '../../../common/services/renewallist.service';
import { Renewallist } from '../../../common/interfaces/renewallist';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-club-card-join-request',
  templateUrl: './club-card-join-request.component.html',
  styleUrls: ['./club-card-join-request.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ClubCardJoinRequestComponent {
  @Input() title: string;
  @Input() memberNames: any = [];
  @Input() memberPlan: string;
  @Input() card: groupCard;
  @Input() isplayer = false;
  @Input() profile_id: any;

  t: any;

  constructor(
    private http: HttpClient,
    private snackBar: SnackbarService,
    public bottomSheetService: BottomSheetService,
    private renewalService: RenewallistService
  ) {}
  manageGroup(group_id) {
    if (this.title === 'Renewals') {
      this.http.get<Renewallist>(environment.service.trackithubapi + '/api/inside/grouprenewals?group_id=' + group_id).subscribe((data: any) => {
        this.bottomSheetService.openBottomSheetWithData(DashboardRenewalsSheetComponent, 'DashboardRenewalsSheetComponent',{list: data});
      });

    }
    if (this.title === 'Join Requests') {
      this.bottomSheetService.openBottomSheetWithData(DashboardGroupManagementComponent, 'DashboardGroupManagementComponent',{group: this.card});
    }
    if (this.title === 'Outgoing Emails') {

      if(this.title == 'Outgoing Emails' && this.isplayer === true){
         this.t='Email Inbox';
      } else {
         this.t='Outgoing Emails';
      }

      this.http.get<Renewallist>(environment.service.trackithubapi + '/api/inside/groupemails?group_id=' + group_id + '&profile_id=' + this.profile_id).subscribe((data: any) => {
        this.bottomSheetService.openBottomSheetWithData(DashboardEmailSheetComponent, 'DashboardEmailSheetComponent',{list: data, title: this.t});
      });
    }
  }
  acceptConfirm() {
    this.snackBar.success('First Name Accepted', 'Undo');
  }
  rejectConfirm() {
    this.snackBar.alert('First Name Rejected', 'Undo');
  }
}
