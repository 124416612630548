import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input, OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { Subscription } from 'rxjs';
import { AccountService } from 'src/app/common/services/account.service';
import { DatePipe } from '@angular/common';
import { FormProfileService } from "../../common/services/form-profile.service";
// @ts-ignore
import { } from '@types/googlemaps';
import {environment} from "../../../environments/environment";
import {ToastService} from "../../common/toast";
import {HttpClient} from "@angular/common/http";
import {RecordsService} from "../../common/services/records.service";

@Component({
  selector: 'app-about-group-readonly',
  templateUrl: './about-group-readonly.component.html',
  styleUrls: ['./about-group-readonly.component.scss']
})
export class AboutGroupReadonlyComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() account: any;
  @Input() profile_type: any;
  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  @Output() setAddressAlt: EventEmitter<any> = new EventEmitter();
  @Output() parentFunctionEvent = new EventEmitter();

  @ViewChild('address', {static: false}) address: any;
  @ViewChild('altaddress', {static: false}) altaddress: any;

  subscription: Subscription;
  s1 = false;
  s2 = false;
  s3 = false;
  s4: any;
  s5: any;

  edit = false;
  profile: any;
  oprofile_info: any;
  profile_info: any;
  is_owner: boolean;
  ratingValue: any;
  options = ['1.0', '0.0', '1.5', '2.0', '2.5', '3.0', '3.5', '4.0', '4.5', '5.0'];
  addressobj: any;
  addressaltobj: any;
  securityPost: number;
  securityPost1: number;
  new_data: boolean;
  jaja: any;

  constructor(private validationService: RecordsService, private toastService: ToastService, private accountService: AccountService, private datePipe: DatePipe, public formService: FormProfileService, private http: HttpClient,private changeDetectorRef: ChangeDetectorRef) {
    this.addressobj = null;
    this.addressaltobj = null;

    this.jaja = {
      cfname: '',
      clname: '',
      address: '',
      city: '',
      state: '',
      zip: '',
      phone: '',
      email: ''
    }

  }




  ngOnDestroy() {


  }

  ngAfterViewInit() {

  }









  ngOnInit() {
    this.http.get<any>(environment.service.trackithubapi + '/api/inside/loadprofile?clean_group=yes&profile=' +  this.account.ownerid).subscribe((profileData: any) => {
      this.jaja = profileData.profile;

    });
  }



}
