import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input, OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { Subscription } from 'rxjs';
import { AccountService } from 'src/app/common/services/account.service';
import { DatePipe } from '@angular/common';
import { FormProfileService } from "../../common/services/form-profile.service";
// @ts-ignore
import { } from '@types/googlemaps';
import {environment} from "../../../environments/environment";
import {ToastService} from "../../common/toast";
import {HttpClient} from "@angular/common/http";
import {RecordsService} from "../../common/services/records.service";

@Component({
  selector: 'app-about-readonly',
  templateUrl: './about-readonly.component.html',
  styleUrls: ['./about-readonly.component.scss']
})
export class AboutReadonlyComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() account: any;

  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  @Output() setAddressAlt: EventEmitter<any> = new EventEmitter();
  @Output() parentFunctionEvent = new EventEmitter();

  @ViewChild('address', {static: false}) address: any;
  @ViewChild('altaddress', {static: false}) altaddress: any;

  subscription: Subscription;
  s1 = false;
  s2 = false;
  s3 = false;
  s4: any;
  s5: any;

  edit = false;
  profile: any;
  oprofile_info: any;
  profile_info: any;
  is_owner: boolean;
  ratingValue: any;
  options = ['1.0', '0.0', '1.5', '2.0', '2.5', '3.0', '3.5', '4.0', '4.5', '5.0'];
  addressobj: any;
  addressaltobj: any;
  securityPost: number;
  securityPost1: number;
  new_data: boolean;

  constructor(private validationService: RecordsService, private toastService: ToastService, private accountService: AccountService, private datePipe: DatePipe, public formService: FormProfileService, private http: HttpClient,private changeDetectorRef: ChangeDetectorRef) {
    this.addressobj = null;
    this.addressaltobj = null;
  }




  ngOnDestroy() {
    if (this.s4 != null) {
      this.s4.unsubscribe();
    }
    if (this.s5 != null) {
      this.s5.unsubscribe();
    }
    if(this.subscription != null){
      this.subscription.unsubscribe();
    }

  }

  ngAfterViewInit() {

  }









  ngOnInit() {

    this.s4 = this.formService.form.controls.email.valueChanges.subscribe(data => {

      if(this.oprofile_info.is_account == 1 && this.new_data == true && this.is_owner == true) {
        if(this.oprofile_info.email != data && data != '') {

        }
      }
    });

    this.subscription = this.accountService.cast.subscribe(data => {
      this.profile = data.current_profile;


      if(this.profile.profile_id == this.account.profile.profile_id){
        this.is_owner = true;
      } else {
        this.is_owner = false;
      }

      this.profile_info = this.account.profile;

      this.oprofile_info = this.account.profile;



      this.new_data = false;

      this.new_data = true;
    })




  }

  s1toggle(){
    this.s1 = !this.s1;
  }

  s2toggle(){
    this.s2 = !this.s2;
  }

  s3toggle(){
    this.s3 = !this.s3;
  }

  editToggle(){
    this.edit = !this.edit;

    if(this.edit){
      this.s1 = true;
      this.s2 = false;
      this.s3 = true;
    } else {
      this.s1 = false;
      this.s2 = false;
      this.s3 = false;
    }

  }

  changeSecurity(sec){
    this.securityPost = sec;
  }

  changeSecurity1(sec){
    this.securityPost1 = sec;
  }

}
