import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnInit,
  ViewChild,
  ElementRef,
  OnDestroy,
  Inject
} from '@angular/core';

import { DashboardEventviewComponent } from '../dashboard-eventview/dashboard-eventview.component';
import { DashboardJoinGroupsComponent } from '../dashboard-join-groups/dashboard-join-groups.component';
import { DashboardResetpasswordComponent } from '../dashboard-resetpassword/dashboard-resetpassword.component';
import { PanelMessage } from '../dashboard-join-marquee/dashboard-join-marquee.component';

import {FieldConfig} from '../../dynamic-form/models/field-config.interface';
import {AccountService} from '../../common/services/account.service';
import {Account} from '../../common/interfaces/account';
import {CalendarService} from '../../common/services/calendar.service';
import {ModalService} from '../../common/services/modal.service';
import {EventsCalendarFullComponent} from 'src/app/events/events-calendar-full/events-calendar-full.component';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {DashboardeventsService} from '../../common/services/dashboardevents.service';
import {ToastService} from '../../common/toast';
import {ActivatedRoute} from '@angular/router';
import {BottomSheetService} from '../../common/services/bottom-sheet.service';
import {CreateLocationComponent} from '../../frontend/create-location/create-location.component';
import {SessionStorageService} from 'ngx-webstorage';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {PanelService} from '../../common/services/panel.service';
import {DynamicComponentService} from '../../common/services/dynamic-component.service';
import {MAT_DIALOG_DATA, MatDialogRef, MatDialog} from '@angular/material/dialog';
import Talk from 'talkjs';
import { TalkService } from '../../common/services/talkjs';

import {DialogData} from '../dashboard-roster/roster-table.component';
import {ProfileComponent} from '../../profile/profile.component';
import {DashboardLeaguemodalComponent} from '../dashboard-leaguemodal/dashboard-leaguemodal.component';
import {DashboardPregamemodalComponent} from '../dashboard-pregamemodal/dashboard-pregamemodal.component';
import {DashboardTeamShareComponent} from '../dashboard-team-share/dashboard-team-share.component';
import {DashboardTeamLeaguemodalComponent} from '../dashboard-teamleaguemodal/dashboard-teamleaguemodal.component';


declare var gtag;

type Position = 'start' | 'mid' | 'end';
@Component({
  selector: 'app-dashboard-overview',
  templateUrl: './dashboard-overview.component.html',
  styleUrls: ['./dashboard-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})




export class DashboardOverviewComponent implements OnInit, OnDestroy {
  @ViewChild(CdkVirtualScrollViewport, {static: false}) viewPort: CdkVirtualScrollViewport;
  @ViewChild('calendargroup', {static: false}) calgrouptarget: ElementRef;
  @ViewChild('tiger', {static: false}) calselecttarget: ElementRef;
  @ViewChild('talkjsContainer',  {static: false}) talkjsContainer!: ElementRef;

  private popup: Talk.Popup;
  private session: Talk.Session;

  env: any;

  account: Account;
  size: number;
  calendar_days: {
    days: [
      {
        days: string
      }
    ];
    events: [
      {
        id: number,
        name: string
      }
    ];
  };
  groupMessage: PanelMessage = {
    text: 'Take the first step',
    href: '/dashboard/maps',
    buttonText: 'Join a Group'
  };
  leagueMessage: PanelMessage = {
    text: 'Feeling Competitive?',
    href: '/dashboard/maps',
    buttonText: 'Join a League'
  };

  pickleballSearch: FieldConfig[] = [
    {
      type: 'input',
      name: 'location',
      placeholder: 'Where?',
    },
    {
      type: 'select',
      label: 'Play Type',
      name: 'playType',
      placeholder: 'Play Type',
      required: false,
      options: ['Premier Pickleball Leagues', 'Club Play', 'Public Courts'],
    },
    {
      label: 'Send',
      name: 'submit',
      type: 'button',
      color: 'primary',
      disabled: true,
    }
  ];

  calgroupselect = null;
  calendardgroup = '';
  group_id = '';
  group_trigger = '';
  current_profile = null;
  isAdmin = false;
  groupCheck = false;
  cardList = [];
  clubList = [];
  groupClk = false;
  isChecked = false;

  is_player = true;
  is_league = false;
  is_club = true;
  is_leagueonly = false;

  subscriber = false;

  clubPanel = true;
  leaguePanel = false;
  sgroup_id = null;
  firsttime = 0;
  shadow = 0;
  trigger = null;

  returnurl = null;
  username = null;
  epassw = null;
  url = null;

  is_leagpanelshowing = 0;
  is_clubpanelshowing = 0;

  s1 = null;
  s2 = null;
  s3 = null;
  alreadyloaded = false;
  timer: any;
  timercount = 0;
  c_group_name = '';
  c_group_id = '';

  constructor(
    private sessionSt: SessionStorageService,
    private accountService: AccountService,
    private calendarService: CalendarService,
    private modalService: ModalService,
    private panelService: PanelService,
    private dashboardevents: DashboardeventsService,
    private toastService: ToastService,
    private changeDetectorRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private bottomSheetService: BottomSheetService,
    private http: HttpClient,
    private service: DynamicComponentService,
    private talkService: TalkService,
    public dialog: MatDialog,
  ) {



    this.env = environment;

    this.calendar_days = {
      days: null,
      events: null
    };


    this.alreadyloaded = false;

    this.s1 = this.accountService.cast.subscribe(data => {


      console.log(data);


    /** Todo: rsudduth --  need to trigger this via api call so we do not have to do a code change to put up an emergency banner.

    /**
    if(data.current_profile.profile_type == 'club'){
      if(!this.sessionSt.retrieve('emergency')){
        this.modalService.openDialogDisable(EmergencyComponent, '500px', 'auto', {});
        this.sessionSt.store('emergency',1)
      }
    }
    **/





      if (data.current_profile.groups.length > 0 && this.account !== undefined){

        for (const dataCheck of data.current_profile.groups) {
          console.log(dataCheck.is_league, dataCheck.clubcard, this.is_leagpanelshowing);
          if(dataCheck.is_league == 1 && dataCheck.clubcard == false && dataCheck.archive == 0){
            this.is_leagpanelshowing++;
          }
          if(dataCheck.is_league != 1 && dataCheck.clubcard == false && dataCheck.archive == 0){
            this.is_clubpanelshowing++;
          }
        }
        if(this.is_clubpanelshowing == 0 && this.is_leagpanelshowing > 0){
         this.onChkChange(2);
        } else {
          this.onChkChange(0);
        }
      }



      if (data.reset_password !== undefined && this.alreadyloaded === false && data.id !== null) {
        this.alreadyloaded = true;




        if(this.sessionSt.retrieve('ambassador_row') !== undefined){
          this.http.get<Account>(environment.service.trackithubapi + '/api/inside/ambassador_row?profile_id=' + data.current_profile.profile_id + '&ambassador_row=' + this.sessionSt.retrieve('ambassador_row')).subscribe((profileData: any) => {
            this.sessionSt.clear('ambassador_row');
          });
        }

        if (this.sessionSt.retrieve('gotodues') !== undefined && this.sessionSt.retrieve('gotodues') !== '' && this.sessionSt.retrieve('gotodues') !== null) {
            const group = this.sessionSt.retrieve('gotodues');
            this.sessionSt.clear('gotodues');
            window.location.replace('/dashboard/frame/dues?group_id=' + group);
        }

        if(this.route.snapshot.queryParamMap.get('profile_id') && this.route.snapshot.queryParamMap.get('media_id')){
          this.http.get<Account>(environment.service.trackithubapi + '/api/inside/loadprofile?clean_group=yes&profile=' + this.route.snapshot.queryParamMap.get('profile_id')).subscribe((profileData: any) => {
            if(this.route.snapshot.queryParamMap.get('comment_id') == null) {
              this.bottomSheetService.openBottomSheetWithDataCustomClass(ProfileComponent, 'ProfileComponent', {
                'profileData': profileData,
                'media_id': this.route.snapshot.queryParamMap.get('media_id'),
                'folder_id': this.route.snapshot.queryParamMap.get('folder_id'),
                'type': 'player',
                'tab': 'media',
              }, 'custom-bottom-p');
            }
          });
        }


        if(this.route.snapshot.queryParamMap.get('group_id')  && this.route.snapshot.queryParamMap.get('media_id')){
          this.http.get<Account>(environment.service.trackithubapi + '/api/inside/loadgroup?profile_id=' + data.current_profile.profile_id + '&clean_group=yes&group=' + this.route.snapshot.queryParamMap.get('group_id')).subscribe((profileData: any) => {
            if(this.route.snapshot.queryParamMap.get('comment_id') == null) {
              this.bottomSheetService.openBottomSheetWithDataCustomClass(ProfileComponent, 'ProfileComponent', {
                'profileData': profileData,
                'media_id': this.route.snapshot.queryParamMap.get('media_id'),
                'type': 'group',
                'tab': 'media',
                'folder_id': this.route.snapshot.queryParamMap.get('folder_id'),
              }, 'custom-bottom-p');
            }
          });
        }


        if(this.route.snapshot.queryParamMap.get('roundrobin_id') && this.route.snapshot.queryParamMap.get('funct') == 'leaguechat'){
            this.http.get<Account>(environment.service.trackithubapi + '/api/inside/league_getcourtinfo?roundrobin_id=' + this.route.snapshot.queryParamMap.get('roundrobin_id')).subscribe((courtData: any) => {
              for (const key in courtData) {
                const value = courtData[key];
                this.talkService.createGroup(value);
              }
            });
        }

        if(this.route.snapshot.queryParamMap.get('profile_id') && this.route.snapshot.queryParamMap.get('post_id') ){
          this.http.get<Account>(environment.service.trackithubapi + '/api/inside/loadprofile?clean_group=yes&profile=' + this.route.snapshot.queryParamMap.get('profile_id')).subscribe((profileData: any) => {
            if(this.route.snapshot.queryParamMap.get('comment_id') == null) {
              this.bottomSheetService.openBottomSheetWithDataCustomClass(ProfileComponent, 'ProfileComponent', {
                'profileData': profileData,
                'post_id': this.route.snapshot.queryParamMap.get('post_id'),
                'type': 'player'
              }, 'custom-bottom-p');
            } else {
              this.bottomSheetService.openBottomSheetWithDataCustomClass(ProfileComponent, 'ProfileComponent', {
                'profileData': profileData,
                'post_id': this.route.snapshot.queryParamMap.get('post_id'),
                'comment_id': this.route.snapshot.queryParamMap.get('comment_id'),
                'type': 'player'
              }, 'custom-bottom-p');
            }
          });
        }


        if(this.route.snapshot.queryParamMap.get('profile_id') && !this.route.snapshot.queryParamMap.get('post_id') && !this.route.snapshot.queryParamMap.get('media_id')){
          this.http.get<Account>(environment.service.trackithubapi + '/api/inside/loadprofile?clean_group=yes&profile=' + this.route.snapshot.queryParamMap.get('profile_id')).subscribe((profileData: any) => {
            this.bottomSheetService.openBottomSheetWithDataCustomClass(ProfileComponent, 'ProfileComponent',{
              'profileData': profileData,
              'type': 'player',
            }, 'custom-bottom-p' );
          });
        }

        if(this.route.snapshot.queryParamMap.get('group_id') && !this.route.snapshot.queryParamMap.get('post_id') && !this.route.snapshot.queryParamMap.get('media_id')) {
          this.http.get<Account>(environment.service.trackithubapi + '/api/inside/loadgroup?profile_id=' + data.current_profile.profile_id + '&clean_group=yes&group=' + this.route.snapshot.queryParamMap.get('group_id')).subscribe((profileData: any) => {
            this.bottomSheetService.openBottomSheetWithDataCustomClass(ProfileComponent, 'ProfileComponent', {
              'profileData': profileData,
              'type': 'group',
            }, 'custom-bottom-p');
          });
        }


        if (this.route.snapshot.queryParamMap.get('event_id') != null && this.route.snapshot.queryParamMap.get('repeat_id') != null) {
          if (this.route.snapshot.queryParamMap.get('event_id') !== '0' && this.route.snapshot.queryParamMap.get('repeat_id') !== '0') {
            if (data.current_profile.subscription === false && data.current_profile.is_trial === false) {
              this.toastService.show({
                text: 'Your free trial has ended. For access to the event, subscribe now.',
                type: 'warn',
              });
            } else {
              if (parseInt(this.route.snapshot.queryParamMap.get('event_id'), 10) > 100000) {
                this.bottomSheetService.openBottomSheetWithDataCustomClass(DashboardEventviewComponent, 'DashboardEventviewComponent',{
                  event: this.route.snapshot.queryParamMap.get('event_id'),
                  repeat_id: this.route.snapshot.queryParamMap.get('event_id'),
                  tab: this.route.snapshot.queryParamMap.get('tab')
                }, 'custom-bottom');
              } else {
                this.bottomSheetService.openBottomSheetWithDataCustomClass(DashboardEventviewComponent, 'DashboardEventviewComponent',{
                  event: this.route.snapshot.queryParamMap.get('event_id'),
                  repeat_id: this.route.snapshot.queryParamMap.get('repeat_id'),
                  tab: this.route.snapshot.queryParamMap.get('tab')
                }, 'custom-bottom');
              }
            }
          }
        }

        this.epassw = data.current_profile.seckey;
        this.username = data.current_profile.username;




        if (this.sessionSt.retrieve('joinoutside') !== '' && this.sessionSt.retrieve('joinoutside') !== null && this.sessionSt.retrieve('joinoutside') !== undefined) {




          if((this.sessionSt.retrieve('leagueevent') !== '' && this.sessionSt.retrieve('leagueevent') !== null && this.sessionSt.retrieve('leagueevent') !== undefined)) {
            this.returnurl = environment.service.trackithubapi + '/api/inside/groupaddx?profile=' + data.current_profile.profile_id + '&group_id=' + this.sessionSt.retrieve('joinoutside');
          } else {
            this.returnurl = environment.service.trackithubapi + '/api/inside/groupadd?profile=' + data.current_profile.profile_id + '&group_id=' + this.sessionSt.retrieve('joinoutside');
          }
          this.returnurl = btoa(this.returnurl);
          this.url = environment.service.trackithubapi + '/index.php?user=' + this.username + '&epassw=' + this.epassw + '&redirect=' + encodeURI(this.returnurl);
          this.http.get<any>(this.url).subscribe((datas: any) => {




            if((this.sessionSt.retrieve('leagueevent') !== '' && this.sessionSt.retrieve('leagueevent') !== null && this.sessionSt.retrieve('leagueevent') !== undefined)){

              if(this.sessionSt.retrieve('pregame') != true) {
                this.returnurl = environment.service.trackithubapi + '/api/inside/addtoevent?profile=' + data.current_profile.profile_id + '&rp_id=' + this.sessionSt.retrieve('leagueevent');
              } else {
                this.returnurl = environment.service.trackithubapi + '/api/inside/addtoeventpregame?profile=' + data.current_profile.profile_id + '&rp_id=' + this.sessionSt.retrieve('leagueevent');
              }

              this.returnurl = btoa(this.returnurl);

              this.url = environment.service.trackithubapi + '/index.php?user=' + this.username + '&epassw=' + this.epassw + '&redirect=' + encodeURI(this.returnurl);
              this.http.get<any>(this.url).subscribe((datas: any) => {

                if(this.sessionSt.retrieve('pregame') != true){
                  this.showLeagueModal();
                } else {
                  this.showPregameModal();
                }
                this.sessionSt.store('leagueevent', null);
                this.sessionSt.store('pregame', null);
                this.accountService.reloadprofile();

              });

            } else {
              this.toastService.show({
                text: 'Your join request(s) must be reviewed and accepted by the admin before you can participate in club play.',
                type: 'success',
              });
            }

            this.accountService.reloadprofile();

            this.returnurl = environment.service.trackithubapi + '/api/inside/checkmembership?list=' + this.sessionSt.retrieve('joinoutside');
            this.returnurl = btoa(this.returnurl);
            this.url = environment.service.trackithubapi + '/index.php?user=' + this.username + '&epassw=' + this.epassw + '&redirect=' + encodeURI(this.returnurl);
            this.http.get<any>(this.url).subscribe((datass: any) => {
              if (datass != '0') {
                window.location.replace('/dashboard/frame/dues?group_id=' + datass);
                this.sessionSt.store('joinoutside', null);
              }
            });
          });


        }
        this.sessionSt.store('joinoutside', null);


        if (data.reset_password == 1) {
          this.toastService.show({
            text: 'Please reset your password.',
            type: 'alert',
            buttonText: '',
          });
          this.bottomSheetService.openBottomSheetWithDataDisable(DashboardResetpasswordComponent, 'DashboardResetpasswordComponent',{id: this.account.id});
        } else {
          if (data.current_profile.locations.length < 1 && data.current_profile.profile_type === 'club' && data.current_profile.groups.length > 1) {
            this.toastService.show({
              text: 'Create a location for players to find your club and join your groups.',
              type: 'warn',
              buttonText: '',
            });
            let grid = null;
            grid = data.current_profile.groups.pop();
            this.modalService.openDialogDisable(CreateLocationComponent, 'auto', 'auto', {
              disableclick: true,
              clucard: false,
              group_id: grid.id,
              profile_id: data.current_profile.profile_id
            });
          }
        }
      }



      this.groupClk = false;
      this.account = data;
      this.cardList = [];
      this.groupCheck = false;

      if (this.account.current_profile.group_id == 9 || this.account.current_profile.group_id == 8){
        this.groupClk = false;
      } else {
        this.groupClk = true;
      }


      if (this.account.current_profile.group_id == 9 || this.account.current_profile.group_id == 8) {
        this.is_player = true;
        this.is_club = false;
        this.is_league = false;

        this.leaguePanel = false;
        this.clubPanel = true;

      } else if (this.account.current_profile.league == 1) {

        this.is_player = false;
        this.is_club = false;
        this.is_league = true;

        this.leaguePanel = true;
        this.clubPanel = false;

      } else {
        this.is_player = false;
        this.is_club = true;
        this.is_league = false;

        this.leaguePanel = false;
        this.clubPanel = true;
      }

      if (this.is_player === false) {
        this.subscriber = true;
      } else {

        if (this.account.current_profile.is_trial === false && this.env.service.subscription === true && this.account.current_profile.subscription === false) {
          this.subscriber = false;
        } else {
          this.subscriber = true;
        }

      }

      this.filtergroups(this.is_leagueonly);

      if (this.account.current_profile.profile_id != null) {





        if (this.sessionSt.retrieve('trigteam') !== '' && this.sessionSt.retrieve('trigteam') !== null && this.sessionSt.retrieve('trigteam') !== undefined) {
          console.log('s1');
          if (this.sessionSt.retrieve('team_id') !== '' && this.sessionSt.retrieve('team_id') !== null && this.sessionSt.retrieve('team_id') !== undefined) {
            console.log('s2');



            const dialogRef = this.dialog.open(DashboardTeamLeaguemodalComponent, {
              maxWidth: 500,
              minWidth: 370,
              data: {}
            });

            dialogRef.afterClosed().subscribe(result => {
              this.http.post<any>(environment.service.trackithubapi + '/api/outside/matchteam', {
                'profile_id': this.account.current_profile.profile_id,
                'team_id': this.sessionSt.retrieve('team_id')
              }).subscribe((datax: any) => {

                console.log('s3');


                if(this.sessionSt.retrieve('player_two') != ''){
                  this.sessionSt.clear('player_two');

                  console.log('s4');

                  this.http.post<any>(environment.service.trackithubapi + '/api/inside/jointeam', {
                    'profile_id': this.account.current_profile.profile_id,
                    'team_id': this.sessionSt.retrieve('team_id'),
                    'start_date': this.sessionSt.retrieve('last_start_date')
                  }).subscribe((x: any) => {
                    console.log('s5');
                    if(x.result == false){
                      this.toastService.show({
                        text: 'You are already part of a team participating in this league.',
                        type: 'alert',
                        buttonText: '',
                      });
                    }

                    console.log('s6');

                    this.sessionSt.clear('trigteam');
                    this.sessionSt.clear('team_id');
                    window.location.replace('/dashboard/overview');


                  });
                } else {

                  console.log('s7');


                  this.sessionSt.clear('trigteam');
                  this.sessionSt.clear('team_id');
                  window.location.replace('/dashboard/overview');
                }
              });
            });
          } else {
            console.log('s8');

            if(this.sessionSt.retrieve('player_two') != ''){
              console.log('s9');
              this.sessionSt.clear('player_two');
              this.http.post<any>(environment.service.trackithubapi + '/api/inside/jointeam', {
                'profile_id': this.account.current_profile.profile_id,
                'team_id': this.sessionSt.retrieve('team_id'),
                'start_date': this.sessionSt.retrieve('last_start_date')
              }).subscribe((x: any) => {
                if(x.result == false){
                  this.toastService.show({
                    text: 'You are already part of a team participating in this league.',
                    type: 'alert',
                    buttonText: '',
                  });
                }
              });
            }

            console.log('s10');

            const dialogRef = this.dialog.open(DashboardTeamLeaguemodalComponent, {
              maxWidth: 500,
              minWidth: 370,
              data: {}
            });

            dialogRef.afterClosed().subscribe(result => {
              this.sessionSt.clear('trigteam');
              console.log('s11');

            });
          }
        }


        if(this.sessionSt.retrieve('team_group_id') !== '' && this.sessionSt.retrieve('team_group_id') !== null && this.sessionSt.retrieve('team_group_id') !== undefined){
          console.log('s12');

          this.http.post<any>(environment.service.trackithubapi + '/api/outside/createteam', {
            'profile_id': this.account.current_profile.profile_id,
            'team_group_id': this.sessionSt.retrieve('team_group_id'),
            'start_date': this.sessionSt.retrieve('last_start_date'),
            'team_event': this.sessionSt.retrieve('team_event'),
            'team_name': this.sessionSt.retrieve('team_name'),
            'team_phone': this.sessionSt.retrieve('team_phone'),
            'team_id': this.sessionSt.retrieve('joinoutsideteamid')
          }).subscribe((datax: any) => {
            this.bottomSheetService.openBottomSheetWithDataCustomClass(DashboardTeamShareComponent, 'DashboardTeamShareComponent', {
              'rp_id': this.sessionSt.retrieve('team_event'),
              'team_id': datax.team_id,
              'team_skip' : this.sessionSt.retrieve('team_skip'),
              'team_name': this.sessionSt.retrieve('team_name')
            }, 'custom-bottom-w');
            this.sessionSt.clear('last_start_date');
            this.sessionSt.clear('joinoutsideteamid')
            this.sessionSt.clear('team_group_id');
            this.sessionSt.clear('team_event');
            this.sessionSt.clear('team_name');
            this.sessionSt.clear('team_phone');
            console.log('s13');

          });
        }


        //this.returnurl = environment.service.trackithubapi + '/index.php?option=com_community&view=profile&userid=' + this.account.current_profile.profile_id;
        //this.epassw = this.account.current_profile.seckey;
        //this.username = this.account.current_profile.username;
        //this.returnurl = btoa(this.returnurl);
        //this.url = this.domSanitizer.bypassSecurityTrustResourceUrl(environment.service.trackithubapi + '/index.php?user=' + this.username + '&epassw=' + this.epassw + '&redirect=' + this.returnurl);




        /** remove splash
        if (this.account.current_profile.splash === '0') {

          if(window.innerWidth < 590) {
            this.modalService.openDialog(DashboardEventVideoComponent, window.innerWidth + 'px', window.innerWidth + 'px', {});
          } else {
            this.modalService.openDialog(DashboardEventVideoComponent, 'auto', 'auto', {});
          }

        }
        **/

        this.s2 = this.calendarService.cast.subscribe(data => {
          this.calendar_days = data;
          if(this.firsttime != this.account.current_profile.profile_id) {
            this.firsttime = this.account.current_profile.profile_id;
            this.calendarService.pulldays('', this.account.current_profile.profile_id);
          }
          this.changeDetectorRef.detectChanges();
        });
      }



    });

    this.s3 = this.dashboardevents.cast.subscribe(data => {
      if((this.calendardgroup != data.calendargroupchange) && data.calendargroupchange != ''){
        this.calendardgroup = data.calendargroupchange;

        this.group_id = data.calendargroupchange;
        this.current_profile = this.account.current_profile.profile_id;
        this.calendarService.pulldays(data.calendargroupchange, this.account.current_profile.profile_id);
        this.calgrouptarget.nativeElement.scrollIntoView();
        this.toastService.show({
          text: 'Group events are now displaying on your calendar.',
          type: 'success',
          buttonText: '',
        });


      }

      //this.changeDetectorRef.detectChanges();


    });




  }


  showLeagueModal(){
    const dialogRef = this.dialog.open(DashboardLeaguemodalComponent, {
      maxWidth: 500,
      minWidth: 370,
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      window.location.replace("/dashboard/overview");
    });
  }

  showPregameModal(){
    const dialogRef = this.dialog.open(DashboardPregamemodalComponent, {
      maxWidth: 500,
      minWidth: 370,
      maxHeight: '90vh', // Adjust this based on your requirements
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      window.location.replace("/dashboard/overview");
    });
  }

  showTeamShareModal(){
    this.bottomSheetService.openBottomSheetWithDataCustomClass(DashboardTeamShareComponent, 'DashboardTeamShareComponent', {
      'rp_id': this.sessionSt.retrieve('teamshare'),
      'team_id': this.sessionSt.retrieve('team_id'),
      'team_name': this.sessionSt.retrieve('team_name')
    }, 'custom-bottom-w');
  }

  overviewSub(){
    /** alert("hello world"); **/
  }


  ngOnInit(): void {
    this.trigger = this.route.snapshot.queryParamMap.get('trigger');
    this.group_trigger = this.route.snapshot.queryParamMap.get('group_trigger');

    if (this.sessionSt.retrieve('teamshare') !== null) {
      this.showTeamShareModal();
    }

    if (this.trigger == 'mygroups') {
      this.bottomSheetService.openBottomSheetWithData(DashboardJoinGroupsComponent, 'DashboardJoinGroupsComponent', '');
    }

    if (this.trigger == 'recommendgroups') {
      this.bottomSheetService.openBottomSheetWithData(DashboardJoinGroupsComponent, 'DashboardJoinGroupsComponent', { tab: 'recommend', group: this.group_trigger });

      this.timer = setInterval(() => {
        if(this.timercount == 20){
          clearInterval(this.timer);
        } else {
          this.accountService.reloadprofile();
          this.timercount++;
        }
      }, 500);
    }


    if (this.trigger == 'gotodues') {
      window.location.replace('/dashboard/frame/dues?group_id=' + this.group_trigger);
    }

    this.trackConversion();




  }



  trackConversion(){
    gtag('event', 'conversion', {
      'send_to': 'AW-809351428/XhnKCInkg4wBEITy9oED'
    });

  }

  filtergroups(league){
    this.cardList = [];

    for (const dataCheck of this.account.current_profile.groups) {




      if(dataCheck.league == 1) {
        if (dataCheck.approved == 1) {
          if (this.is_player == true) {
            this.leaguePanel = true;
          }
        }
      }

      if (dataCheck.permissions == 1 && dataCheck.approved == 1) {
        this.groupCheck = true;
      }

      if(this.groupClk === true && dataCheck.permissions == 1 && dataCheck.approved == 1 && dataCheck.archive == 0) {
        if(this.is_player === true && dataCheck.clubcard === true) {

        } else {

          if(league == true){
            if(dataCheck.is_league == 1 && dataCheck.clubcard == false){
              this.cardList.push(dataCheck);
            }
          } else {
            if(league == false && (dataCheck.is_league != 1 || dataCheck.owner == 1)) {
              this.cardList.push(dataCheck);
            }

          }

          if (this.clubList.indexOf(dataCheck.organization) === -1) {
            this.clubList.push(dataCheck.organization);
          }
        }
      } else {
        if (dataCheck.approved == 1 && !this.groupClk && dataCheck.archive == 0) {
          if(league == true){
            if(dataCheck.is_league == 1 && dataCheck.clubcard == false){
              this.cardList.push(dataCheck);
            }
          } else {
            if(league == false && (dataCheck.is_league != 1 || dataCheck.owner == 1)) {
              this.cardList.push(dataCheck);
            }

          }
          if (this.clubList.indexOf(dataCheck.organization) === -1) {
            this.clubList.push(dataCheck.organization);
          }
        }
        /** if (dataCheck.approved == 0 && dataCheck.membership == 1 && !this.groupClk && dataCheck.archive == 0) { **/
        if (dataCheck.approved == 0 && !this.groupClk && dataCheck.archive == 0) {
          if(league == true){
            if(dataCheck.is_league == 1 && dataCheck.clubcard == false){
              this.cardList.push(dataCheck);
            }
          } else {
            if(league == false && (dataCheck.is_league != 1 || dataCheck.owner == 1)) {
              this.cardList.push(dataCheck);
            }
          }
          if (this.clubList.indexOf(dataCheck.organization) === -1) {
            this.clubList.push(dataCheck.organization);
          }
        }
      }

    }


    if ((this.account.current_profile.group_id == 8 || this.account.current_profile.group_id == 9) && this.groupCheck == true) {


      if (this.isChecked == true) {
        this.groupClk = true;
        this.changeDetectorRef.detectChanges();
      }
    }

  }

  ngOnDestroy() {
    if (this.s1 != null) {
      this.s1.complete();
    }
    if (this.s2 != null) {
      this.s2.complete();
    }
    if (this.s3 != null) {
      this.s3.complete();
    }
  }

  scrollel(el: HTMLElement) {
    el.scrollIntoView();
  }

  onChkChange(con) {

    switch (con){
      case 1:

        this.shadow = 1;

        this.is_leagueonly = false;
        this.groupClk = true;
        this.isChecked = this.groupClk;
        break;
      case 2:

        this.shadow = 2;

        this.is_leagueonly = true;
        this.groupClk = false;
        this.isChecked = this.groupClk;
        break;
      default:

        this.shadow = 0;

        this.is_leagueonly = false;
        this.groupClk = false;
        this.isChecked = this.groupClk;
        break;
    }

    //if(con == 1){
    //  this.groupClk = true;
    //  this.isChecked = this.groupClk;
    //} else {
    //  this.groupClk = false;
    //  this.isChecked = this.groupClk;
    //}



    //this.groupClk = !this.groupClk;
    //this.isChecked = this.groupClk;
    this.changeDetectorRef.detectChanges();
    this.filtergroups(this.is_leagueonly);
  }
  openCalendar() {
    this.modalService.openDialog(EventsCalendarFullComponent, '80%', 'auto', {ref: this.modalService});
  }

  calSelectGroup($event) {
    this.group_id = $event.value;
    this.current_profile = this.account.current_profile.profile_id;
    this.calendarService.pulldays($event.value, this.account.current_profile.profile_id);
  }
  scroll(position: Position) {
    let scrollIndex: number;
    switch (position) {
      case 'start':
        scrollIndex = 0;
        break;
      case 'mid':
        scrollIndex = this.cardList.length / 2;
        break;
      case 'end':
        scrollIndex = this.cardList.length;
        break;
    }
    this.viewPort.scrollToIndex(scrollIndex, 'smooth');
  }


}


@Component({
  selector: 'emergencyComponent',
  templateUrl: 'emergencycomponent.html',
  changeDetection: ChangeDetectionStrategy.Default
})
export class EmergencyComponent {
  constructor(
    public dialogRef: MatDialogRef<EmergencyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close({event:'Cancel'});
  }

  onYesClick(): void {
    this.dialogRef.close({event:'Success'});
  }
}
