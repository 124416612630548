import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {DynamicFormModule} from '../../dynamic-form/dynamic-form.module';
import {DashboardCardModule} from '../../common/dashboard/dashboard-card.module';
import {MaterialModule} from '../../../material.module';
import {
  PrivacyComponent,
  NotificationSettingsComponent,
  PrivacySettingsComponent,
  DeleteProfileSettingsComponent,
  DeleteProfileModalComponent
} from './index';

@NgModule({
    declarations: [
        PrivacyComponent,
        NotificationSettingsComponent,
        PrivacySettingsComponent,
        DeleteProfileSettingsComponent,
        DeleteProfileModalComponent,
    ],
    imports: [
        CommonModule,
        DynamicFormModule,
        DashboardCardModule,
        MaterialModule
    ],
    exports: [
        PrivacyComponent
    ],
    entryComponents: [
        PrivacyComponent,
      DeleteProfileModalComponent
    ]
})
export class PrivacyModule { }
