import {
    ComponentFactoryResolver,
    ComponentRef,
    Directive,
    Input,
    OnChanges,
    OnInit,
    Type,
    ViewContainerRef
} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Field} from '../../models/field.interface';
import {FieldConfig} from '../../models/field-config.interface';

import {
  FormButtonComponent,
  FormInputComponent,
  FormPasswordComponent,
  FormSelectComponent,
  FormSelectAvatarComponent,
  RadioComponent,
  FormCheckboxComponent,
  FormTextareaComponent,
  FormRecaptchaComponent,
  FormCountryComponent,
  FormStateComponent,
  FormFileInputComponent,
  FormDatepickerComponent,
} from '..';

import {FormAutocompleteAddressComponent} from '../form-autocomplete-address/form-autocomplete-address.component';
import {FormAutocompleteRegComponent} from '../form-autocomplete-reg/form-autocomplete-reg.component';
import {FormSelectSpecialComponent} from '../form-selectspecial/form-selectspecial.component';


const components: {[type: string]: Type<Field>} = {
  button: FormButtonComponent,
  checkbox: FormCheckboxComponent,
  input: FormInputComponent,
  email: FormInputComponent,
  radio: RadioComponent,
  select: FormSelectComponent,
  selectavatar: FormSelectAvatarComponent,
  selectspecial: FormSelectSpecialComponent,
  password: FormPasswordComponent,
  textarea: FormTextareaComponent,
  recaptcha: FormRecaptchaComponent,
  country: FormCountryComponent,
  state: FormStateComponent,
  date: FormDatepickerComponent,
  file: FormFileInputComponent,
  search: FormInputComponent,
  places: FormAutocompleteAddressComponent,
  regaddress: FormAutocompleteRegComponent
};

@Directive({
    selector: '[appDynamicField]'
})
export class DynamicFieldDirective implements Field, OnChanges, OnInit {
    @Input() config: FieldConfig;
    @Input() group: FormGroup;

    component: ComponentRef<Field>;

    constructor(
        private resolver: ComponentFactoryResolver,
        private container: ViewContainerRef
    ) {
    }

    ngOnChanges() {
        if (this.component) {
            this.component.instance.config = this.config;
            this.component.instance.group = this.group;
        }
    }

    ngOnInit() {
        if (!components[this.config.type]) {
            const supportedTypes = Object.keys(components).join(', ');
            throw new Error(
                `Trying to use an unsupported type (${this.config.type}).
        Supported types: ${supportedTypes}`
            );
        }
        const component = this.resolver.resolveComponentFactory<Field>(components[this.config.type]);
        this.component = this.container.createComponent(component);
        this.component.instance.config = this.config;
        this.component.instance.group = this.group;
    }
}
