import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {Chat} from '../interfaces/chat';


@Injectable()

export class ChatService {


  private chat = new BehaviorSubject<Chat>({
    is_visible: false,
    conversationID: '',
    user: {
      profile_id: null,
      name: '',
      avatarImg: '',
      avatarPlaceholder: '',
      email: ''
    }
  });

  cast = this.chat.asObservable();

  constructor() {
  }


  openchatconvo(data) {
    this.chat.next(
      {
        is_visible: true,
        conversationID: data.conversationID,
        user: {
          profile_id: data.profile_id,
          name: data.name,
          avatarImg: data.avatarImg,
          avatarPlaceholder: data.avatarPlaceholder,
          email: data.email
        }
      });
  }


  openchatuser(data) {
    this.chat.next(
      {
        is_visible: true,
        conversationID: '',
        user: {
          profile_id: data.profile_id,
          name: data.name,
          avatarImg: data.avatarImg,
          avatarPlaceholder: data.avatarPlaceholder,
          email: data.email
        }
      });
  }

  closeBox(data) {
    this.chat.next(
      {
        is_visible: false,
        conversationID: '',
        user: {
          profile_id: data.profile_id,
          name: data.name,
          avatarImg: data.avatarImg,
          avatarPlaceholder: data.avatarPlaceholder,
          email: data.email
        }
      });
  }

}

