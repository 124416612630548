import {Component, ViewChild, AfterViewInit, HostListener, OnInit} from '@angular/core';
import { FieldConfig } from '../../dynamic-form/models/field-config.interface';
import { DynamicFormComponent } from '../../dynamic-form/containers';
import { LoaderOverlayService } from '../../common/services/loader-overlay.service';
import { LocationSearchService } from '../../common/services/locationsearch.service';
import { NgZone } from '@angular/core';
import {SessionStorageService} from 'ngx-webstorage';
import {Router} from "@angular/router";
import {SearchAddressService} from "../../common/services/searchaddress.service";

@Component({
    selector: 'app-hero',
    templateUrl: './hero.component.html',
    styleUrls: ['./hero.component.scss']
})
export class HeroComponent implements AfterViewInit, OnInit {

  isWideScreen: boolean = false;
  isSmallScreen: boolean = false;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenSize();
  }

  address: Object;
  establishmentAddress: Object;

  location: any;
  playType: any;
  validform: boolean;

    @ViewChild(DynamicFormComponent, { static: true }) nform: DynamicFormComponent;

    formConfig: FieldConfig[] = [
      {
        type: 'places',
        name: 'location',
        placeholder: 'Where?',
      },
      {
        type: 'selectspecial',
        label: 'Play Type',
        name: 'playType',
        placeholder: 'Play Type',
        required: false,
        options: [    /**  {value: 'leagues', viewValue: 'Leagues'} , **/
          {value: 'Clubs', viewValue: 'Clubs'},
          {value: 'Public', viewValue: 'Public'},
          ],
      },

      {
        label: 'Find',
        name: 'submitform',
        type: 'button',
        color: 'primary',
        disabled: true,
      }
    ];

  constructor(private searchSubject: SearchAddressService, private router: Router, public zone: NgZone, private LoaderOverlayService: LoaderOverlayService, private LocationSearch: LocationSearchService, private sessionSt: SessionStorageService) { }

  ngOnInit() {
    this.checkScreenSize();
  }

  findleague(){
    location.href = '/leagues';
  }

  request(){
    window.open('https://www.surveymonkey.com/r/N8N7YSD', '_blank');
  }

  checkScreenSize() {
    const screenWidth = window.innerWidth;
    this.isWideScreen = screenWidth >= 961 && screenWidth <= 1200;
    this.isSmallScreen = screenWidth <= 990;


    //if(this.isSmallScreen == true){
    //  this.isWideScreen = true;
    //}

    console.log(this.isWideScreen, this.isSmallScreen);


  }

  ngAfterViewInit() {

    this.LocationSearch.initialize();

    this.nform.nform.controls.location.valueChanges.subscribe(change => {
      this.location = this.nform.nform.controls.location.value;
      this.updateSearch();
    });

    this.nform.nform.controls.playType.valueChanges.subscribe(change => {
      this.playType = this.nform.nform.controls.playType.value;
      this.sessionSt.store('play_type', this.playType);
      this.searchSubject.forcesearch();
      this.updateSearch();
    });

    this.nform.submit.subscribe(() => {
      this.LocationSearch.outsidesearch(this.sessionSt.retrieve('address_field'));
      this.router.navigate(['/results'], {queryParams: {}});
    });
  }

  updateSearch() {
    if (this.location !== '' && this.playType !== undefined) {
      this.validform = true;
    } else {
      this.validform = false;
    }

    this.nform.setDisabled('submitform', !this.validform);
  }

}
