import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {SessionStorageService} from "ngx-webstorage";
import {Account} from "../../common/interfaces/account";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";


@Component({
  selector: 'app-dashboard-teamleaguemodal',
  templateUrl: './dashboard-teamleaguemodal.component.html',
  styleUrls: ['./dashboard-teamleaguemodal.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})

export class DashboardTeamLeaguemodalComponent implements OnInit, OnDestroy {

  teamname: '';
  team_id: '';

  constructor(public dialogRef: MatDialogRef<DashboardTeamLeaguemodalComponent>,  private sessionSt: SessionStorageService,  private http: HttpClient) {

    if(this.sessionSt.retrieve('team_ids') !== undefined && this.sessionSt.retrieve('team_ids') != ''){
      this.team_id = this.sessionSt.retrieve('team_ids');
    } else {
      this.team_id = this.sessionSt.retrieve('team_id');
    }

    this.http.get<Account>(environment.service.trackithubapi + '/api/inside/get_teamname?team_id=' + this.team_id).subscribe((data: any) => {
      this.teamname = data.teamname;
    });
  }




  ngOnDestroy() {

  }

  ngOnInit() {

  }

  closepop(){
    this.dialogRef.close();
  }



}
