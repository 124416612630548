import {Component, Input, OnChanges, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { MatCalendarCellCssClasses } from '@angular/material';
import { MatCalendar} from '@angular/material';
import { DatePipe } from '@angular/common';
import {CalendarService} from '../../common/services/calendar.service';
import {AccountService} from '../../common/services/account.service';

@Component({
  selector: 'app-events-calendar',
  templateUrl: './events-calendar.component.html',
  styleUrls: ['./events-calendar.component.scss']
})

export class EventsCalendarComponent implements OnChanges, OnInit, OnDestroy {

  selectedDate: any;
  account: any;
  events: any;

  s1 = null;
  s2 = null;

  @Input() highlighted_days: any;
  @Input() selected_events: any;
  @Input() group_id: any;
  @Input() current_profile: any;
  @ViewChild(MatCalendar, {static: false}) calendar: MatCalendar<Date>;

  constructor(private datePipe: DatePipe, private calendarService: CalendarService, private accountService: AccountService) {
  }

  onSelect(event) {
    this.selectedDate = this.datePipe.transform(event, 'MM-dd-yyyy');

    if (this.calendar !== undefined) {
      this.calendar.updateTodaysDate();
    }

    if(this.account.current_profile.profile_id !== undefined && this.account.current_profile.profile_id !== '') {
      this.calendarService.pullevents(this.group_id, this.account.current_profile.profile_id, this.selectedDate);
    }
  }

  ngOnInit(): void {
    this.s1 = this.accountService.cast.subscribe(data => {
      this.account = data;
    });

    this.s2 = this.calendarService.cast.subscribe(data => {
      this.events = data.events;
    });

  };

  ngOnChanges(): void {
    if (this.calendar !== undefined) {
      this.calendar.updateTodaysDate();
    }
  }

  dateClass() {
    return (date: Date): MatCalendarCellCssClasses => {
      if (this.highlighted_days.includes(('0' + (date.getMonth() + 1)).slice(-2) + '-'
        + ('0' + date.getDate()).slice(-2) + '-'
        + date.getFullYear())) {

        const nd = new Date();

        if (('0' + (date.getMonth() + 1)).slice(-2) + '-'
          + ('0' + date.getDate()).slice(-2) + '-'
          + date.getFullYear() === this.selectedDate) {
          return 'special-active';
          } else {
          if (date.getTime() < nd.getTime()) {
            return 'special-date-past';
          } else {
            return 'special-date';
          }
        }

      } else {

        if (('0' + (date.getMonth() + 1)).slice(-2) + '-'
          + ('0' + date.getDate()).slice(-2) + '-'
          + date.getFullYear() === this.selectedDate) {
          return 'special-active';
        } else {
          return;
        }
      }
    };
  }

  ngOnDestroy() {
    if (this.s1 != null) {
      this.s1.complete();
    }
    if (this.s2 != null) {
      this.s2.complete();
    }
  }

}
