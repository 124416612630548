import { Injectable } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})

export class FormAccountSubscriptionService {

  constructor() { }

  form: FormGroup = new FormGroup({
    sub: new FormControl()
  });

  disableForm() {
    this.form.disable();
  }

  enableForm() {
    this.form.enable();
  }

}
