import {AfterViewInit, Component, EventEmitter, Inject, OnInit, Output, ViewChild} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Post } from '../posts.component';






@Component({
  selector: 'app-locationcomment-dialog',
  templateUrl: './location-dialog.component.html',
  styleUrls: ['./location-dialog.component.scss']
})
export class CommentLocationDialogComponent implements OnInit, AfterViewInit {

  @Output() setAddressAlt: EventEmitter<any> = new EventEmitter();
  @ViewChild('altaddress', {static: false}) altaddress: any;

  postValue: string;

  place: any;


   @ViewChild('postField', {static: false}) postField;





  constructor(public dialogRef: MatDialogRef<CommentLocationDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: {post: Post}) {
    //this.post = this.data.post.post;
   }

  ngOnInit() {
  }

  toggleLike(state: boolean){

  }

  //sharePost(post: Object) {
  //  const dialogRef = this.dialog.open(ShareDialogComponent, {
  //    data: {post: post}
  //  })
  //}

  processPost(){
    alert(this.postValue);
    this.postValue = '';

    this.postField.nativeElement.clearfocus();
  }

  close(){
    this.dialogRef.close({place: {}});
  }

  ngAfterViewInit() {
    this.getPlaceAutocomplete();
  }

  noEnter(e) {
    const key = e.key ;
    return key !== 13;
  }

  invokeEvent(place: Object) {
    this.setAddressAlt.emit(place);
  }

  private getPlaceAutocomplete() {


    const autocompletealt = new google.maps.places.Autocomplete(this.altaddress.nativeElement,
      {
        componentRestrictions: { country: ['US', 'UK', 'CA', 'MX']},
        types: ['establishment']  // 'establishment' / 'address' / 'geocode'
      });
    google.maps.event.addListener(autocompletealt, 'place_changed', () => {
      const place = autocompletealt.getPlace();
      this.invokeEvent(place);
      this.place = place;
    });
  }


  onNoClick(): void {
    this.dialogRef.close({place: {}});
  }

  onYesClick(): void {
    this.dialogRef.close({place: this.place});
  }


}
