import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {AccountService} from '../../../common/services/account.service';
import {BottomSheetService} from '../../../common/services/bottom-sheet.service';
import {RegisterComponent} from '../../../register/register.component';
import {Account} from '../../../common/interfaces/account';
import 'rxjs/add/observable/interval';
import { Observable } from 'rxjs';
import {environment} from "../../../../environments/environment";
import {RosterElement} from "../../dashboard-roster/roster-table.component";
import { HttpClient } from '@angular/common/http';
import {ChatService} from "../../../common/services/chat.service";
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-profile-switcher',
  templateUrl: './profile-switcher.component.html',
  styleUrls: ['./profile-switcher.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class ProfileSwitcherComponent implements OnInit, OnDestroy {

  account: Account;
  s1 = null;
  s2 = null;
  datax: any;

  constructor(
    private accountService: AccountService,
    private bottomSheetService: BottomSheetService,
    private http: HttpClient,
    public chatService: ChatService,
    private domSanitizer: DomSanitizer,
    private changeDetectorRef: ChangeDetectorRef,
    ) {}

  smile = 'smile';
  group_id = '';
  current_profile = null;
  isAdmin = false;
  groupCheck = false;
  cardList = [];
  groupClk = false;

  returnurl = null;
  username = null;
  epassw = null;
  url = null;
  safe = false;


  is_player = true;
  is_league = true;
  is_club = true;

  clubPanel = true;
  leaguePanel = true;
  sub = null;

  ngOnInit(): void {

    this.s2 = this.chatService.cast.subscribe(datax => {
      this.datax = datax;
    });


    this.s1 = this.accountService.cast.subscribe(data => {



      this.account = data;
      this.cardList = [];

      if(this.sub != null){
        this.sub.unsubscribe();
      }

      this.ping(this.account.id, this.account.current_profile.profile_id)

      if (this.account.current_profile.group_id === 9 || this.account.current_profile.group_id === 8) {
        this.groupClk = false;
      } else {
        this.groupClk = true;
      }

      for (const dataCheck of this.account.current_profile.groups) {
        if (dataCheck.permissions === 1) {
          this.groupCheck = true;
        }
        if (dataCheck.approved === 1) {
          this.cardList.push(dataCheck);
        }


        if (this.account.current_profile.group_id === 9 || this.account.current_profile.group_id === 8) {
          this.is_player = true;
          this.is_club = false;
          this.is_league = false;

          this.leaguePanel = true;
          this.clubPanel = true;

        } else if (this.account.current_profile.league === 1) {
          this.is_player = false;
          this.is_club = false;
          this.is_league = true;

          this.leaguePanel = true;
          this.clubPanel = false;

        } else {
          this.is_player = false;
          this.is_club = true;
          this.is_league = false;

          this.leaguePanel = false;
          this.clubPanel = true;
        }
      }



    });
  }
  onChkChange() {
    this.groupClk = this.groupClk === false;
  }

  changeProfile(profile) {

    localStorage.setItem('last_chat', '');
    localStorage.setItem('last_unread','');


    if(this.datax.is_visible == true) {
      this.datax.is_visible = false;
      this.chatService.closeBox(this.datax);
    }


    this.returnurl = environment.service.trackithubapi + '/index.php?option=com_shootout&view=contact';
    this.epassw = this.account.current_profile.seckey;
    this.username = this.account.current_profile.username;
    this.returnurl = btoa(this.returnurl);
    this.url = this.domSanitizer.bypassSecurityTrustResourceUrl(environment.service.trackithubapi + '/index.php?user=' + this.username + '&epassw=' + this.epassw + '&redirect=' + this.returnurl);
    this.accountService.pullprofile(profile);
    //this.accountService.reloadprofile();


    this.changeDetectorRef.detectChanges();

    //Todo: revist
    //window.location.href = '/dashboard/overview';


  }

  createProfile() {
    this.bottomSheetService.openBottomSheet(RegisterComponent,'RegisterComponent');
  }

  ping(account_id, profile_id) {
    //this.sub = Observable.interval(60000)
    //  .subscribe((val) => {
    //    const url = environment.service.trackithubapi + '/api/inside/ping?account_id=' + this.account.id + '&profile_id=' + this.account.current_profile.profile_id;
    //    this.http.get<RosterElement>(url).subscribe((data: any) => {});
    //  });
  }

  ngOnDestroy() {
    if (this.s1 != null) {
      this.s1.complete();
    }
    //this.sub.unsubscribe();
  }

}
