import {Component, OnInit, OnDestroy, Inject, ChangeDetectionStrategy} from '@angular/core';
import { AnimationEvent } from '@angular/animations';

import { ToastData, TOAST_CONFIG_TOKEN, ToastConfig } from './toast-config';
import { ToastRef } from './toast-ref';
import { toastAnimations, ToastAnimationState } from './toast-animation';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

/*
* From: Creating a toast service with Angular CDK
* BY Adrian Fâciu
* https://blog.angularindepth.com/creating-a-toast-service-with-angular-cdk-a0d35fd8cc12
* https://stackblitz.com/edit/angular-toast-service?file=src%2Fapp%2Ftoast%2Ftoast-ref.ts
* */
@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  animations: [toastAnimations.fadeToast],
  changeDetection: ChangeDetectionStrategy.Default
})
export class ToastComponent implements OnInit, OnDestroy {
  animationState: ToastAnimationState = 'default';
  iconType: string;
  private intervalId: any;
  private sanitizer: DomSanitizer;

  constructor(
    public data: ToastData,
    readonly ref: ToastRef,
    @Inject(TOAST_CONFIG_TOKEN) public toastConfig: ToastConfig
    ) {
    this.iconType = data.type === 'success' ? 'done' : data.type;
  }

  ngOnInit() {
    if(this.data.html){
      this.data.html = this.sanitizer.bypassSecurityTrustHtml(this.data.html)
    }
    this.intervalId = setTimeout(() => this.animationState = 'closing', 10000);
  }
  ngOnDestroy() {
    clearTimeout(this.intervalId);
  }
  close() {
    this.ref.close();
  }
  onFadeFinished(event: AnimationEvent) {
    const { toState } = event;
    const isFadeOut = (toState as ToastAnimationState) === 'closing';
    const itFinished = this.animationState === 'closing';

    if (isFadeOut && itFinished) {
      this.close();
    }
  }
}
