import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import { Account } from 'src/app/common/interfaces/account';
import {environment} from "../../../environments/environment";


interface Avatar {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-dashboard-avatar',
  templateUrl: './dashboard-avatar.component.html',
  styleUrls: ['./dashboard-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardAvatarComponent {
  account: Account;
  siteUrl = environment.service.trackithubapi + '/';
  avatarURL: string;

  @Input() size: string;
  @Input() avatarImg: string;
  @Input() avatarPlaceholder: string;
  @Input() avatarImgForce: boolean;

  constructor() {
  }
}
