import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-testimonial',
    templateUrl: './testimonial.component.html',
    styleUrls: ['./testimonial.component.scss']
})

export class TestimonialComponent implements OnInit {
    title = 'Testimonials';

    testimonials: any[] = [
        {
            'personsName': 'Chip Hullender',
            'club': 'Cherokee County Pickleball Club',
            'location': 'Canton, Georgia',
            'description': '<q>Our group, Cherokee County Pickleball Circles in Cherokee county Georgia, have used TrackitHub to create the first county-wide pickleball league in the state. All members consistently use TrackitHub to stay informed with league play, standings, individual winning percentages, and league announcements. The league fee payment process was seamless and very user-friendly.</q>',
            'imageUrl': 'assets/angular-img/testimonial-photos/chip-hullenders.jpg'
        },
        {
            'personsName': 'Ray Gagnon',
            'club': 'Kelowna Pickleball Club',
            'location': 'Kelowna, BC',
            'description': '<q>I have had great and immediate support initiating the start up and find the program to be very easy to implement. After only two shootouts our playing members are begging me to use TrackitHub for all upcoming events.</q>',
            'imageUrl': 'assets/angular-img/testimonial-photos/ray-gorgon.png'
        },
        {
            'personsName': '"Z"orano Tubo',
            'club': 'Boys & Girls Club - W. Broome',
            'location': 'Endicott, NY',
            'description': '<q>TrackitHub introduced an idea which encourages players to experience a wider range of play. TrackitHub has contributed to this explosion of steady revenue into our Club. The players love it and I do to. I recommend you contact Travis at TrackitHub. His software is user friendly and he takes the time to make sure you understand how it works before he lets you fly solo.</q>',
            'imageUrl': 'assets/angular-img/testimonial-photos/zorano.jpg'
        }
    ];

    constructor() {}


    ngOnInit() {}
}
