import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, EventEmitter,
  Inject, Input, OnDestroy,
  OnInit, Output,
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';



import { MAT_BOTTOM_SHEET_DATA } from '@angular/material';
import { SessionStorageService } from 'ngx-webstorage';
import { PanelService} from '../../common/services/panel.service';
import {
  PayPalScriptService,
  IPayPalConfig,
  ICreateOrderRequest,
  IPayPalUrlConfig,
  ICreateSubscriptionRequest, ICreateSubscriptionCallbackActions, ICreateSubscriptionCallbackData
} from 'ngx-paypal';
import {ModalService} from '../../common/services/modal.service';
import {MatDialog} from '@angular/material/dialog';
import { DashboardShowSuccessComponent } from '../dashboard-showsuccess/dashboard-showsuccess.component';
import {AccountService} from "../../common/services/account.service";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";


@Component({
  selector: 'app-subscribe',
  templateUrl: './dashboard-subscribe.component.html',
  styleUrls: ['./dashboard-subscribe.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})


export class DashboardSubscribeComponent implements OnInit, OnDestroy {

  @Input() drawerRef: any;

  public payPalConfig?: myCustomIPayPalConfig;
  public payPalConfig2?: myCustomIPayPalConfig;
  public payPalUrl?: IPayPalUrlConfig;

  trigger = null;
  storage: any;
  s1 = null;
  account: any;

  constructor(
    public http: HttpClient,
    public accountService: AccountService,
    private router: Router,
    private modalService: ModalService,
    private ref: ChangeDetectorRef,
    private panelservice: PanelService,
    private sessionSt: SessionStorageService,
    public _router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
  ) {}



  openMonth(){
    window.location.href = 'dashboard/overview?trigger=submonth';
  }

  openYear(){
    window.location.href = '/dashboard/overview?trigger=subyear';
  }

  ngOnInit(): void {
    this.trigger = this.route.snapshot.queryParamMap.get('trigger');
    this.initConfig();

    this.s1 = this.accountService.cast.subscribe(datax => {
      if (datax !== null) {
        this.account = datax.current_profile;
      }
    });

    // tslint:disable-next-line:class-name
    interface sModal {
      profile_id: any;
      details: any;
    }

    this.storage = <sModal>{};


  }

  public initConfig() {
    const self = this;



    this.payPalConfig = {
      clientId: 'AatvF_ia1APg7tJB7n7G0DLrCgkDrqHwdTLT6co11NUkRBgFjiYHGoiIfK8jpEHq5fZOrjSYdwTiRj_h',
      currency: 'USD',
      vault: 'true',
      style: {
        label: 'paypal',
        layout: 'vertical',
        size: 'small',
        shape: 'pill',
        color: 'silver',
        tagline: false,
      },
      createSubscription: function (data, actions) {
        return actions.subscription.create({
          plan_id: 'P-54Y75656P4558670HMEWT4CA',
          custom_id: self.account.profile_id.toString()
        });
      },
      onApprove: function (data, actions) {

         actions.subscription.get().then(function(details){

          self.http.post(environment.service.trackithubapi + '/api/inside/subscription', {'details': details, 'profile_id': self.account.profile_id}, {}).subscribe((data: any) => {
            window.location.href = '/dashboard/overview?trigger=success';
          });
        });
      },
      onCancel: (data, actions) => {
        this.drawerRef.close();
      },
      onError: (err) => {

      },
      onClick: (data, actions) => {

      },
    };
    this.payPalConfig2 = {
      clientId: 'AXud3_cRCn_NdVCbvppfPbMpS2WUy8E-vECdaCGbe0DuvuZ6jl6Zzs7qPT3x3F7NREp-LlGuMXXf12et',
      currency: 'USD',
      vault: 'true',
      style: {
        label: 'paypal',
        layout: 'vertical',
        size: 'small',
        shape: 'pill',
        color: 'silver',
        tagline: false,
      },
      createSubscription: function (data, actions) {
        return actions.subscription.create({
          plan_id: 'P-35E03363XB839303NMEWVPSQ',
          custom_id: self.account.profile_id.toString()
        });
      },
      onApprove: function (data, actions) {

        actions.subscription.get().then(function(details){

          self.http.post(environment.service.trackithubapi + '/api/inside/subscription', {'details': details, 'profile_id': self.account.profile_id}, {}).subscribe((data: any) => {
            self.modalService.openDialog(DashboardShowSuccessComponent, 'auto', 'auto', {ref: self.modalService});
            self.accountService.reloadprofile();
            self.drawerRef.close();
          });
        });
      },
      onCancel: (data, actions) => {

      },
      onError: (err) => {

      },
      onClick: (data, actions) => {

      },
    };
  }

  ngOnDestroy() {
    if (this.s1 != null) {
      this.s1.complete();
    }
  }

}

export interface myCustomIPayPalConfig extends IPayPalConfig{
  createSubscription?: (data: ICreateSubscriptionCallbackData, actions: myCustomICreateSubscriptionCallbackActions) => void;
}

export interface myCustomICreateSubscriptionCallbackActions extends ICreateSubscriptionCallbackActions {
  subscription: {
    create: (subscription: myCustomSubscription) => Promise<any>;
  };
}

interface myCustomSubscription extends ICreateSubscriptionRequest{
  custom_id?:string;
  plan_id: string;
  quantity?: number;
}
