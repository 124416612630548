import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer, Title} from '@angular/platform-browser';
import {RecordsService} from './common/services/records.service';
import {SEOService} from './common/services/seo.service';
import {Event, NavigationStart, Router} from '@angular/router';
import {AccountService} from './common/services/account.service';
import {LocationService} from './common/services/location.service';
import { filter, map, mergeMap } from 'rxjs/operators';
import {SessionStorageService} from 'ngx-webstorage';
import {ActivatedRoute, NavigationEnd} from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default
})
export class AppComponent implements OnInit {
  siteTitle = 'TrackitHub';
  records = {};
  private cookieLong: any;
  private cookieLat: any;

  public lat;
  public lng;

  public ngOnInit(): void {
    this.cookieLat = this.cookieService.get('lat');
    this.cookieLong = this.cookieService.get('long');
    this.getLocation();
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => {
        return this.activatedRoute;
      }),
      map((route) => {
        while (route.firstChild) { route = route.firstChild; }
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data)
    )
      .subscribe((event) => {
        this._seoService.updateTitle(this.siteTitle + ' - ' + event['title']);
        // Updating Description tag dynamically with title
        this._seoService.updateDescription( event['description']);
      });
  }

  constructor(
    private api: RecordsService,
    private router: Router,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private _seoService: SEOService,
    public _router: Router,
    private accountService: AccountService,
    private locationService: LocationService,
    private sessionSt: SessionStorageService,
    private activatedRoute: ActivatedRoute,
    private cookieService: CookieService,
  ) {
    this.router.events.subscribe((event: Event) => {
      this.navigationInterceptor(event);
    });


    this.matIconRegistry.addSvgIcon(
      `trophy`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/angular-img/icons/trophy.svg')
    );

    this.matIconRegistry.addSvgIcon(
      `facebook`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/angular-img/icons/facebook-square.svg')
    );

    this.matIconRegistry.addSvgIcon(
      `youtube`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/angular-img/icons/youtube-square.svg')
    );

    this.matIconRegistry.addSvgIcon(
      `pickleball`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/angular-img/icons/pickleball.svg')
    );

    this.matIconRegistry.addSvgIcon(
      `pickleballClear`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/angular-img/icons/pickleball-transparent.svg')
    );

    this.matIconRegistry.addSvgIcon(
      `pickleballClear`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/icons/pickleball-transparent.svg')
    );

    this.matIconRegistry.addSvgIcon(
      `pickleball-yellow`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/angular-img/icons/pickleball-yellow.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `ladder`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/angular-img/icons/ladder.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `shootout`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/angular-img/icons/shootout.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `round-robin`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/angular-img/icons/round-robin.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `announce`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/angular-img/icons/megaphone.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `photos`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/angular-img/icons/camera.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `globe`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/angular-img/icons/globe.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `news`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/angular-img/icons/news.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `expander`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/angular-img/icons/expander.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `contractor`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/angular-img/icons/contractor.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `calendar_add`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/angular-img/icons/add-to-calendar.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `share`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/angular-img/icons/share.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `restroom`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/angular-img/icons/restroom.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `shower`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/angular-img/icons/shower.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `water`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/angular-img/icons/water.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `light_bulb`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/angular-img/icons/lightbulb.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `clipboard`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/angular-img/icons/clipboard.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `chat_bubble`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/angular-img/icons/chat-bubble.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `money`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/angular-img/icons/attach_money.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `roster`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/angular-img/icons/roster.svg')
    );
  }

  getLocation() {
    if (this.cookieLat !== '' && this.cookieLong !== '') {
      this.lat = this.cookieLat;
      this.lng = this.cookieLong;
      this.sessionSt.store('current_lat', this.lat);
      this.sessionSt.store('current_lng', this.lng);
      this.locationService.load();
    } else {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position: Position) => {
            if (position) {
              this.lat = position.coords.latitude;
              this.lng = position.coords.longitude;
            } else {
              this.lat = 33.448376;
              this.lng = -112.074036;
            }
            const expiredDate = new Date();
            this.sessionSt.store('current_lat', this.lat);
            this.sessionSt.store('current_lng', this.lng);
            this.cookieService.set('lat', this.lat, expiredDate.getDate() + 7);
            this.cookieService.set('long', this.lng, expiredDate.getDate() + 7);
            this.locationService.load();
          },
          (error: PositionError) => {
            this.lat = 33.448376;
            this.lng = -112.074036;
            const expiredDate = new Date();
            this.sessionSt.store('current_lat', this.lat);
            this.sessionSt.store('current_lng', this.lng);
            this.cookieService.set('lat', this.lat, expiredDate.getDate() + 7);
            this.cookieService.set('long', this.lng, expiredDate.getDate() + 7);
            this.locationService.load();
          });
      }
    }
  }


  private navigationInterceptor(event: Event): void {
    if (event instanceof NavigationStart) {
      this.records = this.api.checkauthstatus()
      .subscribe(data => {
        this.records = data;
        if (event.url.includes('/dashboard')) {

          if (data.result === false) {
            this.sessionSt.store('return_url', event.url);



            this.router.navigate(['/', 'login']);
          } else {



            if(this.sessionSt.retrieve('return_url')){
              window.location.href = this.sessionSt.retrieve('return_url');
              this.sessionSt.clear('return_url');
            } else {




              if (data.password_reset === 1 && this.router.url.indexOf('/dashboard/overview') === -1) {
                this.router.navigate(['/dashboard/overview']);
              } else {
                this.accountService.load();
              }
            }
          }
        } else {
          if (data.result === true) {
            if(this.sessionSt.retrieve('return_url')){
              this.router.navigate([this.sessionSt.retrieve('return_url')]);
              this.sessionSt.clear('return_url');
            } else {
              this.router.navigate(['/dashboard/overview']);
            }
          }
        }
      });
    }
  }
}
