import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-regisration-packages',
  templateUrl: './regisration-packages.component.html',
  styleUrls: ['./regisration-packages.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class RegisrationPackagesComponent implements OnInit {
  basicPackage = [
      'League Coordinator (LC) will coordinate ALL league activities',
      'Customer service is only provided once the LC purchases a support block',
      'LC receives 80% of overall league fees paid by participants'
  ];
  proPackage = [
      'All-Inclusive',
      'League Coordinator (LC) will be designated a PPL PRO',
      'The PPL PRO will create and manage all league activities as well as provide immediate support when needed via chat',
      'Minimal to no work is required of LC',
      'LC receives 20% of overall league fees paid by participants'
  ];
  ngOnInit() {
  }

}
