import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-club',
  templateUrl: './club.component.html',
  styleUrls: ['./club.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class ClubComponent implements OnInit {
    title = 'Club Organized Play';
    clubs: any[] = [
        {
            'title': 'Round-Robins',
            'icon': 'round-robin',
            'url': 'http://pickleballrocks.com/high-performance-pickleball-bootcamps-clinics-and-mini-camps-2/',
            'description': 'The Round-Robin format is fairly self-explanatory. Regardless the number players that registered for an event, club admins have the ability to select the number of games they would like the participants to play and the amount of players in each grouping. There is a great deal of flexibility that the Round-Robin and Flex Ladder format offers. Round-Robins are run competitively or socially as players rotate partners each game. If you are interested in playing in a Round-Robin, Create a Player Account. If you are a club admin and would like to run a Round-Robin, Create a Club Account.',
            'buttonText': 'Sign Up',
        },
        {
            'title': 'Flex Ladders',
            'icon': 'ladder',
            'url': 'http://www.pickleballrocks.com/',
            'description': 'Flex Ladders are very similar to Round-Robins. Flex Ladders (ladder-leagues) are perpetually tracked and the players are placed on the courts based on their skill level and percentage of points scored. Each player will compete in 3 or 4 games while rotating partners and substitutes are not needed if one or more of your players do not show. If you are interested in playing in a Flex Ladder, Create a Player Account. If you are a club admin and would like to run a Flex Ladder, Create a Club Account.',
            'buttonText': 'Sign Up',
        },
        {
            'title': 'Shootouts',
            'icon': 'shootout',
            'url': 'https://www.usapa.org/',
            'description': 'The Shootout format is similar to a Flex Ladder but offers the option of playing a second round of 3 or 4 more games. The administrator has the ability to shuffle the player with the lowest percentage down a court and the player with the highest percentage up a court as the event is taking place. A second round of games will then be held with two new players on each court. If you are interested in playing in a Shootout, Create a Player Account. If you are a club admin and would like to run a Shootout, Create a Club Account.',
            'buttonText': 'Sign Up',
        },
    ];

  constructor() { }

  ngOnInit() {
  }

}
