import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-dashboard-card-member-dues',
  templateUrl: './club-card-member-dues.component.html',
  styleUrls: ['./club-card-member-dues.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class ClubCardMemberDuesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
