import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {BrowserModule, Title} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TourMatMenuModule} from 'ngx-tour-md-menu';
import {MaterialModule} from '../material.module';
import {CdkTreeModule} from '@angular/cdk/tree';
import {AngularResizedEventModule} from 'angular-resize-event';
import {LayoutModule} from '@angular/cdk/layout';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { CookieService } from 'ngx-cookie-service';

// Routing
import {appRoutingModule} from './app-routing.module';
import {GoogleAnalyticsService, NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule} from "ngx-google-analytics";

// Custom Modules
import {DashboardModule} from './dashboard/dashboard.module';
import {EventsModule} from './events/events.module';
import {PPLModule} from './ppl/ppl.module';
import {ClubsModule} from './clubs/clubs.module';
import {DynamicFormModule} from './dynamic-form/dynamic-form.module';
import {FrontendModule} from './frontend/frontend.module';

// Vendor Modules
import {AnimateOnScrollModule} from 'ng2-animate-on-scroll';
import {NgxPageScrollModule} from 'ngx-page-scroll';
import {NgxPageScrollCoreModule} from 'ngx-page-scroll-core';
import {RecaptchaModule} from 'ng-recaptcha';
import {RecaptchaFormsModule} from 'ng-recaptcha/forms';
import {NgxMaskModule} from 'ngx-mask';
import {NgxWebstorageModule} from 'ngx-webstorage';

// Services
import {RecordsService} from './common/services/records.service';
import {Backplane} from './common/services/backplane.service';
import {SEOService} from './common/services/seo.service';
import {PanelService} from './common/services/panel.service';
import {ThemeService} from './common/services/theme.service';
import {SnackbarService} from './common/services/snackbar.service';
import { FilterPipeModule } from 'ngx-filter-pipe';

// Components
import {AppComponent} from './app.component';
import {HeaderComponent} from './common/frontend-components/header/header.component';
import {IntroComponent} from './intro/intro.component';
import {ClubComponent} from './club/club.component';
import {FooterComponent} from './common/frontend-components/footer/footer.component';
import {PricingComponent} from './common/frontend-components/pricing/pricing.component';
import {PricingTableComponent} from './pricing-table/pricing-table.component';
import {PricingCardsComponent} from './pricing-cards/pricing-cards.component';
import {MembershipComponent} from './membership/membership.component';
import {EventComponent} from './common/frontend-components/event/event.component';
import {RegisterComponent} from './register/register.component';
import {CommunicationComponent} from './common/frontend-components/communication/communication.component';
import {LoginComponent} from './common/frontend-components/login/login.component';
import {PickelballRatingComponent} from './pickelball-rating/pickelball-rating.component';
import {RegisrationPackagesComponent} from './regisration-packages/regisration-packages.component';
import {ToastModule} from './common/toast';
import {TermsDialogComponent} from './terms-dialog/terms-dialog.component';
import {PlayerJoinDialogComponent} from './player-join-dialog/player-join-dialog.component';
import {TitleComponent} from './title/title.component';
import {ListComponent} from './common/list/list.component';
import {DashboardNavComponent} from './dashboard/dashboard-nav/dashboard-nav.component';
import {DashboardChatPanelComponent} from './dashboard/dashboard-chatpanel/dashboard-chatpanel.component';
import {DashboardOverviewComponent} from './dashboard';
import {ClubPplWelcomeDialogComponent} from './club-ppl-welcome-dialog/club-ppl-welcome-dialog.component';
import {PrivacyPolicyComponent} from './terms/privacy-policy/privacy-policy.component';
import { DashboardCardModule} from './common/dashboard/dashboard-card.module';
import { ScrollingModule} from '@angular/cdk/scrolling';
import { StandingsTableComponent } from './standings/standings-table/standings-table.component';
import { MatTableModule, MatPaginatorModule, MatSortModule } from '@angular/material';
import { StandingsScorecardComponent } from './standings/standings-scorecard/standings-scorecard.component';
import { StandingsResultsComponent } from './standings/standings-results/standings-results.component';
import { RosterTableComponent, DialogRosterAdminAdd, DialogRosterAdminRemove, DialogRosterAdminCopy, DialogRosterAdminMove, DialogRosterAdminDelete } from './dashboard/dashboard-roster/roster-table.component';
import { ScorecardGenerateShootoutComponent} from './scorecard/scorecard-generate-shootout/scorecard-generate-shootout.component';
import { ScorecardGenerateRoundRobinComponent } from './scorecard/scorecard-generate-round-robin/scorecard-generate-round-robin.component';
import { RegisterLeagueComponent } from './register/register-league/register-league.component';
import { RegisterClubComponent } from './register/register-club/register-club.component';
import { InputExpandDirective } from './common/directives/input-expand.directive';
import { LocationService } from './common/services/location.service';
import { LocationSearchService } from './common/services/locationsearch.service';
import { SearchAddressService } from './common/services/searchaddress.service';
import { PanelCardService} from './common/services/panelcard.service';
import { RetrioeveaddressService } from './common/services/retrioeveaddress.service';
import { KebabCasePipe } from './common/pipes/kebab-case.pipe';
import { NotFoundComponent } from './not-found/not-found.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import {AccountSettingsModule} from './settings/account-settings/account-settings.module';
import { TrimPipe } from './common/pipes/trim.pipe';
import { ScrollButtonComponent } from './common/components/scroll-button/scroll-button.component';
import {ZendeskService} from './common/services/zendesk.service';
import {MapResultsModule} from './frontend/map-results/map-results.module';
import {LeaguesModule} from './frontend/leagues/leagues.module';
import {StepperModule} from "./stepper/stepper.module";
import {NgxPayPalModule} from "ngx-paypal";
import { AlertComponent } from './common/components/alert/alert.component';
import { ImageCropperModule } from 'ngx-image-cropper';

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        IntroComponent,
        ClubComponent,
        PricingComponent,
        PricingTableComponent,
        PricingCardsComponent,
        MembershipComponent,
        EventComponent,
        RegisterComponent,
        CommunicationComponent,
        LoginComponent,
        PickelballRatingComponent,
        RegisrationPackagesComponent,
        TermsDialogComponent,
        PlayerJoinDialogComponent,
        TitleComponent,
        ListComponent,
        ClubPplWelcomeDialogComponent,
        PrivacyPolicyComponent,
        DashboardNavComponent,
        DashboardChatPanelComponent,
        DashboardOverviewComponent,
        StandingsTableComponent,
        StandingsScorecardComponent,
        StandingsResultsComponent,
        RosterTableComponent,
        DialogRosterAdminAdd,
        DialogRosterAdminRemove,
        DialogRosterAdminCopy,
        DialogRosterAdminMove,
        DialogRosterAdminDelete,
        ScorecardGenerateShootoutComponent,
        ScorecardGenerateRoundRobinComponent,
        RegisterLeagueComponent,
        RegisterClubComponent,
        InputExpandDirective,
        KebabCasePipe,
        NotFoundComponent,
        ErrorPageComponent,
        TrimPipe,
        ScrollButtonComponent,
        AlertComponent,
    ],
  imports: [
    MapResultsModule,
    LeaguesModule,
    AngularEditorModule,
    BrowserAnimationsModule,
    FilterPipeModule,
    BrowserModule,
    BrowserAnimationsModule,
    appRoutingModule,
    AnimateOnScrollModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    AnimateOnScrollModule,
    NgxPageScrollCoreModule,
    NgxPageScrollModule,
    HttpClientModule,
    CdkTreeModule,
    DynamicFormModule,
    RecaptchaModule.forRoot(),
    RecaptchaFormsModule,
    AngularResizedEventModule,
    NgxMaskModule.forRoot(),
    DashboardModule,
    LayoutModule,
    EventsModule,
    PPLModule,
    ClubsModule,
    DashboardCardModule.forRoot(),
    ScrollingModule,
    NgxWebstorageModule.forRoot(),
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    FrontendModule,
    ToastModule.forRoot(),
    TourMatMenuModule.forRoot(),
    AccountSettingsModule,
    StepperModule,
    NgxPayPalModule,
    NgxGoogleAnalyticsModule.forRoot('UA-90748256-1'),
    NgxGoogleAnalyticsRouterModule,
    ImageCropperModule
  ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent],
    providers: [
        GoogleAnalyticsService,
        CookieService,
        LocationService,
        LocationSearchService,
        SearchAddressService,
        PanelCardService,
        RetrioeveaddressService,
        Title,
        RecordsService,
        Backplane,
        SEOService,
        SnackbarService,
        ThemeService,
        PanelService,
        ZendeskService
    ],
    exports: [
        KebabCasePipe,
    ],
    entryComponents: [
        DialogRosterAdminAdd,
        DialogRosterAdminRemove,
        DialogRosterAdminCopy,
        DialogRosterAdminMove,
        DialogRosterAdminDelete,
        PickelballRatingComponent,
        TermsDialogComponent,
        ClubPplWelcomeDialogComponent,
        StandingsScorecardComponent,
        StandingsResultsComponent,
        RegisterLeagueComponent,
        RegisterClubComponent,
        AlertComponent,
    ]
})

export class AppModule {

}
