import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {
  DashboardCreateGroupComponent,
  DashboardUploadImportComponent
} from '../../../../dashboard';
import {BottomSheetService} from '../../../services/bottom-sheet.service';
import {HttpClient} from '@angular/common/http';
import {ToastService} from '../../../toast';
import {AccountService} from '../../../services/account.service';
import {environment} from '../../../../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard-card-menu',
  templateUrl: './dashboard-card-menu.component.html',
  styleUrls: ['./dashboard-card-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class DashboardCardMenuComponent implements OnInit {

  constructor(
    private bottomSheetService: BottomSheetService, private http: HttpClient, private toastService: ToastService, private accountService: AccountService, private router: Router
  ) {}

  @Input() card: any;
  @Input() group_id: string;
  @Input() event_id: string;
  @Input() isLeague: boolean;
  @Input() membership: number;

  svgIcon?: string;
  icon?: string;
  menuItems: any;

  ngOnInit(): void {

    if (this.isLeague === false) {
      if (this.membership === 1) {
        this.menuItems = [] = [
          {
            name: 'Edit Group',
            icon: 'edit',
            route: '/dashboard/editgroup/' + this.card.id
          },
          {
            name: 'Create Event',
            icon: 'event_note',
            route: '/dashboard/frame/createevent'
          },
          {
            name: 'Membership Setup',
            svgIcon: 'money',
            route: '/dashboard/membershipadmin/' + this.card.id
          },
          {
            name: 'Import Members',
            icon: 'cloud_upload',
            route: '/dashboard/import/' + this.card.id
          },
        ];
      } else {
        this.menuItems = [] = [
          {
            name: 'Edit Group',
            icon: 'edit',
            route: '/dashboard/editgroup/' + this.card.id
          },
          {
            name: 'Create Event',
            icon: 'event_note',
            route: '/dashboard/frame/createevent'
          },
          {
            name: 'Import Members',
            icon: 'cloud_upload',
            route: '/dashboard/import/' + this.card.id
          },
        ];
      }
    } else {
      this.menuItems = [] = [
        {
          name: 'Edit Details',
          icon: 'edit',
          route: '/dashboard/editevent/' + this.event_id
        },
        {
          name: 'Import Members',
          icon: 'cloud_upload',
          route: '/dashboard/import'
        },
      ];
    }
  }

  editGroup(param) {
    this.bottomSheetService.openBottomSheetWithData(DashboardCreateGroupComponent, 'DashboardCreateGroupComponent',{'id': this.card.id, 'card': this.card});
  }
  importGroup(param) {
    this.router.navigateByUrl('/dashboard/import/' + param);


    //this.bottomSheetService.openBottomSheetWithData(DashboardUploadImportComponent, 'DashboardUploadImportComponent',{});

  }

  archiveGroup(){
    this.http.get<any>(environment.service.trackithubapi + '/api/inside/togglearchive?group_id=' + this.card.id + '&flag=1').subscribe((data: any) => {
      this.accountService.reloadprofile();
      this.toastService.show({
        text: 'Group has been archived. Archived groups can be found in the My Groups panel.',
        type: 'success',
        buttonText: '',
      });
    });
  }

}



