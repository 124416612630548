import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter, OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';

import {Router} from "@angular/router";
import { SessionStorageService } from 'ngx-webstorage';
import { SearchAddressService } from '../../common/services/searchaddress.service';
import {environment} from "../../../environments/environment";
import {BottomSheetService} from "../../common/services/bottom-sheet.service";
import {DashboardSearchplayersComponent} from "../dashboard-searchplayers/dashboard-searchplayers.component";
import {Account} from "../../common/interfaces/account";
import {HttpClient} from "@angular/common/http";
import {AccountService} from "../../common/services/account.service";


@Component({
  selector: 'app-dashboard-search',
  templateUrl: './dashboard-search.component.html',
  styleUrls: ['./dashboard-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class DashboardSearchComponent implements OnInit, AfterViewInit, OnDestroy {

  searchtype = 'Club';
  searchbool = true;
  searchvalue = null;
  s1 = null;
  account = null;

  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  @ViewChild('addresstext', {static: false}) addresstext: any;

  constructor(private accountService: AccountService, private http: HttpClient, private bottomSheetService: BottomSheetService, private router: Router, private sessionSt: SessionStorageService, private SearchAddress: SearchAddressService) {
  }

  ngOnInit() {


    this.s1 = this.accountService.cast.subscribe(data => {
      this.account = data;
    });






  }

  ngAfterViewInit() {
    this.getPlaceAutocomplete();
  }

  updatesearch($event,types){
    if(types == 'Input'){
      this.searchvalue = $event.target.value;
      if($event.key == 'Enter'){
        this.search();
      }
    }

    if(types == 'Select'){
      this.searchtype = $event.value;
      if($event.value === 'Players'){
        this.searchbool = false;
      } else {
        this.searchbool = true;
      }

    }

  }

  search(){
    if(this.searchtype == 'Players' || this.searchtype == null || this.searchtype == undefined) {
      if(environment.service.profile == true){
        this.http.get<Account>(environment.service.trackithubapi + '/api/inside/searchplayer?profile_id=' + this.account.current_profile.profile_id  + '&search=' + this.searchvalue).subscribe((playerData: any) => {
          this.bottomSheetService.openBottomSheetWithData(DashboardSearchplayersComponent, 'DashboardSearchplayersComponent',{
            list: playerData
          });
        });
      } else {
        this.router.navigate(['/dashboard/frame/search'], {queryParams: {search: this.searchvalue}});
      }
    } else {
      this.sessionSt.store('play_type', this.searchtype);
      this.SearchAddress.forcesearch();
      this.router.navigate(['/dashboard/map'], {queryParams: {}});
    }
  }

  private getPlaceAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement,
      {
        componentRestrictions: { country: ['US', 'UK', 'CA', 'MX'] },
        types: []  // 'establishment' / 'address' / 'geocode'
      });
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();
      this.SearchAddress.update(place.geometry.location.lat(), place.geometry.location.lng(), place.formatted_address);
      this.invokeEvent(place);
    });
  }

  invokeEvent(place: Object) {
    this.setAddress.emit(place);
  }

  ngOnDestroy() {
    if (this.s1 != null) {
      this.s1.complete();
    }
  }


}
