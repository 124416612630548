import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {AccountService} from '../../common/services/account.service';
import {Account} from '../../common/interfaces/account';

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class AccountSettingsComponent implements OnInit, OnDestroy {

  account: Account;
  s1 = null;

  constructor(private accountService: AccountService) { }

  ngOnInit() {
    this.s1 = this.accountService.cast.subscribe(data => {
      this.account = data;
    });
  }

  ngOnDestroy() {
    if (this.s1 != null) {
      this.s1.complete();
    }
  }

}
