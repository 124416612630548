import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {ModalService} from '../../../common/services/modal.service';
import {DeleteProfileModalComponent} from './delete-profile-modal/delete-profile-modal.component';

@Component({
  selector: 'app-delete-profile-settings',
  templateUrl: './delete-profile-settings.component.html',
  styleUrls: ['./delete-profile-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class DeleteProfileSettingsComponent {
  disable = true;
  @Input() accountdata = null;

  constructor( private modalService: ModalService) {
  }
  deleteProfileConfirm() {
    this.modalService.openDialog(DeleteProfileModalComponent, 'auto', 'auto', {id: this.accountdata.current_profile.profile_id, is_account: this.accountdata.current_profile.is_account, count: this.accountdata.profiles.length});
  }

}
