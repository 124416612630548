import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';

export interface Days {
  label: string;
  id: string;
}

@Component({
  selector: 'app-form-busy-times',
  templateUrl: './form-busy-times.component.html',
  styleUrls: ['./form-busy-times.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class FormBusyTimesComponent implements OnInit {
  days = [
    {
      "label":"Su",
      "id":"sunday"
    },
    {
      "label":"M",
      "id":"monday" },
    {
      "label":"Tu",
      "id":"tuesday"
    },
    {
      "label":"W",
      "id":"wednesday"
    },
    {
      "label":"Th",
      "id":"thursday"
    },
    {
      "label":"F",
      "id":"friday"
    },
    {
      "label":"S",
      "id":"saturday"
    },
  ]
  constructor() { }

  ngOnInit() {
  }

}
