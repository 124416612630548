import {Directive, HostListener, Input} from '@angular/core';
import {ModalService} from '../services/modal.service';
import { DashboardShareComponent} from '../../dashboard';

@Directive({
  selector: '[appShare]'
})

export class ShareDirective {

  @Input() title = '';
  @Input() url = '';

  constructor(private modalService: ModalService) { }

  @HostListener('click', ['$event.target.id']) onClick(id: any) {
    this.modalService.openDialog(DashboardShareComponent, 'auto', 'auto', {'url': this.url, 'title': this.title});
  }
}
