import {Component} from '@angular/core';
import {FormGroup} from '@angular/forms';
import { Backplane } from '../../../common/services/backplane.service';
import {Field} from '../../models/field.interface';
import {FieldConfig} from '../../models/field-config.interface';



@Component({
    selector: 'app-form-state',
    styleUrls: ['form-state.component.scss'],
    template: `
            <mat-form-field color="{{config.color}}" [formGroup]="group">
                <mat-select [formControlName]="config.name" placeholder="{{ config.placeholder }}">
                    <mat-option *ngFor="let item of statelist" [value]="item.name">
                        {{ item.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>`
})
export class FormStateComponent implements Field {
    config: FieldConfig;
    group: FormGroup;

    public statelist;

    constructor(private getters: Backplane) {
        this.getters.stateList.subscribe(tx => {
            this.statelist = tx;
        });
    }

}


