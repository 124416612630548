import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AccountService } from 'src/app/common/services/account.service';
import { FormAccountSettingsService } from 'src/app/common/services/form-account-settings.service';
import { ToastService } from 'src/app/common/toast';
import { environment } from "../../../../environments/environment";
import { Account } from 'src/app/common/interfaces/account';
import { Subscription } from 'rxjs';
import { ChangeAvatarComponent } from './change-avatar/change-avatar.component';
import { DeleteProfileComponent } from './delete-profile/delete-profile.component';
import { ModalService } from 'src/app/common/services/modal.service';
import {RecordsService} from "../../../common/services/records.service";

// import { } from '@types/googlemaps';

interface Avatar {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-personal-info',
  templateUrl: './personal-info.component.html',
  styleUrls: ['./personal-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class PersonalInfoComponent implements OnInit, AfterViewInit {

  @Input() userData: Account["current_profile"];

  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  @Output() setAddressAlt: EventEmitter<any> = new EventEmitter();

  @ViewChild('address', {static: false}) address: any;
  @ViewChild('altaddress', {static: false}) altaddress: any;
  @ViewChild('picker', {static: true}) picker: any;

  s1: Subscription = null;
  s2: Subscription = null;
  s3: Subscription = null;

  siteUrl = environment.service.trackithubapi + '/';

  account: Account;
  profileData: Account["current_profile"];
  rawData: Account["current_profile"]["raw"];

  editMode: boolean;
  nameLabel: String;
  genders: Object[];
  genderValue: string;
  addressObj: google.maps.places.PlaceResult;
  addressAltObj: google.maps.places.PlaceResult;
  ratings: String[];
  ratingValue: string;
  timezones: Object[];
  timezoneValue: string;
  avatarImg: string;
  avatarPlaceholder: string;

  avatars: Avatar[] = [
    {value: 'club', viewValue: 'Club'},
    {value: 'grin', viewValue: 'Grin'},
    {value: 'slight-smile', viewValue: 'Slight Smile'},
    {value: 'smile', viewValue: 'Smile'},
    {value: 'smiling-face-eyes', viewValue: 'Smiling Face Eyes'},
    {value: 'smirking-face', viewValue: 'Smirking Face'},
    {value: 'tilted-grin', viewValue: 'Tilted-grin'},
    {value: 'winking-grin', viewValue: 'Winking-Grin'},
    {value: 'worried-smile', viewValue: 'Worried-Smile'},
  ];


  constructor(private validationService: RecordsService, private toastService: ToastService, private accountService: AccountService, public formService: FormAccountSettingsService, private http: HttpClient, private modalService: ModalService) {

    this.editMode = false;

    this.genders = [ {label: 'Female', value: 'COM_COMMUNITY_FEMALE'}, {label: 'Male', value: 'COM_COMMUNITY_MALE'}, {label: 'Non-binary', value: 'COM_COMMUNITY_BIN'}, {label: 'Prefer not to say', value: 'COM_COMMUNITY_NON'}];
    this.addressObj = null;
    this.addressAltObj = null;
    this.ratings = ['0.0', '1.0', '1.5', '2.0', '2.5', '3.0', '3.5', '4.0', '4.5', '5.0'];


    this.timezones = [
      {label: 'Hawaii-Aleutian Time', value: 'Hawaii-Aleutian Time'},
      {label: 'Alaska Time', value: 'Alaska Time'},
      {label: 'Pacific Time', value: 'Pacific Time'},
      {label: 'Mountain Time', value: 'Mountain Time'},
      {label: 'Central Time', value: 'Central Time'},
      {label: 'Eastern Time', value: 'Eastern Time'}
    ];

    this.s1 = this.accountService.cast.subscribe(data => {
      this.account = data;
      this.profileData = data.current_profile;
      if (this.profileData.group_id === 15) {
        if (this.profileData.league === 1 ) {
          this.nameLabel = 'Organization Name';
        } else {
          this.nameLabel = 'Club Name';
        }
      }

      this.rawData = data.current_profile.raw;

      this.formService.initializeFormGroup(data.current_profile.raw);

      this.avatarImg = this.profileData.avatar;
      this.avatarPlaceholder = this.profileData.avataricon;


    });
   }

  ngOnInit() {
    this.formService.disableForm();
  }

  ngAfterViewInit() {
    this.getPlaceAutocomplete();

    this.s2 = this.formService.form.valueChanges.subscribe(data => {
      if(this.userData.is_account == 1) {
        if(this.userData.raw['email'] != data.email && data.email != '') {

          if(this.validateEmail(data.email)) {
            this.s3 = this.validationService.checkEmail(data.email).subscribe(ret => {
              if (ret.found === 1) {
                this.toastService.show({
                  text: `The email address entered is associated with a different account. Please enter a unique email address.`,
                  type: 'alert',
                });
                this.formService.setemail(this.userData.raw['email']);
              }
            });
          }

        }
      }
    });

  }

  validateEmail(email){
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  ngOnDestroy() {
    if (this.s1 != null) {
      this.s1.unsubscribe();
    }
    if (this.s2 != null) {
      this.s2.unsubscribe();
    }
    if (this.s3 != null) {
      this.s3.unsubscribe();
    }
  }

  toggleEditMode() {
    if (this.editMode == false) {
      this.editMode = true;
      this.formService.enableForm();
     } else if (this.editMode == true) {
       this.editMode = false;
       this.formService.disableForm();
    }
  }

  save() {

    if(!this.validateEmail(this.formService.form.controls.email.value)){
      this.toastService.show({
        text: `Primary email is not valid.`,
        type: 'alert',
      });
    } else {

      if (this.formService.form.invalid) {

        this.toastService.show({
          text: `Please fill out all required fields before saving.`,
          type: 'alert',
        });

      } else {

        this.toggleEditMode();
        this.http.post<any>(environment.service.trackithubapi + '/api/inside/updateprofile', {
          raw: this.formService.form.value,
          addressobj: this.addressObj,
          addressaltobj: this.addressAltObj,
          profile_id: this.profileData.profile_id,
          profile_type: this.profileData.profile_type
        }).subscribe(() => {
          this.accountService.load();
          this.toastService.show({
            text: `Profile Settings have been saved.`,
            type: 'success',
          });
        });

      }
    }
  }

  cancel() {
    this.toggleEditMode();
    this.toastService.show({
      text: `Profile settings have been reset.`,
      type: 'warn',
    });
    this.formService.initializeFormGroup(this.rawData);
  }

  changeAvatar() {
    this.modalService.openDialog(ChangeAvatarComponent, 'auto', 'auto', {id: this.profileData.profile_id, is_account: this.profileData.is_account, image: null, profile_type: 'profile'});
  }

  open() {
    this.picker.open();
  }

  deleteProfile() {
    this.modalService.openDialog(DeleteProfileComponent, 'auto', 'auto', {id: this.profileData.profile_id, is_account: this.profileData.is_account, count: this.account.profiles.length});
  }

  private getPlaceAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(this.address.nativeElement,
      {
        componentRestrictions: { country: ['US', 'UK', 'CA', 'MX']},
        types: ['address']
      });
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();
      this.invokeEvent(place);
      this.addressObj = place;
    });

    const autocompletealt = new google.maps.places.Autocomplete(this.altaddress.nativeElement,
      {
        componentRestrictions: { country: ['US', 'UK', 'CA', 'MX']},
        types: ['address']
      });
    google.maps.event.addListener(autocompletealt, 'place_changed', () => {
      const place = autocompletealt.getPlace();
      this.invokeEvent(place);
      this.addressAltObj = place;
    });
  }

  invokeEvent(place: Object) {
    this.setAddress.emit(place);
  }

  noEnter(e) {
    const key = e.key ;
    return key !== 13;
  }

}
