import {Component, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';

import {Field} from '../../models/field.interface';
import {FieldConfig} from '../../models/field-config.interface';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export interface Countries {
    id;
    name;
    iso3;
    iso2;
    country_code;
    phone_code;
    capital;
    currency;
  }

@Component({
    selector: 'app-form-countryt',
    styleUrls: ['form-country.component.scss'],
    template: `
        <mat-form-field color="{{config.color}}" [formGroup]="group">
            <mat-select [formControlName]="config.name" placeholder="Country">
                <mat-option value="">Country</mat-option>
                <mat-option *ngFor="let item of countrylist" [value]="item.id">
                    {{ item.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>`
})
export class FormCountryComponent implements Field {

    public countrylist;

    config: FieldConfig;
    group: FormGroup;

    constructor( private http: HttpClient) {
        this.getCountry().subscribe(data => {
            this.countrylist = data;
        });
    }
    getCountry(): Observable<Countries[]> {
        return this.http.get<Countries[]>('/assets/angular-data/countries.json');
    }

}
