import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

export interface PanelMessage {
  text?: string;
  href?: string;
  buttonText?: string;
}

@Component({
  selector: 'app-dashboard-join-marquee',
  templateUrl: './dashboard-join-marquee.component.html',
  styleUrls: ['./dashboard-join-marquee.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class DashboardJoinMarqueeComponent {
  @Input() message: PanelMessage;
}
