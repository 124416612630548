import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { SessionStorageService } from 'ngx-webstorage';


export interface AddressLocationInterface {
  address_lat: any;
  address_lng: any;
  address_street: any;
  address_city: any;
  address_state: any;
  address_country: any;
  address_zip: any;
}

@Injectable()

export class RetrioeveaddressService {

  private AddressLocation = new BehaviorSubject<AddressLocationInterface>({
    address_lat: null,
    address_lng: null,
    address_street: null,
    address_city: null,
    address_state: null,
    address_country: null,
    address_zip: null
  });

  cast = this.AddressLocation.asObservable();

  constructor(private http: HttpClient, private sessionSt: SessionStorageService ) {}

  initialize() {
    this.AddressLocation.next({
      address_lat: null,
      address_lng: null,
      address_street: null,
      address_city: null,
      address_state: null,
      address_country: null,
      address_zip: null
    });
  }

  update(lat, lng, street, city, state, country, zip) {
    this.AddressLocation.next({
      address_lat: lat,
      address_lng: lng,
      address_street: street,
      address_city: city,
      address_state: state,
      address_country: country,
      address_zip: zip
    });
  }

}
