import {AfterViewInit, ChangeDetectionStrategy, Component, Input, ViewChild} from '@angular/core';
import {Validators} from '@angular/forms';
import {FieldConfig} from '../../../dynamic-form/models/field-config.interface';
import {
  DynamicChangepassComponent,

} from '../../../dynamic-form/containers';
import {environment} from '../../../../environments/environment';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {ToastService} from '../../../common/toast';


@Component({
  selector: 'app-password-settings',
  templateUrl: './password-settings.component.html',
  styleUrls: ['./password-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class PasswordSettingsComponent implements AfterViewInit {

  randy = true;
  runonce = true;
  disable = true;
  public pwdPattern: any = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{8,25}$/;
  @ViewChild(DynamicChangepassComponent, {static: false}) vform: DynamicChangepassComponent;
  @Input() accountdata = null;

  formConfig: FieldConfig[] = [
    {
      type: 'password',
      name: 'password',
      placeholder: 'Current Password',
      validation: [Validators.required, Validators.pattern(this.pwdPattern)],
      required: true,
    },
    {
      type: 'password',
      name: 'npassword',
      placeholder: 'New Password',
      validation: [Validators.required, Validators.pattern(this.pwdPattern)],
      required: true,
    },
    {
      type: 'password',
      name: 'vpassword',
      placeholder: 'Confirm New Password',
      validation: [Validators.required, Validators.pattern(this.pwdPattern)],
      required: true,
    },
    {
      label: 'Update Password',
      name: 'updatePassword',
      type: 'button',
      color: 'primary',
      disabled: true,
    }
  ];

  constructor(private router: Router, private http: HttpClient, private toastService: ToastService) {
  }

  ngAfterViewInit() {
    this.vform.vform.valueChanges.subscribe(data => {
      if (this.vform.vform.invalid === false && data.npassword === data.vpassword) {
        this.disable = false;
      } else {
        this.disable = true;
      }

      this.vform.setDisabled('updatePassword', this.disable);

      this.vform.submit.subscribe(() => {
        if (this.runonce === true){
          this.disable = true;
          this.vform.setDisabled('updatePassword', this.disable);
          this.runonce = false;

          this.http.get<any>(environment.service.trackithubapi + '/api/inside/setpassword?password=' + this.vform.vform.controls.password.value + '&npassword=' + this.vform.vform.controls.npassword.value).subscribe((datax: any) => {


            if (datax.result === true) {
              this.toastService.show({
                text: `Password has been updated.`,
                type: 'success',
              });

              this.router.navigate(['/dashboard/overview'], {});

            } else {
              this.toastService.show({
                text: `Your account password could not be verified`,
                type: 'warn',
              });

              this.router.navigate(['/dashboard/overview'], {});

            }
          });


        }
      });
    });
  }

}
