import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import { MatBottomSheet } from '@angular/material';
import { AccountService } from '../../common/services/account.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Router} from '@angular/router';
import {Observable} from "rxjs";
import {DashboardNewsComponent} from "../dashboard-news/dashboard-news.component";
import {BottomSheetService} from "../../common/services/bottom-sheet.service";
import {ToastService} from "../../common/toast";
import {ChatService} from "../../common/services/chat.service";
import {SessionStorageService} from "ngx-webstorage";
import {SearchAddressService} from "../../common/services/searchaddress.service";
import {DashboardFriendsComponent} from "../dashboard-friends/dashboard-friends.component";
import {
  DashboardGlobalNotificationsComponent
} from "../dashboard-globalnotifications/dashboard-globalnotifications.component";
import {DashboardFriendrequestsComponent} from "../dashboard-friendrequests/dashboard-friendrequests.component";

export interface navinterface {
  friends: string;
  messages: string;
  notifications: string;
}

@Component({
  selector: 'app-dashboard-mobile-footer-nav',
  templateUrl: './dashboard-mobile-footer-nav.component.html',
  styleUrls: ['./dashboard-mobile-footer-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})

export class DashboardMobileFooterNavComponent implements OnInit, OnDestroy {

  global: number;
  friend: number;
  message: number;

  account: any;
  s1 = null;
  sub = null;
  env: any;

  constructor(
    private bottomSheet: MatBottomSheet, private accountService: AccountService, private http: HttpClient, public router: Router, public bottomSheetService: BottomSheetService,
    private toastService: ToastService,
    private chatService: ChatService,

    private sessionSt: SessionStorageService,
    private SearchAddress: SearchAddressService

    ) {

    this.env = environment;

  }

  ngOnInit() {
    this.s1 = this.accountService.cast.subscribe(data => {
      this.account = data;

      if(this.account.current_profile.profile_id !== null) {

        this.http.get<navinterface>(environment.service.trackithubapi + '/api/inside/notifications?current_profile_id=' + this.account.current_profile.profile_id).subscribe((dataz: any) => {
          this.global = dataz.notifications;
          this.friend = dataz.friends;
          this.message = dataz.messages;
        });

        this.sub = Observable.interval(60000)
          .subscribe((val) => {
            this.http.get<navinterface>(environment.service.trackithubapi + '/api/inside/notifications?current_profile_id=' + this.account.current_profile.profile_id).subscribe((dataz: any) => {
              this.global = dataz.notifications;
              this.friend = dataz.friends;
              this.message = dataz.messages;
            });
          });
      }
    });
  }

  ngOnDestroy() {
    if (this.s1 != null) {
      this.s1.complete();
    }

    this.sub.unsubscribe();

  }


  gotoChat(){
    this.http.get<any>(environment.service.trackithubapi + '/api/inside/lastchatcheck?from_id=' + this.account.current_profile.profile_id).subscribe((data: any) => {
      if(data.result == 'fail') {
        this.toastService.show({
          text: 'You have not chatted with anyone yet.',
          type: 'warn',
          buttonText: '',
        });
      } else {

        this.chatService.openchatconvo({
          conversationID: data.convo_id,
          profile_id: data.profile_id,
          name: data.name,
          avatarImg: data.avatarImg,
          avatarPlaceholder: data.avatarPlaceholder,
          email: data.email,

        });
      }
    });
  }

  gotofriends(){
    this.bottomSheetService.openBottomSheetWithDataCustomClass(DashboardFriendrequestsComponent,'DashboardFriendrequestsComponent',{}, 'custom-bottom-x' )
  }

  gotoglobal(){
    this.bottomSheetService.openBottomSheetWithDataCustomClass(DashboardGlobalNotificationsComponent, 'DashboardGlobalNotificationsComponent',{}, 'custom-bottom-x' )
  }

  gotonews(){
    this.bottomSheetService.openBottomSheetWithDataCustomClass(DashboardNewsComponent, 'DashboardNewsComponent',{}, 'custom-bottom-x' );
  }

  findpeople(){
    this.bottomSheetService.openBottomSheetWithDataCustomClass(DashboardFriendsComponent, 'DashboardFriendsComponent',{}, 'custom-bottom-x' );
  }

  findclubs(){
    this.sessionSt.store('play_type', 'Clubs');
    this.SearchAddress.forcesearch();
    this.router.navigate(['/dashboard/map'], {queryParams: {}});
  }

  findcourts(){
    this.sessionSt.store('play_type', 'Public');
    this.SearchAddress.forcesearch();
    this.router.navigate(['/dashboard/map'], {queryParams: {}});
  }

}

