import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/common/services/account.service';
import { ToastService } from 'src/app/common/toast';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-delete-profile',
  templateUrl: './delete-profile.component.html',
  styleUrls: ['./delete-profile.component.scss']
})
export class DeleteProfileComponent implements OnInit, AfterViewInit {

  hide = true;
  disable = true;

  public pwdPattern: any = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{8,25}$/;

  params: any;

  passwordForm: FormGroup = new FormGroup({
    password: new FormControl('', [Validators.required,
      Validators.minLength(8), Validators.pattern(this.pwdPattern)] ),
  });



  validation_messages = {
    'password': [
      { type: 'required', message: 'Password is required' },
      { type: 'minlength', message: 'Password must be at least 8 characters long' },
      { type: 'pattern', message: 'Your password must contain at least one uppercase, one lowercase, and one number' }
    ]
  }

  constructor(private router: Router, public dialogRef: MatDialogRef<DeleteProfileComponent>, private fb: FormBuilder, private http: HttpClient, private toastService: ToastService, private accountService: AccountService, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.params = data;

  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.passwordForm.valueChanges.subscribe(data => {
      this.disable = this.passwordForm.invalid;
    });
  }

  deleteprofile(){
    this.http.get<any>(environment.service.trackithubapi + '/api/inside/deleteprofile?password=' + this.passwordForm.controls.password.value + '&profile_id=' + this.params.id).subscribe((data: any) => {
      if (data.result === true) {
        this.toastService.show({
          text: `Profile has been deleted.`,
          type: 'success',
        });
        this.dialogRef.close();
        this.accountService.load();
        this.router.navigate(['/dashboard/overview'], {});
      } else {
        this.toastService.show({
          text: `Your account password could not be verified`,
          type: 'warn',
        });
      }
    });
  }

  get password() {
    return this.passwordForm.get('password');
 }

  cancel() {
    this.dialogRef.close();
  }

}
