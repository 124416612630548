import {
  Component,
  ViewChild,
  AfterViewInit,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Inject,
  OnDestroy
} from '@angular/core';
import { Validators } from '@angular/forms';
import { FieldConfig } from '../dynamic-form/models/field-config.interface';
import { DynamicFormloginComponent, DynamicFormcontactComponent, DynamicFormRateComponent } from '../dynamic-form/containers';
import { RecordsService } from '../common/services/records.service';
import { MatBottomSheet, MatDialog } from '@angular/material';
import { TermsDialogComponent } from '../terms-dialog/terms-dialog.component';
import { HttpClient } from '@angular/common/http';
import { PickelballRatingComponent } from '../pickelball-rating/pickelball-rating.component';
import { ToastService } from '../common/toast';
import { RetrioeveaddressService } from '../common/services/retrioeveaddress.service';
import { ActivatedRoute, Router } from '@angular/router';
import {MAT_BOTTOM_SHEET_DATA} from '@angular/material';
import {AccountService} from '../common/services/account.service';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default
})

export class RegisterComponent implements AfterViewInit, OnDestroy {
  public addressobj = null;
  public lasterror = null;
  public errormessage = null;
  public stepLabel = ['Login Info', 'Contact Info', 'Skill Rating'];
  public pwdPattern: any = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{8,25}$/;
  public records = {};
  public message = '';
  public _loading = false;
  public loaderMessage = 'Loading...';
  public loginFormValid = true;
  public contactFormValid = true;
  public rateFormValid = true;
  public finalValid = true;
  public loginerror_valid = false;
  public loginerror;
  public stepOneFlag = true;
  public stepTwoFlag = true;

  account_id: null;
  sheetRef: null;
  s1 = null;

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private getaddress: RetrioeveaddressService,
    private http: HttpClient,
    private validationService: RecordsService,
    private LoginService: RecordsService,
    public dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
    private toastService: ToastService,
    private route: ActivatedRoute,
    public _router: Router,
    private accountService: AccountService
  ) {


    this.sheetRef = this.data.ref;
    if (this.data.id !== undefined) {
      this.account_id = this.data.id.account_id;
    }

  }

  loginInfo: FieldConfig[] = [
    {
      type: 'input',
      label: 'Enter Email',
      name: 'email',
      placeholder: 'Enter Email',
      validation: [Validators.required, Validators.email],
      required: true,
      email: true
    },
    {
      type: 'password',
      label: 'Password must be at least 8 characters, including 1 uppercase letter, 1 lowercase letter and 1 number',
      name: 'password',
      placeholder: 'Password',
      validation: [Validators.required, Validators.pattern(this.pwdPattern)],
      required: true
    },
    {
      type: 'password',
      label: 'Confirm Password',
      name: 'password_confirm',
      placeholder: 'Confirm Password',
      validation: [Validators.required, Validators.pattern(this.pwdPattern)],
      required: true
    },
  ];

  contactInfo: FieldConfig[] = [
    {
      type: 'input',
      label: 'First Name',
      name: 'firstname',
      placeholder: 'First Name',
      validation: [Validators.required, Validators.minLength(2)],
      required: true
    },
    {
      type: 'input',
      label: 'Last Name',
      name: 'lastname',
      placeholder: 'Last Name',
      validation: [Validators.required, Validators.minLength(2)],
      required: true
    },
    {
      type: 'radio',
      label: '',
      name: 'gender',
      placeholder: 'Gender',
      options: ['Male', 'Female','Non-binary', 'Prefer not to say'],
      required: false,
    },
    {
      type: 'input',
      label: 'Ex: 05/26/1960',
      name: 'birthyear',
      mask: '00/00/0000',
      placeholder: 'Date of Birth',
      validation: [Validators.required, Validators.maxLength(8)],
      required: false,
    },
    {
      type: 'input',
      label: '(555) 555-5555',
      mask: '(000) 000-0000',
      name: 'phonenumber',
      placeholder: 'Phone Number',
      validation: [Validators.required],
      required: true
    },
    {
      type: 'regaddress',
      label: 'Address',
      placeholder: 'Address',
      name: 'addresstext',
      color: 'primary',
      validation: [Validators.required],
      required: true
    },
    {
      type: 'input',
      label: 'Unit, apartment, or lot number',
      name: 'lot',
      placeholder: 'Lot #',
      validation: [],
      required: false
    },
    {
      type: 'selectavatar',
      label: 'Pick a Avatar',
      name: 'avataricon',
      color: 'primary',
    },
    {
      type: 'file',
      label: 'Upload Avatar',
      name: 'avatarupload',
      color: 'primary',
    },
    {
      type: 'checkbox',
      label: 'Accept Terms and Conditions',
      name: 'termsandconditions',
      value: '',
      validation: [Validators.required, Validators.requiredTrue],
      required: true

    },
  ];

  rateForm: FieldConfig[] = [
    {
      type: 'select',
      label: 'Pickleball Skill Rating',
      name: 'pickleballrating',
      options: ['No Rating', '1.0', '1.5', '2.0', '2.5', '3.0', '3.5', '4.0', '4.5', '5.0'],
      placeholder: 'Select an option',
      validation: [Validators.required],
      required: true
    },
  ];

  @ViewChild(DynamicFormloginComponent, {static: false}) lform: DynamicFormloginComponent;
  @ViewChild(DynamicFormcontactComponent, {static: false}) cform: DynamicFormcontactComponent;
  @ViewChild(DynamicFormRateComponent, {static: false}) rform: DynamicFormRateComponent;

  openBottomSheet() {
    const dialogRef = this.dialog.open(PickelballRatingComponent, {
      height: '450px',
      data: {'is_blue': false}
    });
  }

  finishReg($event: any) {
    this._loading = true;
    this.finalValid = true;

    window.scroll(0, 0);

    interface sModal {
      type: string;
      email: string;
      password: string;
      firstname: string;
      lastname: string;
      phonenumber: string;
      gender: string;
      birthyear: string;
      lat: any;
      long: any;
      street: string;
      country: string;
      state: string;
      city: string;
      zipcode: string;
      termsandconditions: string;
      rating: string;
      avataricon: string;
      avatarupload: string;
      account_id: number;
      lot: string;
    }

    const storage = <sModal>{};
    storage.type = 'player';
    storage.email = this.lform.lform.controls.email.value;
    storage.password = this.lform.lform.controls.password.value;
    storage.firstname = this.cform.cform.controls.firstname.value;
    storage.lastname = this.cform.cform.controls.lastname.value;
    storage.gender = this.cform.cform.controls.gender.value;
    storage.birthyear = this.cform.cform.controls.birthyear.value;
    storage.phonenumber = this.cform.cform.controls.phonenumber.value;
    storage.street = this.addressobj.address_street;
    storage.country = this.addressobj.address_country;
    storage.lat = this.addressobj.address_lat;
    storage.long = this.addressobj.address_lng;
    storage.state = this.addressobj.address_state;
    storage.city = this.addressobj.address_city;
    storage.lot = this.cform.cform.controls.lot.value;
    storage.zipcode = this.addressobj.address_zip;
    storage.termsandconditions = this.cform.cform.controls.termsandconditions.value;
    storage.rating = this.rform.rform.controls.pickleballrating.value;
    storage.avataricon = this.cform.cform.controls.avataricon.value;
    storage.avatarupload = this.cform.cform.controls.avatarupload.value;
    storage.account_id = this.account_id;

    if (storage.gender === 'Male') {
      storage.gender = 'COM_COMMUNITY_MALE';
    } else {
      storage.gender = 'COM_COMMUNITY_FEMALE';
    }

    this.records = this.LoginService.createccount(storage).subscribe(data => {
        this.records = data;
        if (this.records['result'] === true) {
            setTimeout(() => {
              localStorage.setItem('tour', 'true');
              if ( this.account_id !== undefined) {
                this.accountService.load();
                this.accountService.pullprofile(this.records['profile_id']);
                // @ts-ignore
                this.sheetRef.dismiss();
              }
              this._router.navigate(['/dashboard/overview'], {});
            }, 3000);
        } else {
          this._loading = false;
          this.finalValid = true;
          this.errormessage = 'System error, please try again.';
          if (this.lasterror !== this.errormessage) {
            this.toastService.show({
              text: this.errormessage,
              type: 'warn',
              buttonText: '',
            });
            this.lasterror = this.errormessage;
          }
        }
      });



  }

  ngAfterViewInit() {


    this.s1 = this.getaddress.cast.subscribe(data => {
      this.addressobj = data;
      if(data.address_lat === null && data.address_lng === null){
        this.cform.cform.controls.addresstext.setValue(null);
        //this.cform.cform.controls.addresstext.setErrors({
        //  empty: true
        //});
      }

    });



    if (this.lform !== undefined) {

      if (this.lform.lform.controls.email.valueChanges !== undefined) {
        this.lform.lform.controls.email.valueChanges.subscribe(data => {

          this.loginerror_valid = true;
          this.loginerror = '';

          if (this.lform.lform.controls.email.invalid === false) {
            this.validationService.checkEmail(data).subscribe(ret => {
              if (this.lform.lform.controls.email.invalid === false) {
                if (ret.found === 1) {

                  this.lform.lform.controls.email.setErrors({
                    notUnique: true
                  });

                  this.loginerror_valid = true;
                  this.errormessage = 'The email address entered is currently associated with an existing TrackitHub account. Log into your account or use a different email address to create your new TrackitHub account.';
                  this.toastService.show({
                    text: this.errormessage,
                    type: 'warn',
                    buttonText: '',
                  });

                  this.lasterror = this.errormessage;

                }
              }
            });
          }
        });
      }

      /** Password  */
      if (this.lform.lform.controls.password !== undefined) {
        this.lform.lform.controls.password.valueChanges.subscribe(data => {

          this.lform.lform.controls.password_confirm.setValue(null);

          if (data === '') {
            this.loginerror_valid = true;
            this.loginerror = '';
            this.checkformvalid();
          } else {
            if (this.pwdPattern.test(data) === false) {
              this.lform.lform.controls.password.setErrors({
                empty: true

              });

              this.loginerror_valid = true;

              this.errormessage = 'The password entered does not meet the required criteria.';

              if (this.errormessage !== this.lasterror) {
                //this.toastService.show({
                //  text: this.errormessage,
                //  type: 'warn',
                //  buttonText: '',
                //});
                this.lasterror = this.errormessage;
              }

            } else {
              this.loginerror_valid = true;
              this.loginerror = '';
            }
            this.checkformvalid();
          }
        });
      }

      /** Password confirm */
      if (this.lform.lform.controls.password_confirm !== undefined) {
        this.lform.lform.controls.password_confirm.valueChanges.subscribe(data => {


          if (data === '') {
            this.loginerror_valid = false;
            this.loginerror = '';
          } else {

            if (data !== this.lform.lform.controls.password.value) {
              this.lform.lform.controls.password_confirm.setErrors({
                empty: true
              });

              this.loginerror_valid = true;

              if (this.lform.lform.controls.password_confirm.value !== null) {
                this.errormessage = 'The confirm password does not match.';

                if (this.errormessage !== this.lasterror) {
                  //this.toastService.show({
                  //  text: this.errormessage,
                  //  type: 'warn',
                  //  buttonText: '',
                  //});
                  this.lasterror = this.errormessage;
                }
              }

            } else {
              this.loginerror_valid = false;
              this.loginerror = '';
            }
          }
        });
      }

    }


    if (this.cform !== undefined) {
      if (this.cform.cform.controls.phonenumber !== undefined) {
        this.cform.cform.controls.phonenumber.valueChanges.subscribe(data => {
          if (data.length !== 10) {
            this.cform.cform.controls.phonenumber.setErrors({
              empty: true
            });
          }
        });
      }
    }

    if (this.rform !== undefined) {
      if (this.rform !== undefined) {
        this.rform.rform.valueChanges.subscribe(data => {
          this.rateFormValid = !this.rform.rform.valid;
          this.checkformvalid();
        });
      }
    }

    if (this.cform !== undefined) {
      this.cform.cform.valueChanges.subscribe(data => {
        this.contactFormValid = !this.cform.cform.valid;
        this.checkformvalid();
      });
    }

    if (this.lform !== undefined) {
      this.lform.lform.valueChanges.subscribe(data => {
        this.loginFormValid = !this.lform.lform.valid;
        this.checkformvalid();
      });
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(TermsDialogComponent, {
      data: {
        myVar: 'myVar',
      }
    });
    this.cform.cform.controls.termsandconditions.setValue(true);
  }

  checkformvalid(): void {
    if (this.lform.lform.valid === true) {
      this.stepOneFlag = false;
    } else {
      this.stepOneFlag = true;
    }
    if (this.cform.cform.valid === true) {
      this.stepTwoFlag = false;
    } else {
      this.stepTwoFlag = true;
    }
    if (this.lform.lform.valid === true && this.cform.cform.valid === true && this.rform.rform.valid === true) {
      this.finalValid = false;
    } else {
      this.finalValid = true;
    }
  }

  ngOnDestroy() {
    if (this.s1 != null) {
      this.s1.complete();
    }
  }


}


