import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-dashboard-action-center',
  templateUrl: './dashboard-action-center.component.html',
  styleUrls: ['./dashboard-action-center.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class DashboardActionCenterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
