import {ChangeDetectionStrategy, Component} from '@angular/core';
import {Router} from '@angular/router';
export interface Terms {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class PrivacyPolicyComponent {
  constructor(public router: Router) { }
  selectedValue: string;
  title: string[] = ['Privacy Policy', 'Terms of Use', 'Disclaimer', 'Change Password'];
  terms: Terms[] = [
    { value: '/privacy', viewValue: 'Privacy Policy'},
    { value: '/terms', viewValue: 'Terms of Use'},
    { value: '/disclaimer', viewValue: 'Disclaimer'},
  ];
}
