import {Component, Input, ChangeDetectionStrategy} from '@angular/core';
import {CardRenewal} from '../../../class/card-renewal';
import {AccountService} from '../../../services/account.service';
import {environment} from '../../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import { ToastService } from '../../../toast';

@Component({
  selector: 'app-group-renewals',
  templateUrl: './group-renewals.component.html',
  styleUrls: ['./group-renewals.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})

export class GroupRenewalsComponent {
  @Input() renewal: CardRenewal;
  @Input() profile_id: any;

  show(renewal) {
      this.http.get<any>(environment.service.trackithubapi + '/api/inside/ignorerenewal?group_id=' + renewal.group_id + '&player_id=' + renewal.user_id + '&current_id=' + renewal.current_id).subscribe((data: any) => {
        this.accountService.reloadprofile();
        this.toastService.show({
          text: 'Renewal has been hidden.',
          type: 'success',
          buttonText: '',
        });
      });
  }

  clearemail(renewal) {
    this.http.get<any>(environment.service.trackithubapi + '/api/inside/ignoreemail?email_id=' + renewal.email_id + '&profile_id=' + this.profile_id).subscribe((data: any) => {
      this.accountService.reloadprofile();
      this.toastService.show({
        text: 'Email has been hidden.',
        type: 'success',
        buttonText: '',
      });
    });
  }


  constructor(private accountService: AccountService, private http: HttpClient, private toastService: ToastService) { }

}
