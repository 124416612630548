import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {SnackbarService} from '../../common/services/snackbar.service';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {environment} from "../../../environments/environment";


@Component({
  selector: 'app-share',
  templateUrl: './dashboard-share.component.html',
  styleUrls: ['./dashboard-share.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})

export class DashboardShareComponent {
  title = '';
  url = '';
  siteurl = environment.service.trackithubapi;

    readonly separatorKeysCodes: number[] = [ENTER, COMMA];


  constructor(
    private snackBar: SnackbarService,
    public dialogRef: MatDialogRef<DashboardShareComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      title: string;
      url: string
    },
  ) {
    this.title = data.title;
    this.url = data.url;
  }


  copyInputMessage(inputElement) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
    this.snackBar.success('Link Copied to Clipboard', '');
    this.dialogRef.close();
  }

}
