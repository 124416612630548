import {
  Component,
  OnInit,
  ViewChild,
  ViewContainerRef,
  Input, ChangeDetectionStrategy
} from '@angular/core';

import {DynamicComponentService} from '../../services/dynamic-component.service';

@Component({
    selector: 'app-dashboard-accordion',
    templateUrl: './dashboard-accordion.component.html',
    styleUrls: ['./dashboard-accordion.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default
})
export class DashboardAccordionComponent implements OnInit {
    @ViewChild('dynamic', {static: false, read: ViewContainerRef}) view: ViewContainerRef;

    constructor(private service: DynamicComponentService) {}

    @Input() title?: string;
    icon: boolean;
    collapsedHeight: '80px';

    ngOnInit() {
        this.service.setRootViewContainerRef(this.view);
        this.service.insertPPLHeaderComponent();
    }

    click() {
        this.icon = false;
    }
}
