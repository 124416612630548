import {Component, HostListener, OnInit } from '@angular/core';

@Component({
    selector: 'app-intro',
    templateUrl: './intro.component.html',
    styleUrls: ['./intro.component.scss']
})
export class IntroComponent implements OnInit {
    public currentWindowWidth: number;
    title = 'Premier Pickleball Leagues';
    description = 'The Premier Pickleball League (PPL), powered by TrackitHub’s comprehensive program, provides organization and structure which is required for any league in any sport to be successful. TrackitHub’s mission is to provide first-class pickleball leagues for all skill levels while focusing on the social and fun elements of the game. This is done by guaranteeing a specific number of games each week, eliminating the intimidation factor, and delayed matches that players experience at tournaments. TrackitHub offers two packages that each League Coordinator can choose from; The <b>PRO (All-Inclusive)</b> or Basic package. Premier Pickleball Leagues provide pickleball players from all around the world with an opportunity to meet new people, compete against friends, and play the game they love in an organized fashion. If you love pickleball, then signing up for a Premier Pickleball League is a must! The future of pickleball is here! If you are interested in playing in a Premier Pickleball League, Create a Player Account.  If you are interested in running your own Premier Pickleball League, Create a Premier Pickleball League Account.';
    image = 'assets/angular-img/logos/premier-pickleball-league.svg';

    constructor() {
    }

    @HostListener('window:resize')
    onResize() {
        this.currentWindowWidth = window.innerWidth;
    }

    ngOnInit() {
        this.currentWindowWidth = window.innerWidth;

    }

}
